import React from "react";

function NoDocumentFound(props: any) {
  return (
    <div className="d-flex align-items-center justify-content-center p-4 mt-4">
      <div>
        <img src="/images/nodata.svg" className="img-fluid upload_image" />
        <p className="small_text__16 mt-2 text-center">No Documents Found</p>
      </div>
    </div>
  );
}

export default NoDocumentFound;
