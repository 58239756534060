import { QueryRequestHelper } from "@common/query-request-helper";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function SortPage(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  const [sort_by, setSortBy] = useState<any>({
    field: "",
    value: "asc",
  });

  const sortOptions = [
    { value: "asc", label: "Asc" },
    { value: "desc", label: "Desc" },
  ];

  useEffect(() => {
    let parsed = getValue(localStorage, `s:${props.module}`, "")
      ? JSON.parse(getValue(localStorage, `s:${props.module}`, ""))
      : [];
    if (getValue(parsed, `field`, "")) {
      if (getValue(localStorage, `s:${props.module}`, "")) {
        let sort = JSON.parse(getValue(localStorage, `s:${props.module}`, ""));
        setSortBy(sort);
      } else {
        setSortBy({ field: "", value: "asc" });
      }
    } else {
      localStorage.removeItem(`s:${props.module}`);
      setSortBy({ field: "", value: "asc" });
    }
  }, [getValue(localStorage, `s:${props.module}`, "")]);
  const navigate = useNavigate();

  /* -------------------------------------------------------------------------- */
  /*                                 Helper Section                             */
  /* -------------------------------------------------------------------------- */
  const handleSelectField = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const UrlParams = Object.fromEntries(urlSearchParams.entries());
    localStorage.setItem(
      `s:${getValue(props, `module`, "")}`,
      JSON.stringify({
        field: value,
        value: getValue(sort_by, `value`, ""),
      })
    );
    let payload = {
      ...UrlParams,
      page_no: 1,
      smart_filter: true,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleSelectValue = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const UrlParams = Object.fromEntries(urlSearchParams.entries());
    localStorage.setItem(
      `s:${getValue(props, `module`, "")}`,
      JSON.stringify({
        field: getValue(sort_by, `field`, ""),
        value: value,
      })
    );
    let payload = {
      ...UrlParams,
      page_no: 1,
      smart_filter: true,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  return (
    <div className="d-flex align-items-center">
      <p className="small_text__16 mx-2">Sort by</p>
      <TextHeaderTooltip
        title={`Fields`}
        value={getValue(sort_by, `field`, "")}
        label={`label`}
        onChange={(e: object) => {
          handleSelectField(getValue(e, `value`, ""));
          setSortBy({
            ...sort_by,
            field: getValue(e, `value`, ""),
          });
        }}
        data={
          getValue(props, "fields.length", 0) > 0
            ? getValue(props, "fields", [])
                .filter((cus: object) => !getValue(cus, "custom", false))
                .map((item: object) => ({
                  label: getValue(item, "label", ""),
                  value: getValue(item, "api_name", ""),
                }))
            : []
        }
        selectKey={"value"}
        editInline
        showActiveInline
      />

      <TextHeaderTooltip
        title={`Type`}
        value={getValue(sort_by, `value`, "")}
        label={`label`}
        onChange={(e: object) => {
          handleSelectValue(getValue(e, `value`, ""));
          setSortBy({
            ...sort_by,
            value: getValue(e, `value`, ""),
          });
        }}
        data={sortOptions}
        selectKey={"value"}
        editInline
        // showActiveInline
      />
      <p className="color_primary">|</p>
    </div>
  );
}

export default SortPage;
