import { getValue } from "@utils/lodash";
import React from "react";
import TaskListPopup from "./tag-list";

function TagsDetailList(props: any) {
  const { tags } = props;
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-wrap  tag_task_container ms-2 align-items-center">
        {tags.map((item: any, index: number) => {
          if (index < 3)
            return (
              <div
                className="d-flex align-items-center hexagon position-relative justify-content-center"
                style={{
                  background: getValue(item, `color_code`, ""),
                }}
                key={index}
              >
                <p className="small_text__13">{getValue(item, `label`, "")}</p>
              </div>
            );
        })}
      </div>
      {getValue(tags, `length`, 0) > 3 && (
        <div
          className="d-flex align-items-center"
          // style={{ marginTop: "-15px" }}
        >
          <p className="header_text__14">and</p>
          <p className="mx-1">{getValue(tags, `length`, 0) - 3}</p>
          <TaskListPopup
            header={
              <p className="header_text__16 text-decoration-underline cursor-pointer">
                more
              </p>
            }
            tags={tags}
          />
        </div>
      )}
    </div>
  );
}

export default TagsDetailList;
