import { getValue } from "@utils/lodash";
import React, { ChangeEvent } from "react";
import "./form.scss";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxLength: number;
  placeholder: string;
  validator: any;
  label: string;
  mandatory: boolean;
  hideLabel: boolean;
  validatoryType: string;
  removeBottom: any;
  inline: boolean;
  onBlur:any
};

export default function Input({
  disabled,
  name,
  type,
  value,
  onChange,
  maxLength,
  placeholder,
  validator,
  label,
  mandatory,
  hideLabel,
  validatoryType,
  removeBottom,
  inline,
  onBlur
}: Props) {
  const checkNumber = (e: any, type: any) => {
    const regex = /^[0-9]+$/;

    if (type === "number" || type === "numerical") {
      if (e.target.value > 0) {
        return onChange(e);
      }
    } else {
      return onChange(e);
    }
  };
  return (
    <div
      className={
        inline
          ? ""
          : `signup-form-wrapper__form-group ${
              validator && validator.current.message(label, value, "required")
                ? "signup-form-wrapper__form-group--error"
                : ""
            } form-group ${removeBottom ? "" : "mb-3"}`
      }
    >
      {!hideLabel && (
        <label className={`form-label`}>
          {label}{" "}
          {/* {mandatory && <span className="color_danger small_text__16">*</span>} */}
        </label>
      )}
      {type === "number" ||
      type === "numerical" ||
      type === "decimal" ||
      type === "percentage" ? (
        <input
          // className={`custom-form-control form-control`}
          className={
            mandatory ? `custom-form-control__required` : `custom-form-control`
          }
          disabled={false || disabled}
          name={name}
          type={type}
          value={value}
          min={0}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          maxLength={maxLength}
          onBlur={onBlur}
        />
      ) : (
        <input
          // className={`custom-form-control form-control`}
          className={
            mandatory ? `custom-form-control__required` : `custom-form-control`
          }
          disabled={false || disabled}
          name={name}
          type={type}
          value={value}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          maxLength={maxLength}
          pattern="https://.+"
          required
          // title="Requires https://"
          style={{
            borderLeft: mandatory ? "2px solid red" : "",
          }}
          onBlur={onBlur}
        />
      )}

      {/* <p
        className={`error-text ${
          // getValue(props, `validLeft`, "") ? "" :
          "text-center"
        }`}
      >
        {validator &&
          validator.current.message(
            label,
            value,
            validatoryType ? validatoryType : "required"
          )}
      </p> */}
      {validator && (
        <p className={`error-text`}>
          {validator.current.message(
            label,
            value,
            validatoryType ? validatoryType : "required"
          )}
        </p>
      )}
    </div>
  );
}
