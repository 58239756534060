import UrlSvgComponent from "@assets/svg/custom/url";
import EditSvgComponent from "@assets/svg/edit";
import CallSvgComponent from "@assets/svg/phone";
import ReplySvgComponent from "@assets/svg/whatsapp-reply";
import { getValue } from "@utils/lodash";
import { Empty, Image, Tag, Tooltip } from "antd";
import { config } from "env";
import React from "react";

const BroadcastCarouselTab = ({ request, showModal }: any) => {
  return (
    <div className="d-flex gap-4">
      <div className="w-100">
        {getValue(request, "variableConfig.carousels.length", "") > 0 && (
          <div className="template_main_button_container position-relative bg-white">
            {getValue(request, "variableConfig.carousels", "").map(
              (item: any, index: any) => (
                <div
                  className={`py-2 px-3 d-flex align-items-center gap-2 ${
                    index !== 0 ? "border-top" : ""
                  }`}
                >
                  <div className="w-100">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p>Card {index + 1}</p>
                      <div className="d-flex align-items-center gap-2">
                        <Tooltip title="Edit">
                          <div
                            onClick={() => showModal(index)}
                            className="cursor-pointer"
                          >
                            <EditSvgComponent />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="d-flex grid-container gap-3 p-3 rounded-3 bg-white">
                      {getValue(
                        request,
                        `variableConfig.carousels[${0}].headerLinkDetail.mime_type`,
                        ""
                      ).includes("image") ? (
                        <Image
                          src={
                            getValue(item, `headerLinkDetail.key`, "")
                              ? `https://${config.URL}/${getValue(
                                  item,
                                  `headerLinkDetail.key`,
                                  ""
                                )}`
                              : getValue(item, `header.link`, "")
                              ? `https://${config.URL}/${getValue(
                                  item,
                                  `headerLinkDetail.key`,
                                  ""
                                )}`
                              : "/property/dummy-image.jpg"
                          }
                          width={200}
                          height={108}
                          style={{
                            width: "180px",
                            height: "108px",
                            border: "1px solid #efefef",
                            objectFit: "cover",
                          }}
                          className="rounded-2"
                        />
                      ) : (
                        <>
                          {getValue(item, "header.link.key", "") ||
                          getValue(item, "header.link", "") ? (
                            <video
                              autoPlay
                              className="carousal-video-preview w-100"
                            >
                              <source
                                src={
                                  getValue(item, `header.link.key`, "")
                                    ? `https://${config.URL}/${getValue(
                                        item,
                                        `headerLinkDetail.key`,
                                        ""
                                      )}`
                                    : `https://${config.URL}/${getValue(
                                        item,
                                        `headerLinkDetail.key`,
                                        ""
                                      )}`
                                }
                                type="video/mp4"
                              />
                            </video>
                          ) : (
                            <Empty
                              description="Video not found"
                              className="rounded-2 px-4 py-2"
                            />
                          )}
                        </>
                      )}

                      <div className="w-100">
                        <p className="small_text__14">
                          {getValue(item, "body", "")}
                        </p>

                        {/* <InputRuleForm
                          inputType="TEXTAREA"
                          value={getValue(item, "body", "")}
                          name="body"
                          placeholder={"Enter body here"}
                        /> */}
                        <div className="d-flex w-100 gap-1 flex-grow-1 mt-2 flex-wrap">
                          {item.buttons.map((button: any, buttonIndex: any) => {
                            const actionType = getValue(button, "type", "");
                            const buttonText = getValue(button, "value", "");
                            const buttonPhone = getValue(button, "value", "");
                            const buttonUrl = getValue(button, "value", "");

                            const isMissing =
                              (actionType === "URL" &&
                                (!buttonUrl || !buttonText)) ||
                              (actionType === "CALL_TO_ACTION" &&
                                (!buttonPhone ||
                                  buttonPhone.length <= 5 ||
                                  !buttonText)) ||
                              (actionType === "QUICK_REPLY" && !buttonText);

                            const color = isMissing ? "red" : "blue";

                            return actionType ? (
                              <Tag
                                key={buttonIndex}
                                color={color}
                                className="px-4 py-1 d-flex align-items-center gap-1"
                              >
                                {actionType === "QUICK_REPLY" ? (
                                  <ReplySvgComponent
                                    color={buttonText ? "#408dfb" : "#cf1322"}
                                    size={16}
                                  />
                                ) : actionType === "CALL_TO_ACTION" ? (
                                  <CallSvgComponent
                                    color={isMissing ? "#cf1322" : "#408dfb"}
                                    size={14}
                                  />
                                ) : actionType === "URL" ? (
                                  <UrlSvgComponent
                                    color={isMissing ? "#cf1322" : "#408dfb"}
                                    size={14}
                                  />
                                ) : null}

                                {isMissing ? "Missing" : buttonText}
                              </Tag>
                            ) : null;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BroadcastCarouselTab;
