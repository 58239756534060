// import CloseMainSvgComponent from "@assets/svg/close-main";
// import InfoSvgComponent from "@assets/svg/info";
// import ProgressBarLoader from "@components/common/Loader/progress-loader";
// import { getValue } from "@utils/lodash";
// import { Alert } from "antd";
// import React, { useCallback, useEffect, useMemo, useState } from "react";
// import { useDropzone } from "react-dropzone";
// import { Link } from "react-router-dom";
// import { toast } from "sonner";

// const baseStyle = {
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center",
//   padding: "10px",
//   borderWidth: 1,
//   borderRadius: "20px",
//   borderColor: "#90c6fe",
//   borderStyle: "dashed",
//   boxShadow: "0px 2px 10px 0px rgba(39,49,58,.2)",
//   backgroundColor: "#fff",
//   color: "#bdbdbd",
//   transition: "border .3s ease-in-out",
//   minHeight: "250px",
// };

// const activeStyle = {
//   borderColor: "#2196f3",
// };

// const acceptStyle = {
//   borderColor: "#adff2f",
// };

// const rejectStyle = {
//   borderColor: "red",
// };
// const hasDoubleExtension = (filename) => {
//   return /\.\w+\.\w+/.test(filename);
// };
// const hasInvalidFileName = (filename) => {
//   // Check for files without a filename (e.g., .htaccess) or specific configuration files (e.g., web.config)
//   return filename.startsWith(".") || filename.toLowerCase() === "web.config";
// };

// function DragAndDropFileComponent(props) {
//   const [files, setFiles] = useState([]);

//   const supportedFormats =
//     props.supportedFormats || ".csv,.xlsx; max file size 5 MB";

//   const onDrop = useCallback(
//     (acceptedFiles, rejectedFiles) => {
//       if (!props.isLoading) {
//         const filteredAcceptedFiles = acceptedFiles.filter(
//           (file) =>
//             fileTypes.includes(file.type) &&
//             !hasDoubleExtension(file.name) &&
//             !hasInvalidFileName(file.name)
//         );
//         const filteredRejectedFiles =
//           (rejectedFiles, `length`, 0) > 0
//             ? rejectedFiles
//             : acceptedFiles.filter(
//                 (file) =>
//                   !fileTypes.includes(file.type) ||
//                   hasInvalidFileName(file.name) ||
//                   hasDoubleExtension(file.name) ||
//                   file.size > 10 * 1024 * 1024
//               );

//         // Display error messages for rejected files
//         filteredRejectedFiles.forEach((rejectedFile) => {
//           if (!fileTypes.includes(rejectedFile.type)) {
//             toast.error(`File type not supported: ${rejectedFile.name}`);
//           } else if (hasDoubleExtension(rejectedFile.name)) {
//             toast.error(`File contains double extension: ${rejectedFile.name}`);
//           } else if (hasInvalidFileName(rejectedFile.name)) {
//             toast.error(`Invalid file name: ${rejectedFile.name}`);
//           } else {
//             toast.error(
//               `File exceeds the maximum size (10MB): ${rejectedFile.name}`
//             );
//           }
//         });

//         props.uploadFile(filteredAcceptedFiles);
//         setFiles(
//           filteredAcceptedFiles.map((file) =>
//             Object.assign(file, {
//               preview: URL.createObjectURL(file),
//             })
//           )
//         );
//       }
//     },
//     [props.uploadFile]
//   );

//   const fileTypes = [
//     "application/pdf", // PDF
//     "image/jpeg", // JPEG
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
//     "text/csv", // CSV
//     "application/vnd.ms-excel.sheet.macroEnabled.12", // XLSM
//     "application/vnd.ms-excel", // XLS
//     "image/jpeg", // JPG
//     "image/png", // PNG
//     "image/svg+xml", // SVG
//   ];
//   const {
//     getRootProps,
//     getInputProps,
//     isDragActive,
//     isDragAccept,
//     isDragReject,
//     acceptedFiles,
//     fileRejections,
//   } = useDropzone({
//     onDrop,
//     accept: fileTypes.join(","),
//     maxSize: 10 * 1024 * 1024, // 10MB in bytes
//   });

//   const style = useMemo(
//     () => ({
//       ...baseStyle,
//       ...(isDragActive ? activeStyle : {}),
//       ...(isDragAccept ? acceptStyle : {}),
//       ...(isDragReject ? rejectStyle : {}),
//     }),
//     [isDragActive, isDragReject, isDragAccept]
//   );

//   const thumbs = acceptedFiles.map((file) => (
//     <div key={file.name}>
//       <p>{file.name} </p>
//     </div>
//   ));

//   const fileRejectionItems = fileRejections.map(({ file, errors }) => {
//     return (
//       <li key={file.path}>
//         {file.path} - {file.size} bytes
//         <ul>
//           {errors.map((e) => (
//             <li key={e.code}>{e.message}</li>
//           ))}
//         </ul>
//       </li>
//     );
//   });
//   // clean up
//   useEffect(
//     () => () => {
//       files.forEach((file) => URL.revokeObjectURL(file.preview));
//     },
//     [files]
//   );
//   return (
//     <section style={{ cursor: "pointer" }} className="mt-3">
//       <div {...getRootProps({ style })} className="">
//         {!props.isLoading ? (
//           <>
//             <input {...getInputProps()} />
//             <img
//               src="/images/upload.jpeg"
//               className="img-fluid d-flex align-items-center justify-content-center"
//               height={"100px"}
//               width={"100px"}
//             />
//             <div className="custom-inputfile">
//               <div className="d-block mb-3">
//                 <p className="drag-drop-text text-secondary">
//                   Drag & Drop / Browse Files
//                 </p>
//               </div>
//               {getValue(props, `file`, "") !== "false" &&
//                 getValue(props, `fileName`, "") && (
//                   // <div
//                   //   className="d-flex mb-2 mt-3"
//                   //   style={{ justifyContent: "center" }}
//                   // >
//                   //   <p
//                   //     style={{
//                   //       fontSize: "12px",
//                   //       marginTop: "0px",
//                   //       textAlign: "center",
//                   //     }}
//                   //   >
//                   //     {getValue(props, `fileName`, "")}
//                   //   </p>
//                   //   <div
//                   //     onClick={() => props.setImportTedFile("")}
//                   //     style={{ marginLeft: "7px" }}
//                   //   >
//                   //     <CloseMainSvgComponent size={16} />
//                   //   </div>
//                   // </div>
//                   // <div
//                   <Alert
//                     message={getValue(props, `fileName`, "")}
//                     type="success"
//                     showIcon
//                     closable
//                     onClose={() => props.setImportTedFile("")}
//                   />
//                 )}
//             </div>
//             <div className="mt-2 mb-2">
//               <p className="helper-text mt-3">
//                 (Supported formats {supportedFormats})
//               </p>
//               {/* <p className="helper-text text-secondary mt-2 text-center">
//             Download a &nbsp;<Link to="#">sample CSV</Link>
//           </p> */}
//             </div>
//             <p style={{ color: "red" }}> {fileRejectionItems} </p>
//           </>
//         ) : (
//           <>
//             <ProgressBarLoader />
//             <p className="small_text__16 mt-4">
//               Uploading in progress, kindly wait.
//             </p>
//           </>
//         )}
//       </div>
//     </section>
//   );
// }

// export default DragAndDropFileComponent;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Alert } from "antd";
import { toast } from "sonner";
import { getValue } from "@utils/lodash";
import CloseMainSvgComponent from "@assets/svg/close-main";
import InfoSvgComponent from "@assets/svg/info";
import ProgressBarLoader from "@components/common/Loader/progress-loader";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px",
  borderWidth: 1,
  borderRadius: "20px",
  borderColor: "#90c6fe",
  borderStyle: "dashed",
  boxShadow: "0px 2px 10px 0px rgba(39,49,58,.2)",
  backgroundColor: "#fff",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
  minHeight: "250px",
};

const activeStyle = { borderColor: "#2196f3" };
const acceptStyle = { borderColor: "#adff2f" };
const rejectStyle = { borderColor: "red" };

const FILE_TYPES = {
  "application/pdf": [".pdf"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/svg+xml": [".svg"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "text/csv": [".csv"],
  "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
  "application/vnd.ms-excel": [".xls"],
  "video/mp4": [".mp4"], // Added MP4 support
};

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

function DragAndDropFileComponent(props) {
  const {
    uploadFile,
    isLoading,
    supportedFormats = ".csv,.xlsx; max file size 5 MB",
    setImportTedFile,
    file,
    fileName,
  } = props;
  const [files, setFiles] = useState([]);

  const validateFile = (file) => {
    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      return {
        valid: false,
        error: `File ${file.name} exceeds maximum size of 10MB`,
      };
    }

    // Check file extension
    const extension = `.${file.name.split(".").pop().toLowerCase()}`;
    const validExtensions = Object.values(FILE_TYPES).flat();
    if (!validExtensions.includes(extension)) {
      return {
        valid: false,
        error: `File type ${extension} is not supported`,
      };
    }

    // Check for double extensions
    if (file.name.split(".").length > 2) {
      return {
        valid: false,
        error: `File ${file.name} contains multiple extensions`,
      };
    }

    // Check for invalid filenames
    if (file.name.startsWith(".") || file.name.toLowerCase() === "web.config") {
      return {
        valid: false,
        error: `Invalid filename: ${file.name}`,
      };
    }

    return { valid: true };
  };

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (isLoading) return;

      const validFiles = [];
      const invalidFiles = [];

      acceptedFiles.forEach((file) => {
        const validation = validateFile(file);
        if (validation.valid) {
          validFiles.push(file);
        } else {
          invalidFiles.push({ file, error: validation.error });
        }
      });

      // Handle rejected files
      [...rejectedFiles, ...invalidFiles].forEach((rejection) => {
        const error = rejection.error || rejection.errors?.[0]?.message;
        toast.error(error || `Failed to upload ${rejection.file.name}`);
      });

      if (validFiles.length > 0) {
        uploadFile(validFiles);
        setFiles(
          validFiles.map((file) => ({
            ...file,
            preview: URL.createObjectURL(file),
          }))
        );
      }
    },
    [isLoading, uploadFile]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: FILE_TYPES,
    maxSize: MAX_FILE_SIZE,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="mt-3" style={{ cursor: "pointer" }}>
      <div {...getRootProps({ style })} className="">
        {!isLoading ? (
          <>
            <input {...getInputProps()} />
            <img
              src="/images/upload.jpeg"
              className="img-fluid d-flex align-items-center justify-content-center"
              height="100px"
              width="100px"
              alt="Upload"
            />
            <div className="custom-inputfile">
              <div className="d-block mb-3">
                <p className="drag-drop-text text-secondary">
                  Drag & Drop / Browse Files
                </p>
              </div>
              {getValue({ file, fileName }, "file", "") !== "false" &&
                fileName && (
                  <Alert
                    message={fileName}
                    type="success"
                    showIcon
                    closable
                    onClose={() => setImportTedFile("")}
                  />
                )}
            </div>
            <div className="mt-2 mb-2">
              <p className="helper-text mt-3">
                (Supported formats {supportedFormats})
              </p>
            </div>
          </>
        ) : (
          <>
            <ProgressBarLoader />
            <p className="small_text__16 mt-4">
              Uploading in progress, kindly wait.
            </p>
          </>
        )}
      </div>
    </section>
  );
}

export default DragAndDropFileComponent;
