import "./TaskCompanies.scss";
import { getValue } from "@utils/lodash";
import DescriptionInfo from "../common/DescInfo";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";

function TaskDeatilCompaniesList(props: any) {
  const { isRollsListPopupVisibile, setRollsListPopupVisisbility } = props;
  return (
    <SlideFromRightPopup
      isPopupVisible={isRollsListPopupVisibile}
      addCustomClass={"rolls-popup"}
      toggle={() => {
        setRollsListPopupVisisbility(false);
      }}
      title="Companies"
      width={"570px"}
    >
      <div className={"task_more-list"}>
        <ul>
          {getValue(props, `companies`, []).map(
            (item: object, index: number) => {
              return <DescriptionInfo index={index} item={item} />;
            }
          )}
        </ul>
      </div>
    </SlideFromRightPopup>
  );
}

export default TaskDeatilCompaniesList;
