import SettingUsers from "@pages/Private/Settings/Users";
import LoginHistory from "@pages/Private/Settings/Users/LoginHistory";

export const SettingsUsersRoutes = [
  {
    path: "/:orgId/settings/users",
    name: "settings_organisation_users",
    component: SettingUsers,
  },
  {
    path: "/:orgId/settings/users/login/:id",
    name: "settings_organisation_users_history",
    component: LoginHistory,
  },
];
