import DraftJSEditor from "@components/custom/DraftJSEditor";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { Button, Dropdown, MenuProps, Space, Tooltip } from "antd";
import AIStars from "@components/common/Loader/ai-stars";
import { aiRephraseMethods } from "@components/AI/AiRephraseMethods";

function NotesEditor(props: any) {
  const {
    isTextarea,
    setNotesRequest,
    notesRequest,
    textareaRef,
    setIsTextarea,
    setEditId,
    handleSubmitNotes,
    submitNotesLoading,
    editId,
    handleInputClick,
    handleChangeEditor,
    toggleAiModal,
    isRephraseLoading,
  } = props;
  const [types] = useState([
    { value: "EMAIL", label: "Email" },
    { value: "CALL", label: "Call" },
    { value: "TODO", label: "To-Do" },
  ]);

  const items: MenuProps["items"] = aiRephraseMethods.map(
    (method: any, index: any) => ({
      key: `${index + 1}`,
      label: method,
      onClick: () => toggleAiModal(method),
      disabled: isRephraseLoading === true,
    })
  );

  return (
    <div>
      <div className="notes_container mt-3 mb-4">
        <div className="tasks_notes_container w-100">
          {isTextarea ? (
            <div>
              {/* <textarea
                className="tasks_notes-textarea"
                placeholder="Enter notes"
                name="content"
                onChange={(e) =>
                  setNotesRequest({
                    ...notesRequest,
                    content: e.target.value,
                  })
                }
                ref={textareaRef}
                autoFocus
                value={getValue(notesRequest, `content`, "")}
                rows={5}
              /> */}
              {!editId && (
                <DraftJSEditor
                  toolbarHidden
                  editorState={getValue(notesRequest, `content`, "")}
                  handleChangeEditor={handleChangeEditor}
                  name="content"
                  placeholder={
                    props.placeholder ? props.placeholder : "Add a note..."
                  }
                  focus
                  editorStyle={{
                    background: "white",
                    paddingLeft: "10px",
                    minHeight: "15vh",
                    border: "1px solid #efefef",
                  }}
                  toolbar={{
                    options: ["inline", "fontSize", "list"],
                  }}
                />
              )}
            </div>
          ) : (
            <motion.div
              initial={{ y: 0, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 20, opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <input
                className="tasks_notes-textarea"
                type="text"
                onClick={handleInputClick}
                placeholder="Add a note..."
              />
            </motion.div>
          )}
          {/* <div
            className={` notes_select_container d-flex p-2 align-items-center`}
          >
            <div className="ms-3">
              <CustomCheckbox />
            </div>
            <div className="d-flex align-items-center">
              <p className="mx-2 small_text__14">Create a</p>
              <TextHeaderTooltip
                title={`Follow up type`}
                data={types}
                label={`label`}
                value={getValue(notesRequest, `follow_up_type`, "")}
                onChange={(e: any) =>
                  handleSelectChange(
                    e,
                    "follow_up_type",
                    notesRequest,
                    setNotesRequest
                  )
                }
                selectKey={"value"}
                width={"270px"}
                editInline
              />
              <p className="small_text__14">task to follow up</p>
              <TextHeaderTooltip
                title={`Follow up Date`}
                data={getFutureDateFunctions().filter(
                  (item: object) => getValue(item, `key`, "") !== "custom_date"
                )}
                label={`label`}
                value={getValue(notesRequest, `follow_up_datetime`, "")}
                onChange={(e: any) =>
                  handleSelectChange(
                    e,
                    "follow_up_datetime",
                    notesRequest,
                    setNotesRequest
                  )
                }
                selectKey={"value"}
                width={"270px"}
                editInline
              />
            </div>
          </div> */}
        </div>
        {!editId && (
          <>
            {getValue(notesRequest, `content`, "") && (
              <div className="tasks_notes_footer mt-2 d-flex justify-content-between gap-2">
                <Tooltip
                  title={
                    getValue(notesRequest, "content", "").length < 12
                      ? "Add a note to let AI help you"
                      : ""
                  }
                >
                  <Dropdown
                    menu={{ items }}
                    disabled={getValue(notesRequest, "content", "").length < 12}
                  >
                    <a onClick={(e) => e.preventDefault()} className="w-auto">
                      <div className="ai-button-wrapper">
                        <button className="ai-button" type="button">
                          <div className="heart-beat-anim">
                            <AIStars />
                          </div>
                          AI Rewrite
                        </button>
                        <div className="ai-button-bg"></div>
                      </div>
                    </a>
                  </Dropdown>
                </Tooltip>
                <div className="d-flex align-items-center gap-2">
                  <Button
                    onClick={() => {
                      setNotesRequest({
                        ...notesRequest,
                        content: "",
                      });
                      setIsTextarea(false);
                      // setEditId("");
                    }}
                  >
                    Cancel
                  </Button>
                  {getValue(props, `permissions`, []).includes("create") && (
                    <Button
                      type="primary"
                      onClick={handleSubmitNotes}
                      disabled={
                        !getValue(notesRequest, `content`, "") ||
                        getValue(notesRequest, `content`, "") === "<p></p>\n"
                          ? true
                          : false
                      }
                    >
                      {submitNotesLoading ? "Please wait..." : "Save"}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default NotesEditor;
