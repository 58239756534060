import { Drawer, Tag } from "antd";
import React from "react";
import { DashboardKPITypeList } from "../helpers/enum";
import { getValue } from "@utils/lodash";
import UpSvgComponent from "@assets/svg/up";

const industries = [
  {
    heading: "Communications",
    count: "36",
    percentage: "14.0",
  },
  {
    heading: "Advertisement",
    count: "35",
    percentage: "22",
  },
  {
    heading: "Cold Call",
    count: "13",
    percentage: "13",
  },
  {
    heading: "Employee Referral",
    count: "4",
    percentage: "19.7",
  },
  {
    heading: "Enternal Referral",
    count: "2",
    percentage: "20.76",
  },
];

const deals = [
  {
    name: "Christopher",
    count: "1280",
  },
  {
    name: "Jason Bell",
    count: "1010",
  },
  {
    name: "Micheal Gold",
    count: "777",
  },
  {
    name: "Steve Wright",
    count: "728",
  },
  {
    name: "David Maxwell",
    count: "533",
  },
  {
    name: "David Maxwell",
    count: "533",
  },
];

function ComponentKPIStyleDrawer(props: any) {
  return (
    <Drawer
      title="Choose KPI Style"
      onClose={props.onClose}
      open={props.open}
      width={1000}
      className="dashboard-slider-container"
    >
      <div className="dashboard_charttype_wrapper">
        {DashboardKPITypeList.map((item: object, index: number) => {
          return (
            <div
              className="height-100"
              onClick={() =>
                props.openComponentDrawer(getValue(item, `value`, ""))
              }
            >
              {index === 0 && (
                <div className="dashboard_charttype p-4">
                  <h6 className="header_text__16">REVENUE THIS MONTH</h6>
                  <div className="py-3 d-flex align-items-center gap-1">
                    <p className="standard-highlight">$300</p>
                    <div className="standard-growth-percentage">
                      <UpSvgComponent />
                      3.09%
                    </div>
                  </div>
                  <p className="small_text__14">Last month: $291</p>
                </div>
              )}
              {index === 1 && (
                <div className="dashboard_charttype p-4">
                  <h6 className="header_text__16">REVENUE THIS MONTH</h6>
                  <div className="py-3 d-flex align-items-center gap-3">
                    <p className="gindex-highlight">13.11%</p>
                    <div className="gindex-growth-percentage">$391</div>
                  </div>
                  <p className="small_text__14">Last month: $450</p>
                </div>
              )}
              {index === 2 && (
                <div className="dashboard_charttype p-4">
                  <h6 className="header_text__16">OPEN DEALS</h6>
                  <div className="standard-highlight text-center mt-4 pb-4">
                    2,000
                  </div>
                </div>
              )}
              {index === 3 && (
                <div className="dashboard_charttype p-4">
                  <h6 className="header_text__16">
                    TOP INDUSTRIES- DEALS CREATED
                  </h6>
                  <div className="row d-grid gap-3 mt-4 ms-3">
                    {industries.map((item, index) => (
                      <div
                        className={`row pb-3 px-0 ${
                          index !== industries.length - 1 ? "border-bottom" : ""
                        }`}
                        key={index}
                      >
                        <div className="col-8 px-0 small_text__16">
                          {item.heading}
                        </div>
                        <div className="col-2 px-0 small_text__16">
                          {item.count}
                        </div>
                        <div className="col-2 text-right mx-0 px-0 d-flex justify-content-end align-items-center">
                          <Tag
                            color={`${index % 2 === 0 ? "#4cc792" : "#f88"}`}
                            className="me-0"
                          >
                            {item.percentage}%
                          </Tag>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {index === 4 && (
                <div className="dashboard_charttype p-4">
                  <h6 className="header_text__16">TOP 5 DEAL OWNERS</h6>
                  <div className="row d-grid gap-3 mt-4 ms-3">
                    {deals.map((item, index) => (
                      <div
                        className={`row pb-2 px-0 ${
                          index !== deals.length - 1 ? "border-bottom" : ""
                        }`}
                        key={index}
                      >
                        <div className="col-1 px-0 small_text__16">
                          {index + 1}
                        </div>
                        <div className="col-9 px-0 small_text__16">
                          {item.name}
                        </div>
                        <div className="col-2 px-0 small_text__16 mx-0 px-0">
                          {item.count}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <p className="small_text__16 text-center pt-2">
                {getValue(item, `label`, "")}
              </p>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
}

export default ComponentKPIStyleDrawer;
