import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const allSalePipelines = (queryRequest: string) =>
  get(`${config.API_URL}/sale-pipelines?${queryRequest}`);

export const salePipelinesDefaultFields = (queryRequest: string) =>
  get(`${config.API_URL}/sale-pipelines/default-fields?${queryRequest}`);

export const listAllSalePipelines = (queryRequest: string) =>
  get(`${config.API_URL}/sale-pipelines/list?${queryRequest}`);

export const getSpecificSalePipeline = (id: string) =>
  get(`${config.API_URL}/sale-pipelines/${id}`);

export const createSalePipeline = (payload: object) =>
  post(`${config.API_URL}/sale-pipelines`, payload);

export const updateSalePipeline = (id: string, payload: object) =>
  patch(`${config.API_URL}/sale-pipelines/${id}`, payload);


