import DeleteSvgComponent from "@assets/svg/delete";
import PowerOffSvgComponent from "@assets/svg/power-off";
import { getValue } from "@utils/lodash";
import { Handle, Position } from "reactflow";

function BotEndChatOption(props: any) {
  const { data, handleDelete, handleStyle } = props;
  return (
    <>
      <div className="custom-node-header">
        <p className="small_text__14 text-black d-flex align-items-center gap-2">
          <PowerOffSvgComponent color="#0083ff" size="16" />
          {getValue(data, `label`, "")}
        </p>
        {!getValue(data, `path`, "") && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(getValue(data, `id`, ""));
          }}
          className="custom-node-delete"
        >
          <DeleteSvgComponent color={"red"} size={18} />
        </div>)}
      </div>
      <div className="custom-button-body">
        <p
          className="small_text__14"
          contentEditable="true"
          dangerouslySetInnerHTML={{
            __html: getValue(data, `description`, ""),
          }}
        ></p>
      </div>
      {/* <div className="visitor_section">
        <h6 className="header_text__14 text-center">End Chat</h6>
      </div> */}
      <Handle type="target" position={Position.Left} style={handleStyle} />
      {/* <Handle type="source" position={Position.Right} style={handleStyle} /> */}
    </>
  );
}

export default BotEndChatOption;
