import "./signup-form.scss";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import InputFormField from "./inputForm";
import React, { useState } from "react";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import timezones from "@common/json/standardized-timezone-data.json";
import { Select } from "antd";

export default function OrgSignupForm(props: any) {
  const { request, setRequest, validator, handleSubmit, submitLoading } = props;
  const [visibility, setVisisbility] = React.useState(false);

  const handleTimezoneChange = (value: any) => {
    setRequest({
      ...request,
      timezone: value,
    });
  };

  return (
    <div className={"signup-form-wrapper"}>
      <h2 className={`signup-title text-center mt-4`}>
        Your accepting an invitation sent to{" "}
        <span className="header_text__18">
          <b>{getValue(props, `email`, "")}</b>
        </span>{" "}
        within the{" "}
        <p className="text_blue__18">{getValue(props, `info.org.name`, "")}</p>
      </h2>
      <div className={"signup-form-details"}>
        <InputFormField
          inputType="TEXT"
          validator={validator}
          placeholder="Enter First Name"
          label="First Name"
          name="first_name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          value={getValue(request, `first_name`, "")}
        />
        <InputFormField
          inputType="TEXT"
          validator={validator}
          placeholder="Enter Last Name"
          label="Last Name"
          name="last_name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          value={getValue(request, `last_name`, "")}
        />

        {/* <InputFormField
          inputType="PASSWORD"
          validator={validator}
          icon={"eye"}
          placeholder="Create Password"
          label="Password"
          name="password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          value={getValue(request, `password`, "")}
        /> */}
        <div className="position-relative">
          <InputFormField
            inputType={visibility ? "TEXT" : "PASSWORD"}
            validator={validator}
            icon={visibility ? "key" : "eye"}
            placeholder="Create Password"
            label="Password"
            name="password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, request, setRequest)
            }
            value={getValue(request, `password`, "")}
          />
          <img
            src={
              !visibility
                ? `/images/visibility_off.svg`
                : `/images/visibility.svg`
            }
            className={"visbility_icon"}
            onClick={() => setVisisbility(!visibility)}
          />
        </div>

        <div className="mb-3">
          {/* <SearchToolTip
            placeholder={`Enter Time Zone`}
            onChange={(e: object) =>
              setRequest({
                ...request,
                timezone: getValue(e, `value`, ""),
              })
            }
            label={`label`}
            width={"365px"}
            data={timezones}
            value={getValue(request, `timezone`, "")}
            selectKey={"value"}
            // name={"Time Zone"}
          /> */}
          <Select
            showSearch
            className="w-100 custom-signup-time-zone"
            placeholder="Enter Time Zone"
            value={request.timezone}
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={timezones.map((tz) => ({
              value: tz.value,
              label: tz.label,
            }))}
            onChange={handleTimezoneChange}
          />
        </div>
      </div>

      {/* <Link to="/thankyou"> */}
      <button
        className="superteams-button__primary-button mt-4"
        onClick={handleSubmit}
        disabled={submitLoading}
      >
        {submitLoading ? "Please wait..." : "Continue"}
      </button>
      {/* </Link> */}
      <p className={`signup-text text-center`}>
        By Clicking on “Continue” you agree to our terms and acknowledge reading
        our <span>privacy notice</span>
      </p>
    </div>
  );
}
