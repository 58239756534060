import React from "react";
import "./button.scss";
import { getValue } from "@utils/lodash";
import ButtonOnHover from "../Tooltip/button-on-hover";
import { Button, MenuProps } from "antd";

function SubmitButtonContainer(props: any) {
  const items: MenuProps["items"] = [
    {
      label: "Submit and continue",
      key: "1",
    },
  ];

  return (
    <div
      className={`submit-button__footer d-flex align-items-center justify-content-between`}
    >
      <div className={"submit-button__container"}>
        <div></div>
        <div className="d-flex align-items-center gap-2">
          <Button size="large" onClick={() => props.handleBackButton()}>
            {getValue(props, `first_title`, "")
              ? getValue(props, `first_title`, "")
              : "Cancel"}
          </Button>
          {getValue(props, `disableValidation`, false) ? (
            <ButtonOnHover
              header={
                getValue(props, `permissions`, []).includes("create") && (
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => props.handleSubmit()}
                    disabled={props.disabled}
                    loading={props.submitLoading}
                  >
                    {getValue(props, `second_title`, "")
                      ? getValue(props, `second_title`, "")
                      : "Cancel"}
                  </Button>
                )
              }
              text={"Please add all required information"}
              width={"200px"}
              height={"50px"}
              hover={props.disabled}
            />
          ) : (
            getValue(props, `permissions`, []).includes("create") && (
              <Button
                type="primary"
                size="large"
                onClick={() => props.handleSubmit()}
                loading={props.submitLoading}
                disabled={props.disabled}
              >
                {getValue(props, `second_title`, "")
                  ? getValue(props, `second_title`, "")
                  : "Create"}
              </Button>
            )
          )}
          {!getValue(props, `hideNew`, false) &&
            getValue(props, `permissions`, []).includes("create") && (
              <Button
                type="primary"
                size="large"
                onClick={() => props.handleSubmit("new")}
                loading={props.submitLoading}
                disabled={props.disabled}
              >
                {"Create & Save New"}
              </Button>
            )}
        </div>
      </div>
    </div>
  );
}

export default SubmitButtonContainer;
