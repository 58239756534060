import { Drawer, Button, Switch } from "antd";
import React, { useState } from "react";
import {
  DashboardCompareObjectList,
  DashboardCompareToList,
  DashboardDateGranularityList,
  DashboardDurationTypeList,
  DashboardKPITypeList,
  DashboardShowRankList,
} from "../helpers/enum";
import { getValue } from "@utils/lodash";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { formatString } from "@common/text-helpers";

function ComponentKPIDrawer(props: any) {
  const {
    request,
    setRequest,
    getAllPipelines,
    allPipelines,
    setNewModule,
    newModule,
    allModules,
    getSpecificPipelineInfo,
    dateColumnFields,
    isLoading,
    handleSubmit,
    onClose,
    showKPIStyleComponentDrawer,
    formColumnFields,
  } = props;

  const findName = (name: string) => {
    let info: any = DashboardKPITypeList.find(
      (item: object) => getValue(item, `value`, "") === name
    );
    return getValue(info, `label`, "");
  };

  const handleChangeStyle = () => {
    setRequest((prevState: any) => ({
      ...prevState,
      graph_type: "",
      is_child: false,
    }));
    onClose();
    showKPIStyleComponentDrawer();
  };
  const [showDate, setShowDate] = useState(false);

  return (
    <Drawer
      title={findName(props.selectedComponentOption)}
      onClose={props.onClose}
      open={props.open}
      width={720}
      className="dashboard-slider-container-footer"
    >
      <div className="d-flex align-items-start flex-column">
        <div className="mb-3 d-flex align-items-center justify-content-end gap-4 header_text__16 w-100">
          <h6
            className=" color_primary cursor-pointer"
            onClick={() => handleChangeStyle()}
          >
            Change Style
          </h6>
        </div>
        <div className="d-grid gap-3">
          <div className="bg-white rounded-3 p-4">
            <h6 className="header_text__20">Component</h6>
            <div className="mb-3">
              <InputRuleForm
                inputType="TEXT"
                label="Component Name"
                placeholder="Enter Component Name"
                name="name"
                value={getValue(request, `name`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div className="d-flex align-items-center w-75 gap-3">
              <div className="mb-3">
                <SearchToolTip
                  name="Module"
                  data={getValue(props, `allModules`, [])}
                  label="label"
                  selectKey="id"
                  value={getValue(request, `module`, "")}
                  onChange={(e: any) => {
                    getAllPipelines(getValue(e, `api_name`, ""), allModules);
                    setNewModule(getValue(e, `api_name`, ""));
                    setRequest({
                      ...request,
                      module: getValue(e, `id`, ""),
                      pipeline: "",
                    });
                  }}
                />
              </div>
              {(newModule === "deals" || newModule === "leads") && (
                <div className="mb-3">
                  <SearchToolTip
                    name="Pipeline"
                    label="label"
                    selectKey="id"
                    value={getValue(request, `pipeline`, "")}
                    data={allPipelines}
                    onChange={(e: any) => {
                      getSpecificPipelineInfo(getValue(e, `id`, ""));
                      setRequest({
                        ...request,
                        pipeline: getValue(e, `id`, ""),
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div className="d-flex align-items-center w-75 gap-3">
              <div className="mb-3">
                <SearchToolTip
                  name="Module Column"
                  placeholder="Enter Module Column"
                  selectKey="id"
                  data={dateColumnFields}
                  label="label"
                  value={getValue(request, `module_field`, "")}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      module_field: getValue(e, `id`, ""),
                    })
                  }
                />
              </div>
              <div className="mb-3">
                <SearchToolTip
                  name="Duration Type"
                  placeholder="Enter Duration Type"
                  selectKey="value"
                  data={DashboardDurationTypeList}
                  value={getValue(request, `duration_type`, "")}
                  label="label"
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      duration_type: getValue(e, `value`, ""),
                      custom_from_date: null,
                      custom_to_date: null,
                    })
                  }
                />
              </div>
            </div>
            {getValue(request, `duration_type`, "") === "custom" && (
              <div className="d-flex gap-3">
                <InputRuleForm
                  inputType="DATE"
                  placeholder="Enter From Date"
                  label="Custom From Date"
                  value={getValue(request, `custom_from_date`, null)}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      custom_from_date: e,
                      custom_to_date: null,
                    })
                  }
                />
                <InputRuleForm
                  inputType="DATE"
                  placeholder="Enter To Date"
                  label="Custom To Date"
                  value={getValue(request, `custom_to_date`, null)}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      custom_to_date: e,
                    })
                  }
                  minDate={getValue(request, `custom_from_date`, null)}
                />
              </div>
            )}
          </div>
          {(getValue(request, `graph_type`, "") === "standard" ||
            getValue(request, `graph_type`, "") === "growth_index" ||
            getValue(request, `graph_type`, "") === "scorecard") && (
            <div className="bg-white rounded-3 p-4">
              <h6 className="header_text__20 ">Comparison Indicator</h6>
              <div className="mb-3">
                <SearchToolTip
                  name="Compare To"
                  data={DashboardCompareToList}
                  label="label"
                  selectKey="value"
                  value={getValue(request, `compare_to`, "")}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      compare_to: getValue(e, `value`, ""),
                      compare_from_date: null,
                      compare_to_date: null,
                    })
                  }
                />
              </div>
              {getValue(request, `compare_to`, "") === "custom" && (
                <div className="d-flex gap-3">
                  <InputRuleForm
                    inputType="DATE"
                    placeholder="Enter From Date"
                    label="Custom From Date"
                    value={getValue(request, `compare_from_date`, null)}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        compare_from_date: e,
                        compare_to_date: null,
                      })
                    }
                  />
                  <InputRuleForm
                    inputType="DATE"
                    placeholder="Enter To Date"
                    label="Custom To Date"
                    value={getValue(request, `compare_to_date`, null)}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        compare_to_date: e,
                      })
                    }
                    minDate={getValue(request, `compare_from_date`, null)}
                  />
                </div>
              )}
              <div className="mb-3">
                <SearchToolTip
                  name="Objective"
                  data={DashboardCompareObjectList}
                  label="label"
                  selectKey="value"
                  value={getValue(request, `compare_objective`, "")}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      compare_objective: getValue(e, `value`, ""),
                    })
                  }
                />
              </div>
            </div>
          )}

          {(getValue(request, `graph_type`, "") === "rankings" ||
            getValue(request, `graph_type`, "") === "scorecard") && (
            <div className="bg-white rounded-3 p-4">
              <h6 className="header_text__20 ">Rank</h6>
              <div className="d-flex align-items-center w-100 gap-3">
                <Switch
                  size="small"
                  checked={showDate}
                  onChange={() => setShowDate(!showDate)}
                  checkedChildren="Date Fields"
                  unCheckedChildren="Form Fields"
                />
                {showDate ? (
                  <>
                    <div className="mb-3">
                      <SearchToolTip
                        name="Rank ( Date Fields )"
                        placeholder="Enter Rank"
                        selectKey="id"
                        data={dateColumnFields}
                        label="label"
                        value={getValue(request, `child_module_field`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            is_child: true,
                            child_module_field: getValue(e, `id`, ""),
                          })
                        }
                      />
                    </div>
                    <div className="mb-3 w-50 mt-4">
                      <SearchToolTip
                        name=""
                        data={DashboardDateGranularityList}
                        label="label"
                        selectKey="value"
                        value={getValue(request, `child_date_granularity`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            is_child: true,
                            child_date_granularity: getValue(e, `value`, ""),
                          })
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-3">
                      <SearchToolTip
                        name="Rank ( Form fields )"
                        placeholder="Enter Rank"
                        selectKey="id"
                        data={formColumnFields}
                        label="label"
                        value={getValue(request, `child_module_field`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            is_child: true,
                            child_date_granularity: "",
                            child_module_field: getValue(e, `id`, ""),
                          })
                        }
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="mb-3">
                <SearchToolTip
                  name="Show"
                  data={DashboardShowRankList}
                  label="label"
                  selectKey="value"
                  value={getValue(request, `show_rank`, "")}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      is_child: true,
                      show_rank: getValue(e, `value`, ""),
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="text-right mt-2 ant-slider-custom-footer gap-2">
        <Button size="large" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isLoading}
        >
          Submit
        </Button>
      </div>
    </Drawer>
  );
}

export default ComponentKPIDrawer;
