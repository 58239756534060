import { convertCurrentDate } from "@common/date-helpers";
import { concatString, formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
// import "../../../PendingTaskMainBody.scss";

function EmailList(props: any) {
  return (
    <div>
      <div className="w-100">
        <table className={`card_table w-100`}>
          <thead className={`card_table_header`}>
            <tr>
              <td>Subject</td>
              <td>Date</td>
              <td>Source</td>
              <td>Sent By</td>
            </tr>
          </thead>
          <tbody className={`card_table_body`}>
            {getValue(props, `list`, []).map((item: object, key: number) => {
              return (
                <tr>
                  <td
                    onClick={() =>
                      props.getSpecificEmailInfo(
                        getValue(item, `thread_id`, "")
                      )
                    }
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={`/images/outgoing-mail.svg`}
                        height={"20px"}
                        width={"20px"}
                      />
                      <p className="ms-2">
                        {concatString(getValue(item, `subject`, ""), 27)}
                      </p>
                    </div>
                    <p className={`text text-muted`}>
                      {" "}
                      {getValue(item, `to`, "")}
                    </p>
                  </td>
                  <td>
                    <p className={`text`}>
                      {convertCurrentDate(getValue(item, `created_at`, ""))}
                    </p>
                  </td>
                  <td>
                    <p className={`text`}>
                      {formatString(
                        getValue(item, `provider_type`, "").split("EMAIL_")[1]
                      )}
                    </p>
                  </td>
                  <td>
                    <p className={`text`}>
                      {formatString(getValue(item, `user_first_name`, ""))}{" "}
                      {formatString(getValue(item, `user_last_name`, ""))}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EmailList;
