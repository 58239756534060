import LeadPipelineDetails from "@components/Pages/Pipeline/Detail/leads";
import Leads from "@pages/Private/CRM/Leads";

export const CrmLeadsRoutes = [
  {
    path: "/:orgId/crm/leads",
    name: "crm_leads",
    component: Leads,
  },
  {
    path: "/:orgId/crm/leads/detail/:id",
    name: "crm_leads",
    component: LeadPipelineDetails,
  },
];
