import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { Radio, Space } from "antd";
import React from "react";

const BroadcastVariables = ({
  request,
  setRequest,
  options,
  onChangeExtractType,
  uploadedFileFieldOption,
}: any) => {
  return (
    <div className="p-4">
      <table className="w-100">
        <thead className="pb-2">
          <tr>
            <td className="header_text__14 pb-2">VARIABLE</td>
            <td className="header_text__14 pb-2">SOURCE</td>
            <td className="header_text__14 pb-2">MAP</td>
          </tr>
        </thead>
        <tbody>
          {getValue(request, `variableConfig.templateTags`, []).map(
            (item: any, index: any) => (
              <tr key={index} className={` ${index !== 0 ? "border-top" : ""}`}>
                <td className="small_text__14 py-2">
                  {"[["}
                  {item.actual_tag}
                  {"]]"}
                </td>
                {/* <Radio.Group
              options={options}
              onChange={(e: any) => onChangeExtractType(e, index)}
              value={getValue(item, `extractType`, "")}
              // optionType="button"
              // buttonStyle="solid"
            /> */}

                <td className="py-2">
                  <Radio.Group
                    onChange={(e: any) => onChangeExtractType(e, index)}
                    value={getValue(item, `extractType`, "")}
                  >
                    <Space direction="vertical">
                      {options.map((item: any, i: any) => (
                        <Radio value={item.value} key={i}>
                          {item.label}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </td>
                <td className="py-2">
                  {getValue(item, `extractType`, "") === "manual" ? (
                    <InputRuleForm
                      // label={`{{${getValue(item, `tag`, "")}}}`}
                      inputType="TEXT"
                      value={
                        getValue(item, `extractType`, "") === "manual"
                          ? getValue(item, `value`, "")
                          : getValue(item, `tag`, "")
                      }
                      name="name"
                      placeholder={`${getValue(item, `actual_tag`, "")}`}
                      onChange={(e: any) =>
                        setRequest((prevRequest: any) => {
                          return {
                            ...prevRequest,
                            variableConfig: {
                              ...prevRequest.variableConfig,
                              templateTags:
                                prevRequest.variableConfig.templateTags.map(
                                  (button: any, i: number) => {
                                    if (index !== i) return button;
                                    return {
                                      ...button,
                                      [getValue(item, `extractType`, "") ===
                                      "manual"
                                        ? "value"
                                        : "tag"]: e.target.value,
                                    };
                                  }
                                ),
                            },
                          };
                        })
                      }
                      required
                      className="mb-2"
                      disabled={getValue(item, `extractType`, "") !== "manual"}
                      hideLabel
                    />
                  ) : (
                    <div className="w-100">
                      <SearchToolTip
                        label="label"
                        value={getValue(item, `tag`, "")}
                        data={uploadedFileFieldOption}
                        placeholder="Select"
                        selectKey={"value"}
                        onChange={(e: any) =>
                          setRequest((prevRequest: any) => {
                            return {
                              ...prevRequest,
                              variableConfig: {
                                ...prevRequest.variableConfig,
                                templateTags:
                                  prevRequest.variableConfig.templateTags.map(
                                    (button: any, i: number) => {
                                      if (index !== i) return button;
                                      return {
                                        ...button,
                                        tag: getValue(e, `value`, ""),
                                      };
                                    }
                                  ),
                              },
                            };
                          })
                        }
                      />
                    </div>
                  )}
                </td>
                {/* {getValue(item, `extractType`, "") !== "manual" && (
                  <Button className="mt-3" onClick={() => handleModal(index)}>
                    Pick Variable
                  </Button>
                )} */}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BroadcastVariables;
