import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllFilterViews  = (queryRequest: string) =>
  get(`${config.API_URL}/filter-views?${queryRequest}`);

export const getSpecificFilterView = (id: string) =>
  get(`${config.API_URL}/filter-views/${id}`);

export const createFilterView = (payload: object) =>
  post(`${config.API_URL}/filter-views`, payload);

export const updateFilterView = (id: string, payload: object) =>
  patch(`${config.API_URL}/filter-views/${id}`, payload);

export const deleteFilterView = (id: string) =>
  Delete(`${config.API_URL}/filter-views/${id}`);
