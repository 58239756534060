import OrgHeader from "@components/common/Header/OrgHeader/Org-Header";

export default function Layout({ children }: any) {
  return (
    <>
      <OrgHeader />
      {/* <div className={`${classNames['common-login-wrapper']} d-flex`}> */}
      <div className="flex-1">{children}</div>
      {/* <div className={`${classNames['blue-background']} flex-1`}></div>
      </div> */}
    </>
  );
}
