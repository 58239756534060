import AppBrandingSvgComponent from "@assets/svg/app-branding";
import AtSvgComponents from "@assets/svg/at";
import TagSvgComponent from "@assets/svg/tag";
import WhatsappSvgComponent from "@assets/svg/whastapp";
import { handleUpdateArray } from "@common/handleChange";
import ImportCheckbox from "@components/BulkImport/components/import-checkbox";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import MultiSearchTooltip from "@components/custom/Dropdown/MultiSearchTooltip";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import { getValue } from "@utils/lodash";
import { Button, Checkbox } from "antd";

const WaAdvancefilter = (props: any) => {
  const {
    allConnectionList,
    isOpenFilterView,
    toggleFilterView,
    filterRequest,
    setFilterRequest,
    handleResetFilter,
    handleApplyFilter,
    UrlParams,
  } = props;
  return (
    <SlideFromRightPopup
      isPopupVisible={isOpenFilterView}
      addCustomClass={"rolls-popup"}
      title={"Filter"}
      toggle={toggleFilterView}
      width={"550px"}
      submit_text={"Apply"}
    >
      <div className="subpipeline-wrapper p-4">
        {/* <div className="d-flex gap-3">
          <div className="flex-1">
            <ImportCheckbox
              value="open"
              label="Open chats"
              // selectedOption={selectedOption}
              // onChange={handleChange}
              selectedOption
              checked
            />
          </div>
          <div className="flex-1">
            <ImportCheckbox
              value="resolved"
              label="Resolved chats"
              //   onChange={handleChange}
              //   selectedOption={selectedOption}
              disabled
            />
          </div>
        </div>
        <hr /> */}
        {/* <div className="d-flex gap-2 align-items-center">
          <Checkbox
            onChange={() =>
              setFilterRequest({
                ...filterRequest,
                onlyUnread: !getValue(filterRequest, `onlyUnread`, false),
              })
            }
            checked={getValue(filterRequest, `onlyUnread`, false)}
          />
          <p className="form-label">Unread chats</p>
        </div> */}
        {/* <hr />
        <div>
          <div className="d-flex align-items-center gap-2 mb-3">
            <TagSvgComponent color="#1a73e8" />
            <h6>Tags</h6>
          </div>
          <SearchToolTip placeholder="Search tags" />
          <div className="mt-3">
            <div className="d-flex gap-2 align-items-center">
              <CustomCheckbox />
              <p className="form-label">Referral</p>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <CustomCheckbox />
              <p className="form-label">Facebook</p>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <CustomCheckbox />
              <p className="form-label">Google</p>
            </div>
          </div>
        </div> */}
        <div>
          <div className="d-flex align-items-center gap-2 mb-3">
            <AtSvgComponents size="18" />
            <h6>Assign to</h6>
          </div>
          <SearchToolTip
            placeholder="Search tags"
            lookup_api={"org_users"}
            value={getValue(filterRequest, `forOrgUser`, "")}
            onChange={(e: any) =>
              setFilterRequest({
                ...filterRequest,
                forOrgUser: getValue(e, `id`, ""),
              })
            }
            label={`label`}
          />
        </div>
        <hr />
        <div>
          <div className="d-flex align-items-center gap-2 mb-3">
            <AppBrandingSvgComponent color="#1a73e8" />
            <h6>Channel</h6>
          </div>
          <MultiSearchTooltip
            placeholder="Choose Channels"
            label="label"
            data={allConnectionList}
            selectKey={"value"}
            onChange={(e: any) => {
              if (!getValue(filterRequest, `waAccountIds`, []).includes(e.id)) {
                getValue(filterRequest, `waAccountIds`, []).push(e.id);
                setFilterRequest((prevState: any) => ({
                  ...prevState,
                }));
              } else {
                let fitlered = getValue(
                  filterRequest,
                  `waAccountIds`,
                  []
                ).filter((item: object) => item !== e.id);
                setFilterRequest((prevState: any) => ({
                  ...prevState,
                  waAccountIds: fitlered,
                }));
              }
            }}
            value={getValue(filterRequest, `waAccountIds`, [])}
            icon={<WhatsappSvgComponent color={"#30d14f"} />}
          />
        </div>
      </div>
      <SubmitContainer
        handleBackButton={() => {
          toggleFilterView();
        }}
        handleSubmit={() => handleApplyFilter()}
        // submitLoading={submitLoading}
        first_title={"Cancel"}
        second_title={"Apply"}
        third_title="Reset filter"
        disableValidation
        handleThirdButton={() => handleResetFilter()}
        permissions={["create"]}
        // disabled={hasErrors ? true : false}
      />
    </SlideFromRightPopup>
  );
};

export default WaAdvancefilter;
