import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
import { getValue, setValue } from "@utils/lodash";
import { concatString } from "common/text-helpers";
import Popup from "reactjs-popup";
import { QueryRequestHelper } from "common/query-request-helper";
import { getLookupAPIs } from "@components/helpers/lookup-apis";

const InlineSearchToolTip = (props) => {
  const {
    label,
    id,
    value,
    onChange,
    width,
    selectKey,
    name,
    data,
    isLoading,
    hideLabel,
  } = props;
  const ref = useRef();
  const inputRef = useRef(null);
  const popupRef = useRef(null);

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (getValue(props, `lookup_api`, "")) {
      fetchDefaultData();
    }
  }, [getValue(props, `lookup_api`, "")]);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (popupRef.current) {
        popupRef.current.close();
      }
    } else {
    }
  };
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setQuery("");
  }, [props.resetInput]);

  useEffect(() => {
    if (query) {
      setInputChange(true);
    }
  }, [query]);

  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */

  const handleClickOption = (option) => {
    onChange && onChange(option);
    setInputChange(false);
    setQuery("");
    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  const onClickAdd = () => {
    props.handleOpenPopup();
  };
  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */

  const [dataInfo, setData] = useState([]);
  const [isLoadingInfo, setIsLoading] = useState(true);
  const handleFetchData = async (search_text) => {
    if (search_text)
      try {
        let payload = {
          search: search_text,
          page_no: 1,
          page_size: 10,
        };
        setIsLoading(true);
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getLookupAPIs(
          getValue(props, `lookup_api`, ""),
          queryRequest
        );
        if (resp) {
          setData(resp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    else onChange(null);
  };
  const fetchDefaultData = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 10,
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getLookupAPIs(
        getValue(props, `lookup_api`, ""),
        queryRequest
      );
      if (resp) {
        setData(resp);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const filter = (options) => {
    return getValue(options, `length`, 0) > 0
      ? options.filter(
          (option) =>
            getValue(option, `[${label}]`, "") &&
            option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      : [];
  };
  const findSelectedValue = (id) => {
    let list =
      getValue(
        getValue(props, `lookup_api`, "") ? dataInfo : data,
        `length`,
        0
      ) > 0
        ? getValue(props, `lookup_api`, "")
          ? dataInfo.filter(
              (item) =>
                getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
            )
          : data.filter(
              (item) =>
                getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
            )
        : [];
    return getValue(list, `length`, 0) > 0
      ? getValue(list, `[${0}].label`, "")
      : "";
  };
  const valueLabel = useMemo(
    () => findSelectedValue(value),
    [
      value,
      getValue(
        getValue(props, `lookup_api`, "") ? dataInfo : data,
        `length`,
        0
      ) > 0,
    ]
  );

  const handleClose = (e) => {
    e.stopPropagation();
    onChange(null);
    if (popupRef.current) {
      popupRef.current.close();
    }
    document.body.style.overflow = "auto";
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
    document.body.style.overflow = "hidden";
  };
  const POSITION_TYPES = [
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "bottom right",
    "left center",
    "center center",
  ];
  /* -------------------------------------------------------------------------- */
  /*                           Dynamic Width section                            */
  /* -------------------------------------------------------------------------- */
  const [parentWidth, setParentWidth] = useState(null);
  const div = useCallback((node) => {
    if (node !== null) {
      setParentWidth(node.getBoundingClientRect().width);
    }
  });
  return (
    <>
      <div className={"dropdown-select-form position-relative"} ref={div}>
        {!hideLabel && name && (
          <label className="form-label-inline">
            {name} {getValue(props, `required`, false) && "*"}
          </label>
        )}

        <Popup
          trigger={(open) => (
            <div
              className={`popup-selected-value-box_inline  ${
                open ? "active" : ""
              }`}
              style={
                props.validator && getValue(props, `required`, false) && !value
                  ? {
                      width: width,
                      marginLeft: props.add && "10px",
                      border: getValue(
                        props,
                        `validator.current.messagesShown`,
                        false
                      )
                        ? "1px solid red"
                        : "1px solid #dadce0",
                    }
                  : { width: width, marginLeft: props.add && "10px" }
              }
            >
              <div>
                {valueLabel ? (
                  <p className="value font_size__14">
                    {concatString(valueLabel, 25)}
                  </p>
                ) : (
                  <p
                    className="inline_select_value"
                    style={{ color: "#00000029" }}
                  >
                    Select
                  </p>
                )}
              </div>
              <img
                src={
                  open
                    ? "/dropdown/arrow_up_filter.svg"
                    : "/dropdown/arrow_down_filter.svg"
                }
                className="inline_popup_arrow_image"
              />
            </div>
          )}
          position={POSITION_TYPES}
          ref={popupRef}
          arrow={false}
          contentStyle={{
            width: parentWidth,
            marginTop: "5px",
            padding: "0",
            zIndex: "1000",
            // border: "none",
          }}
          onOpen={handleOpen}
          on={"click"}
          closeOnDocumentClick
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          onClose={() => popupRef.current.close()}
          lockScroll
          type="tooltip"
          nested
        >
          {getValue(props, `lookup_api`, "") ? (
            <div
              className={`dropdown-select-form options ${
                isOpen ? "open" : ""
              }`} /* add custom width here */
              // style={{ width: width }}
            >
              <div className="popup_search_container">
                <img src={"/dropdown/search.svg"} className="search_image" />
                <input
                  className="search_input"
                  ref={inputRef}
                  autoFocus
                  type="text"
                  value={query}
                  name="searchTerm"
                  autoComplete="off"
                  placeholder={getValue(props, `placeholder`, "search here...")}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    handleFetchData(e.target.value);
                  }}
                  //
                />
                {query && (
                  <div className="input_close_image_container">
                    <img
                      src={"/dropdown/close.svg"}
                      className="input_close_image"
                      onClick={(e) => {
                        setQuery("");
                      }}
                    />
                  </div>
                )}
              </div>
              <div>
                <div className="popup_option_container">
                  {isLoadingInfo ? (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <img src={`/dropdown/loader1.gif`} className="loader" />
                    </div>
                  ) : filter(
                      getValue(props, `lookup_api`, "") ? dataInfo : data
                    ).length > 0 ? (
                    filter(dataInfo).map((option, index) => {
                      return (
                        <div
                          onClick={() => {
                            handleClickOption(option);
                          }}
                          className={`popup_options ${
                            getValue(
                              option,
                              `[${selectKey ? selectKey : "id"}]`,
                              ""
                            ) == value
                              ? "selected"
                              : ""
                          }`}
                          key={`${id}-${index}`}
                        >
                          <div className="selected_text hover-text font_size__14">
                            {concatString(`${option[label]}`, 30)}
                            {getValue(option, `[${label}].length`, 0) > 30 && (
                              <span className="tooltip-text" id="top">
                                {option[label]}
                              </span>
                            )}
                          </div>
                          {getValue(
                            option,
                            `[${selectKey ? selectKey : "id"}]`,
                            ""
                          ) == value && (
                            <img
                              src="/dropdown/done.svg"
                              className="done_image"
                            />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="nodata">No Data Found</div>
                  )}
                </div>
              </div>
              {getValue(props, `add`, "") ? (
                <div className="button_container" onClick={onClickAdd}>
                  <img src={"/dropdown/add.svg"} className="button_image" />
                  <p className="button_input">
                    {getValue(props, `button_title`, "")}
                  </p>
                </div>
              ) : null}
            </div>
          ) : (
            <div
              className={`dropdown-select-form options ${
                isOpen ? "open" : ""
              }`} /* add custom width here */
              style={{ width: width, inset: 0 }}
            >
              <div className="popup_search_container">
                <img src={"/dropdown/search.svg"} className="search_image" />
                <input
                  className="search_input"
                  ref={inputRef}
                  autoFocus
                  type="text"
                  value={query}
                  name="searchTerm"
                  autoComplete="off"
                  placeholder={getValue(props, `placeholder`, "search here...")}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    handleFetchData(e.target.value);
                  }}
                  //
                />
                {query && (
                  <div className="input_close_image_container">
                    <img
                      src={"/dropdown/close.svg"}
                      className="input_close_image"
                      onClick={(e) => {
                        setQuery("");
                      }}
                    />
                  </div>
                )}
              </div>
              <div>
                <div className="popup_option_container">
                  {isLoading ? (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <img src={`/dropdown/loader1.gif`} className="loader" />
                    </div>
                  ) : filter(data).length > 0 ? (
                    filter(data).map((option, index) => {
                      return (
                        <div
                          onClick={() => {
                            handleClickOption(option);
                          }}
                          className={`popup_options ${
                            getValue(
                              option,
                              `[${selectKey ? selectKey : "id"}]`,
                              ""
                            ) == value
                              ? "selected"
                              : ""
                          }`}
                          key={`${id}-${index}`}
                        >
                          <div className="selected_text hover-text font_size__14">
                            {concatString(`${option[label]}`, 30)}
                            {getValue(option, `[${label}].length`, 0) > 30 && (
                              <span className="tooltip-text" id="top">
                                {option[label]}
                              </span>
                            )}
                          </div>
                          {getValue(
                            option,
                            `[${selectKey ? selectKey : "id"}]`,
                            ""
                          ) == value && (
                            <img
                              src="/dropdown/done.svg"
                              className="done_image"
                            />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="nodata">No Data Found</div>
                  )}
                </div>
              </div>
              {getValue(props, `add`, "") ? (
                <div className="button_container" onClick={onClickAdd}>
                  <img src={"/dropdown/add.svg"} className="button_image" />
                  <p className="button_input">
                    {getValue(props, `button_title`, "")}
                  </p>
                </div>
              ) : null}
            </div>
          )}
        </Popup>
      </div>
      {props.validator && (
        <p className={"form-error text-center"}>
          {props.validator.current.message(
            getValue(props, `name`, ""),
            props.value,
            "required"
          )}
        </p>
      )}
    </>
  );
};

export default InlineSearchToolTip;
