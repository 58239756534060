import CannedResponse from "@pages/Private/Settings/CannedResponse";
import CannedResponseDetails from "@pages/Private/Settings/CannedResponse/CannedResponseDetails";

export const SettingsCannedRoutes = [
  {
    path: "/:orgId/settings/canned-response",
    name: "settings_general_company_profile",
    component: CannedResponse,
  },
  {
    path: "/:orgId/settings/canned-response/create",
    name: "settings_general_company_profile",
    component: CannedResponseDetails,
  },
  {
    path: "/:orgId/settings/canned-response/detail/:id",
    name: "settings_general_company_profile",
    component: CannedResponseDetails,
  },
];
