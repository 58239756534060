import SettingsFormCreator from "@pages/Private/Settings/FormCreator";
import SettingsFormCreatorManage from "@pages/Private/Settings/FormCreator/manage/manage-form";

export const SettingsFormsRoutes = [
  {
    path: "/:orgId/settings/forms",
    name: "settings_marketing_web_forms",
    component: SettingsFormCreator,
  },
  {
    path: "/:orgId/settings/forms/create",
    name: "settings_marketing_web_forms",
    component: SettingsFormCreatorManage,
  },
  {
    path: "/:orgId/settings/forms/edit/:id",
    name: "settings_marketing_web_forms",
    component: SettingsFormCreatorManage,
  },

  // {
  //   path: "/:orgId/settings/web-forms/:slug",
  //   name: "Dashboard",
  //   component: SettingsPreviewForm,
  // },
];
