import { config } from "../env";
import {
  Delete,
  DeleteWithPayload,
  get,
  getWithToken,
  patch,
  patchWithCode,
  post,
  postFileUpload,
  postWithMethod,
  postWithoutBody,
} from "./helpers/http-handler";

export const listOfOrganisations = (queryRequest: string) =>
  queryRequest
    ? get(`${config.API_URL}/orgs/list?${queryRequest}`)
    : get(`${config.API_URL}/orgs/list`);

export const listOfOrganisationsToken = (token: any) =>
  getWithToken(`${config.API_URL}/orgs/list`, token);

export const getSpecificOrganisations = () =>
  get(`${config.API_URL}/settings/org/profile`);

export const getSpecificProfile = () =>
  get(`${config.API_URL}/settings/account/profile`);

export const updateProfile = (payload: object) =>
  patch(`${config.API_URL}/settings/account/profile`, payload);

export const createOrganisations = (payload: object) =>
  post(`${config.API_URL}/orgs`, payload);

export const updateOrganisations = (payload: object) =>
  patch(`${config.API_URL}/settings/org/profile`, payload);

export const createOrganisationIntro = (payload: object) =>
  post(`${config.API_URL}/orgs/intro`, payload);

export const updateOrganisationsWithCode = (payload: object, code: string) =>
  patchWithCode(`${config.API_URL}/settings/org/profile`, payload, code);

export const getProfileInfo = () =>
  get(`${config.API_URL}/settings/org/users/me`);

//--------- Org Users ---------//

export const getOrganisationUsers = (queryRequest: string) =>
  get(`${config.API_URL}/settings/org/users?${queryRequest}`);

export const updateOrganisationUsers = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/org/users/${id}`, payload);

export const getOrganisationUserLoginHistory = (queryRequest: string) =>
  get(`${config.API_URL}/settings/org/users/login/logs?${queryRequest}`);

export const getOrganisationInvitedUsers = (queryRequest: string) =>
  get(`${config.API_URL}/settings/org/users/invites?${queryRequest}`);

export const activateOrgUser = (id: string) =>
  postWithoutBody(`${config.API_URL}/settings/org/users/${id}/activate`);

export const deactivateOrgUser = (id: string) =>
  postWithoutBody(`${config.API_URL}/settings/org/users/${id}/deactivate`);

export const sendInvOrgUser = (payload: object) =>
  post(`${config.API_URL}/settings/org/users/invites`, payload);

export const resendInvOrgUser = (id: string) =>
  post(`${config.API_URL}/settings/org/users/invites/${id}/resend`, {});

export const cancelInvOrgUser = (id: string) =>
  Delete(`${config.API_URL}/settings/org/users/invites/${id}/remove`);

//--------- Invite Org Users ---------//

export const checkTokenValidity = (queryRequest: string) =>
  get(
    `${config.API_URL}/settings/org/users/invites/token-info?${queryRequest}`
  );

export const checkSignupTokenValidity = (queryRequest: string) =>
  get(`${config.API_URL}/auth/signup/invite-flow/token-info?${queryRequest}`);

export const accept_decline_orgInvite = (payload: object) =>
  post(
    `${config.API_URL}/settings/org/users/invites/accept-or-decline`,
    payload
  );
