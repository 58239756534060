import { getValue } from "@utils/lodash";
import React from "react";

function KPIBasicComponent(props: any) {
  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, `name`, "")}</p>
      <div className="d-flex align-items-center">
        <h6 className="standard-highlight ms-3">
          {getValue(props, `graph.current`, "")}
        </h6>
      </div>
    </div>
  );
}

export default KPIBasicComponent;
