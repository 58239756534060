import { convertCurrentDate } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import { Tag } from "antd";
import React from "react";

function KPIScoreCardComponent(props: any) {
  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, `name`, "")}</p>

      <table className="w-100">
        {/* <thead>
          <th></th>
          <th></th>
          <th></th>
        </thead> */}
        {getValue(props, `graph`, []).map((item: object, index: number) => {
          return (
            <tr
              className={`w-100 pb-3 ${
                index !== getValue(props, `graph`, []).length - 1
                  ? "border-bottom"
                  : ""
              }`}
            >
              <td className="60">
                {convertCurrentDate(getValue(item, `key.created_at`, ""))}
              </td>
              <td className="w-20 small_text__16">
                {getValue(item, `count`, "")}
              </td>
              <td className="w-20 text-right small_text__16">
                <Tag
                  color={`${
                    getValue(item, `percentageToCurrent`, "") !== "positive" //dummy color
                      ? "#4cc792"
                      : "#f88"
                  }`}
                  className="me-0"
                >
                  {getValue(item, `percentageToCurrent`, "")}
                </Tag>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default KPIScoreCardComponent;
