import MeSvgComponent from "@assets/svg/me";
import SMSSvgComponent from "@assets/svg/sms";
import SMSDoneSvgComponent from "@assets/svg/sms-done";
import UnAssignSvgComponent from "@assets/svg/unassign";
import { getValue } from "@utils/lodash";
import { Badge, Tooltip } from "antd";
import React from "react";

function ChatFilter(props: any) {
  const {
    handleClickFilter,
    UrlParams,
    userId,
    unreadCount,
    unassignedCount,
    assignedCount,
    myCount,
    resolvedCount,
  } = props;
  const FilterButton = ({
    status,
    tooltipText,
    SvgComponent,
    tooltipCls,
    userId,
    active,
    unreadCount,
  }: any) => (
    <Tooltip title={tooltipText}>
      <div
        className={`cursor-pointer ${tooltipCls}-tooltip ${
          active ? "chat-filter-active" : "chat-filter"
        } py-2 w-100 d-flex justify-content-center position-relative`}
        onClick={() => {
          handleClickFilter(status, userId);
        }}
      >
        <SvgComponent color={active ? "#3182ce" : "#667781"} />
        {unreadCount > 0 && <Badge color="#25d366" count={unreadCount} />}
      </div>
    </Tooltip>
  );
  return (
    <div className="d-flex align-items-center bg-white justify-content-evenly chat-summary-filter">
      <FilterButton
        status="un-assigned"
        tooltipCls="un-assigned"
        tooltipText="Unassigned"
        SvgComponent={UnAssignSvgComponent}
        active={
          (getValue(UrlParams, `convStatus`, "") === "un-assigned" &&
            !getValue(UrlParams, `userId`, "")) ||
          !getValue(UrlParams, `convStatus`, "")
            ? "active"
            : ""
        }
        unreadCount={unassignedCount}
      />
      <FilterButton
        status="assigned"
        tooltipCls="mine"
        tooltipText="Mine"
        SvgComponent={MeSvgComponent}
        userId={userId}
        active={
          getValue(UrlParams, `convStatus`, "") === "assigned" &&
          getValue(UrlParams, `userId`, "")
            ? "active"
            : ""
        }
        unreadCount={myCount}
      />
      {/* <FilterButton
          status="resolved"
          tooltipCls="resolved-me"
          tooltipText="All resolved by me"
          SvgComponent={MessageDoneSvgComponent}
          userId={userId}
          active={
            getValue(UrlParams, `convStatus`, "") === "resolved" &&
            getValue(UrlParams, `userId`, "")
              ? "active"
              : ""
          }
        /> */}
      <FilterButton
        status="assigned"
        tooltipCls="assigned"
        tooltipText="Assigned"
        SvgComponent={SMSSvgComponent}
        active={
          getValue(UrlParams, `convStatus`, "") === "assigned" &&
          !getValue(UrlParams, `userId`, "")
            ? "active"
            : ""
        }
        unreadCount={assignedCount}
      />
      <FilterButton
        status="resolved"
        tooltipCls="all-resolved"
        tooltipText="All resolved"
        SvgComponent={SMSDoneSvgComponent}
        active={
          getValue(UrlParams, `convStatus`, "") === "resolved" &&
          !getValue(UrlParams, `userId`, "")
            ? "active"
            : ""
        }
        unreadCount={resolvedCount}
      />
    </div>
  );
}

export default ChatFilter;
