import PrinterSvgComponent from "@assets/svg/printer";
import React from "react";

const detailsCard = [
  {
    count: "0%",
    percentage: "-100%",
    heading: "Today's occupancy",
    color: "#96c7c1",
  },
  {
    count: "₹ 9999",
    percentage: "0%",
    heading: "Today's revenue",
    color: "#f7d59c",
  },
  {
    count: "1 room",
    percentage: "",
    heading: "Today's pickup ",
    color: "#CDF2CA",
  },
  {
    count: "2 days",
    percentage: "",
    heading: "Pre-booking window",
    color: "#6b7aa1",
  },
];

const CustomDataTable = [
  {
    heading: "Rooms pickup by source",
    description: "Data is for past 7 days",
    tHeadings: ["Source", "Room pickup"],
    body: [["Walk-In", "1"]],
  },
  {
    heading: "Revenue pickup by source",
    description: "Data is for past 7 days",
    tHeadings: ["Source", "Revenue ( ₹ )"],
    body: [["Walk-In", "1999"]],
  },
  {
    heading: "Rooms generated by source",
    description: "Data is for past 7 days",
    tHeadings: ["Source", "Revenue ( ₹ )"],
    body: [
      ["TRIPADVISOR", "12456"],
      ["Walk-In", "4646.67"],
    ],
  },
  {
    heading: "Revenue generated by roomtype",
    description: "Data is for past 7 days",
    tHeadings: ["Source", "Revenue ( ₹ )"],
    body: [["Ac", "17102.67"]],
  },
];

const PerformanceTab = () => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3 className="header_text__22">Good morning</h3>
          <p className="mt-1">Welcome, here is your property performance</p>
        </div>
        <PrinterSvgComponent />
      </div>
      <div className="reservation-card-container mt-3 border-bottom pb-4 ">
        {detailsCard.map((details, index) => (
          <div
            className="reserversation-details-card py-3 px-3 position-relative"
            key={index}
          >
            <div
              className="p-1 position-absolute top-0 w-100 rounded-top-2"
              style={{ background: details.color }}
            ></div>
            {details.percentage ? (
              <div className="text-danger text-end 1 w-100">
                {details.percentage}{" "}
                <span className="small_text__14">
                  <br />
                  Since yesterday
                </span>{" "}
              </div>
            ) : (
              <div className="text-danger text-end 1 w-100 my-2">
                <div className="py-2 "></div>
              </div>
            )}
            <span className="card-count">{details.count}</span>
            <p>{details.heading}</p>
          </div>
        ))}
      </div>
      <h3 className="header_text__20 mt-4">Competitors intelligence</h3>
      <p className=" small_text__14">
        Your prices for today compared to your competitors. Setup or change your
        competitors{" "}
        <span className="touchable-text header_blue_text__14">here.</span>
      </p>
      <p className=" small_text__16 mt-4">
        No competitors rates found please configure competitors by
        <span className="touchable-text header_blue_text__16">
          {" "}
          clicking here.
        </span>
      </p>
      <div className="mt-5">
        <div className="grid-container gap-4 ">
          {CustomDataTable.map((table, index) => (
            <div className=" p-3 rounded-3 room-box-shadow" key={index}>
              <h3 className="header_text__20">{table.heading}</h3>
              <p className=" small_text__14">{table.description}</p>
              <table className="datatable_table w-100 mt-3">
                <thead className="datatable_table_thead position-relative">
                  <tr className="datatable_table_tr">
                    {table.tHeadings.map((tHeading, idx) => (
                      <th className="datatable_table_th" key={idx}>
                        <h6 className="header_text__12">{tHeading}</h6>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="datatable_table_tbody">
                  {table.body.map((row, rowIndex) => (
                    <tr className="datatable_table_tr" key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td
                          className="datatable_table_td"
                          style={{ display: "table-cell" }}
                          key={cellIndex}
                        >
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PerformanceTab;
