import {
  formHandleTextArrayChange,
  handleDateNestedArrayChange,
  handleSelectNestedArrayChange,
  handleTextNestedArrayChange,
} from "@common/handleChange";
import { capitalizeFirstLetter } from "@common/text-helpers";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import DateMonthYearPicker from "@components/custom/Forms/Date";
import Input from "@components/custom/Forms/Input";
import MainTimePicker from "@components/custom/Forms/Time";
import TextArea from "@components/custom/Forms/textarea";
import { getValue } from "@utils/lodash";
import React from "react";

function WorkflowBuilder(props: any) {
  const renderForm = (item: any, index: number, label: string) => {
    //------------------------ Required ----------------------//
    // let required =
    //   getValue(item, `required`, false) ||
    //   getValue(item, `system_required`, false);
    let required = false;
    //----------------------- Due date time -------------------//
    switch (getValue(item, `fieldType`, "")) {
      case "text":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "conditions",
                  props.setRequest
                )
              }
              type={"text"}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name"
                  ? capitalizeFirstLetter(label)
                  : ""
              }${" "}${capitalizeFirstLetter(
                getValue(item, `fieldLabel`, "")
              )}`}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
            />
          </div>
        );
      case "email":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "conditions",
                  props.setRequest
                )
              }
              type={"email"}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `fieldLabel`, "")}`}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
            />
          </div>
        );
      case "tel":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "conditions",
                  props.setRequest
                )
              }
              type={"number"}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `fieldLabel`, "")}`}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
            />
          </div>
        );
      case "url":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "conditions",
                  props.setRequest
                )
              }
              type={"url"}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `fieldLabel`, "")}`}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
            />
          </div>
        );
      case "currency":
      case "percent":
      case "integer":
      case "decimal":
      case "auto_number":
      case "autonumber":
      case "bigint":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "conditions",
                  props.setRequest
                )
              }
              type={"number"}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              label={`${getValue(item, `fieldLabel`, "")}`}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
            />
          </div>
        );
      case "textarea":
        return (
          <div className="">
            <TextArea
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "conditions",
                  props.setRequest
                )
              }
              type={"textarea"}
              label={`${getValue(item, `fieldLabel`, "")}`}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              rows={2}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
            />
          </div>
        );
      case "date":
        return (
          <div className="">
            <DateMonthYearPicker
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDateNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "conditions",
                  props.setRequest
                )
              }
              type={"text"}
              label={`${getValue(item, `fieldLabel`, "")}`}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              hideLabel
              inline
              value={
                getValue(props, `value[${0}].key`, null)
                  ? getValue(props, `value[${0}].key`, null)
                  : null
              }
            />
          </div>
        );
      case "time":
        return (
          <div className="">
            <MainTimePicker
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDateNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "conditions",
                  props.setRequest
                )
              }
              type={"text"}
              label={`${getValue(item, `fieldLabel`, "")}`}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              showTimeInput
              hideLabel
              inline
              value={
                getValue(props, `value[${0}].key`, null)
                  ? getValue(props, `value[${0}].key`, null)
                  : null
              }
            />
          </div>
        );
      case "select":
        return (
          <div className="">
            <SearchToolTip
              {...item}
              label={`label`}
              data={getValue(item, `dropDownOpts`, [])}
              selectKey={"value"}
              onChange={(e: any) =>
                handleSelectNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "conditions",
                  props.setRequest,
                  "value"
                )
              }
              value={getValue(props, `value[${0}].key`, "")}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              hideLabel
            />
          </div>
        );
      case "lookup":
        return (
          <div className="">
            <SearchToolTip
              {...item}
              label={`label`}
              lookup_api={
                getValue(item, `lookupModule.api_name`, "")
                  ? getValue(item, `lookupModule.api_name`, "")
                  : getValue(item, `fieldId`, "") === "pipeline_stage_id"
                  ? `stage_${getValue(
                      props,
                      `request.moduleId`,
                      ""
                    )}_${getValue(props, `request.pipelineId`, "")}`
                  : ""
              }
              selectKey={"id"}
              onChange={(e: any) =>
                handleSelectNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "conditions",
                  props.setRequest,
                  "id"
                )
              }
              value={getValue(props, `value[${0}].key`, "")}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              hideLabel
            />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="workflow_builder_container">
      {renderForm(props.item, 0, "")}
    </div>
  );
}

export default WorkflowBuilder;
