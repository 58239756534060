import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllNotes = (
  module: string,
  main_id: string,
  queryRequest: string
) =>
  get(`${config.API_URL}/records/${module}/${main_id}/notes?${queryRequest}`);

export const getSpecificNote = (module: string, main_id: string, id: string) =>
  get(`${config.API_URL}/records/${module}/${main_id}/notes/${id}`);

export const createNote = (module: string, main_id: string, payload: object) =>
  post(`${config.API_URL}/records/${module}/${main_id}/notes`, payload);

export const updateNote = (
  module: string,
  main_id: string,
  id: string,
  payload: object
) =>
  patch(`${config.API_URL}/records/${module}/${main_id}/notes/${id}`, payload);

export const deleteNote = (module: string, main_id: string, id: string) =>
  Delete(`${config.API_URL}/records/${module}/${main_id}/notes/${id}`);
