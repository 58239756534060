import { InboxOutlined } from "@ant-design/icons";
import { formatString } from "@common/text-helpers";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Alert, Empty, Image, Modal } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { config } from "env";
import { useEffect, useState } from "react";

const BroadcastTemplateCarouselModal = (props: any) => {
  const {
    isModalOpen,
    handleOk,
    request,
    setRequest,
    activeCarouselTab,
    handleCancel,
    handleCarouseFileChange,
    handleFileRemove,
    carouselFileLists,
    simpleValidator,
    handleUploadCarouselDocuments,
  } = props;

  const imageFileDetails = {
    name: "file",
    multiple: false,
    beforeUpload: () => false,
    accept: ".jpg, .png, ",
    maxFileSize: 2 * 1024 * 1024,
    onRemove: (file: any) => handleFileRemove(file, 0),
  };

  const videoFileDetails = {
    name: "file",
    multiple: false,
    beforeUpload: () => false,
    accept: ".jpg, .png, ",
    maxFileSize: 2 * 1024 * 1024,
    onRemove: (file: any) => handleFileRemove(file, 0),
  };

  const [newRequest, setNewRequest] = useState(request);

  useEffect(() => {
    if (isModalOpen) {
      setNewRequest(request);
    }
  }, [isModalOpen, request]);

  const handleSave = () => {
    setRequest(newRequest);
    handleOk();
  };
  return (
    <Modal
      title={`Carousel card ${activeCarouselTab + 1}`}
      open={isModalOpen}
      onOk={handleSave}
      okText="Save"
      // onClose={}
      width={760}
      onCancel={() => handleCancel(activeCarouselTab)}
      key={activeCarouselTab}
      okButtonProps={{
        disabled: getValue(
          newRequest,
          `variableConfig.carousels.${activeCarouselTab}.buttons`,
          []
        ).some((item: object) => {
          const actionType = getValue(item, "type", "");
          const buttonText = getValue(item, "text", "");
          const buttonPhone = getValue(item, "value", "");
          const buttonUrl = getValue(item, "value", "");

          // Determine if any button data is missing based on actionType
          const isMissing =
            (actionType === "URL" && (!buttonUrl || !buttonText)) ||
            (actionType === "CALL_TO_ACTION" &&
              (!buttonPhone || buttonPhone.length <= 5 || !buttonText)) ||
            (actionType === "QUICK_REPLY" && !buttonText);

          // Return true if the item is missing required data or value is empty
          return isMissing || getValue(item, "value", "") === "";
        }),
      }}
    >
      <div className="d-flex align-items-center justify-content-between w-100 mb-3">
        <div className="w-60">
          <p className="form-label">Card Header</p>
          {/* <Dragger
            {...(getValue(request, "carousel[0].header.type", "") === "IMAGE"
              ? imageFileDetails
              : videoFileDetails)}
            fileList={
              (carouselFileLists && carouselFileLists[activeCarouselTab]) || []
            }
            onChange={(e) => handleCarouseFileChange(e, activeCarouselTab)}
            onRemove={(file) => handleFileRemove(file, activeCarouselTab)}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              {getValue(request, "carousel[0].header.type", "") === "IMAGE"
                ? "Accepted file types: .jpg, .png within 2MB size"
                : "Accepted file types: .mp4, .3gpp within 10MB size"}
            </p>
          </Dragger> */}
          <DragAndDropFileComponent
            uploadFile={handleUploadCarouselDocuments}
            supportedFormats=".jpeg,.jpg,.png; max file size 2 MB"
          />
        </div>
        <div className="w-40 d-flex align-items-center justify-content-center">
          {getValue(
            newRequest,
            `variableConfig.carousels[${0}].headerLinkDetail.mime_type`,
            ""
          ) ? (
            <Image
              src={
                getValue(
                  newRequest,
                  `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail.key`,
                  ""
                )
                  ? `https://${config.URL}/${getValue(
                      newRequest,
                      `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail.key`,
                      ""
                    )}`
                  : getValue(
                      newRequest,
                      `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail`,
                      ""
                    )
                  ? `https://${config.URL}/${getValue(
                      newRequest,
                      `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail`,
                      ""
                    )}`
                  : "/property/dummy-image.jpg"
              }
              width={200}
              height={108}
              style={{
                width: "200px",
                height: "108px",
                objectFit: "cover",
                border: "1px solid #efefef",
              }}
              className="rounded-2"
            />
          ) : (
            <>
              {getValue(
                newRequest,
                `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail.key`,
                ""
              ) ||
              getValue(
                newRequest,
                `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail`,
                ""
              ) ? (
                <video autoPlay className="carousal-video-preview w-80">
                  <source
                    src={
                      getValue(
                        newRequest,
                        `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail.key`,
                        ""
                      )
                        ? `https://${config.URL}/${getValue(
                            newRequest,
                            `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail.key`,
                            ""
                          )}`
                        : `https://${config.URL}/${getValue(
                            newRequest,
                            `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail`,
                            ""
                          )}`
                    }
                    type="video/mp4"
                  />
                </video>
              ) : (
                <Empty
                  description="Video not found"
                  className="rounded-2 px-4 pt-2 mt-4"
                  style={{
                    border:
                      !getValue(
                        newRequest,
                        `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail.key`,
                        ""
                      ) &&
                      !getValue(
                        newRequest,
                        `variableConfig.carousels[${activeCarouselTab}].headerLinkDetail`,
                        ""
                      )
                        ? "1px solid red"
                        : "",
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <InputRuleForm
        inputType="TEXTAREA"
        label="Card Body"
        placeholder={`Hi [[name]]!🖐\n\nThe awesome products 😍 from [[brand_name]] are waiting to be yours!\n Get your products here👇`}
        value={getValue(
          newRequest,
          `variableConfig.carousels.${activeCarouselTab}.body`,
          ""
        )}
        name="body"
        onChange={(e: any) => {
          setNewRequest((prevRequest: any) => {
            return {
              ...prevRequest,
              variableConfig: {
                ...prevRequest.variableConfig,
                carousels: prevRequest.variableConfig.carousels.map(
                  (carousel: any, i: number) => {
                    if (activeCarouselTab !== i) return carousel;
                    return {
                      ...carousel,
                      body: e.target.value,
                    };
                  }
                ),
              },
            };
          });
        }}
        // validator={simpleValidator}
        validLeft
        showCount
        maxLength="1024"
      />
      {getValue(
        newRequest,
        `variableConfig.carousels.${activeCarouselTab}.buttons`,
        []
      ).map((item: object, aIndex: number) => {
        if (getValue(item, `type`, ""))
          return (
            <div className="mt-2">
              <p className="small_text__14 mt-3">
                {formatString(getValue(item, `type`, ""))}
              </p>
              {getValue(item, `type`, "") === "QUICK_REPLY" ? (
                <InputRuleForm
                  inputType="TEXT"
                  onChange={(e: any) => {
                    setNewRequest((prevRequest: any) => {
                      return {
                        ...prevRequest,
                        variableConfig: {
                          ...prevRequest.variableConfig,
                          carousels: prevRequest.variableConfig.carousels.map(
                            (carousel: any, i: number) => {
                              if (activeCarouselTab !== i) return carousel;
                              return {
                                ...carousel,
                                buttons: carousel.buttons.map(
                                  (but: any, bIndex: number) => {
                                    if (aIndex !== bIndex) return but;
                                    return {
                                      ...but,
                                      value: e.target.value,
                                    };
                                  }
                                ),
                              };
                            }
                          ),
                        },
                      };
                    });
                  }}
                  name="text"
                  label="text"
                  value={getValue(item, "value", "")}
                  placeholder="eg. Yes"
                  maxLength={25}
                  showCount
                  // validator={simpleValidator}
                />
              ) : (
                <div className="d-flex align-items-center justify-content-between">
                  <div className="w-50 ">
                    <InputRuleForm
                      inputType="TEXT"
                      onChange={(e: any) => {
                        setNewRequest((prevRequest: any) => {
                          return {
                            ...prevRequest,
                            variableConfig: {
                              ...prevRequest.variableConfig,
                              carousels:
                                prevRequest.variableConfig.carousels.map(
                                  (carousel: any, i: number) => {
                                    if (activeCarouselTab !== i)
                                      return carousel;
                                    return {
                                      ...carousel,
                                      buttons: carousel.buttons.map(
                                        (but: any, bIndex: number) => {
                                          if (aIndex !== bIndex) return but;
                                          return {
                                            ...but,
                                            text: e.target.value,
                                          };
                                        }
                                      ),
                                    };
                                  }
                                ),
                            },
                          };
                        });
                      }}
                      name={"text"}
                      value={getValue(item, "text", "")}
                      placeholder={
                        getValue(item, `action_type`, "") === "URL"
                          ? "eg. Visit website"
                          : "eg. Call us"
                      }
                      label={"Title"}
                      maxLength={20}
                      // showCount
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="w-50 ps-2">
                    {getValue(item, "actionType", "") === "call" ? (
                      <PhoneInputComponent
                        placeholder="Enter phone number (+91...)"
                        value={getValue(item, "value", "")}
                        onChange={(e: any) => {
                          setNewRequest((prevRequest: any) => {
                            return {
                              ...prevRequest,
                              variableConfig: {
                                ...prevRequest.variableConfig,
                                carousels:
                                  prevRequest.variableConfig.carousels.map(
                                    (carousel: any, i: number) => {
                                      if (activeCarouselTab !== i)
                                        return carousel;
                                      return {
                                        ...carousel,
                                        buttons: carousel.buttons.map(
                                          (but: any, bIndex: number) => {
                                            if (aIndex !== bIndex) return but;
                                            return {
                                              ...but,
                                              value: e,
                                            };
                                          }
                                        ),
                                      };
                                    }
                                  ),
                              },
                            };
                          });
                        }}
                        label={"Phone Number"}
                        // validator={simpleValidator}
                      />
                    ) : (
                      <InputRuleForm
                        inputType="TEXT"
                        onChange={(e: any) => {
                          setNewRequest((prevRequest: any) => {
                            return {
                              ...prevRequest,
                              variableConfig: {
                                ...prevRequest.variableConfig,
                                carousels:
                                  prevRequest.variableConfig.carousels.map(
                                    (carousel: any, i: number) => {
                                      if (activeCarouselTab !== i)
                                        return carousel;
                                      return {
                                        ...carousel,
                                        buttons: carousel.buttons.map(
                                          (but: any, bIndex: number) => {
                                            if (aIndex !== bIndex) return but;
                                            return {
                                              ...but,
                                              value: e.target.value,
                                            };
                                          }
                                        ),
                                      };
                                    }
                                  ),
                              },
                            };
                          });
                        }}
                        name={"url"}
                        value={getValue(item, "value", "")}
                        placeholder={`Enter ${
                          getValue(item, "type", "") === "url"
                            ? "url"
                            : "Phone Number"
                        }`}
                        label={
                          getValue(item, "type", "") === "url" ? "URL" : "Phone"
                        }
                        // validator={simpleValidator}
                        // maxLength={20}
                        // showCount
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
      })}
    </Modal>
  );
};

export default BroadcastTemplateCarouselModal;
