import React from "react";
import "./nodata.scss";
function ContactsNoDataPage(props: any) {
  return (
    <div className="n_container">
      <div className="nodata_container">
        <div className="d-flex justify-content-center align-items-center">
          <div className="nodata_container_main">
            <h6 className="nodata_container_header">
              Business is no fun without people
            </h6>
            <p className="nodata_container_desc mt-2">
              Create and manage your contacts, all in one place. After, you can
              sort, search, and filter to find what you need and hide what you
              don’t.
            </p>
          </div>
          <div className="w-10"></div>
          <img
            src={"/nodata/contacts.svg"}
            alt="SVG Image"
            className="nodata_container_image"
          />
        </div>
      </div>
    </div>
  );
}

export default ContactsNoDataPage;
