import { getValue } from "@utils/lodash";
import { Link } from "react-router-dom";
import "./CreateHeader.scss";
import { concatString } from "common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";
import { Cross, X } from "lucide-react";
import { Button } from "antd";

export default function CreateHeader(props: any) {
  const { title } = props;
  return (
    <section className={`create-header`}>
      <div className="container-fluid-padding d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center gap-2">
          <Link
            to={
              getValue(props, `backUrl`, "")
                ? getValue(props, `backUrl`, "")
                : "/more/organization/users/user-information"
            }
          >
            {/* <img
              src="/images/icons/close.svg"
              className={`cursor-pointer create-header__cancel-button`}
            /> */}
            <X size={20} />
          </Link>
          <h6 className={`create-header__title"]} normal-font`}>
            {title ? concatString(title, 20) : ""}
          </h6>
        </div>
        {/* {!getValue(props, `hideSubmit`, "") ? (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <Button
              size="large"
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                props.onSubmit();
              }}
              disabled={getValue(props, `isLoading`, false) ? true : false}
              loading={getValue(props, `isLoading`, false)}
            >
              Submit
            </Button>
          </div>
        ) : null} */}
      </div>
    </section>
  );
}
