// import "../../PendingTaskMainBody.scss";
import { getValue } from "@utils/lodash";
import { useNavigate } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";

function EmailNavViews(props: any) {
  const { pendingLinkArray } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  const navigate = useNavigate();
  const handleNavigate = (tab: number) => {
    delete urlParams.activeActivitySubTab;
    let payload = {
      ...urlParams,
      activeEmailSubTab: tab,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  return (
    <ul
      className="d-flex align-items-center justify-content-start"
      style={{ borderBottom: "1px solid #efefef" }}
    >
      {pendingLinkArray.map((linkObj: object, index: number) => {
        return (
          <li
            key={`pending-${index}`}
            className={`${
              index + 1 == getValue(urlParams, `activeEmailSubTab`, "") ||
              (!getValue(urlParams, `activeEmailSubTab`, "") && index === 0)
                ? "pending-wrapper__main-body-list_active"
                : "pending-wrapper__main-body-list"
            }`}
            onClick={() => handleNavigate(index + 1)}
          >
            <a
              className={"pending-wrapper__main-body-link"}
              // onClick={toggleCreateNote}
            >
              {getValue(linkObj, `title`, "")}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default EmailNavViews;
