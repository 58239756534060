import { getValue } from "@utils/lodash";
import { Checkbox } from "antd";

const SheetCheckboxEditor = ({
  col,
  row,
  fieldCol,
  handleDisplayItem,
  handleInputChange,
}: any) => {
  return (
    <Checkbox
      checked={handleDisplayItem(col, row)}
      onChange={(e) =>
        handleInputChange(
          getValue(row, "id", ""),
          col,
          e.target.checked,
          fieldCol
        )
      }
    />
  );
};
export default SheetCheckboxEditor;
