import { useState } from "react";
import { getValue } from "@utils/lodash";
// import "../../PendingTaskMainBody.scss";
import TaskPopup from "@components/Dialogs/CenteredAlignedPopup/TaskPopup";
import DraftJSEditor from "@components/custom/DraftJSEditor";
import { handleSelectChange } from "@common/handleChange";
import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";

function CommonDetailNotesPopup(props: any) {
  const {
    isRollsPopupVisibile,
    setRollsPopupVisisbility,
    request,
    setRequest,
    handleSubmit,
    isLoading,
    reset,
  } = props;

  const [types] = useState([
    { value: "EMAIL", label: "Email" },
    { value: "CALL", label: "Call" },
    { value: "TODO", label: "To-Do" },
  ]);
  return (
    <TaskPopup
      isPopupVisible={isRollsPopupVisibile}
      addCustomClass={"rolls-popup"}
      toggle={() => {
        setRollsPopupVisisbility(false);
      }}
      handleCloseButtonClick={() => {
        setRollsPopupVisisbility(!isRollsPopupVisibile);
        reset();
      }}
      title={getValue(props, `title`, "")}
      width={"570px"}
      submit_text={"Submit"}
      handleSubmitButtonClick={handleSubmit}
      isLoading={isLoading}
    >
      {isRollsPopupVisibile && (
        <div>
          <div className="">
            <DraftJSEditor
              toolbarHidden
              editorState={getValue(request, `content`, "")}
              handleChangeEditor={props.handleChangeEditor}
              name="content"
              placeholder="Enter here"
              editorStyle={{
                background: "white",
                paddingLeft: "30px",
                minHeight: "30vh",
              }}
            />
          </div>
          <div
            className={` notes_select_container d-flex p-2 align-items-center`}
          >
            <div className="ms-3">
              <CustomCheckbox />
            </div>
            <div className="w-75 d-flex align-items-center">
              <p className="mx-2">Create a</p>
              <TextHeaderTooltip
                title={`Follow up type`}
                data={types}
                label={`label`}
                value={getValue(request, `follow_up_type`, "")}
                onChange={(e: any) =>
                  handleSelectChange(e, "follow_up_type", request, setRequest)
                }
                selectKey={"value"}
                width={"270px"}
                editInline
              />
              <p className="mx-2">task to follow up</p>
              <TextHeaderTooltip
                title={`Follow up Date`}
                data={dateFilters}
                label={`label`}
                value={getValue(request, `follow_up_datetime`, "")}
                onChange={(e: any) =>
                  handleSelectChange(
                    e,
                    "follow_up_datetime",
                    request,
                    setRequest
                  )
                }
                selectKey={"value"}
                width={"270px"}
                editInline
              />
            </div>
          </div>
        </div>
      )}
    </TaskPopup>
  );
}

export default CommonDetailNotesPopup;
