import { config } from "../env";
import { get, post } from "./helpers/http-handler";

export const getAllSmartFilterFields = (queryRequest: string) =>
  get(`${config.API_URL}/smart-filters/fields?${queryRequest}`);

export const getSmartFilterGroupFields = (queryRequest: string) =>
  get(`${config.API_URL}/smart-filters/group-by/fields?${queryRequest}`);

export const commonCustomFilter = (
  module: string,
  queryRequest: string,
  payload: object
) =>
  post(
    `${config.API_URL}/records/${module}/custom/filter?${queryRequest}`,
    payload
  );
