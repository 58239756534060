import React, { useState } from "react";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

function ChatbotNavviews(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabs] = useState([
    {
      value: "Responses",
      route: `/${getValue(params, `orgId`, "")}/chatbot/logs/${getValue(
        params,
        `id`,
        ""
      )}`,
    },
    {
      value: "Settings",
      route: `/${getValue(params, `orgId`, "")}/chatbot/settings/${getValue(
        params,
        `id`,
        ""
      )}`,
    },
    {
      value: "Share",
      route: `/${getValue(params, `orgId`, "")}/chatbot/share/${getValue(
        params,
        `id`,
        ""
      )}`,
    },
    {
      value: "Manage",
      route: `/${getValue(params, `orgId`, "")}/chatbot/manage/${getValue(
        params,
        `id`,
        ""
      )}`,
    },
  ]);
  return (
    <ul
      className="d-flex align-items-center justify-content-between px-5 pt-3 chatbot-navbar"
      style={{ borderBottom: "1px solid #efefef" }}
    >
      <div className="d-flex align-items-center justify-content-start">
        {tabs.map((linkObj: object, index: number) => {
          return (
            <li
              key={`pending-${index}`}
              className={`pt-1 ${
                getValue(location, `pathname`, "") ==
                getValue(linkObj, `route`, "")
                  ? "activebar__main-body-list_active"
                  : "activebar__main-body-list"
              }`}
              onClick={() => navigate(getValue(linkObj, `route`, ""))}
            >
              <a className={"activebar__main-body-link p-2 "}>
                {formatString(getValue(linkObj, `value`, ""))}
              </a>
            </li>
          );
        })}
      </div>
      <div className="d-flex align-items-center">
        {props.showExport && (
          <Tooltip title="Export">
            <Button
              // type="primary"
              // shape="round"
              icon={<DownloadOutlined />}
              size="middle"
              onClick={props.handleExport}
            />
          </Tooltip>
        )}
      </div>
    </ul>
  );
}

export default ChatbotNavviews;
