import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Register the necessary components
ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

function DynamicBarChartComponent(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [isStacked, setIsStacked] = useState(false);

  useEffect(() => {
    // Extract the second object in the graph data
    const secondObject = getValue(props, "graph[1]", {});

    const count = getValue(secondObject, "count", null);
    const children = getValue(secondObject, "child", []);

    const defaultColors = ["#157aed", "#92C5F9", "#003366", "#0066CC"];

    const generateDynamicColor = (index: number) => {
      // Generate color dynamically if needed beyond the default palette
      return `rgba(${(index * 35) % 256}, ${(index * 75) % 256}, ${
        192 + ((index * 10) % 64)
      }, 0.5)`;
    };

    if (children && children.length > 0) {
      // If `child` exists, create a stacked bar chart
      const labels = ["Data"]; // Single label for the stack
      const datasets = children.map((child: any, index: number) => ({
        label: getValue(child, "key", `Dataset ${index + 1}`),
        data: [getValue(child, "count", 0)], // Each child count is a data point in its own dataset
        backgroundColor: defaultColors[index] || generateDynamicColor(index),
        borderColor:
          defaultColors[index] ||
          generateDynamicColor(index).replace("0.5", "1"),
        borderWidth: 1,
      }));

      setData({
        labels: labels,
        datasets: datasets,
      });
      setIsStacked(true); // Enable stacked option
    } else if (count !== null) {
      // If `count` exists but no `child`, create a normal bar chart
      setData({
        labels: ["Data Count"], // Label for the single bar
        datasets: [
          {
            label: getValue(props, "module_field.label", "Data Count"),
            data: [count], // Single data point for the bar chart
            backgroundColor: defaultColors[0],
            borderColor: defaultColors[0].replace("0.5", "1"),
            borderWidth: 1,
          },
        ],
      });
      setIsStacked(false); // Disable stacked option
    }
  }, [props.graph, props.module_field.label]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
    },
    scales: {
      x: {
        stacked: isStacked,
      },
      y: {
        beginAtZero: true,
        stacked: isStacked,
      },
    },
  };

  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, "name", "")}</p>
      <Bar data={data} options={options} />
    </div>
  );
}

export default DynamicBarChartComponent;
