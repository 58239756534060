import { Link } from "react-router-dom";
import "./CommonSubHeader.scss";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
// import CustomInputSelect from "@components/Custom/CustomDropdown";

export default function CommonSubHeader(props: any) {
  const {
    pipeline,
    options,
    handleOpenPopup,
    selectedPipeline,
    handleChangePipeline,
    handleClickPipeline,
    hideClose,
  } = props;
  return (
    <div className={"common-subheader-wrapper"}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          <Link to={props.linkAddress}>
            <img src="/images/icons/close.svg" className="img-fluid" />
          </Link>
          <h6 className={`${"common-subheader-wrapper__submenu-name"} mx-3`}>
            {props.subHeaderTitle}
          </h6>
          {props.isTitleVisible && (
            <>
              <div>
                <img
                  src="/images/header/right-arrow.svg"
                  className="img-fluid"
                />
              </div>
              <p className={"Service-text"}>Service</p>
            </>
          )}
          {/* {pipeline && (
            <CustomInputSelect
              Placeholder={"Create Pipeline"}
              options={options}
              label="label"
              id="id"
              name="Location"
              handleOpenPopup={handleOpenPopup}
              // pipeline={pipeline}
              value={selectedPipeline}
              handleChange={handleChangePipeline}
              handleClick={handleClickPipeline}
              removeClose={hideClose}
            />
          )} */}
        </div>
        {!props.hideButtons && (
          <div className="d-flex align-items-center gap">
            {props.isTitleVisible && (
              <button className={"preview-btn"}>Preview</button>
            )}
            <ButtonComponent
              buttonType="secondary"
              buttonText="Cancel"
              onClickHandler={props.handleCancel}
            />

            {props.isSubmitVisible ? (
              <ButtonComponent buttonType="primary" buttonText="Submit" />
            ) : (
              <ButtonComponent
                buttonType="primary"
                buttonText={
                  getValue(props, `submitLoading`, false)
                    ? "Please wait..."
                    : "Save"
                }
                onClickHandler={props.handleSubmit}
                disabled={
                  getValue(props, `submitLoading`, false) ? true : false
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
