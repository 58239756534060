import React, { useState } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import "./index.scss";
import { getValue } from "@utils/lodash";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";
import CloseSvgComponent from "@assets/svg/close";
import CloseMainSvgComponent from "@assets/svg/close-main";
import ProfileCircle from "@components/common/ProfileCircle";
import { getColorCode, getTextColor } from "@common/colors";
import { getOnlyFirstLetterOfWord } from "@common/text-helpers";

const TagInput = (props: any) => {
  const styles = {
    fontFamily: "sans-serif",
    width: "500px",
    border: "1px solid #eee",
    background: "#f3f3f3",
    padding: "25px",
    margin: "20px",
  };

  const { request, setRequest } = props;
  const [emails, setTags] = useState([]);
  const [invalidTags, setInvalidTags] = useState([]);

  const handleTagChange = (emails: any) => {
    // Validate the emails and filter out invalid ones
    const validEmails = emails.filter((tag: string) => isValidTag(tag));
    setRequest(validEmails);

    // Store the invalid emails separately for displaying feedback
    const invalidTags = emails.filter((tag: string) => !isValidTag(tag));
    setInvalidTags(invalidTags);
  };

  const handleChange = (emails: string[]) => {
    // if(isValidTag(emails))
    let filterEmails = emails.filter((item: any) => isValidTag(item));
    setRequest({ ...request, [props.name]: filterEmails });
  };
  const isValidTag = (tag: any) => {
    // Check if the tag is a valid email address using a regex pattern
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(tag);
  };

  return (
    <div className="ms-3 w-100">
      {/* <TagsInput
        value={
          getValue(props, `emails.length`, 0) > 0
            ? getValue(props, `emails`, [])
            : []
        }
        
        onChange={handleChange}
        inputProps={{
          placeholder: props.placeholder, // Set your desired placeholder text here
        }}
        disabled={props.disabled}
      /> */}
      <ReactMultiEmail
        placeholder="Enter recipients"
        emails={
          getValue(props, `emails.length`, 0) > 0
            ? getValue(props, `emails`, [])
            : []
        }
        style={{ border: "none" }}
        onChange={handleChange}
        enableSpinner
        allowDuplicate={false}
        allowDisplayName={true}
        getLabel={(
          email: string,
          index: number,
          removeEmail: (index: number) => void
        ) => {
          return (
            <div data-tag key={index} className="d-flex align-items-center">
              <span
                data-tag-handle
                onClick={() => removeEmail(index)}
                className=""
              >
                <div
                  className="profile_circle_email"
                  style={{
                    background: getColorCode(getOnlyFirstLetterOfWord(email)),
                    // border: "1px solid green",
                  }}
                >
                  <p
                    className="title"
                    style={{
                      color: getTextColor(getOnlyFirstLetterOfWord(email)),
                    }}
                  >
                    {getOnlyFirstLetterOfWord(email)}
                  </p>
                </div>
              </span>
              <span className="mx-2">{email}</span>
              <span
                data-tag-handle
                onClick={() => removeEmail(index)}
                className="react_mail_close_icon"
              >
                {/* <DropdownCloseSvgComponent size={18}/> */}
                <CloseMainSvgComponent size={18} />
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default TagInput;
