import React, { useState } from "react";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
function FieldsEditPopup(props: any) {
  const { request, setRequest } = props;
  return (
    <div className={`${"add-more-rolls-wrapper"} mt-3`}>
      <div className={"popup-container"}>
        <InputRuleForm
          inputType="TEXT"
          label={"Name"}
          placeholder="Enter"
          name={"name"}
          value={getValue(request, `name`, "")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          // validator={simpleValidator}
          // validLeft
          // mandatory
          // disabled
        />
        {/* <div className={ClassNames["switch-container"]}>
          <label htmlFor="">Required</label>
          <Switch
            isActive={getValue(request, `required`, false)}
            handleChangeStatus={(e: any) =>
              setRequest({
                ...request,
                required: !getValue(request, `required`, false),
              })
            }
          />
        </div> */}
      </div>
    </div>
  );
}

export default FieldsEditPopup;
