import { config } from "../env";
import {
  Delete,
  DeleteWithPayload,
  get,
  getWithToken,
  patch,
  post,
  postFileUpload,
} from "./helpers/http-handler";

export const listAllModules = (queryRequest: string) =>
  get(`${config.API_URL}/settings/modules?${queryRequest}`);

export const getSpecificModule = (name: string) =>
  get(`${config.API_URL}/settings/modules/${name}`);

export const UpdateModule = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/modules/${id}`, payload);

export const getModuleFieldsById = (id: string) =>
  get(`${config.API_URL}/settings/modules/${id}/form-fields`);

export const defaultFields = (queryRequest: string) =>
  get(`${config.API_URL}/settings/modules/default-fields?${queryRequest}`);

export const UpdateSalesPipelineInfo = (id: string, payload: object) =>
  patch(`${config.API_URL}/sale-pipelines/${id}/info`, payload);

export const UpdateSalesPipelineStages = (id: string, payload: object) =>
  patch(`${config.API_URL}/sale-pipelines/stages/${id}`, payload);

export const UpdateSalesPipelineFields = (id: string, payload: object) =>
  patch(`${config.API_URL}/sale-pipelines/${id}/fields`, payload);

export const UpdateSalesPipelineField = (id: string, payload: object) =>
  patch(`${config.API_URL}/sale-pipelines/${id}/field`, payload);
