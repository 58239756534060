import AddSvgComponent from "@assets/svg/add";
import InfoSvgComponent from "@assets/svg/info";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { getValue } from "@utils/lodash";
import { Alert, Badge, Button, Empty, Image, Select, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import CarouselNavViews from "../../components/template-messages/components/carousel-nav-views";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { Link } from "react-router-dom";
import { formatString } from "@common/text-helpers";
import { config } from "env";
import InputRuleForm from "@components/InputRuleForm/form";
import {
  handlePhoneNestedRequestArrayChange,
  handleSelectNestedRequestArrayChange,
  handleSelectRequestArrayChange,
  handleTextNestedRequestArrayChange,
  handleTextRequestArrayChange,
} from "@common/handleChange";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import EditSvgComponent from "@assets/svg/edit";
import {
  CopyOutlined,
  DeleteOutlined,
  LinkOutlined,
  PaperClipOutlined,
  PhoneOutlined,
  SendOutlined,
} from "@ant-design/icons";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import ReplySvgComponent from "@assets/svg/whatsapp-reply";
import CallSvgComponent from "@assets/svg/phone";
import UrlSvgComponent from "@assets/svg/custom/url";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const TemplateCarousel = (props: any) => {
  const {
    request,
    setRequest,
    editId,
    activeCarouselTab,
    setActiveCarouselTab,
    handleAddCarousel,
    handleRemoveCarousel,
    handleCarouselTab,
    handlChangeType,
    handleCarouseFileChange,
    carouselType,
    handleCarouselTypeChange,
    button1Type,
    handleButton1TypeChange,
    button2Type,
    handleButton2TypeChange,
    showModal,
    handleClone,
    templateData,
  } = props;

  const [carouselHeaderEnum] = useState([
    { value: "IMAGE", label: "Image Carousel" },
    { value: "VIDEO", label: "Video Carousel" },
  ]);
  const [buttonTypeEnum] = useState([
    {
      value: "QUICK_REPLY",
      label: (
        <>
          <SendOutlined size={12} /> Quick Reply
        </>
      ),
    },
    {
      value: "CALL_TO_ACTION",
      label: (
        <>
          <PhoneOutlined size={12} /> Call to Action
        </>
      ),
    },
    {
      value: "URL",
      label: (
        <>
          <LinkOutlined size={12} /> URL
        </>
      ),
    },
  ]);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const newCarousel = reorder(
      request.carousel,
      result.source.index,
      result.destination.index
    );

    setRequest((prevRequest: any) => ({
      ...prevRequest,
      carousel: newCarousel,
    }));
  };

  return (
    <div className="mt-3 mb-5">
      <div className="">
        <div className="d-flex align-items-center gap-2">
          <h6 className="">Carousel Format</h6>
          <Tooltip
            title="The media header format and button types must be the same across all cards that make up a carousel template."
            className="mb-1"
          >
            <span>
              <InfoSvgComponent color="#676767" size="16" />
            </span>
          </Tooltip>
        </div>
        <p className="greyed-description">
          The media header format and button types must be the same across all
          cards that make up a carousel template.
        </p>
        {/* <div className="d-flex align-items-center">
          {getValue(request, `carousel.length`, 0) > 0 &&
            getValue(request, `carousel.length`, 0) < 10 && (
              <div
                className="d-flex cursor-pointer "
                onClick={() => {
                  handleAddCarousel();
                  setActiveCarouselTab(getValue(request, `carousel.length`, 0));
                }}
              >
                <AddSvgComponent color={"#1a73e8"} />
                <h6 className="header_blue_text__16">Add more</h6>
              </div>
            )}
          {getValue(request, `carousel.length`, 0) > 1 && (
            <div
              className="d-flex cursor-pointer  ms-4"
              onClick={() => {
                handleRemoveCarousel(activeCarouselTab - 1);
                setActiveCarouselTab(
                  activeCarouselTab === 1 ? 1 : activeCarouselTab - 1
                );
              }}
            >
              <RemoveCircleSvgComponent color={"red"} />
              <h6 className="header_blue_text__16 color_danger">Remove</h6>
            </div>
          )}
        </div> */}
      </div>
      <div className="d-grid gap-2 my-3">
        <div className="d-flex align-items-center">
          <div className="w-30">
            <p className="form-label">
              Carousel Type <span style={{ color: "red" }}>*</span>
            </p>
          </div>
          <div className="w-40">
            <Select
              defaultValue={carouselType}
              size="large"
              onChange={handleCarouselTypeChange}
              options={[
                { value: "IMAGE", label: "Image Carousel" },
                { value: "VIDEO", label: "Video Carousel" },
              ]}
              className="w-100"
            />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="w-30">
            <p className="form-label w-100">
              Button 1 type <span style={{ color: "red" }}>*</span>
            </p>
          </div>
          <div className="w-40">
            <Select
              size="large"
              className="w-100"
              value={button1Type}
              onChange={handleButton1TypeChange}
              options={buttonTypeEnum}
              placeholder="Select Button 1 Type"
            />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="w-30">
            <p className="form-label">Button 2 type</p>
          </div>
          <div className="w-40">
            <Select
              size="large"
              className="w-100"
              value={button2Type}
              onChange={handleButton2TypeChange}
              options={buttonTypeEnum}
              allowClear
              placeholder="Select Button 2 Type"
            />
          </div>
        </div>
      </div>
      <p className="greyed-description">
        <i>
          Cards must have a media header (image or video), body text, and at
          least 1 button. Supports 2 buttons. Buttons can be the same or a mix
          of{" "}
          <Link
            to="https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/components#quick-reply-buttons"
            target="__blank"
          >
            quick reply buttons
          </Link>
          ,{" "}
          <Link
            to="https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/components#phone-number-buttons"
            target="__blank"
          >
            phone number buttons
          </Link>
          , or{" "}
          <Link
            to="https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/components#url-buttons"
            target="__blank"
          >
            URL buttons
          </Link>
          .
        </i>
      </p>
      <div className="mt-4">
        <h6 className="">Carousel Cards</h6>
        <p className="greyed-description">
          Carousel templates support up to 10 carousel cards.
        </p>
        {getValue(request, "carousel.length", "") > 0 && (
          <div className="template_main_button_container mt-3 position-relative">
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              <Droppable droppableId="carousel">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {getValue(request, "carousel", "").map(
                      (item: any, index: any) => (
                        <Draggable
                          key={index}
                          draggableId={`${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="py-2 pe-2  d-flex align-items-center gap-2"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div
                                className="drag-handle"
                                {...provided.dragHandleProps}
                              >
                                <DragIndicatorSvgComponent color={"#aeaeae"} />
                              </div>
                              <div className="w-100">
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                  <p>Card {index + 1}</p>
                                  <div className="d-flex align-items-center gap-2">
                                    <Tooltip title="Edit">
                                      <div
                                        onClick={() => showModal(index)}
                                        className="cursor-pointer"
                                      >
                                        <EditSvgComponent />
                                      </div>
                                    </Tooltip>
                                    <Tooltip title="Clone">
                                      <div
                                        onClick={() => handleClone(index)}
                                        className="cursor-pointer"
                                      >
                                        <CopyOutlined />
                                      </div>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <div
                                        onClick={() =>
                                          handleRemoveCarousel(index)
                                        }
                                        className="cursor-pointer"
                                      >
                                        <DeleteOutlined />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div
                                  className="d-flex grid-container gap-3 p-3 rounded-3 bg-white"
                                  style={{
                                    border:
                                      !request.carousel[0]?.header?.link ||
                                      Object.keys(
                                        request.carousel[0]?.header?.link
                                      ).length === 0
                                        ? "1px solid red"
                                        : "1px solid #fff",
                                  }}
                                >
                                  {getValue(
                                    request,
                                    "carousel[0].header.type",
                                    ""
                                  ) === "IMAGE" ? (
                                    <Image
                                      src={
                                        getValue(item, `header.link.key`, "")
                                          ? `https://${config.URL}/${getValue(
                                              item,
                                              `header.link.key`,
                                              ""
                                            )}`
                                          : getValue(item, `header.link`, "")
                                          ? `https://${config.URL}/${getValue(
                                              item,
                                              `header.link`,
                                              ""
                                            )}`
                                          : "/property/dummy-image.jpg"
                                      }
                                      width={200}
                                      height={108}
                                      style={{
                                        width: "200px",
                                        height: "108px",
                                        border:
                                          !getValue(
                                            item,
                                            `header.link.key`,
                                            ""
                                          ) &&
                                          !getValue(item, `header.link`, "")
                                            ? "1px solid red"
                                            : "",
                                      }}
                                      className="rounded-2"
                                    />
                                  ) : (
                                    <>
                                      {getValue(item, "header.link.key", "") ||
                                      getValue(item, "header.link", "") ? (
                                        <video
                                          autoPlay
                                          className="carousal-video-preview w-100"
                                        >
                                          <source
                                            src={
                                              getValue(
                                                item,
                                                `header.link.key`,
                                                ""
                                              )
                                                ? `https://${
                                                    config.URL
                                                  }/${getValue(
                                                    item,
                                                    `header.link.key`,
                                                    ""
                                                  )}`
                                                : `https://${
                                                    config.URL
                                                  }/${getValue(
                                                    item,
                                                    `header.link`,
                                                    ""
                                                  )}`
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      ) : (
                                        <Empty
                                          description="Video not found"
                                          className="rounded-2 px-4 py-2"
                                          style={{
                                            border:
                                              !getValue(
                                                item,
                                                `header.link.key`,
                                                ""
                                              ) &&
                                              !getValue(item, `header.link`, "")
                                                ? "1px solid red"
                                                : "",
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                  {/* </>
                                )} */}
                                  <div className="w-100">
                                    <p className="small_text__14">
                                      {getValue(item, "body.length", "") > 3 ? (
                                        getValue(item, "body", "")
                                      ) : (
                                        <Alert
                                          message="Missing body"
                                          type="error"
                                        />
                                      )}
                                    </p>
                                    <div className="d-flex w-100 gap-1 flex-grow-1 mt-2">
                                      {item.buttons.map(
                                        (button: any, buttonIndex: any) => {
                                          const actionType = getValue(
                                            button,
                                            "action_type",
                                            ""
                                          );
                                          const buttonText = getValue(
                                            button,
                                            "text",
                                            ""
                                          );
                                          const buttonPhone = getValue(
                                            button,
                                            "phone",
                                            ""
                                          );
                                          const buttonUrl = getValue(
                                            button,
                                            "url",
                                            ""
                                          );

                                          const isMissing =
                                            (actionType === "URL" &&
                                              (!buttonUrl || !buttonText)) ||
                                            (actionType === "CALL_TO_ACTION" &&
                                              (!buttonPhone ||
                                                buttonPhone.length <= 5 ||
                                                !buttonText)) ||
                                            (actionType === "QUICK_REPLY" &&
                                              !buttonText);

                                          const color = isMissing
                                            ? "red"
                                            : "blue";

                                          return actionType ? (
                                            <Tag
                                              key={buttonIndex}
                                              color={color}
                                              className="px-4 py-1 d-flex align-items-center gap-1"
                                            >
                                              {actionType === "QUICK_REPLY" ? (
                                                <ReplySvgComponent
                                                  color={
                                                    buttonText
                                                      ? "#408dfb"
                                                      : "#cf1322"
                                                  }
                                                  size={16}
                                                />
                                              ) : actionType ===
                                                "CALL_TO_ACTION" ? (
                                                <CallSvgComponent
                                                  color={
                                                    isMissing
                                                      ? "#cf1322"
                                                      : "#408dfb"
                                                  }
                                                  size={14}
                                                />
                                              ) : actionType === "URL" ? (
                                                <UrlSvgComponent
                                                  color={
                                                    isMissing
                                                      ? "#cf1322"
                                                      : "#408dfb"
                                                  }
                                                  size={14}
                                                />
                                              ) : null}

                                              {isMissing
                                                ? "Missing"
                                                : buttonText}
                                            </Tag>
                                          ) : null;
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}

        <Button
          type="primary"
          onClick={() => {
            handleAddCarousel();
          }}
          className="mt-3"
          disabled={getValue(request, "carousel.length", "") >= 10}
        >
          Add carousel card
        </Button>
      </div>
    </div>
  );
};

export default TemplateCarousel;
