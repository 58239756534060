import ButtonComponent from "@components/Form/Button/Button";
import PropertyDetailsLayout from "@layouts/PropertyLayout/property-details-layout";
import React, { useState } from "react";
import ReservationTab from "../settings/components/reservation-tab";
import PerformanceTab from "../settings/components/performance-tab";
// import {
//   Nav,
//   NavItem,
//   NavLink,
// } from "antd";

const PropertySummary = () => {
  const [openCheckin, setopenCheckin] = useState(false);
  const toggleCheckin = () => {
    setopenCheckin(!openCheckin);
  };

  const [openMagicLink, setOpenMagicLink] = useState(false);
  const toggleMagicLink = () => {
    setOpenMagicLink(!openMagicLink);
  };

  const [activeHeaderTab, setActiveHeaderTab] = useState("1");

  const handleClick = () => {};

  const views = [
    {
      id: "1",
      name: "Reservations",
    },
    {
      id: "2",
      name: "Performance",
    },
  ];

  return (
    <PropertyDetailsLayout>
      <div className="position-sticky top-0 pb-3">
        {/* <Nav tabs>
          {views.map((item, index) => (
            <NavItem key={index}>
              <NavLink
                className={`d-flex justify-content-between align-items-center ${
                  activeHeaderTab == item.id ? "active" : ""
                }`}
                onClick={() => {
                  setActiveHeaderTab(item.id);
                }}
              >
                {item.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav> */}
      </div>
      <div className="px-5 pt-3 pb-5 property-container">
        <div className="row">
          <div className="col-9">
            {activeHeaderTab === "1" && (
              <ReservationTab
                toggleCheckin={toggleCheckin}
                toggleMagicLink={toggleMagicLink}
              />
            )}
            {activeHeaderTab === "2" && <PerformanceTab />}
          </div>
          <div className="col-3">
            <div className="border rounded-3 p-3">
              <h3 className="header_text__16 text-center mb-3">
                Availability Today
              </h3>
              <table className="w-100">
                <tr className="small_text__14 ">
                  <td>Room category</td>
                  <td className="text-end">Inventory</td>
                  <td className="text-end">Base price</td>
                </tr>
                <tr>
                  <td className="p-1">Ac</td>
                  <td className="text-end p-1">0</td>
                  <td className="text-end p-1">₹ 1999</td>
                </tr>
              </table>
            </div>
            <div className="border rounded-3 p-3 mt-3 d-flex flex-column gap-2 align-items-center">
              <h3 className="header_text__16 text-center ">
                Get 20% more direct bookings
              </h3>
              <p className="small_text__14 text-center mb-1">
                Your direct rates on google is low compared to OTAs. Click below
                to enable 7% discount on direct bookings.
              </p>
              <ButtonComponent
                buttonType="primary"
                buttonText="Get more direct bookings"
                onClickHandler={handleClick}
              />
            </div>
            <div className="border rounded-3 p-3 mt-3 d-flex flex-column gap-2 align-items-center">
              <h3 className="header_text__16 text-center ">
                Reduce booking.com cancellations
              </h3>
              <p className="small_text__14 mb-1 text-center">
                Appzo has launched an innovative payment automation system. It
                holds bookings with invalid cards, collects payments, and
                confirms bookings upon successful payment.
              </p>
              <ButtonComponent
                buttonType="primary"
                buttonText="Enable flex connect"
                onClickHandler={handleClick}
              />
            </div>
            <div className="border rounded-3 p-3 mt-3 d-flex flex-column gap-2 align-items-center">
              <h3 className="header_text__16 text-center ">
                Upgrade to Appzo Empower
              </h3>
              <p className="small_text__14 mb-1 text-center">
                Revenue management service by Appzo to boost your online
                bookings by 30%.
              </p>
              <ButtonComponent
                buttonType="primary"
                buttonText="Request demo"
                onClickHandler={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
    </PropertyDetailsLayout>
  );
};

export default PropertySummary;
