import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllSale = (queryRequest: string) =>
  get(`${config.API_URL}/records/deals?${queryRequest}`);

export const getListOfAllSales = (queryRequest: string) =>
  get(`${config.API_URL}/records/deals/list?${queryRequest}`);

export const getSpecificSale = (id: string) =>
  get(`${config.API_URL}/records/deals/${id}`);

export const createSale = (payload: object) =>
  post(`${config.API_URL}/records/deals`, payload);

export const updateSale = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/deals/${id}`, payload);

export const deleteSale = (id: string) =>
  Delete(`${config.API_URL}/records/deals/${id}`);

export const saleBulkUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/deals/bulk-update`, payload);

export const saleSheetUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/deals/sheet-view`, payload);

//association

export const contactAssociationInSale = (id: string, payload: object) =>
  post(`${config.API_URL}/records/deals/${id}/associations/add`, payload);

export const contactDisAssociationInSale = (id: string, payload: object) =>
  post(`${config.API_URL}/records/deals/${id}/associations/remove`, payload);
