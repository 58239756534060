import { useEffect, useRef, useState } from "react";
// import classNames from "./Signup/superteam.module.scss";
import SimpleReactValidator from "simple-react-validator";
import { signUpInviteInitiate } from "@services/auth.service";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { checkSignupTokenValidity } from "@services/organisations.service";
import Signup from "./Signup/components/signup-page";
import OrgSignupForm from "./common/orgSignupForm";
import Header from "@components/common/Header/LoginHeader/header";
import timezones from "@common/json/standardized-timezone-data.json";

function CreateOrgUserByInviteToken(props: any) {
  const simpleValidator = useRef(new SimpleReactValidator());
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    getTokenInfo();
  }, []);

  const [email, setEmail] = useState("");
  const [info, setInfo] = useState({});
  const getTokenInfo = async () => {
    let payload = {
      invite_token: getValue(UrlParams, `invite_token`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await checkSignupTokenValidity(queryRequest);
      if (resp) {
        setEmail(getValue(resp, `data.masked_identifier`, ""));
        setInfo(getValue(resp, `data`, {}));
        // if (getValue(resp, `data.is_valid_token`, false)) {
        //   navigate(
        //     `/user-signup/invite?masked_identifier=${getValue(
        //       resp,
        //       `data.masked_identifier`,
        //       ""
        //     )}&token=${getValue(
        //       UrlParams,
        //       `invite_token`,
        //       ""
        //     )}&orgId=${getValue(params, `orgId`, "")}`
        //   );
        // } else {
        //   toast.error("Token is not valid");
        //   navigate(`/signin`);
        // }
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = useState({
    first_name: "",
    last_name: "",
    password: "",
    invite_token: getValue(UrlParams, `invite_token`, ""),
    timezone: "",
  });

  const findTimezone: any = (timezone: string) => {
    return timezones.find(
      (item: object) => getValue(item, `value`, "") === timezone
    );
  };
  useEffect(() => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (timeZone === "Asia/Calcutta") {
      setRequest({
        ...request,
        timezone: "Asia/Kolkata",
      });
    } else if (timeZone === findTimezone(timeZone)) {
      setRequest({
        ...request,
        timezone: timeZone,
      });
    } else {
      setRequest({
        ...request,
        timezone: "Asia/Kolkata",
      });
    }
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setSubmitLoading(true);
      try {
        let resp = await signUpInviteInitiate(request);
        if (resp) {
          localStorage.setItem(
            "accessToken",
            getValue(resp, `data.access_token`, "")
          );
          window.location.href = "/organisations";
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className={`mobile-flex-direction d-flex pb-sm-0 pb-5 gap`}>
          <div className="flex-1 d-flex d-none">
            <div className={"try-superteam-wrapper__container"}>
              <Signup />
            </div>
          </div>
          <div className="flex-1 mb-4 mb-lg-0 mt-5">
            <OrgSignupForm
              request={request}
              setRequest={setRequest}
              handleSubmit={handleSubmit}
              submitLoading={submitLoading}
              validator={simpleValidator}
              email={email}
              info={info}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateOrgUserByInviteToken;
