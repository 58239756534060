import { config } from "../env";
import {
  Delete,
  DeleteWithPayload,
  get,
  patch,
  post,
} from "./helpers/http-handler";

export const getUsers = (queryRequest: string) =>
  get(`${config.API_URL}/settings/org/users/list?${queryRequest}`);

export const getUsersEmail = (queryRequest: string) =>
  get(`${config.API_URL}/settings/org/users?${queryRequest}`);

export const getOrgUsers = () => get(`${config.API_URL}/settings/org/users/me`);

export const associateRole = (id: string, payload: object) =>
  post(
    `${config.API_URL}/settings/org/users/${id}/role/association/add`,
    payload
  );

export const disAssociateRole = (id: string, payload: object) =>
  DeleteWithPayload(
    `${config.API_URL}/settings/org/users/${id}/role/associations/remove`,
    payload
  );
