import { getValue } from "@utils/lodash";
import { Button, Popover } from "antd";
import React from "react";
import { PhoneInput } from "react-international-phone";

const SheetPhoneEdit = ({
  row,
  col,
  item,
  orgInfo,
  tempPhoneNumbers,
  handlePhoneInputChange,
  numberError,
  handleCancel,
  handleUpdate,
  handleDisplayItem,
}: any) => {
  return (
    <Popover
      content={
        <div>
          <PhoneInput
            defaultCountry={
              getValue(orgInfo, `country`, "").toLowerCase() || "in"
            }
            value={tempPhoneNumbers[row.id]?.[col] ?? getValue(row, col, "")}
            onChange={(newPhoneNumber) =>
              handlePhoneInputChange(newPhoneNumber, row.id, col)
            }
            placeholder="Enter Phone Number"
            className="mt-0 pt-0"
          />

          <div
            className={`${
              numberError === "Validated"
                ? "number-validated"
                : "number-notvalidated"
            }`}
          >
            {numberError}
          </div>
          <div className="d-flex gap-3 justify-content-center align-items-center mt-2">
            <Button onClick={() => handleCancel(row.id, col)}>Cancel</Button>
            <Button type="primary" onClick={() => handleUpdate(row.id, col)}>
              Update
            </Button>
          </div>
        </div>
      }
      title={getValue(item, "label", "")}
      trigger="click"
      placement="bottom"
    >
      <div className="w-100">
        {tempPhoneNumbers[row.id]?.[col] || handleDisplayItem(col, row)}
      </div>
    </Popover>
  );
};

export default SheetPhoneEdit;
