import { convertCurrentDate } from "@common/date-helpers";
import { handleDownloadOnClick } from "@common/downloadfile";
import { QueryRequestHelper } from "@common/query-request-helper";
import { formatString } from "@common/text-helpers";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { useStateContext } from "@context/profileProvider";
import {
  getDataImports,
  getSpecificDataImport,
} from "@services/imports-exports.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function ModuleImportLogs(props: any) {
  const params = useParams();
  const { selectedModuleId } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [importList, setImportList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        module_id: await selectedModuleId(getValue(params, `module`, "")),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getDataImports(queryRequest);
      if (resp) {
        setImportList(getValue(resp, `data.dataImports`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const [info, setInfo] = useState({});
  const [infoLoading, setInfoLoading] = useState(false);
  const getSpecificImportInfo = async (id: string) => {
    try {
      setInfoLoading(true);
      let resp = await getSpecificDataImport(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setInfoLoading(false);
      } else {
        setInfoLoading(false);
      }
    } catch (error) {
      setInfoLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <HomeHeader />
      <PipelineSubHeader subHeaderListTitle="Import Logs" />
      <div className="p-5">
        <table className="common_table">
          <thead>
            <tr>
              <td>
                <h6>Module</h6>
              </td>
              <td>
                <h6>Create By</h6>
                <p>Created on</p>
              </td>
              <td>
                <h6>Created Count</h6>
              </td>
              <td>
                <h6>Failed Count</h6>
              </td>
              <td>
                <h6>Total Count</h6>
              </td>
              <td>
                <h6>Status</h6>
              </td>
              <td>
                <h6>Action</h6>
              </td>
            </tr>
          </thead>
          <tbody>
            {getValue(importList, `length`, 0) > 0 &&
              importList.map((item: object) => {
                return (
                  <tr>
                    <td>
                      <p>{getValue(item, `module.label`, "")}</p>
                    </td>
                    <td>
                      <h6>
                        {getValue(item, `created_by.first_name`, "")}{" "}
                        {getValue(item, `created_by.last_name`, "")}
                      </h6>
                      <p>
                        {convertCurrentDate(getValue(item, `created_at`, ""))}
                      </p>
                    </td>
                    <td>
                      <p>{getValue(item, `created_count`, "")}</p>
                    </td>
                    <td>
                      <p>{getValue(item, `failed_count`, "")}</p>
                    </td>
                    <td>
                      <p>{getValue(item, `total_count`, "")}</p>
                    </td>
                    <td>
                      <p>{formatString(getValue(item, `status`, ""))}</p>
                    </td>
                    <td
                      onClick={() =>
                        getValue(item, `log_file_url`, "") &&
                        handleDownloadOnClick(
                          getValue(item, `log_file_url`, ""),
                          "log_file"
                        )
                      }
                    >
                      {getValue(item, `log_file_url`, "") ? (
                        <p className="color_primary header_text__16">
                          Download
                        </p>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ModuleImportLogs;
