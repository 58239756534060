import './index.scss'
export default function TeamsList() {
  const ListArray = [
    { text: "Upgrade your small business CRM" },
    {
      text:
        "See how automation can grow your business",
    },
    {
      text:
        "Expert help every step of the way",
    },
  ];
  return (
    <div className={"try-superteam-wrapper__details-list"}>
      <ul>
        {ListArray.map((listObj, index) => {
          return (
            <li
              key={`lisArray${index}`}
              className={"try-superteam-wrapper__list-item"}
            >
              <img
                src="/images/icons/check-circel.svg"
                className={`try-superteam-wrapper__list-check img-fluid`}
                height={20}
                width={20}
              />
              {listObj.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
