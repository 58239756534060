import React, { useEffect, useRef, useState } from "react";
import InputRuleForm from "@components/InputRuleForm/form";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import {
  Alert,
  Button,
  Checkbox,
  Empty,
  Image,
  Radio,
  RadioChangeEvent,
  Tag,
  Tooltip,
} from "antd";
import { getValue } from "@utils/lodash";
import "../../TemplateCreate/sampleTemplate.scss";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { config } from "../../../../../env";
import EditSvgComponent from "@assets/svg/edit";
import ReplySvgComponent from "@assets/svg/whatsapp-reply";
import CallSvgComponent from "@assets/svg/phone";
import UrlSvgComponent from "@assets/svg/custom/url";
import BroadcastVariables from "./map-variables/broadcast-variables";
import BroadcastVariableConfig from "./map-variables/variable-config";
import BroadcastMediaEdit from "./map-variables/broadcast-media-edit";
import BroadcastCarouselTab from "./map-variables/broadcast-carousel-tab";
import BroadcastButtonsEdit from "./map-variables/broadcast-buttons-edit";
import { capitalizeFirstLetterOfEachWord } from "@common/text-helpers";
import { replacePlaceholders } from "@common/markdown/markdown-helper";

import { Tabs } from "antd";
import EditTemplatePreview from "./map-variables/edit-template-preview";

const { TabPane } = Tabs;

const TabHeaders = ({ activeTab, setActiveTab, request }: any) => {
  const [tabs, setTabs] = useState([
    { key: "1", label: "Map Columns" },
    { key: "2", label: "Variables" },
    { key: "3", label: "Media" },
    { key: "4", label: "Carousel Media" },
    { key: "5", label: "Advanced" },
  ]);

  useEffect(() => {
    if (getValue(request, `contactUploadId`, "")) {
      setTabs(
        filterTabs([
          { key: "1", label: "Map Columns" },
          { key: "3", label: "Media" },
          { key: "4", label: "Carousel Media" },
          { key: "5", label: "Advanced" },
        ])
      );
    } else {
      setTabs(
        filterTabs([
          { key: "2", label: "Variables" },
          { key: "3", label: "Media" },
          { key: "4", label: "Carousel Media" },
          { key: "5", label: "Advanced" },
        ])
      );
    }
  }, [request]);

  useEffect(() => {
    if (getValue(request, `contactUploadId`, "")) {
      const data = filterTabs([
        { key: "1", label: "Map Columns" },
        { key: "3", label: "Media" },
        { key: "4", label: "Carousel Media" },
        { key: "5", label: "Advanced" },
      ]);
      setActiveTab(getValue(data, `[${0}].key`, ""));
    } else {
      const data = filterTabs([
        { key: "2", label: "Variables" },
        { key: "3", label: "Media" },
        { key: "4", label: "Carousel Media" },
        { key: "5", label: "Advanced" },
      ]);
      setActiveTab(getValue(data, `[${0}].key`, ""));
    }
  }, []);

  const filterTabs: any = (updatedTabs: any) => {
    // Remove "Map Columns" and "Variables" tabs if buttons is an empty array
    if (
      !getValue(request, `variableConfig.templateTags`, []) ||
      getValue(request, `variableConfig.templateTags.length`, 0) === 0
    ) {
      updatedTabs = updatedTabs.filter(
        (tab: any) => tab.key !== "1" && tab.key !== "2"
      );
    }

    // Remove "Media" tab if headerLinkDetail is empty in all carousels
    const isHeaderLinkDetailEmpty = Object.values(
      getValue(request, `variableConfig.headerLinkDetail`, {})
    ).every((value) => !value);
    if (isHeaderLinkDetailEmpty) {
      updatedTabs = updatedTabs.filter((tab: any) => tab.key !== "3");
    }

    // Remove "Carousel Media" tab if carousels is an empty array
    if (getValue(request, `variableConfig.carousels.length`, 0) === 0) {
      updatedTabs = updatedTabs.filter((tab: any) => tab.key !== "4");
    }

    // Remove "Advanced" tab if templateTags is an empty array
    if (getValue(request, `variableConfig.buttons.length`, 0) === 0) {
      updatedTabs = updatedTabs.filter((tab: any) => tab.key !== "5");
    }

    return updatedTabs;
  };

  return (
    <Tabs activeKey={activeTab} onChange={setActiveTab} type="card">
      {tabs.map((tab) => (
        <TabPane tab={tab.label} key={tab.key}></TabPane>
      ))}
    </Tabs>
  );
};

const TabContent = (props: any) => {
  const {
    activeTab,
    request,
    setRequest,
    handleModal,
    handleUploadDocuments,
    showModal,
    uploadedFileFieldOption,
  } = props;
  const onChangeExtractType = (
    { target: { value } }: RadioChangeEvent,
    index: number
  ) => {
    setRequest((prevRequest: any) => {
      return {
        ...prevRequest,
        variableConfig: {
          ...prevRequest.variableConfig,
          templateTags: prevRequest.variableConfig.templateTags.map(
            (button: any, i: number) => {
              if (index !== i) return button;
              return {
                ...button,
                extractType: value,
              };
            }
          ),
        },
      };
    });
  };
  const options = [
    {
      label: "From Excel",
      value: getValue(request, `contactUploadId`, "") ? "file" : "field",
    },
    { label: "Enter my own", value: "manual" },
  ];

  const orgOptions = [
    {
      label: "From Contacts",
      value: getValue(request, `contactUploadId`, "") ? "file" : "field",
    },
    { label: "Enter my own", value: "manual" },
  ];

  return (
    <div className="tab-content">
      {activeTab === "1" && (
        <BroadcastVariables
          request={request}
          setRequest={setRequest}
          options={options}
          onChangeExtractType={onChangeExtractType}
          uploadedFileFieldOption={uploadedFileFieldOption}
        />
      )}

      {activeTab === "2" && (
        <BroadcastVariableConfig
          request={request}
          setRequest={setRequest}
          handleModal={handleModal}
          options={orgOptions}
          onChangeExtractType={onChangeExtractType}
        />
      )}

      {activeTab === "3" && (
        <BroadcastMediaEdit
          request={request}
          handleUploadDocuments={handleUploadDocuments}
        />
      )}

      {activeTab === "4" && (
        <BroadcastCarouselTab request={request} showModal={showModal} />
      )}

      {activeTab === "5" && (
        <BroadcastButtonsEdit request={request} setRequest={setRequest} />
      )}
    </div>
  );
};

const EditBroadcastTemplate = (props: any) => {
  const {
    request,
    setRequest,
    handleModal,
    handleUploadDocuments,
    showModal,
    uploadedFileFieldOption,
  } = props;

  const [activeTab, setActiveTab] = useState("1");

  return (
    <div className="edit-broadcast-template d-flex justify-content-between gap-4">
      <div className=" w-100 p-4 mx-auto" style={{ maxWidth: "700px" }}>
        <div className="mb-3">
          <h6 className="header_text__20">Map Template Variables</h6>
        </div>
        <div className="w-100">
          <TabHeaders
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            request={request}
          />
          <TabContent
            activeTab={activeTab}
            request={request}
            setRequest={setRequest}
            handleModal={handleModal}
            handleUploadDocuments={handleUploadDocuments}
            showModal={showModal}
            uploadedFileFieldOption={uploadedFileFieldOption}
          />
        </div>
      </div>
      <EditTemplatePreview request={request} />
    </div>
  );
};

export default EditBroadcastTemplate;
