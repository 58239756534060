import { QueryRequestHelper } from "@common/query-request-helper";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import FormBuilder from "@components/Builder/FormBuilder";
import ButtonComponent from "@components/Form/Button/Button";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import Loader from "@components/common/Loader/loading";
import { FormRequestHelper } from "@components/helpers/request-helper";
import {
  createPublicForm,
  getPublicGlobalFormInfo,
  getWebFormRoute,
} from "@services/web-forms.service";
import { getValue } from "@utils/lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";

function SettingsPreviewForm(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = useState(false);
  const [fieldInfo, setFieldInfo] = useState({});
  const [formFields, setFormFields] = useState([]);
  const getData = async () => {
    let slug = getValue(params, `slug`, "")
      ? getValue(params, `slug`, "").trim(" ")
      : "";
    if (slug) {
      try {
        setIsLoading(true);
        let resp = await getPublicGlobalFormInfo(
          getValue(params, `orgId`, ""),
          slug
        );
        if (resp) {
          setFormFields(
            getValue(resp, `data.form_fields`, []).map((item: object) => ({
              ...getValue(item, `module_field`, {}),
              description: getValue(item, `description`, ""),
              display_label: getValue(item, `display_label`, ""),
              required: getValue(item, `required`, ""),
              seq_num: getValue(item, `seq_num`, ""),
              id: getValue(item, `id`, ""),
              label: getValue(item, `display_label`, ""),
            }))
          );
          setFieldInfo(getValue(resp, `data`, {}));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      toast.error("Someting went wrong, Please try after sometime");
    }
  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const showValidation = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      showValidation();
    } else {
      try {
        let payload = {
          form_id: getValue(fieldInfo, `form_id`, ""),
        };
        let fields = FormRequestHelper(formFields);
        setSubmitLoading(true);
        let queryRequest = QueryRequestHelper(payload);
        let resp = await createPublicForm(
          queryRequest,
          removeNullOrUndefinedProperties(fields)
        );
        if (resp) {
          toast.success("Submitted successfully");
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          setSubmitLoading(false);
          if (getValue(fieldInfo, `redirect_url`, "")) {
            window.location.href = getValue(fieldInfo, `redirect_url`, "");
          } else {
            navigate(
              `/${getValue(params, `orgId`, "")}/settings/web-forms/${getValue(
                params,
                `slug`,
                ""
              )}/thankyou`
            );
          }
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  return (
    <div>
      {/* <HomeHeader /> */}
      {/* <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center justify-content-center">
            <h6
              className={`company-profile-subheader-wrapper__submenu-name mx-2`}
            >
              Preview
            </h6>
          </div>
        </div>
      </div> */}
      <div className="w-100  m-auto">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <FormBuilder
              fields={formFields}
              setFields={setFormFields}
              simpleValidator={simpleValidator}
              phoneInline
            />
          </div>
        )}
        {getValue(params, `slug`, "") && !getValue(params, `preview`, "") && (
          <div className="ms-4 mt-4">
            <ButtonComponent
              buttonType={submitLoading ? "scecondary" : "primary"}
              buttonText={submitLoading ? "Please wait..." : "Save"}
              onClickHandler={() => handleSubmit()}
              disabled={submitLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SettingsPreviewForm;
