import TextSvgComponent from "@assets/svg/custom/text";
import NumaricSvgComponent from "@assets/svg/custom/numaric";
import EmailSvgComponent from "@assets/svg/email";
import DropdownListSvgComponent from "@assets/svg/custom/dropdownlist";
import UrlSvgComponent from "@assets/svg/custom/url";
import CurrencySvgComponent from "@assets/svg/custom/currency";
import PhoneSvgComponent from "@assets/svg/custom/phone";
import DateSvgComponent from "@assets/svg/custom/date";
import TimeSvgComponent from "@assets/svg/custom/time";
import CheckboxSvgComponent from "@assets/svg/custom/checkbox";
import LookupSvgComponent from "@assets/svg/custom/lookup";
import TextAreaSvgComponent from "@assets/svg/custom/textarea";
import CompanyProfileSvgComponent from "@assets/svg/company-profile";
import PersonSvgComponent from "@assets/svg/person";
import SaleSvgComponent from "@assets/svg/sale";
import TaskSvgComponent from "@assets/svg/task";
import CircleSvgComponent from "@assets/svg/circle";
import ChecklistSvgComponent from "@assets/svg/checklist";

export const getImages = (props: any) => {
  const { name, size } = props;
  switch (name) {
    case "text":
      return <TextSvgComponent size={size ? size : 20} />;
    case "decimal":
    case "auto_number":
    case "bigint":
    case "numarical":
    case "integer":
    case "autonumber":
      return <NumaricSvgComponent size={size ? size : 20} />;
    case "email":
      return <EmailSvgComponent size={size ? size : 20} />;
    case "boolean_choice":
    case "select":
    case "select_multiple":
      return <DropdownListSvgComponent size={size ? size : 20} />;
    case "url":
      return <UrlSvgComponent size={size ? size : 20} />;
    case "currency":
    case "percent":
      return <CurrencySvgComponent size={size ? size : 20} />;
    case "tel":
    // case "tel_intl":
      return <PhoneSvgComponent size={size ? size : 20} />;
    case "date":
      return <DateSvgComponent size={size ? size : 20} />;
    case "datetime":
    case "time":
    case "datetime_duration":
    case "datetime_alarm":
      return <TimeSvgComponent size={size ? size : 20} />;
    case "lookup":
    case "lookup_association":
      return <LookupSvgComponent size={size ? size : 20} />;
    case "boolean_checkbox":
      return <CheckboxSvgComponent size={size ? size : 20} />;
    case "boolean_radio":
      return <CircleSvgComponent size={size ? size : 20} color={"#3C4043"} />;
    case "boolean_select":
      return (
        <ChecklistSvgComponent size={size ? size : 20} color={"#3C4043"} />
      );
    case "textarea":
      return <TextAreaSvgComponent size={size ? size : 20} />;
    case "companies":
      return <CompanyProfileSvgComponent size={size ? size : 20} />;
    case "sales":
      return <SaleSvgComponent size={size ? size : 20} />;
    case "tasks":
      return <TaskSvgComponent size={size ? size : 20} />;
    case "contacts":
      return <PersonSvgComponent size={size ? size : 20} />;
    default:
      return null;
  }
};
