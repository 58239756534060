// https://stackoverflow.com/questions/32540667/moment-js-utc-to-local-time
import { getValue } from "@utils/lodash";
import moment from "moment-timezone";
import "moment-timezone";

let timezone = getValue(localStorage, `timezone`, "")
  ? getValue(localStorage, `timezone`, "")
  : "Asia/Kolkata";

export const todayDate = (date: any) => {
  if (date) {
    return new Date(moment(date).format("YYYY-MM-DD"));
  }
};

export const getDiffInDates = (date: any, days: any) => {
  if (date && days >= 0) {
    var d = date;
    d.setDate(d.getDate() - days);
    return d;
  }
};

export const getAdditionInDates = (date: any, days: any) => {
  if (date && days) {
    var d = date;
    d.setDate(d.getDate() + days);
    return d;
  }
};

export const convertCurrentTime = (timeString: any) => {
  const momentTime = moment(timeString); // Parse the time string with moment
  return momentTime.isValid()
    ? momentTime.format("hh:mm A") // Format as 12-hour time with AM/PM
    : ""; // Return empty string if invalid
};

export const convertDate = (date: any) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  } else {
    return null;
  }
};
export const convertDateAsString = (date: any) => {
  if (date) {
    return moment(date).format("DD MMM YYYY");
  } else {
    return null;
  }
};

export const convertCurrentDate = (date: any) => {
  if (date) {
    return moment(date).tz(timezone).format("DD MMM YYYY");
  } else {
    return "";
  }
};

export const convertCurrentDateWithTimezone = (date: any) => {
  const timezone = getValue(localStorage, "timezone", "Asia/Kolkata");
  if (moment(date).isValid()) {
    return moment(date).tz(timezone).format("DD MMM YYYY");
  } else {
    return "";
  }
};

export const convertCurrentDateWithTime = (date: any) => {
  if (date) {
    return moment.tz(date, timezone).format("DD MMM YYYY, hh:mm a");
  } else {
    return null;
  }
};

export const convertCurrentDateToTime = (date: any) => {
  if (date) {
    return moment.tz(date, timezone).format("hh:mm a");
  } else {
    return null;
  }
};

export const currentDate = () => {
  return Date.now();
};

export const NewDate = (date: any) => {
  if (date) {
    return new Date(date);
  } else {
    return null;
  }
};

export const convertDateTime = (dateTime: any) => {
  let localDateTime = moment(dateTime).format("YYYY-MM-DDTHH:mm:ss");
  let localDateTime1 = moment.tz(localDateTime, timezone);
  const utcDateTime = localDateTime1.utc().toISOString();
  return utcDateTime;
};

export const getDate = (dateTime: any) => {
  const mo1 = moment(dateTime); // in browser default time zone, e.g. America/Denver
  const mo2 = moment(dateTime).tz(timezone); // in current state time zone e.g. Africa/Bamako
  const modifiedDate = moment(dateTime);
  modifiedDate.subtract(mo1.utcOffset() - mo2.utcOffset(), "minutes");
  return new Date(modifiedDate.valueOf());
};

export const formatUTC = (dateInt: any, addOffset = false) => {
  let date = !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt);
  if (typeof dateInt === "string") {
    return date;
  } else {
    const offset = addOffset
      ? date.getTimezoneOffset()
      : -date.getTimezoneOffset();
    const offsetDate = new Date();
    offsetDate.setTime(date.getTime() + offset * 60000);
    return offsetDate;
  }
};

export const formatDateToHoursAgo = (dateString: any) => {
  const currentDate = moment();
  const providedDate = moment(dateString);

  const daysAgo = currentDate.diff(providedDate, "days");
  const remainingHours = currentDate.diff(providedDate, "hours") % 24;
  const remainingMinutes = currentDate.diff(providedDate, "minutes") % 60;

  let formattedString = "";

  if (daysAgo > 0) {
    formattedString += `${daysAgo} ${daysAgo === 1 ? "day" : "days"}`;
    if (remainingHours > 0 || remainingMinutes > 0) formattedString += " ";
  }

  if (remainingHours > 0) {
    formattedString += `${remainingHours} ${
      remainingHours === 1 ? "hour" : "hours"
    }`;
    if (remainingMinutes > 0) formattedString += " ";
  }

  if (remainingMinutes > 0) {
    formattedString += `${remainingMinutes} ${
      remainingMinutes === 1 ? "minute" : "minutes"
    }`;
  }

  if (formattedString.length === 0) {
    return "Less than a minute ago";
  } else {
    return `${formattedString} ago`;
  }
};
export const getThirdDayFromDate = () => {
  const thirdDay = moment().clone().add(2, "days");
  return thirdDay.toDate();
};
export function getDateTime15MinutesBefore(date: any) {
  let convertedDate = date.clone().subtract(15, "minutes").toDate();
  // return convertDateTime(convertedDate);
  return convertedDate;
}
export function getDateTime30MinutesBefore(date: any) {
  let convertedDate = date.clone().subtract(30, "minutes").toDate();
  return convertedDate;
}
export function getDateTime1HourBefore(date: any) {
  let convertedDate = date.clone().subtract(1, "hours").toDate();
  return convertedDate;
}
export function getDate1DayBefore(date: any) {
  let convertedDate = date.clone().subtract(1, "days").toDate();
  return convertedDate;
}
export function getDate1WeekBefore(date: any) {
  let convertedDate = date.clone().subtract(1, "weeks").toDate();
  return convertedDate;
}

//date picker

export function getTodayDate() {
  const today = moment();
  const formattedStartDate = today.format("YYYY-MM-DD");
  return `${formattedStartDate},${formattedStartDate}`;
}

export function getLastWeekDate() {
  const lastWeek = moment().subtract(1, "weeks");
  const formattedStartDate = lastWeek.format("YYYY-MM-DD");
  const formattedEndDate = moment().format("YYYY-MM-DD");
  return `${formattedStartDate},${formattedEndDate}`;
}

export function getStartOfMonthDate() {
  const startOfMonth = moment().startOf("month");
  const endOfMonth = moment().endOf("month");
  const formattedStartDate = startOfMonth.format("YYYY-MM-DD");
  const formattedEndDate = endOfMonth.format("YYYY-MM-DD");
  return `${formattedStartDate},${formattedEndDate}`;
}

export function getStartOfYearDate() {
  const startOfYear = moment().startOf("year");
  const endOfMonth = moment().endOf("year");
  const formattedStartDate = startOfYear.format("YYYY-MM-DD");
  const formattedEndDate = endOfMonth.format("YYYY-MM-DD");
  return `${formattedStartDate},${formattedEndDate}`;
}

export const getDateFunctions = () => {
  let array = [
    { label: "Today", value: getTodayDate(), old: "Previous Value" },
    { label: "Last week", value: getLastWeekDate(), old: "Previous Value" },
    {
      label: "This month",
      value: getStartOfMonthDate(),
      old: "Last Month",
    },
    {
      label: "This Year",
      value: getStartOfYearDate(),
      old: "Last Year",
    },
  ];
  return array;
};

export const formatDateRange = (date: string) => {
  let dates = date.split(",");
  return `${convertDateAsString(dates[0])} - ${convertDateAsString(dates[1])}`;
};
export function addHour(time: any, hour: any) {
  return moment(time, "hh A").tz(timezone).add(hour, "hour").format("hh A");
}
export function formatTime(date: any) {
  return moment(date).tz(timezone).format("hh A");
}

// export function addHour(time: string, hour: number): string {
//   // Parse the UTC time with moment-timezone
//   const utcTime = moment.tz(time, "hh A", "UTC");
//   // Add the specified number of hours
//   const newTime = utcTime.add(hour, "hour");
//   // Convert to Asia/Kolkata timezone and format
//   if (timezone) {
//     return newTime.tz(timezone).format("hh A");
//   } else {
//     return newTime.local().format("hh A");
//   }
// }

// export function formatTime(date: string): string {
//   // Parse the UTC time with moment-timezone (assuming date is also in UTC)
//   const utcTime = moment.tz(date, moment.ISO_8601, "UTC");
//   // Convert to Asia/Kolkata timezone and format
//   if (timezone) {
//     return utcTime.tz(timezone).format("hh A");
//   } else {
//     return utcTime.local().format("hh A");
//   }
// }

export const formatDatetoDay = (date: any) => {
  const parsedDate = moment(date);

  if (parsedDate.isSame(moment(), "day")) {
    // return "Today";
    return convertCurrentDateToTime(date);
  }

  if (parsedDate.isSame(moment().subtract(1, "day"), "day")) {
    return "Yesterday";
  }

  if (parsedDate.isSame(moment(), "week")) {
    return parsedDate.format("dddd"); // Returns the day of the week, e.g., "Monday"
  }

  return parsedDate.format("DD/MM/YYYY"); // Returns the date in the format YYYY-MM-DD
};

export const renderDate = (date: any) => {
  const today = moment().startOf("day");
  const messageDate = moment(date).startOf("day");
  const startOfWeek = moment().startOf("week");

  if (messageDate.isSame(today, "day")) {
    return "Today";
  } else if (messageDate.isSame(today.subtract(1, "day"), "day")) {
    return "Yesterday";
  } else if (messageDate.isSameOrAfter(startOfWeek)) {
    return messageDate.format("dddd"); // e.g., Monday, Tuesday
  } else {
    return messageDate.format("DD/MM/YYYY");
  }
};

export const groupByDate = (data: any) => {
  if (getValue(data, `length`, 0) > 0) {
    return data.reduce((groups: any, item: any) => {
      const category = renderDate(item.created_at);
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(item);
      return groups;
    }, {});
  } else {
    return {};
  }
};
