import { getValue } from "@utils/lodash";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Tabs } from "antd";

const { TabPane } = Tabs;

function Navbar(props: any) {
  const { views, route } = props;
  const location = useLocation();
  const params = useParams();

  return (
    <div className="w-100">
      <Tabs
        type="card"
        activeKey={location.pathname}
        onChange={(activeKey) => {
          // Handle tab change if necessary
        }}
      >
        {views.map((item: object, index: number) => (
          <TabPane
            tab={
              <div className="d-flex justify-content-between align-items-center">
                {getValue(item, `name`, "")}
              </div>
            }
            key={`${route}`}
          />
        ))}
      </Tabs>
    </div>
  );
}

export default Navbar;
