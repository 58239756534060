import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { getImages } from "@common/image-helper";
import PenSvgComponent from "assets/svg/pen";
import DragIndicatorSvgComponent from "assets/svg/drag-indicator";
import LoaderMain from "@components/common/Loader/loading1";
import FileSvgComponent from "@assets/svg/file";
import BrandingSvgComponent from "@assets/svg/branding";
import ModulesAndFieldsSvgComponent from "@assets/svg/modules-fields";
import { formatString, sortJSONObjectArray } from "@common/text-helpers";
export default function ModuleAndFieldsTable(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = (route: string) => {
    navigate(route);
  };
  return (
    <div className={"admin-wrapper"}>
      {/* {getValue(props, `isLoading`, false) ? (
        <LoaderMain />
      ) : ( */}
      <table className={`${"admin-wrapper__table"} w-100`}>
        <thead>
          <tr>
            <td style={{ width: "70px" }}>{/* <CustomCheckbox /> */}</td>
            <td>
              <div className="d-flex align-items-center w-100">
                {/* <div className={ClassNames["admin-wrapper__drag-icon"]}>
                    <div>
                      <span className={ClassNames["dot"]}></span>
                      <span
                        className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
                      ></span>
                      <span className={ClassNames["dot"]}></span>
                    </div>
                    <div>
                      <span className={ClassNames["dot"]}></span>
                      <span
                        className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
                      ></span>
                      <span className={ClassNames["dot"]}></span>
                    </div>
                  </div> */}
                <h6 className="header_text__15 ms-4">Display Name</h6>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center w-100">
                {/* <div className={ClassNames["admin-wrapper__drag-icon"]}>
                    <div>
                      <span className={ClassNames["dot"]}></span>
                      <span
                        className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
                      ></span>
                      <span className={ClassNames["dot"]}></span>
                    </div>
                    <div>
                      <span className={ClassNames["dot"]}></span>
                      <span
                        className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
                      ></span>
                      <span className={ClassNames["dot"]}></span>
                    </div>
                  </div> */}
                <h6 className="header_text__15 ms-4"> Type</h6>
              </div>
            </td>

            <td>
              <div className="d-flex align-items-center w-100">
                {/* <div className={ClassNames["admin-wrapper__drag-icon"]}>
                    <div>
                      <span className={ClassNames["dot"]}></span>
                      <span
                        className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
                      ></span>
                      <span className={ClassNames["dot"]}></span>
                    </div>
                    <div>
                      <span className={ClassNames["dot"]}></span>
                      <span
                        className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
                      ></span>
                      <span className={ClassNames["dot"]}></span>
                    </div>
                  </div> */}
                <h6 className="header_text__15 ms-4">Status</h6>
              </div>
            </td>
          </tr>
        </thead>

        <tbody>
          {sortJSONObjectArray(getValue(props, `modules`, []), "seq_num").map(
            (item: object, index: number) => {
              return (
                <tr key={index}>
                  <td
                    onClick={() =>
                      navigate(
                        `/${getValue(
                          params,
                          `orgId`,
                          ""
                        )}/settings/module-and-field/manage-fields/fields/${getValue(
                          item,
                          `id`,
                          ""
                        )}/${getValue(item, `api_name`, "")}`
                      )
                    }
                  >
                    {/* {getImages({
                        name: getValue(item, `api_name`, ""),
                        size: 24,
                      })} */}
                    {/* <DragIndicatorSvgComponent color={"lightgray"} /> */}
                    <ModulesAndFieldsSvgComponent color={"lightgray"} />
                  </td>
                  <td
                    className="d-flex align-items-center justify-content-between hide-hover"
                    onClick={() =>
                      navigate(
                        `/${getValue(
                          params,
                          `orgId`,
                          ""
                        )}/settings/module-and-field/manage-fields/fields/${getValue(
                          item,
                          `id`,
                          ""
                        )}/${getValue(item, `api_name`, "")}`
                      )
                    }
                  >
                    <p className="small_text__14">
                      {getValue(item, `label`, "")}
                    </p>
                    <div
                      className=" hidden-button image_hover_background"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        props.handleEdit(item);
                      }}
                    >
                      <PenSvgComponent />
                    </div>
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        `/${getValue(
                          params,
                          `orgId`,
                          ""
                        )}/settings/module-and-field/manage-fields/fields/${getValue(
                          item,
                          `id`,
                          ""
                        )}/${getValue(item, `api_name`, "")}`
                      )
                    }
                  >
                    <p className="small_text__14">
                      {formatString(getValue(item, `type`, ""))}
                    </p>
                  </td>

                  <td
                    onClick={() =>
                      navigate(
                        `/${getValue(
                          params,
                          `orgId`,
                          ""
                        )}/settings/module-and-field/manage-fields/fields/${getValue(
                          item,
                          `id`,
                          ""
                        )}/${getValue(item, `api_name`, "")}`
                      )
                    }
                  >
                    <p className="small_text__14">
                      {getValue(item, `is_active`, false)
                        ? "Active"
                        : "Inactive"}
                    </p>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
      {/* )} */}
    </div>
  );
}
