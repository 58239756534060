import { getValue } from "@utils/lodash";
import React, { ChangeEvent } from "react";
import "./form.scss";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxLength: number;
  placeholder: string;
  validator: any;
  label: string;
  mandatory: boolean;
  hideLabel: boolean;
  rows: any;
  inline: boolean;
};

export default function TextArea({
  disabled,
  name,
  type,
  value,
  onChange,
  maxLength,
  placeholder,
  validator,
  label,
  mandatory,
  hideLabel,
  rows,
  inline,
}: Props) {
  return (
    <div
      className={
        inline
          ? ""
          : `signup-form-wrapper__form-group ${
              validator && validator.current.message(label, value, "required")
                ? "signup-form-wrapper__form-group--error"
                : ""
            } form-group mb-3`
      }
    >
      {!hideLabel && (
        // <label className={`create-user-form-body__label mb-1`}>
        //   {label}
        // </label>
        <label className="form-label ">
          {label}{" "}
          {/* {mandatory && <span className="color_danger small_text__16">*</span>} */}
        </label>
      )}
      {/* <br /> */}
      <textarea
        // className="form-control-textarea"
        className={
          mandatory
            ? `custom-form-control-textarea__required`
            : `custom-form-control-textarea`
        }
        disabled={false || disabled}
        name={name}
        value={value}
        onChange={(e: any) => onChange(e)}
        placeholder={placeholder}
        maxLength={maxLength}
        // style={{
        //   borderLeftColor: mandatory ? "red" : "",
        // }}
        rows={rows}
      />
      {/* <p
        className={`error-text ${
          // getValue(props, `validLeft`, "") ? "" :
          "text-center"
        }`}
      >
        {validator && validator.current.message(label, value, "required")}
      </p> */}
      {validator && (
        <p className={`error-text`}>
          {validator.current.message(label, value, "required")}
        </p>
      )}
    </div>
  );
}
