import ButtonComponent from "@components/Form/Button/Button";
import CommonPopupHeader from "@components/common/Header/CommonPopupHeader/CommonPopupHeader";


export default function ManageFieldPopup(props: any) {
  return (
    <div className={'add-more-rolls-wrapper'}>
      <CommonPopupHeader
        title={props.title}
        handleBackButton={() => {
          props.handleBackButton();
        }}
      />
      <div className={'edit-fields__wrapper'}>
        <div className={'edit-fileds__form-group'}>
          <label className="mb-2">Field Name *</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Field Name"
          />
        </div>
        <div className={'edit-fileds__form-group'}>
          <label className="mb-2">Display Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Display Name"
          />
        </div>
        <h6 className={'edit-fields__title'}>Field Properties</h6>
        <div
          className={`${'edit-fileds__form-group'} position-relative`}
        >
          <label className="mb-2">Field Type</label>
          <input
            type="text"
            className="form-control"
            placeholder="Select Field Type"
          />
        </div>
        <div
          className={`${'edit-fileds__mandatory'} d-flex align-items-center justify-content-between`}
        >
          Mandatory
          {/* <Switch /> */}
        </div>
        <div
          className={`${'edit-fileds__mandatory'} d-flex align-items-center justify-content-between`}
        >
          Hide
          {/* <Switch /> */}
        </div>
        <h6 className={'edit-fields__title'}>Choice</h6>
        <div className="d-flex align-items-center gap mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="First Choice"
          />
          <img src="/images/icons/red-close.svg" className="cursor-pointer" />
        </div>
        <div className="d-flex align-items-center">
          <img
            src="/images/icons/add-blue.svg"
            className={'edit-fileds__add-icon'}
          />
          <span className={'edit-fileds__add'}>Add More</span>
        </div>
      </div>
      <div
        className={`${'add-more-rolls-wrapper__footer'} d-flex justify-content-end `}
      >
        <div className="d-flex align-items-center gap">
          <ButtonComponent buttonType={'scecondary'} buttonText="Cancel" />
          <ButtonComponent buttonType={'primary'} buttonText="Save" />
        </div>
      </div>
    </div>
  );
}
