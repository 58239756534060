import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import React from "react";
import { Tooltip } from "react-tooltip";

function ManageFormStep2(props: any) {
  const {
    params,
    setRequest,
    request,
    allPipelinesList,
    allPipelinesStageList,
    visitor_see,
    setVisitor_see,
    info,
  } = props;

  const handleChangeRouteName = (e: any) => {
    const value = e.target.value.toLowerCase();
    if (value) {
      // Regular expression pattern to allow only alphabetical characters and underscore
      const pattern = /^[a-zA-Z-]+$/;
      if (pattern.test(value)) {
        setRequest({
          ...request,
          route_name: value,
        });
      }
    } else {
      setRequest({
        ...request,
        route_name: "",
      });
    }
  };
  return (
    <div className="manageFieldRight w-100  add-overflow-y">
      <div className=" grayed-background d-flex justify-content-center ">
        <div className="bg-white w-75 form-details-container pb-5">
          <h6 className="header_text__18 text-center mt-4 mb-3">
            Form Details
          </h6>
          <div className="d-flex justify-content-c align-items-center flex-column">
            {/* <div className="d-flex justify-content-center align-items-center">
                <h6 className="header_text__14 w-25">FormLink</h6>:
                <div className="w-25  ms-3 mb-2 d-flex align-items-center">
                  <p>{window.location.origin}/forms/</p> <InputRuleForm
                    inputType="TEXTAREA"
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        thank_you_message: e.target.value,
                      })
                    }
                    value={getValue(request, `thank_you_message`, "")}
                    placeholder="Enter Thankyou Message"
                  />
                </div>
              </div> */}
            <div className="w-50">
              <div>
                {getValue(info, `org_id`, "") ? (
                  <div className="d-flex align-items-center">
                    <div
                      className={`small_text__16 mt-2 my-template-${getValue(
                        info,
                        `org_id`,
                        ""
                      )}`}
                    >
                      <p className="small_text__16">
                        /{getValue(info, `org_id`, "")}/settings/web-forms/
                      </p>
                      <Tooltip
                        className="tooltip"
                        anchorSelect={`.my-template-${getValue(
                          info,
                          `org_id`,
                          ""
                        )}`}
                      >
                        /{getValue(info, `org_id`, "")}/settings/web-forms/
                        {getValue(request, "title", "")}
                      </Tooltip>
                    </div>

                    <div>
                      <InputRuleForm
                        inputType="TEXT"
                        value={getValue(request, "title", "")}
                        placeholder="Enter Route Name"
                        required
                        disabled
                      />
                    </div>
                  </div>
                ) : (
                  <InputRuleForm
                    inputType="TEXT"
                    value={getValue(request, "route_name", "")}
                    placeholder="Enter Route Name"
                    name="route_name"
                    onChange={handleChangeRouteName}
                    required
                    label={`Route Name`}
                    validLeft
                    validator={props.validator}
                  />
                )}
              </div>
              <div>
                <SearchToolTip
                  data={allPipelinesList}
                  value={getValue(request, `pipeline_id`, "")}
                  onChange={(e: object) => {
                    props.getUniquePipeline(getValue(e, `id`, ""));
                    setRequest({
                      ...request,
                      pipeline_id: getValue(e, `id`, ""),
                      pipeline_stage_id: "",
                    });
                  }}
                  label={`label`}
                  placeholder="Choose Pipeline"
                  required
                  mandtory
                  name="Pipeline"
                  validator={props.validator}
                />
              </div>
              {/* <div>
              <h6 className="header_text__16 add-nowrap mt-2">
                Pipeline Status
              </h6>
            </div> */}
              <SearchToolTip
                data={allPipelinesStageList}
                value={getValue(request, `pipeline_stage_id`, "")}
                onChange={(e: object) =>
                  setRequest({
                    ...request,
                    pipeline_stage_id: getValue(e, `value`, ""),
                  })
                }
                label={`label`}
                placeholder="Choose Pipeline Status"
                name="Pipeline Status"
                required
                validator={props.validator}
              />
              {/* <div>
              <h6 className="header_text__16 add-nowrap mt-3">Assign Owner</h6>
            </div> */}
              <div>
                <SearchToolTip
                  lookup_api={"org_users"}
                  value={getValue(request, `owner_id`, "")}
                  onChange={(e: object) =>
                    setRequest({
                      ...request,
                      owner_id: getValue(e, `id`, ""),
                    })
                  }
                  label={`label`}
                  placeholder="Choose Owner"
                  required
                  name="Owner"
                  validator={props.validator}
                />
                <div className="d-flex align-items-center gap-2 my-2">
                  <CustomCheckbox
                    onChange={(e: object) => {
                      const newValue = !getValue(
                        request,
                        `notify_owner`,
                        false
                      );
                      setRequest({
                        ...request,
                        notify_owner: newValue,
                      });
                    }}
                  />
                 <h6 className="header_text__16"> Notify Owner</h6>
                </div>
              </div>
            </div>

            {/* <div className="d-flex justify-content-center align-items-center">
                <h6 className="header_text__14 w-25">Tag</h6>:
                <div className="w-25 ms-3 mb-2">
                  <MultiSearchTooltip
                    data={allTags}
                    value={getValue(request, `tags`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        tags: handleUpdateArray(getValue(e, `value`, ""), []),
                      })
                    }
                    label="label"
                    placeholder="Choose Tag"
                    selectKey={"value"}
                  />
                </div>
              </div> */}
            <div className="d-flex justify-content-center">
              <div className="">
                <h6 className="header_text__16 mt-4">
                  What should the visitor see when they submit this form?
                </h6>
                <div className="d-flex align-items-center ">
                  <div>
                    <input
                      type="radio"
                      id="thank-you"
                      checked={!visitor_see}
                      onChange={() => setVisitor_see(!visitor_see)}
                    />
                    <label
                      className="form-label ms-2 cursor-pointer"
                      htmlFor="thank-you"
                    >
                      Thank You Message
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      id="redirect"
                      checked={visitor_see}
                      onChange={() => {
                        setVisitor_see(!visitor_see);
                        setRequest({
                          ...request,
                          thank_you_message: "",
                        });
                      }}
                    />
                    <label
                      className="form-label ms-2 cursor-pointer"
                      htmlFor="redirect"
                    >
                      Redirect them to a Page
                    </label>
                  </div>
                </div>

                {!visitor_see ? (
                  <div className="w-100 mb-2">
                    <InputRuleForm
                      inputType="TEXTAREA"
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          thank_you_message: e.target.value,
                        })
                      }
                      value={getValue(request, `thank_you_message`, "")}
                      placeholder="Enter Thankyou Message"
                    />
                  </div>
                ) : (
                  <div className="w-100 mb-2">
                    <InputRuleForm
                      inputType="TEXT"
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          redirect_url: e.target.value,
                        })
                      }
                      value={getValue(request, `redirect_url`, "")}
                      placeholder="Enter Redirecting URL"
                      required
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageFormStep2;
