import { capitalizeFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Button } from "antd";
import { FolderClosed, FolderLock, Pencil, Plus, Trash2 } from "lucide-react";

const CannedFolders = ({
  params,
  list,
  showModal,
  handleUpdateModal,
  handleDeleteModal,
  selectedFolder,
  handleSelectFolder,
}: any) => {
  return (
    <div className="col-3 canned-folder-wrapper ">
      <div className="canned-folder-top-section">
        <h6 className="header_text__18  px-4">Folders</h6>
        <Button
          icon={<Plus size={18} />}
          type="primary"
          onClick={() => showModal()}
        >
          New Folder
        </Button>
      </div>
      <div className="canned-folder-container px-3">
        {/* <div
          className="canned-new-folder canned-folder d-flex align-items-center gap-2 justify-content-center"
          onClick={() => showModal()}
        >
          <Plus />
          New Folder
        </div> */}
        {getValue(list, "folders.length", "") > 0 &&
          getValue(list, "folders", "").map((item: any, index: number) => (
            <div
              key={index}
              className={`d-flex align-items-center gap-3 canned-folder ${
                selectedFolder === getValue(item, "id", "")
                  ? "canned-folder-selected"
                  : ""
              }`}
              onClick={() => handleSelectFolder(getValue(item, "id", ""))}
            >
              {(getValue(item, "is_default", "") ||
                getValue(item, "is_personal", "")) === true ? (
                <FolderLock size={20} />
              ) : (
                <FolderClosed size={20} />
              )}
              <div
              // to={`/${getValue(
              //   params,
              //   "orgId",
              //   ""
              // )}/settings/canned-response/${getValue(item, "id", "")}`}
              >
                <p className="header-Text__14">
                  {capitalizeFirstLetter(getValue(item, "name", ""))}
                </p>
                <span className="small_text__13">
                  {getValue(item, "canned_responses.length", "")} canned
                  response
                </span>
              </div>
              {(getValue(item, "is_default", "") ||
                getValue(item, "is_personal", "")) === false && (
                <div className="canned-folder-edit">
                  <Pencil
                    size={16}
                    onClick={() =>
                      handleUpdateModal(
                        getValue(item, "name", ""),
                        getValue(item, "id", "")
                      )
                    }
                  />
                  <Trash2
                    size={16}
                    onClick={() =>
                      handleDeleteModal(
                        getValue(item, "id", ""),
                        capitalizeFirstLetter(getValue(item, "name", ""))
                      )
                    }
                  />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CannedFolders;
