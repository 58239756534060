import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllNotes  = (queryRequest: string) =>
  get(`${config.API_URL}/notes/list?${queryRequest}`);

export const getSpecificNote = (id: string) =>
  get(`${config.API_URL}/notes/${id}`);

export const createNote = (payload: object) =>
  post(`${config.API_URL}/notes`, payload);

export const updateNote = (id: string, payload: object) =>
  patch(`${config.API_URL}/notes/${id}`, payload);

export const deleteNote = (id: string) =>
  Delete(`${config.API_URL}/notes/${id}`);
