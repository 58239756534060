import Google from "./google";
import Linebreak from "./line-break";

export default function Signup() {
  return (
    <div className={"try-superteam-wrapper__container-deatils"}>
      <h2 className={`signup-title text-center`}>
        Sign up for a 21 day free trail
      </h2>
      <Google buttonText={"Sign up with Google"} />
      <Linebreak />
      <button
        className="superteams-button__primary-button"
        // onClick={() => showForm}
      >
        Sign up with Email/Phone Number
      </button>
      <p className={`signup-text text-center`}>
        By signing up, you are agreeing to our <span>terms</span> And
        acknowledge that you read our <span>privacy notice</span>
      </p>
    </div>
  );
}
