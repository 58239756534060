import { emojisList } from "@common/emojis";
import { aiRephraseMethods } from "@components/AI/AiRephraseMethods";
import AIStars from "@components/common/Loader/ai-stars";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import { getValue } from "@utils/lodash";
import { Dropdown, MenuProps, Tooltip } from "antd";

function BotSendMessageComponent(props: any) {
  const { request, setRequest } = props;
  const handleChangeEditor = (name: any, value: any, editorState: any) => {
    setRequest({
      ...request,
      description: value,
    });
  };

  const items: MenuProps["items"] = aiRephraseMethods.map(
    (method: any, index: any) => ({
      key: `${index + 1}`,
      label: method,
      onClick: () => props.toggleAiModal(method),
      disabled: props.isRephraseLoading === true,
    })
  );

  return (
    <div>
      {/* <div className="mb-2">
        <InputRuleForm
          inputType="TEXT"
          value={getValue(request, `name`, "")}
          name="name"
          onChange={(e: any) =>
            setRequest({ ...request, name: e.target.value })
          }
          label="Name"
          placeholder="Enter Name"
          // required
        />
      </div> */}
      {/* <div className="d-flex justify-content-end mb-3">
        <Tooltip
          title={
            getValue(request, "description", "").length < 12
              ? "Add a message to let AI help you"
              : ""
          }
        >
          <Dropdown
            menu={{ items }}
            disabled={getValue(request, "description", "").length < 12}
          >
            <div onClick={(e) => e.preventDefault()} className="">
              <div className="ai-button-wrapper">
                <button className="ai-button" type="button">
                  <div className="heart-beat-anim">
                    <AIStars />
                  </div>
                  AI Rewrite
                </button>
                <div className="ai-button-bg"></div>
              </div>
            </div>
          </Dropdown>
        </Tooltip>
      </div>
      <Tooltip title="hii">Hiii</Tooltip> */}
      <div className="mb-2">
        {/* <InputRuleForm
          inputType="TEXTAREA"
          value={getValue(request, `description`, "")}
          name="description"
          onChange={(e: any) =>
            setRequest({ ...request, description: e.target.value })
          }
          label="Message"
          placeholder="Enter Message"
          // mandatory
        /> */}
        <DraftJSRefEditor
          editorState={getValue(request, `description`, "")}
          handleChangeEditor={handleChangeEditor}
          name="content"
          placeholder="Enter here"
          editorStyle={{
            background: "white",
            paddingLeft: "20px",
            paddingTop: "20px",
            // height: "30vh",
          }}
          focusColor={"#1a73e8"}
          toolbar={{
            options: ["inline", "emoji", "image"],
            inline: {
              options: ["bold", "italic"],
            },
            emoji: {
              emojis: emojisList,
            },
          }}
          hideFocus
          focus
          editorClassName={"bot-editorClassName"}
        />
      </div>
    </div>
  );
}

export default BotSendMessageComponent;
