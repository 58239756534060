import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register the necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

function PieChartComponent(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const graphData = getValue(props, "graph", []);

    if (graphData.length > 0) {
      const colorPalette = [
        "#6a98cc",
        "#ffa600",
        "#bb5dfb",
        "#00a887",
        "#f95d6a",
      ];

      const dynamicColors = graphData.map(
        (_: any, index: any) => colorPalette[index % colorPalette.length]
      );

      const labels = graphData.map((item: any) =>
        typeof getValue(item, "key", "") === "object"
          ? `${getValue(item, "key.first_name", "")} ${getValue(
              item,
              "key.last_name",
              ""
            )}`
          : getValue(item, "key", "")
      );
      const dataValues = graphData.map((item: any) =>
        getValue(item, "count", 0)
      );
      const label = getValue(props, "module_field.label", "");

      setData({
        labels: labels,
        datasets: [
          {
            label: label,
            data: dataValues,
            backgroundColor: dynamicColors,
            borderColor: dynamicColors, // Use solid colors for borders as well
            borderWidth: 1,
          },
        ],
      });
    }
  }, [props.graph, props.module_field.label]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
    },
  };

  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, "name", "")}</p>
      <Pie data={data} options={options} className="pb-4 pe-3" />
    </div>
  );
}

export default PieChartComponent;
