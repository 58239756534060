import "./createPipeline.scss";
import PipelineStep1 from "./Step1";
import PipelineStep2 from "./Step2";
import Stepper from "./stepper";
import PipelineStep3 from "./Step3";
import { handleNavigatePipeline } from "../../helpers/create-pipeline-helper";
import ButtonComponent from "@components/Form/Button/Button";
function CreatePipeline(props: any) {
  const {
    request,
    setRequest,
    activeStep,
    setActiveStep,
    handleBackButton,
    onSubmit,
    isLoading,
    simpleValidator,
    module_id,
    forceUpdate,
  } = props;
  return (
    <div className={"add-more-rolls-wrapper_pipeline"}>
      <Stepper activeStep={activeStep} />
      <div className={"create_pipeline-wrapper_pipeline"}>
        {activeStep == 1 ? (
          <PipelineStep1
            request={request}
            setRequest={setRequest}
            simpleValidator={simpleValidator}
            activeStep={1}
          />
        ) : null}
        {activeStep == 2 ? (
          <PipelineStep2
            request={request}
            setRequest={setRequest}
            simpleValidator={simpleValidator}
            activeStep={2}
          />
        ) : null}
        {activeStep == 3 ? (
          <PipelineStep3
            request={request}
            setRequest={setRequest}
            module_id={module_id}
          />
        ) : null}
      </div>
      <div
        className={`submit-button__footer d-flex align-items-center justify-content-between`}
      >
        <div className={"submit-button__container"}>
          <div></div>
          <div className="d-flex align-items-center">
            <ButtonComponent
              buttonType={"scecondary"}
              buttonText="Cancel"
              onClickHandler={() => handleBackButton()}
            />
            {activeStep > 1 ? (
              <ButtonComponent
                buttonType={"scecondary"}
                buttonText="Previous"
                // onClickHandler={() => handleSubmit(activeStep - 1, "back")}
                onClickHandler={() =>
                  handleNavigatePipeline(
                    request,
                    activeStep - 1,
                    setActiveStep,
                    "back",
                    simpleValidator,
                    forceUpdate
                  )
                }
              />
            ) : null}
            {activeStep < 3 ? (
              <ButtonComponent
                buttonType={"primary"}
                buttonText="Next"
                // onClickHandler={() => handleSubmit(activeStep + 1, "next")}
                onClickHandler={() =>
                  handleNavigatePipeline(
                    request,
                    activeStep + 1,
                    setActiveStep,
                    "next",
                    simpleValidator,
                    forceUpdate
                  )
                }
              />
            ) : null}
            {activeStep == 3 ? (
              <ButtonComponent
                buttonType={"primary"}
                buttonText={isLoading ? "Please wait..." : "Create Pipeline"}
                onClickHandler={() => onSubmit()}
                disabled={isLoading}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePipeline;
