import React from "react";
import BackSvgComponent from "assets/svg/back-link";
import { formatString } from "common/text-helpers";

function Tab3(props: any) {
  const getImage = (type: string) => {
    switch (type) {
      case "EMAIL_GMAIL":
        return "/images/gmail.svg";
      case "EMAIL_OUTLOOK":
        return "/images/outlook.png";
      case "EMAIL_SMTP":
        return "/images/smtp.png";
      default:
        return "";
    }
  };
  return (
    <div>
      <div className="d-flex align-items-center">
        <div
          onClick={() => props.handleActiveTab(1)}
          className="cursor-pointer"
        >
          <BackSvgComponent />
        </div>
        <img src={getImage(props.type)} className="header_image ms-3" />
        <h6 className="main_header_text ms-3">{formatString(props.type)}</h6>
      </div>
      <div className={`${"list_container"}`}>
        <ul>
          <li>Sync your emails across multiple devices using oauth</li>
          <li>
            Send and receive instant notifications via MailMagnet and Signals
          </li>
          <li>
            Get access to SalesInbox — an intuitive mail client for sales people
          </li>
        </ul>
      </div>
      <div
        className={`${"button_left_container"}`}
        onClick={() => props.getConfigList(props.type)}
      >
        <p>Configure</p>
      </div>
    </div>
  );
}

export default Tab3;
