import { getValue } from "@utils/lodash";

export function authHeader() {
  let org_x_code = window.location.pathname.split("/");
  let code =  getValue(org_x_code, `[${1}]`, "")
  let access_token = localStorage.getItem("accessToken");
  if (!access_token) {
    return {
      "Content-Type": "application/json",
    };
  } else {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "x-org-code": code,
      // localStorage.getItem("X-Org-Code"),
    };
  }
}
