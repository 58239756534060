import { config } from "../env";
import { get, patch, post } from "./helpers/http-handler";

//Import APIs
export const getDataImports = (queryRequest: string) =>
  get(`${config.API_URL}/data-imports?${queryRequest}`);

export const createDataImport = (payload: object) =>
  post(`${config.API_URL}/data-imports`, payload);

export const createDataImportEntry = (payload: object) =>
  post(`${config.API_URL}/data-imports/entry`, payload);

export const getSpecificDataImport = (id: string) =>
  get(`${config.API_URL}/data-imports/${id}`);

//Export APIs
export const createDataExport = (payload: object) =>
  post(`${config.API_URL}/data-exports`, payload);

export const getDataExports = (queryRequest: string) =>
  get(`${config.API_URL}/data-exports?${queryRequest}`);

export const getSpecificDataExport = (id: string) =>
  get(`${config.API_URL}/data-exports/${id}`);
