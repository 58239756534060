import { config } from "../env";
import { Delete, get, patch, post, put } from "./helpers/http-handler";

export const allPipelines = (id: string, queryRequest: string) =>
  // get(`${config.API_URL}/pipelines?${queryRequest}`);
  get(`${config.API_URL}/settings/modules/${id}/pipelines?${queryRequest}`);

export const listAllPipelines = (id: string, queryRequest: string) =>
  // get(`${config.API_URL}/pipelines/list?${queryRequest}`);
  get(
    `${config.API_URL}/settings/modules/${id}/pipelines/list?${queryRequest}`
  );

export const getSpecificPipelineColumns = (id: string, pipeline_id: string) =>
  get(
    `${config.API_URL}/settings/modules/${id}/pipelines/${pipeline_id}/columns`
  );

export const getFormFields = (id: string) =>
  get(`${config.API_URL}/settings/modules/${id}/form-fields`);

export const updatePipelineColumns = (
  id: string,
  pipeline_id: string,
  payload: object
) =>
  put(
    `${config.API_URL}/settings/modules/${id}/pipelines/${pipeline_id}/columns`,
    payload
  );

export const updateModuleColumns = (id: string, payload: object) =>
  put(`${config.API_URL}/settings/modules/${id}/columns`, payload);

export const getSpecificPipeline = (id: string, pipeline_id: string) =>
  // get(`${config.API_URL}/pipelines/${id}`);
  get(`${config.API_URL}/settings/modules/${id}/pipelines/${pipeline_id}`);

export const createPipeline = (id: string, payload: object) =>
  post(`${config.API_URL}/settings/modules/${id}/pipelines`, payload);

export const updatePipelineInfo = (
  module_id: string,
  pipeline_id: string,
  payload: object
) =>
  // patch(
  //   `${config.API_URL}/settings/modules/${module_id}/pipelines/${id}/info`,
  //   payload
  // );
  patch(
    `${config.API_URL}/settings/modules/${module_id}/pipelines/${pipeline_id}`,
    payload
  );

export const updateModuleDisplayName = (module_id: string, payload: object) =>
  patch(`${config.API_URL}/settings/modules/${module_id}`, payload);

export const updatePipelineField = (
  module_id: string,
  id: string,
  payload: object,
  field_id: string
) =>
  patch(
    `${config.API_URL}/settings/modules/${module_id}/pipelines/${id}/form-fields/${field_id}`,
    payload
  );

export const updatePipelineFields = (id: string, payload: object) =>
  put(`${config.API_URL}/settings/modules/${id}/form-fields`, payload);

export const updatePipelineFormFieldsActivity = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/modules/${id}/form-fields`, payload);

export const updatePositionPipelineFormFields = (
  id: string,
  pipeline_id: string,
  payload: object
) =>
  put(
    `${config.API_URL}/settings/modules/${id}/pipelines/${pipeline_id}/form-fields`,
    payload
  );

//-------- Pipeline Module Fields Add/Remove ----------//

export const updatePipelineFormFields = (
  id: string,
  pipeline_id: string,
  payload: object
) =>
  post(
    `${config.API_URL}/settings/modules/${id}/pipelines/${pipeline_id}/form-fields`,
    payload
  );

export const deletePipelineFormFields = (
  id: string,
  pipeline_id: string,
  field_id: string
) =>
  Delete(
    `${config.API_URL}/settings/modules/${id}/pipelines/${pipeline_id}/form-fields/${field_id}`
  );

//-------- Task Module Fields Add/Remove ----------//

export const updateFormFields = (id: string, payload: object) =>
  post(`${config.API_URL}/settings/modules/${id}/form-fields`, payload);

export const deleteFormFields = (id: string, field_id: string) =>
  Delete(`${config.API_URL}/settings/modules/${id}/form-fields/${field_id}`);

//-------- Stages ----------//
export const createPipelineStage = (
  module_id: string,
  id: string,
  payload: object
) =>
  post(
    `${config.API_URL}/settings/modules/${module_id}/pipelines/${id}/stages`,
    payload
  );

export const getPipelineStages = (module_id: string, id: string) =>
  get(`${config.API_URL}/settings/modules/${module_id}/pipelines/${id}/stages`);

export const updatePipelineStages = (
  module_id: string,
  pipeline_id: string,
  id: string,
  payload: object
) =>
  patch(
    `${config.API_URL}/settings/modules/${module_id}/pipelines/${pipeline_id}/stages/${id}`,
    payload
  );

export const UpdatePipelineStagePositions = (
  module_id: string,
  pipeline_id: string,
  payload: object
) =>
  put(
    `${config.API_URL}/settings/modules/${module_id}/pipelines/${pipeline_id}/stages`,
    payload
  );
