import HomeHeader from "@components/common/Header/HomeHeader/Header";
import React from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import MainLayout from "@layouts/HomeLayout/NewLayout";

function MessageLayout({ children, restProps }: any) {
  return (
    <MainLayout {...restProps}>
      <CompanyProfileSubHeader />
      <div className={`${"email_container"} w-100`}>
        <CompanyProfileNavigation />
        {children}
      </div>
    </MainLayout>
  );
}

export default MessageLayout;
