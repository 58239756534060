import * as React from "react";
import "./index.scss";
import Loading from "./progress_bar.json";
import Lottie from "react-lottie";

interface ILoaderProps {}

const ProgressBarLoader: React.FunctionComponent<ILoaderProps> = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="loader-container_1">
      <div className="loader-wrapper__details" style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Lottie
          options={defaultOptions}
          height={"20px"}
          width={"250px"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginTop: "17vh",
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBarLoader;
