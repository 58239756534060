import React from "react";
import bin from "../../pages/Public/Auth/Signin/login.json";
import "./login-layout.scss";
import Header from "@components/common/Header/LoginHeader/header";
import { Link } from "react-router-dom";

export default function LoginLayout({ children }: any) {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: bin,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Header />
      <div
        className={`common-login-wrapper d-flex justify-content-center align-align-items-center flex-column `}
      >
        <div className="flex-1">{children}</div>
        {/* <div className={`blue-background flex-1 d-flex justify-content-center`}>
        </div> */}
        {/* <div className="d-flex justify-content-end ">
          <p>Policy</p>
          <p>Terms</p>
        </div> */}
      </div>
    </>
  );
}
