import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import timezones from "@common/json/standardized-timezone-data.json";
import { CurrencyList, PropertyTypeList } from "@common/property-enums";
import { useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import validator from "validator";

const CreatePropertyStepOne = (props: any) => {
  const { request, setRequest, forceUpdate, simpleValidator } = props;

  // const handleInputChange = (e: any) => {
  //   const value = e.target.value;
  //   const numericValue = Number(value);

  //   if (!isNaN(numericValue) && numericValue >= 1) {
  //     props.setRoomCount(numericValue);
  //     setRequest((prevRequest: any) => ({
  //       ...prevRequest,
  //       room_count: numericValue,
  //     }));
  //   } else if (value === "") {
  //     props.setRoomCount("");
  //   }
  // };

  // const handleBlur = () => {
  //   if (props.roomCount === "" || props.roomCount < 1) {
  //     props.setRoomCount(1);
  //     setRequest((prevRequest: any) => ({
  //       ...prevRequest,
  //       room_count: 1,
  //     }));
  //   }
  // };

  const [isValid, setIsValid] = useState(false);

  const [hasTouched, setHasTouched] = useState(false);
  const handlePhoneChange = (phone: any) => {
    const valid = isValidPhoneNumber(phone);
    setIsValid(valid);
    setRequest({
      ...request,
      contact_no: phone,
    });
  };

  const handleBlur = () => {
    setHasTouched(true);
    simpleValidator.current.showMessageFor("contact_no");
    forceUpdate(1);
  };

  useEffect(() => {
    if (!request.contact_no) {
      setRequest({
        ...request,
        contact_no: "+91",
      });
    }
  }, []);
  return (
    <div>
      <InputRuleForm
        inputType="TEXT"
        name="name"
        value={getValue(request, `name`, "")}
        onChange={(e: any) =>
          setRequest({
            ...request,
            name: e.target.value,
          })
        }
        placeholder="Enter a Name"
        className="mb-2"
        label="Property Name"
        validator={props.simpleValidator}
        validLeft
      />
      <div className="mt-3">
        <div className="d-flex align-items- gap-2">
          <div className="w-70">
            <p className="form-label">Property Type</p>
            <SearchToolTip
              placeholder="Choose property type"
              className="pt-3"
              value={getValue(request, `type`, "")}
              data={PropertyTypeList}
              selectKey={"value"}
              label="label"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  type: getValue(e, `value`, ""),
                })
              }
              validator={props.simpleValidator}
            />
            {/* <SearchToolTip
              placeholder="Choose property type"
              className="pt-3"
              value={getValue(request, `type`, "")}
              data={PropertyTypeList}
              selectKey={"value"}
              label="label"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  type: e.target.value,
                })
              }
              validator={props.simpleValidator}
            /> */}
          </div>
          <div className="w-40">
            <InputRuleForm
              inputType="TEXT"
              name="number"
              value={getValue(request, `room_count`, "")}
              placeholder="Enter no of rooms"
              className=""
              label="Rooms across selected category"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  room_count: e.target.value,
                })
              }
              marginZero
              validator={props.simpleValidator}
            />
          </div>
          {/* <div className="w-30">
            <div className="d-flex align-items-center gap-2">
              <div
                onClick={props.handleDecrement}
                className="mt-3 cursor-pointer"
              >
                <RemoveCircleSvgComponent size={30} />
              </div>

              <div className="w-40">
                <InputRuleForm
                  inputType="NUMBER"
                  name="number"
                  value={props.roomCount}
                  placeholder="Enter no of rooms"
                  className=""
                  label="Rooms across categories"
                  onChange={(e: any) =>
                    props.setRoomCount(Number(e.target.value))
                  }
                  marginZero
                  validator={props.simpleValidator}
                />
              </div>
              <div
                onClick={props.handleIncrement}
                className="mt-3 cursor-pointer"
              >
                <AddCircleSvgComponent size={30} />
              </div>
            </div>
          </div> */}
        </div>
        {/* <InputRuleForm
          inputType="NUMBER"
          name="number"
          value={getValue(request, `contact_no`, "")}
          placeholder="Enter Contact No"
          className="mb-2"
          label="Property Contact No"
          onChange={(e: any) =>
            setRequest({
              ...request,
              contact_no: e.target.value,
            })
          }
          validator={props.simpleValidator}
          validLeft
        /> */}
        <label className="form-label">Property Contact No</label>
        <PhoneInput
          defaultCountry="IN"
          value={getValue(request, `contact_no`, "")}
          onChange={handlePhoneChange}
          placeholder="Enter Contact No"
          className="input-number-field mb-2"
          onBlur={handleBlur}
        />
        <p className={`error-text`}>
          {simpleValidator.current.message(
            "contact_no",
            getValue(request, `contact_no`, ""),
            "required|phone"
          )}
        </p>
        <InputRuleForm
          inputType="EMAIL"
          name="email"
          value={getValue(request, `email`, "")}
          placeholder="Enter Email"
          className="mb-2"
          label="Email"
          onChange={(e: any) =>
            setRequest({
              ...request,
              email: e.target.value,
            })
          }
          validator={props.simpleValidator}
          validLeft
        />
        <SearchToolTip
          placeholder={`Enter Currency`}
          onChange={(e: any) =>
            setRequest({
              ...request,
              currency: getValue(e, `value`, ""),
            })
          }
          label={`label`}
          data={props.currencyList}
          value={getValue(request, `currency`, "")}
          selectKey={"value"}
          name={"Currency"}
          validator={props.simpleValidator}
        />
        <SearchToolTip
          placeholder={`Enter Time Zone`}
          onChange={(e: any) =>
            setRequest({
              ...request,
              timezone: getValue(e, `value`, ""),
            })
          }
          label={`label`}
          className="pt-3"
          data={timezones}
          selectKey={"value"}
          value={getValue(request, `timezone`, "")}
          name={"Time Zone"}
          validator={props.simpleValidator}
          validLeft
          concatLength="100"
        />
      </div>
      <div className="mt-3">
        <InputRuleForm
          inputType="TEXTAREA"
          name="description"
          placeholder="Enter Description"
          className="mb-2"
          label="Property Description"
          value={getValue(request, `description`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              description: e.target.value,
            })
          }
          validator={props.simpleValidator}
          validLeft
        />
      </div>
    </div>
  );
};

export default CreatePropertyStepOne;
