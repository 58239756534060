import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

// Register the necessary components
ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

function DynamicLineChartComponent(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [isStacked, setIsStacked] = useState(false);

  useEffect(() => {
    // Extract the second object in the graph data
    const secondObject = getValue(props, "graph[1]", {});

    const count = getValue(secondObject, "count", null);
    const children = getValue(secondObject, "child", []);

    if (children && children.length > 0) {
      // If `child` exists, create a stacked line chart
      const labels = ["Data"]; // Single label for stacked data points
      const datasets = children.map((child: any, index: number) => ({
        label: getValue(child, "key", `Dataset ${index + 1}`),
        data: [getValue(child, "count", 0)], // Each child count is a data point in its own dataset
        backgroundColor: `rgba(${75 + index * 30}, 192, 192, 0.5)`, // Vary colors for each line
        borderColor: `rgba(${75 + index * 30}, 192, 192, 1)`,
        fill: true, // Fill area under each line for stacking effect
      }));

      setData({
        labels: labels,
        datasets: datasets,
      });
      setIsStacked(true); // Enable stacked option
    } else if (count !== null) {
      // If `count` exists but no `child`, create a normal line chart
      setData({
        labels: ["Data Count"], // Label for the single line
        datasets: [
          {
            label: getValue(props, "module_field.label", "Data Count"),
            data: [count], // Single data point for the line chart
            backgroundColor: "rgba(75, 192, 192, 0.5)",
            borderColor: "rgba(75, 192, 192, 1)",
            fill: false, // No fill for normal line chart
          },
        ],
      });
      setIsStacked(false); // Disable stacked option
    }
  }, [props.graph, props.module_field.label]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
    },
    scales: {
      x: {
        stacked: isStacked,
      },
      y: {
        beginAtZero: true,
        stacked: isStacked,
      },
    },
  };

  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, "name", "")}</p>
      <Line data={data} options={options} />
    </div>
  );
}

export default DynamicLineChartComponent;
