// import "react-multi-email/dist/style.css";
// import "../../components/PendingTaskDetails/PendingTaskMainBody/PendingTaskMainBody.scss";
import { handleTextChange } from "common/handleChange";
import { getValue } from "@utils/lodash";
// import EmailPopup from "@components/CommonComponents/Dialogs/CenteredAlignedPopup/SendEmail";
// import TagInput from "@components/common/Pipeline/React-Taginput";
import { useState } from "react";
import TagInput from "@components/Pages/Pipeline/React-Taginput";
import EmailPopup from "@components/Dialogs/CenteredAlignedPopup/SendEmail";
import DraftJSEditor from "@components/custom/DraftJSEditor";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";

function CommonSendEmailPopup(props: any) {
  const {
    isPopupVisible,
    setPopupVisibility,
    request,
    setRequest,
    handleSubmit,
    isLoading,
    handleClose,
    handleSubmitDraft,
    fromMails,
  } = props;
  const [fromActive, setFromActive] = useState(false);
  const [ccActive, setCcActive] = useState(false);
  const [bccActive, setBccActive] = useState(false);
  const [replyToActive, setReplyToActive] = useState(false);

  // replyTo
  return (
    <div>
      <EmailPopup
        isPopupVisible={isPopupVisible}
        popupProperties={{
          title: "New Email",
          isMoreDetailsCTAVisible: true,
        }}
        handleSubmitButtonClick={() => handleSubmit()}
        handleSubmitDraft={() => {
          handleSubmitDraft();
          handleClose();
        }}
        handleCloseButtonClick={() => handleClose()}
        isLoading={isLoading}
        style={{ zIndex: "1001" }}
        documentList={props.documentList}
        handleUploadDocuments={props.handleUploadDocuments}
        uploadLoading={props.uploadLoading}
        handleRemoveDocuments={props.handleRemoveDocuments}
      >
        <div className={`email-popup_container`}>
          <div>
            {fromActive && (
              <div
                className={`email-popup_container_email_body d-flex align-items-center mt-2 pb-2 w-100`}
              >
                <div className="w-100 d-flex align-items-center">
                  <label className={`email_labels`}>From</label>
                  {/* <TagInput
                    placeholder=""
                    emails={getValue(request, `from`, [])}
                    request={request}
                    setRequest={setRequest}
                    name="from"
                    onChange={props.onChange}
                    className="w-100"
                    disabled
                  /> */}
                  <div className="w-50">
                    <SearchToolTip
                      data={fromMails}
                      label={"label"}
                      selectKey={"value"}
                      onChange={(e: object) =>
                        setRequest({
                          ...request,
                          from: getValue(e, `value`, ""),
                        })
                      }
                      value={getValue(request, `from`, "")}
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              className={`email-popup_container_email_body d-flex align-items-center mt-2 pb-1 w-100 `}
            >
              <div className="w-100 d-flex align-items-center">
                <label className={`email_labels`}>To</label>
                <TagInput
                  placeholder=""
                  emails={getValue(request, `to`, [])}
                  request={request}
                  setRequest={setRequest}
                  name="to"
                  onChange={props.onChange}
                  className="w-100"
                />
              </div>
              <div className="d-flex px-4 d-flex">
                <p
                  className={`ms-2 cursor-pointer email-labels ${
                    fromActive ? "header_blue_text" : "header_text__14_new"
                  }`}
                  onClick={() => setFromActive(!fromActive)}
                >
                  From
                </p>
                <p
                  className={`ms-2 cursor-pointer email-labels ${
                    replyToActive ? "header_blue_text" : "header_text__14_new"
                  }`}
                  onClick={() => setReplyToActive(!replyToActive)}
                >
                  ReplyTo
                </p>
                <p
                  className={`ms-2 cursor-pointer email-labels ${
                    bccActive ? "header_blue_text" : "header_text__14_new"
                  }`}
                  onClick={() => setBccActive(!bccActive)}
                >
                  Bcc
                </p>
                <p
                  className={`ms-2 cursor-pointer email-labels ${
                    ccActive ? "header_blue_text" : "header_text__14_new"
                  }`}
                  onClick={() => setCcActive(!ccActive)}
                >
                  Cc
                </p>
              </div>
            </div>
            {replyToActive && (
              <div
                className={`email-popup_container_email_body d-flex align-items-center mt-2 pb-2 w-100 ${
                  replyToActive ? "bcc-active-class" : ""
                }`}
              >
                <div className="w-100 d-flex align-items-center">
                  <label className={`email_labels`}>Reply To</label>
                  <TagInput
                    placeholder=""
                    emails={getValue(request, `replyTo`, [])}
                    request={request}
                    setRequest={setRequest}
                    name="replyTo"
                    onChange={props.onChange}
                    className="w-100"
                  />
                </div>
              </div>
            )}
            {bccActive && (
              <div
                className={`email-popup_container_email_body d-flex align-items-center mt-2 pb-2 w-100 ${
                  bccActive ? "bcc-active-class" : ""
                }`}
              >
                <div className="w-100 d-flex align-items-center">
                  <label className={`email_labels`}>Bcc</label>
                  <TagInput
                    placeholder=""
                    emails={getValue(request, `bcc`, [])}
                    request={request}
                    setRequest={setRequest}
                    name="bcc"
                    onChange={props.onChange}
                    className="w-100"
                  />
                </div>
              </div>
            )}
            {ccActive && (
              <div
                className={`email-popup_container_email_body d-flex align-items-center mt-2 pb-2 w-100`}
              >
                <div className="w-100 d-flex align-items-center">
                  <label className={`email_labels`}>Cc</label>
                  <TagInput
                    placeholder=""
                    emails={getValue(request, `cc`, [])}
                    request={request}
                    setRequest={setRequest}
                    name="cc"
                    onChange={props.onChange}
                    className="w-100"
                  />
                </div>
              </div>
            )}

            <div
              className={`email-popup_container_email_body d-flex align-items-center w-100`}
            >
              <div className="w-100">
                <input
                  type="text"
                  className={`email-popup_container_form-control_body w-100`}
                  // autocomplete="new-password"
                  onChange={(e: any) =>
                    handleTextChange(e, request, setRequest)
                  }
                  name="subject"
                  value={getValue(request, `subject`, "")}
                  placeholder="Write a subject line"
                />
              </div>
            </div>

            {/* <p
              className="text-right m-3 cursor-pointer"
              onClick={() => {
                props.setTextActive(!getValue(props, `textActive`, false));
                setRequest({
                  ...request,
                  html: "",
                });
              }}
            >
              {!getValue(props, `textActive`, false) ? "Text" : "Rich Text"
            </p> */}
            <div
              className={`email-popup_container_body d-flex align-items-center  mt-2`}
            >
              {getValue(props, `textActive`, false) ? (
                <textarea
                  rows={7}
                  className="form-control-white-texarea w-100 mb-2"
                  value={getValue(request, `html`, "")}
                  onChange={(e: any) =>
                    handleTextChange(e, request, setRequest)
                  }
                  name="html"
                />
              ) : (
                <div className="email_draft_container">
                  <DraftJSEditor
                    editorState={getValue(request, `html`, "")}
                    handleChangeEditor={props.handleChangeEditor}
                    name="html"
                    className="email-message-body"
                    placeholder="Start typing your email..."
                    editorStyle={{
                      background: "white",
                      paddingLeft: "30px",
                      minHeight: "30vh",
                      fontSize: "12px",
                      border:'none'
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </EmailPopup>
    </div>
  );
}

export default CommonSendEmailPopup;
