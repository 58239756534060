import SettingsUserEmails from "@pages/Private/Settings/email/Email/user-email";
import SettingsOrgEmails from "@pages/Private/Settings/email/OrgEmails";
import SettingsEmail from "@pages/Private/Settings/email/Email";
import SettingsEmailDeliverability from "@pages/Private/Settings/email/Deliverability";
import SettingsAddEmailDeliverability from "@pages/Private/Settings/email/Deliverability/add-sender";
import SettingsEmailTemplates from "@pages/Private/Settings/email/Templates";

export const SettingsEmailsRoutes = [
  {
    path: "/:orgId/settings/emails/config",
    name: "settings_customisation_emails",
    component: SettingsEmail,
  },
  {
    path: "/:orgId/user-emails/config",
    name: "settings_customisation_emails",
    component: SettingsUserEmails,
  },
  {
    path: "/:orgId/settings/emails/deliverability",
    name: "settings_customisation_emails",
    component: SettingsEmailDeliverability,
  },
  {
    path: "/:orgId/settings/emails/deliverability/add",
    name: "settings_customisation_emails",
    component: SettingsAddEmailDeliverability,
  },
  {
    path: "/:orgId/settings/emails/templates",
    name: "settings_customisation_emails",
    component: SettingsEmailTemplates,
  },
  {
    path: "/:orgId/settings/emails/org-emails",
    name: "settings_customisation_emails",
    component: SettingsOrgEmails,
  },
];
