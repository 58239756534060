import { getValue } from "@utils/lodash";
import React from "react";

function KPIGrowthIndexComponent(props: any) {
  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, `name`, "")}</p>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center ">
          <h6
            className={`standard-highlight-percentage ${
              getValue(props, `compare_objective`, "") !== "positive" //dummy color
                ? "db_color_success"
                : "db_color_negative"
            }`}
          >
            {parseInt(getValue(props, `graph.percentage`, ""))}%
          </h6>
        </div>
        <h6 className="header_text__20 ms-3">
          {getValue(props, `graph.current`, "")}
        </h6>
      </div>
      <div className="d-flex align-items-center">
        <p className="small_text__14 mt-2">
          {getValue(props, `graph.previous_label`, "")} :{" "}
          {getValue(props, `graph.previous`, "")}
        </p>
      </div>
    </div>
  );
}

export default KPIGrowthIndexComponent;
