import * as React from "react";
import "./index.scss";
import Loading from "./circle_loader/loader2.json";
// import HeaderOnlyLayout from "@layouts/HeaderOnlyLayout/HeaderOnlyLayout";
import Lottie from "react-lottie";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILoaderProps {}

// const Loader: React.FunctionComponent<ILoaderProps> = (props: any) => {
function LoaderMain(props: any) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="">
      <Lottie
        options={defaultOptions}
        height={"150px"}
        width={"150px"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop:'20vh'
        }}
      />
    </div>
  );
}

export default LoaderMain;
