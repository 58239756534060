import DeleteSvgComponent from "@assets/svg/delete";
import { getValue } from "@utils/lodash";
import React from "react";
import { Handle, Position } from "reactflow";
import { findConnectedEdgeInfo } from "../../helpers/node-helper";
import AddCircleSvgComponent from "@assets/svg/add-circle";
import CallSvgComponent from "@assets/svg/phone";

function BotVisitorPhoneOption(props: any) {
  const { data, handleDelete, handleStyle } = props;
  return (
    <>
      {findConnectedEdgeInfo(data) !== getValue(data, `id`, "") && (
        <div
          style={{
            position: "absolute",
            right: "-12px",
            top: "50%",
            background: "none",
            transform: "translateY(-50%)",
          }}
          onClick={(e) => {
            e.stopPropagation();
            data.addNodeByID(getValue(data, `id`, ""));
          }}
        >
          <AddCircleSvgComponent color={"black"} size={28} />
        </div>
      )}
      <div className="custom-node-header">
        <p className="small_text__14 text-black d-flex align-items-center gap-2">
          <CallSvgComponent color="#0083ff" size="18" />
          {getValue(data, `label`, "")}
        </p>
        {!getValue(data, `path`, "") && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(getValue(data, `id`, ""));
          }}
          className="custom-node-delete"
        >
          <DeleteSvgComponent color={"red"} size={18} />
        </div>)}
      </div>
      <div className="custom-button-body">
        <p
          className="small_text__14"
          contentEditable="true"
          dangerouslySetInnerHTML={{
            __html: getValue(data, `description`, ""),
          }}
        ></p>
      </div>
      {/* <div className="visitor_section">
        <h6 className="header_text__14 text-center">Phone</h6>
      </div> */}
      <Handle type="target" position={Position.Left} style={handleStyle} />
      <Handle type="source" position={Position.Right} style={handleStyle} />
    </>
  );
}

export default BotVisitorPhoneOption;
