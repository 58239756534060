import SettingsLayout from "@layouts/Layout/settings-layout";
import React, { useRef, useState } from "react";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { addInboxDomain } from "@services/email-config.service";
import { toast } from "sonner";
import SimpleReactValidator from "simple-react-validator";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import InputRuleForm from "@components/InputRuleForm/form";
import ButtonComponent from "@components/Form/Button/Button";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";

function SettingsAddEmailDeliverability(props: any) {
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const params = useParams();
  const [request, setRequest] = useState({
    domain: "",
    subDomain: "",
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        let resp = await addInboxDomain(request);
        if (resp) {
          toast.success("Added successfully");
          navigate(
            `/${getValue(params, `orgId`, "")}/settings/emails/deliverability`
          );
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
        setSubmitLoading(true);
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <SettingsLayout {...props}>
      <CompanyProfileSubHeader />

      <div className={`${"email_container"} w-100`}>
        <CompanyProfileNavigation />
        <div className="m-4">
          <p className="sub_text__14">
            Send bulk messages from your own email address. Add a new sender
            below.
          </p>
          <div className="mt-3">
            <div>
              {/* <label className="label_text mb-1">Domain</label> */}
              <InputRuleForm
                inputType="TEXT"
                label="Domain"
                style={{ height: "38px" }}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    domain: e.target.value,
                  })
                }
                value={getValue(request, `domain`, "")}
                name="domain"
                placeholder={"Enter Domain"}
                validator={simpleValidator}
                required
                validLeft
              />
            </div>
            <div className="mt-3">
              {/* <label className="label_text mb-1">Sub Domain</label> */}
              <InputRuleForm
                inputType="TEXT"
                label="Sub Domain"
                style={{ height: "38px" }}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    subDomain: e.target.value,
                  })
                }
                value={getValue(request, `subDomain`, "")}
                name="subDomain"
                placeholder={"Enter Sub Domain"}
                // validator={simpleValidator}
                // required
                // validLeft
              />
            </div>
            <div className="popup-submit-container">
              <ButtonComponent
                onClickHandler={() =>
                  navigate(
                    `/${getValue(
                      params,
                      `orgId`,
                      ""
                    )}/settings/emails/deliverability`
                  )
                }
                buttonType={"scecondary"}
                buttonText="Close"
              />
              <ButtonComponent
                buttonType={"primary"}
                buttonText={submitLoading ? "Please wait..." : "Submit"}
                onClickHandler={handleSubmit}
                disabled={submitLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
}

export default SettingsAddEmailDeliverability;
