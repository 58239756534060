// export const emojisList = [
//   "😀",
//   "😁",
//   "😂",
//   "😃",
//   "😉",
//   "😋",
//   "😎",
//   "😍",
//   "😗",
//   "🤗",
//   "🤔",
//   "😣",
//   "😫",
//   "😴",
//   "😌",
//   "🤓",
//   "😛",
//   "😜",
//   "😠",
//   "😇",
//   "😷",
//   "😈",
//   "👻",
//   "😺",
//   "😸",
//   "😹",
//   "😻",
//   "😼",
//   "😽",
//   "🙀",
//   "🙈",
//   "🙉",
//   "🙊",
//   "👼",
//   "👮",
//   "🕵",
//   "💂",
//   "👳",
//   "🎅",
//   "👸",
//   "👰",
//   "👲",
//   "🙍",
//   "🙇",
//   "🚶",
//   "🏃",
//   "💃",
//   "⛷",
//   "🏂",
//   "🏌",
//   "🏄",
//   "🚣",
//   "🏊",
//   "⛹",
//   "🏋",
//   "🚴",
//   "👫",
//   "💪",
//   "👈",
//   "👉",
//   "👉",
//   "👆",
//   "🖕",
//   "👇",
//   "🖖",
//   "🤘",
//   "🖐",
//   "👌",
//   "👍",
//   "👎",
//   "✊",
//   "👊",
//   "👏",
//   "🙌",
//   "🙏",
//   "🐵",
//   "🐶",
//   "🐇",
//   "🐥",
//   "🐸",
//   "🐌",
//   "🐛",
//   "🐜",
//   "🐝",
//   "🍉",
//   "🍄",
//   "🍔",
//   "🍤",
//   "🍨",
//   "🍪",
//   "🎂",
//   "🍰",
//   "🍾",
//   "🍷",
//   "🍸",
//   "🍺",
//   "🌍",
//   "🚑",
//   "⏰",
//   "🌙",
//   "🌝",
//   "🌞",
//   "⭐",
//   "🌟",
//   "🌠",
//   "🌨",
//   "🌩",
//   "⛄",
//   "🔥",
//   "🎄",
//   "🎈",
//   "🎉",
//   "🎊",
//   "🎁",
//   "🎗",
//   "🏀",
//   "🏈",
//   "🎲",
//   "🔇",
//   "🔈",
//   "📣",
//   "🔔",
//   "🎵",
//   "🎷",
//   "💰",
//   "🖊",
//   "📅",
//   "✅",
//   "❎",
//   "💯",
// ];

export const emojisList = [
  "😀",
  "😁",
  "😂",
  "😃",
  "😄",
  "😅",
  "😆",
  "😉",
  "😊",
  "😋",
  "😎",
  "😍",
  "😘",
  "😗",
  "😙",
  "😚",
  "🤗",
  "🤔",
  "😐",
  "😑",
  "😶",
  "🙄",
  "😏",
  "😣",
  "😥",
  "😮",
  "🤐",
  "😯",
  "😪",
  "😫",
  "😴",
  "😌",
  "🤓",
  "😛",
  "😜",
  "😝",
  "🤤",
  "😒",
  "😓",
  "😔",
  "😕",
  "🙃",
  "🤑",
  "😲",
  "☹️",
  "🙁",
  "😖",
  "😞",
  "😟",
  "😤",
  "😢",
  "😭",
  "😦",
  "😧",
  "😨",
  "😩",
  "😬",
  "😰",
  "😱",
  "😳",
  "😵",
  "😡",
  "😠",
  "😷",
  "🤒",
  "🤕",
  "🤢",
  "🤧",
  "😇",
  "🤠",
  "🤡",
  "🤥",
  "🤫",
  "🤭",
  "🧐",
  "🤨",
  "😈",
  "👿",
  "👹",
  "👺",
  "💀",
  "☠️",
  "👻",
  "👽",
  "👾",
  "🤖",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "🙀",
  "😿",
  "😾",
  "🙈",
  "🙉",
  "🙊",
  "💋",
  "💌",
  "💘",
  "💝",
  "💖",
  "💗",
  "💓",
  "💞",
  "💕",
  "💟",
  "❣️",
  "💔",
  "❤️",
  "🧡",
  "💛",
  "💚",
  "💙",
  "💜",
  "🖤",
  "💯",
  "💢",
  "💥",
  "💫",
  "💣",
  "💦",
  "💨",
  "🕳",
  "💬",
  "👁️‍🗨️",
  "🗨️",
  "🗯",
  "💭",
  "🕴",
  "👑",
  "👒",
  "👓",
  "🕶",
  "🥽",
  "🥼",
  "👔",
  "👕",
  "👖",
  "🧣",
  "🧤",
  "🧥",
  "🧦",
  "👗",
  "👘",
  "👙",
  "👚",
  "👛",
  "👜",
  "👝",
  "🛍",
  "🎒",
  "👞",
  "👟",
  "🥾",
  "🥿",
  "👠",
  "👡",
  "🩰",
  "👢",
  "👑",
  "👒",
  "🎩",
  "🎓",
  "🧢",
  "🪖",
  "⛑️",
  "📿",
  "💄",
  "💍",
  "💎",
  "🔇",
  "🔈",
  "🔉",
  "🔊",
  "📢",
  "📣",
  "📯",
  "🔔",
  "🔕",
  "🎼",
  "🎵",
  "🎶",
  "🎙",
  "🎚",
  "🎛",
  "🎤",
  "🎧",
  "📻",
  "🎷",
  "🎸",
  "🎹",
  "🎺",
  "🎻",
  "🥁",
  "📱",
  "📲",
  "☎️",
  "📞",
  "📟",
  "📠",
  "🔋",
  "🔌",
  "💻",
  "🖥️",
  "🖨️",
  "⌨️",
  "🖱️",
  "🖲️",
  "💽",
  "💾",
  "💿",
  "📀",
  "🧮",
  "🎥",
  "🎬",
  "📽",
  "🎞",
  "📸",
  "📹",
  "📼",
  "🔍",
  "🔎",
  "🕯",
  "💡",
  "🔦",
  "🏮",
  "📔",
  "📕",
  "📖",
  "📗",
  "📘",
  "📙",
  "📚",
  "📓",
  "📒",
  "📃",
  "📜",
  "📄",
  "📰",
  "🗞",
  "📑",
  "🔖",
  "🏷",
  "💰",
  "🪙",
  "💴",
  "💵",
  "💶",
  "💷",
  "💸",
  "💳",
  "🧾",
  "💹",
  "💱",
  "💲",
  "✉️",
  "📧",
  "📨",
  "📩",
  "📤",
  "📥",
  "📦",
  "📫",
  "📪",
  "📬",
  "📭",
  "📮",
  "🗂",
  "📁",
  "📂",
  "🗃",
  "🗄",
  "🗑",
  "🛢",
  "💼",
  "📅",
  "📆",
  "🗒",
  "🗓",
  "📇",
  "📈",
  "📉",
  "📊",
  "📋",
  "📌",
  "📍",
  "📎",
  "🖇",
  "📏",
  "📐",
  "✂️",
  "🗃",
  "🖍",
  "🖊",
  "🖋",
  "✒️",
  "✏️",
  "📒",
  "🧷",
  "🧵",
  "🧶",
  "🔒",
  "🔓",
  "🔏",
  "🔐",
  "🔑",
  "🗝",
  "🔨",
  "🛠",
  "⛏",
  "🪓",
  "🔧",
  "🧰",
  "🔩",
  "⚙️",
  "🗜",
  "🧱",
  "⛓",
  "🔫",
  "💣",
  "🧨",
  "🪃",
  "🛡",
  "🪖",
  "🚬",
  "⚰️",
  "⚱️",
  "🏺",
  "🧿",
  "🪔",
  "🔮",
  "📿",
  "💈",
  "⚗️",
  "🔭",
  "🔬",
  "🕳",
  "🩺",
  "🛏",
  "🛋",
  "🪑",
  "🚪",
  "🛍",
  "🛒",
  "🎁",
  "🎈",
  "🎀",
  "🎊",
  "🎉",
  "🎎",
  "🎏",
  "🎐",
  "🎀",
  "🎗",
  "🎖",
  "🏆",
  "🏅",
  "🥇",
  "🥈",
  "🥉",
  "🧩",
  "🧸",
  "🪅",
  "🎯",
  "🎳",
  "🎮",
  "🕹",
  "🎰",
  "🎲",
  "🃏",
  "🀄",
  "🃏",
  "🀄",
  "🎴",
  "🎭",
  "🖼️",
  "🎨",
  "🧵",
  "🧶",
  "🧩",
  "🎤",
  "🎧",
  "🎷",
  "🎺",
  "🎸",
  "🥁",
  "🎹",
  "🎻",
  "🎶",
  "🎵",
];
