import AddSvgComponent from "@assets/svg/add";
import ResendSvgComponent from "@assets/svg/resend";
import { emojisList } from "@common/emojis";
import { aiRephraseMethods } from "@components/AI/AiRephraseMethods";
import AIStars from "@components/common/Loader/ai-stars";
import WhatsappAddHover from "@components/common/Tooltip/whats-app-add";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import { Dropdown, Tooltip } from "antd";
import EmojiPicker from "emoji-picker-react";
import { useRef } from "react";

function ChatFooter(props) {
  const {
    showEmojiPicker,
    handleEmojiClick,
    message,
    handleSendMessages,
    handleUploadDocument,
    setMessage,
    handleChangeEditor,
    toggleAiModal,
    isRephraseLoading,
    contentLength,
  } = props;

  const editorRef = useRef(null);
  const handleSubmit = () => {
    setMessage(`<p></p>`);
    handleSendMessages();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const items = aiRephraseMethods.map((method, index) => ({
    key: `${index + 1}`,
    label: method,
    onClick: () => toggleAiModal(method),
    disabled: isRephraseLoading === true,
  }));

  return (
    <div className="position-relative">
      <div
        style={{
          position: "absolute",
          top: "60%",
          right: "80px",
          zIndex: "99",
        }}
      >
        <Tooltip
          title={contentLength < 12 ? "Add message to let AI help you" : ""}
        >
          <Dropdown menu={{ items }} disabled={contentLength < 12}>
            <a onClick={(e) => e.preventDefault()} className="w-auto">
              <AIStars />
            </a>
          </Dropdown>
        </Tooltip>
      </div>
      <div className="message-bottom-section p-2">
        <div
          style={{
            position: "absolute",
            bottom: "60px",
            left: "60px",
          }}
        >
          {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
        </div>

        <div className="d-flex align-items-center">
          <div className="cursor-pointer mx-2">
            <WhatsappAddHover
              header={<AddSvgComponent color={"#54656f"} size={32} />}
              text={"Please add all required information"}
              width={"200px"}
              height={"200px"}
              hover
              handleUploadDocument={handleUploadDocument}
              message={message}
              toggleAiModal={toggleAiModal}
              contentLength={contentLength}
              isRephraseLoading={isRephraseLoading}
            />
          </div>
          <div className="w-100">
            <div
              onKeyDown={handleKeyDown}
              style={{
                outline: "none",
              }}
              ref={editorRef}
            >
              <DraftJSRefEditor
                editorState={message}
                handleChangeEditor={handleChangeEditor}
                placeholder="Enter here"
                editorStyle={{
                  background: "white",
                  paddingLeft: "20px",
                  paddingRight: "60px",
                  paddingTop: "20px",
                  // height: "10vh",
                }}
                // ref={props.reference}
                focusColor={"#1a73e8"}
                toolbar={{
                  options: ["inline", "emoji"],
                  inline: {
                    options: ["bold", "italic"],
                  },
                  emoji: {
                    emojis: emojisList,
                  },
                }}
                // hideFocus
                autoFocus
                focus
              />
            </div>
          </div>
          <div
            onClick={!message || message === "<p></p>\n" ? null : handleSubmit}
            className="cursor-pointer mx-2 ms-3"
          >
            <ResendSvgComponent
              color={
                !message || message === "<p></p>\n" ? "#8696a0" : "#408dfb"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatFooter;
