import { QueryRequestHelper } from "@common/query-request-helper";
import {
  getDashboardLeads,
  getDashboardModule,
} from "@services/dashboard.service";
import * as React from "react";
import DashboardHeader from "./components/header";
import { getValue } from "@utils/lodash";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import { listAllModules } from "@services/modules.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  formatDateRange,
  getDateFunctions,
  getStartOfMonthDate,
} from "@common/date-helpers";
import "./dashboard.scss";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  PointElement,
  LineElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import { useStateContext } from "@context/profileProvider";
import { getDashboardContact } from "@services/dashboard.service";
import useDynamicTitle from "@context/useDynamicTitle";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import { usePermissionContext } from "@context/permissionContext";
import { toast } from "sonner";
import ListLoader from "@components/common/Loader";
import {
  createAnalytics,
  createAnalyticsComponent,
  deleteAnalytics,
  deleteAnalyticsComponent,
  getAllAnalytics,
  getAnalyticsGraph,
  updateAnalytics,
  updateAnalyticsComponent,
} from "@services/analytics.service";
import ComponentKPIStyleDrawer from "./Drawers/component-kpi-style-drawer";
import ComponentKPIDrawer from "./Drawers/component-kpi-drawer";
import ComponentChartStyleDrawer from "./Drawers/component-chart-style-drawer";
import ComponentChartDrawer from "./Drawers/component-chart-drawer";
import DashboardComponentGraphs from "./Graphs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Colors
);

const NewDashboard = (props: any) => {
  useDynamicTitle("Dashboard");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { subTabPermissionList, permissionAPITriggered } =
    usePermissionContext();
  let date_range = getStartOfMonthDate();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  React.useEffect(() => {
    if (Object.keys(UrlParams).length === 0 && permissionAPITriggered) {
      getModules();
      getAllDashboardList();
    }
  }, [permissionAPITriggered]);

  React.useEffect(() => {
    if (Object.keys(UrlParams).length > 0 && permissionAPITriggered) {
      getModules();
      getAllDashboardList();
      if (getValue(UrlParams, `analyticsId`, "")) {
        setSelectedDashboard(getValue(UrlParams, `analyticsId`, ""));
      }
      if (getValue(UrlParams, `module`, "")) {
        setSelectedModule(getValue(UrlParams, `module`, ""));
      }
      if (getValue(UrlParams, `pipeline`, "")) {
        setSelectedPipeline(getValue(UrlParams, `pipeline`, ""));
      }
    }
  }, [
    window.location.href,
    getValue(location, `key`, ""),
    permissionAPITriggered,
  ]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                            Dashboard Section                               */
  /* -------------------------------------------------------------------------- */
  const [selectedComponentList, setSelectedComponentList] = React.useState<any>(
    []
  );
  const getDashboardInfo = async (components: any) => {
    if (getValue(components, `length`, 0) > 0) {
      try {
        const results = await Promise.all(
          components.map(async (item: object) => {
            let payload = {};
            let queryRequest = QueryRequestHelper(payload);
            let resp = await getAnalyticsGraph(
              getValue(item, `id`, ""),
              queryRequest
            );
            return resp ? getValue(resp, `data`, {}) : null;
          })
        );
        // Filter out null responses and remove duplicates using Set
        const uniqueResults = Array.from(
          new Set(
            results
              .filter((resp) => resp !== null)
              .map((item) => {
                // Assuming each item has a unique identifier like 'id'
                // If not, you might need to create a unique key based on relevant properties
                return JSON.stringify(item);
              })
          )
        ).map((item) => JSON.parse(item));

        setSelectedComponentList([...uniqueResults]);
      } catch (error) {
        console.error(error);
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                        Module & Pipepline Section                          */
  /* -------------------------------------------------------------------------- */
  const [selectedModule, setSelectedModule] = React.useState("");
  const [allModules, setAllModules] = React.useState([]);
  const getModules = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await listAllModules(queryRequest);
      if (resp) {
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                name: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
                permission_key:
                  getValue(item, `api_name`, "") === "companies"
                    ? `crm_company`
                    : `crm_${getValue(item, `api_name`, "")}`,
              }))
            : [];
        let allNew = list.filter(
          (item: object) =>
            getValue(item, `name`, "") !== "calls" &&
            getValue(item, `name`, "") !== "meetings"
        );
        let all = allNew.filter((item: object) =>
          subTabPermissionList.includes(getValue(item, `permission_key`, ""))
        );
        setAllModules(sortJSONObjectArray(all, "seq_num"));
        setSelectedModule(
          getValue(UrlParams, `module`, "")
            ? getValue(UrlParams, `module`, "")
            : getValue(all, `[${0}].api_name`, "")
        );
        if (
          getValue(UrlParams, `module`, "") !== "tasks" ||
          getValue(all, `[${0}].api_name`, "") !== "tasks"
        ) {
          setNewModule(getValue(all, `[${0}].api_name`, ""));
          getAllPipelines(
            getValue(UrlParams, `module`, "")
              ? getValue(UrlParams, `module`, "")
              : getValue(all, `[${0}].api_name`, ""),
            list
          );
        }
      }
    } catch (error) {}
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedPipeline, setSelectedPipeline] = React.useState("");
  const [pipelines, setAllPipelines] = React.useState([]);
  const getAllPipelines = async (code: string, allModules: any) => {
    try {
      setIsLoading(true);
      let payload = {
        // module_name: code,
      };

      let moduleInfo: any =
        getValue(allModules, `length`, 0) > 0 &&
        allModules.find(
          (item: object) => getValue(item, `api_name`, "") === code
        );

      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(
        getValue(moduleInfo, `id`, ""),
        queryRequest
      );
      if (getValue(resp, `data.length`, 0) > 0) {
        let list = getValue(resp, `data`, []).map((item: object) => ({
          ...item,
          value: getValue(item, `api_name`, ""),
          label: getValue(item, `label`, ""),
        }));
        setAllPipelines(list);
        setSelectedPipeline(
          getValue(UrlParams, `pipeline`, "")
            ? getValue(UrlParams, `pipeline`, "")
            : getValue(list, `[${0}].id`, "")
        );
        setIsLoading(false);

        // ----------------- Pipeline Section -----------------//
        setComponentRequest((prevState: any) => ({
          ...prevState,
          module: getValue(resp, `data[${0}].module_id`, ""),
          pipeline: getValue(resp, `data[${0}].id`, ""),
        }));
        getSpecificPipelineInfo(
          getValue(resp, `data[${0}].id`, ""),
          getValue(resp, `data[${0}].module_id`, "")
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [stageList, setStageList] = React.useState<any>([]);
  const [staticFieldRequest, setStaticFieldRequest] = React.useState({
    pipeline_id: "",
    stage: "",
  });
  const [formLoading, setFormLoading] = React.useState(false);
  const [form, setForm] = React.useState<any>([]);
  const [dateColumnFields, setDateColumnFields] = React.useState<any>([]);
  const [formColumnFields, setFormColumnFields] = React.useState<any>([]);
  const getSpecificPipelineInfo = async (id: string, module: string) => {
    if (id) {
      try {
        let resp = await getSpecificPipeline(
          getValue(componentRequest, `module`, ""),
          id
        );
        if (resp) {
          const formFieldsData = getValue(resp, `data.form_fields`, []);
          const hasFormFields = formFieldsData.length > 0;

          const mapFields = (fields: object[]) =>
            fields.map((item: object) => ({
              ...getValue(item, `module_field`, {}),
              name: getValue(item, `api_name`, ""),
              quick_add: getValue(item, `quick_add`, ""),
              required: getValue(item, `required`, ""),
              seq_num: getValue(item, `seq_num`, ""),
            }));

          const fields = hasFormFields ? mapFields(formFieldsData) : [];

          const deteFields = hasFormFields
            ? formFieldsData.filter((item: object) =>
                ["date", "datetime"].includes(
                  getValue(item, `module_field.input_type`, "")
                )
              )
            : [];

          const formFields = hasFormFields
            ? formFieldsData.filter(
                (item: object) =>
                  !["date", "datetime"].includes(
                    getValue(item, `module_field.input_type`, "")
                  )
              )
            : [];

          setDateColumnFields(
            deteFields.length > 0 ? mapFields(deteFields) : []
          );
          setFormColumnFields(
            formFields.length > 0 ? mapFields(formFields) : []
          );

          setForm(fields);
          if (
            module !== "tasks" &&
            module !== "calls" &&
            module !== "meetings"
          ) {
            setStageList(
              getValue(resp, `data.stages`, []).map((item: object) => ({
                ...item,
                label: getValue(item, `label`, ""),
                value: getValue(item, `api_name`, ""),
              }))
            );
            setStaticFieldRequest((prevStaticFieldRequest) => ({
              ...prevStaticFieldRequest,
              stage: getValue(resp, `data.stages[${0}].id`, ""),
              pipeline_id: id,
            }));
          }
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {}
    } else {
      setForm([]);
    }
  };
  const [dashboardLoading, setDashboardLoading] = React.useState(false);

  /* -------------------------------------------------------------------------- */
  /*                   Create Analytics Dashboard Section                       */
  /* -------------------------------------------------------------------------- */

  const [newModule, setNewModule] = React.useState("");
  const [allDashboardList, setAllDashboardList] = React.useState([]);
  const [listLoading, setListLoading] = React.useState(true);
  const [selectedDashboard, setSelectedDashboard] = React.useState("");
  const getAllDashboardList = async () => {
    try {
      setListLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllAnalytics(queryRequest);
      if (resp) {
        setAllDashboardList(
          getValue(resp, `data.analytics.length`, 0) > 0
            ? getValue(resp, `data.analytics`, []).map((item: object) => ({
                ...item,
                label: getValue(item, `name`, ""),
                value: getValue(item, `id`, ""),
              }))
            : []
        );
        if (getValue(UrlParams, `analyticsId`, "")) {
          setSelectedDashboard(getValue(UrlParams, `analyticsId`, ""));
          const analyticsData = getValue(resp, "data.analytics", []);
          const selectedDashboard = analyticsData.find(
            (item: object) =>
              getValue(item, "id", "") ===
              getValue(UrlParams, `analyticsId`, "")
          );
          getDashboardInfo(
            getDashboardInfo(getValue(selectedDashboard, "components", []))
          );
        } else {
          setSelectedDashboard(getValue(resp, `data.analytics[${0}].id`, ""));
          getDashboardInfo(
            getValue(resp, `data.analytics[${0}].components`, [])
          );
        }
        setListLoading(false);
      } else {
        setListLoading(false);
      }
    } catch (error) {
      setListLoading(false);
    }
  };

  const [dashboardName, setDashboardName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const CreateAnalyticsDashboard = async (name: string) => {
    try {
      setLoading(true);
      let resp = await createAnalytics({
        name: name,
      });
      if (resp) {
        setLoading(false);
        toast.success("Create successfully");
        getAllDashboardList();
        let payload = {
          ...UrlParams,
          analyticsId: getValue(resp, `data.id`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [dashboardId, setDashboardId] = React.useState("");
  const UpdateAnalyticsDashboard = async () => {
    try {
      setLoading(true);
      let resp = await updateAnalytics(dashboardId, {
        name: dashboardName,
      });
      if (resp) {
        setLoading(false);
        toast.success("Create successfully");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [deleteIdLoading, setDeleteLoading] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const DeleteAnalyticsDashboard = async () => {
    try {
      setDeleteLoading(true);
      let resp = await deleteAnalytics(deleteId);
      if (resp) {
        setDeleteLoading(false);
        toast.success("Create successfully");
      } else {
        setDeleteLoading(false);
      }
    } catch (error) {
      setDeleteLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                   Create Analytics Dashboard Component Section             */
  /* -------------------------------------------------------------------------- */
  const intialState = {
    name: "",
    analytics: "",
    component_type: "chart",
    graph_type: "standard",
    module: "",
    pipeline: "",
    module_field: "",
    module_column: "",
    child_module_field: "",
    child_module_column: "",
    date_granularity: "",
    child_date_granularity: "",
    is_child: false,
    duration_type: "today",
    show_rank: "top_5",
    custom_from_date: null,
    custom_to_date: null,
    custom_last_days: 1,
    compare_to: "previous_period",
    compare_from_date: null,
    compare_to_date: null,
    compare_objective: "positive",
    benchmark: 0,
    maximum_grouping: 0,
    criteria_filters: [
      // {
      //   comparator: "",
      //   field: "",
      //   column: "created_by",
      //   condition: "and",
      //   value: "",
      // },
    ],
  };

  const [componentRequest, setComponentRequest] = React.useState(intialState);
  const resetAnalyticRequest = () => {
    setComponentRequest(intialState);
  };

  const [cLoading, setCLoading] = React.useState(false);
  const CreateAnalyticsComponent = async () => {
    try {
      let obj = {};
      let payload = JSON.parse(JSON.stringify(componentRequest));
      obj = {
        name: getValue(payload, `name`, ""),
        analytics: getValue(UrlParams, `analyticsId`, "")
          ? getValue(UrlParams, `analyticsId`, "")
          : getValue(payload, `analytics`, ""),
        component_type: getValue(payload, `component_type`, ""),
        graph_type: getValue(payload, `graph_type`, ""),
        module: getValue(payload, `module`, ""),
        pipeline: getValue(payload, `pipeline`, ""),
        module_field: getValue(payload, `module_field`, ""),
        module_column: getValue(payload, `module_column`, ""),
        duration_type: getValue(payload, `duration_type`, ""),
        compare_to: getValue(payload, `compare_to`, ""),
        compare_objective: getValue(payload, `compare_objective`, ""),
        child_module_field: getValue(payload, `child_module_field`, ""),
        child_module_column: getValue(payload, `child_module_column`, ""),
        date_granularity: getValue(payload, `date_granularity`, ""),
        child_date_granularity: getValue(payload, `child_date_granularity`, ""),
        is_child: getValue(payload, `is_child`, false),
        show_rank: getValue(payload, `show_rank`, ""),
        custom_from_date: getValue(payload, `custom_from_date`, null),
        custom_to_date: getValue(payload, `custom_to_date`, null),
        compare_from_date: getValue(payload, `compare_from_date`, null),
        compare_to_date: getValue(payload, `compare_to_date`, null),
        benchmark: getValue(payload, `benchmark`, 0)
          ? parseInt(getValue(payload, `benchmark`, 0))
          : 0,
        maximum_grouping: getValue(payload, `maximum_grouping`, 0)
          ? parseInt(getValue(payload, `maximum_grouping`, 0))
          : 0,
      };
      setCLoading(true);
      let resp = await createAnalyticsComponent(
        removeNullOrUndefinedProperties(obj)
        // removeNullOrUndefinedPropertiesForProperty(obj)
      );
      if (resp) {
        setCLoading(false);
        toast.success("Create successfully");
        getAllDashboardList();
        onCloseKPIComponentDrawer();
        onCloseChartComponentDrawer();
      } else {
        setCLoading(false);
      }
    } catch (error) {
      setCLoading(false);
    }
  };

  const [editComponentId, setEditComponentId] = React.useState("");
  const UpdateAnalyticsComponent = async () => {
    try {
      setCLoading(true);
      let resp = await updateAnalyticsComponent(
        editComponentId,
        componentRequest
      );
      if (resp) {
        setCLoading(false);
        toast.success("Create successfully");
      } else {
        setCLoading(false);
      }
    } catch (error) {
      setCLoading(false);
    }
  };

  const [isDeleteOpen1, setIsDeleteOpen1] = React.useState(false);
  const [deleteId1, setDeleteId1] = React.useState("");
  const DeleteAnalyticsComponent = async () => {
    try {
      setDeleteLoading(true);
      let resp = await deleteAnalyticsComponent(deleteId);
      if (resp) {
        setDeleteLoading(false);
        toast.success("Delete successfully");
      } else {
        setDeleteLoading(false);
      }
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  /* ---------------------------   Drawer Section -------------------------------- */

  const [selectedComponentOption, setSelectedComponentOption] =
    React.useState("");
  /* ---------------------------   KPI Style Section -------------------------------- */

  const [openKPIStyleComponent, setOpenKPIStyleComponent] =
    React.useState(false);
  const showKPIStyleComponentDrawer = () => {
    setOpenKPIStyleComponent(true);
  };
  const onCloseKPIStyleComponentDrawer = () => {
    setOpenKPIStyleComponent(false);
  };

  /* ---------------------------   Chart Style Section -------------------------------- */

  const [openChartStyleComponent, setOpenChartStyleComponent] =
    React.useState(false);
  const showChartStyleComponentDrawer = () => {
    setOpenChartStyleComponent(true);
  };
  const onCloseChartStyleComponentDrawer = () => {
    setOpenChartStyleComponent(false);
  };

  /* ---------------------------   KPI Section -------------------------------- */

  const [openKPIComponent, setOpenKPIComponent] = React.useState(false);
  const showKPIComponentDrawer = () => {
    setOpenKPIComponent(true);
  };
  const onCloseKPIComponentDrawer = () => {
    setOpenKPIComponent(false);
  };

  const openKPIDrawer = (name: string) => {
    resetAnalyticRequest();
    setComponentRequest({
      ...componentRequest,
      component_type: "kpi",
      graph_type: name,
    });
    setSelectedComponentOption(name);
    onCloseKPIStyleComponentDrawer();
    showKPIComponentDrawer();
  };

  /* ---------------------------   Chart Section -------------------------------- */

  const [openChartComponent, setOpenChartComponent] = React.useState(false);
  const showChartComponentDrawer = () => {
    setOpenChartComponent(true);
  };
  const onCloseChartComponentDrawer = () => {
    setOpenChartComponent(false);
  };

  const openChartDrawer = (name: string) => {
    resetAnalyticRequest();
    setComponentRequest({
      ...componentRequest,
      component_type: "chart",
      graph_type: name,
    });
    setSelectedComponentOption(name);
    onCloseChartStyleComponentDrawer();
    showChartComponentDrawer();
  };

  /* -------------------------------------------------------------------------- */
  /*                                Charts Logic Section                        */
  /* -------------------------------------------------------------------------- */

  console.log("selectedComponentList", selectedComponentList);
  return (
    <>
      <MainLayout {...props} isLoading={dashboardLoading}>
        <DashboardHeader
          //module
          allModules={allModules}
          selectedModule={selectedModule}
          //pipelines
          pipelines={pipelines}
          selectedPipeline={selectedPipeline}
          isLoading={isLoading}
          //date
          setDashboardLoading={setDashboardLoading}
          //----------- New Dashboard  ----------//
          dashboardName={dashboardName}
          setDashboardName={setDashboardName}
          CreateAnalyticsDashboard={CreateAnalyticsDashboard}
          allDashboardList={allDashboardList}
          loading={loading}
          openKPI={showKPIStyleComponentDrawer}
          openGraph={showChartStyleComponentDrawer}
          getSpecificPipelineInfo={getSpecificPipelineInfo}
          request={componentRequest}
          setRequest={setComponentRequest}
          selectedDashboard={selectedDashboard}
          setSelectedComponentList={setSelectedComponentList}
        />
        {dashboardLoading ? (
          <ListLoader />
        ) : (
          <div className="p-4 gap-3 dashboard__container">
            {selectedComponentList.map((item: object, index: number) => {
              return (
                <div key={index}>
                  <DashboardComponentGraphs props={item} />
                </div>
              );
            })}
          </div>
        )}

        {/*  -----------------------   Drawer Section -----------------------  */}

        <ComponentKPIStyleDrawer
          open={openKPIStyleComponent}
          onClose={onCloseKPIStyleComponentDrawer}
          openComponentDrawer={openKPIDrawer}
        />
        <ComponentKPIDrawer
          open={openKPIComponent}
          onClose={onCloseKPIComponentDrawer}
          selectedComponentOption={selectedComponentOption}
          allModules={allModules}
          request={componentRequest}
          setRequest={setComponentRequest}
          getAllPipelines={getAllPipelines}
          allPipelines={pipelines}
          setNewModule={setNewModule}
          newModule={newModule}
          getSpecificPipelineInfo={getSpecificPipelineInfo}
          dateColumnFields={dateColumnFields}
          formColumnFields={formColumnFields}
          isLoading={cLoading}
          handleSubmit={CreateAnalyticsComponent}
          showKPIStyleComponentDrawer={showKPIStyleComponentDrawer}
        />
        <ComponentChartStyleDrawer
          open={openChartStyleComponent}
          onClose={onCloseChartStyleComponentDrawer}
          openComponentDrawer={openChartDrawer}
        />
        <ComponentChartDrawer
          open={openChartComponent}
          onClose={onCloseChartComponentDrawer}
          selectedComponentOption={selectedComponentOption}
          allModules={allModules}
          request={componentRequest}
          setRequest={setComponentRequest}
          getAllPipelines={getAllPipelines}
          allPipelines={pipelines}
          setNewModule={setNewModule}
          newModule={newModule}
          getSpecificPipelineInfo={getSpecificPipelineInfo}
          dateColumnFields={dateColumnFields}
          formColumnFields={formColumnFields}
          isLoading={cLoading}
          handleSubmit={CreateAnalyticsComponent}
          showChartStyleComponentDrawer={showChartStyleComponentDrawer}
        />
      </MainLayout>
    </>
  );
};

export default NewDashboard;
