import { getValue } from "@utils/lodash";
import KPIStandardComonent from "./KPI/Standard";
import KPIBasicComponent from "./KPI/Basic";
import KPIGrowthIndexComponent from "./KPI/GrowthIndex";
import KPIScoreCardComponent from "./KPI/ScoreCard";
import KPIRankingsComponents from "./KPI/Rankings";
import ColumnChartComponent from "./Chart/ColumnChart";
import DonutChartComponent from "./Chart/DonutChart";
import PieChartComponent from "./Chart/PieChart";
import BarChartComponent from "./Chart/BarChart";
import LineChartComponent from "./Chart/LineChart";
import TableChartComponent from "./Chart/TableChart";
import FunnelChartComponent from "./Chart/FunnelChart";
import AreaChartComponent from "./Chart/AreaChart";
import HeatMapChartComponent from "./Chart/HeatMapChart";

function DashboardComponentGraphs(props: any) {
  const renderComponent = (props: any) => {
    switch (getValue(props, `graph_type`, "")) {
      // -------- KPI  ---------//
      case "standard":
        return <KPIStandardComonent {...props} />;
      case "growth_index":
        return <KPIGrowthIndexComponent {...props} />;
      case "basic":
        return <KPIBasicComponent {...props} />;
      case "scorecard":
        return <KPIScoreCardComponent {...props} />;
      case "rankings":
        return <KPIRankingsComponents {...props} />;
      // -------- Chart  ---------//
      case "column_chart":
        return <ColumnChartComponent {...props} />;
      case "donut_chart":
        return <DonutChartComponent {...props} />;
      case "pie_chart":
        return <PieChartComponent {...props} />;
      case "bar_chart":
        return <BarChartComponent {...props} />;
      case "line_chart":
        return <LineChartComponent {...props} />;
      case "table_chart":
        return <TableChartComponent {...props} />;
      case "funnel_chart":
        return <FunnelChartComponent {...props} />;
      case "area_chart":
        return <AreaChartComponent {...props} />;
      case "heat_map":
        return <HeatMapChartComponent {...props} />;
      default:
        return null;
    }
  };
  return renderComponent(getValue(props, `props`, {}));
}

export default DashboardComponentGraphs;
