import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllCalls = (queryRequest: string) =>
  get(`${config.API_URL}/records/calls?${queryRequest}`);

export const getSpecificCall = (id: string) =>
  get(`${config.API_URL}/records/calls/${id}`);

export const createCall = (payload: object) =>
  post(`${config.API_URL}/records/calls`, payload);

export const updateCall = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/calls/${id}`, payload);

export const deleteCall = (id: string) =>
  Delete(`${config.API_URL}/records/calls/${id}`);

export const callsBulkUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/calls/bulk-update`, payload);

//comments

export const getSpecificCallComment = (id: string) =>
  get(`${config.API_URL}/records/calls/${id}/comments`);

export const createCallComment = (payload: object, id: string) =>
  post(`${config.API_URL}/records/calls/${id}/comments`, payload);

export const updateCallComment = (
  id: string,
  payload: object,
  comment_id: string
) =>
  patch(
    `${config.API_URL}/records/calls/${id}/comments/${comment_id}`,
    payload
  );

export const deleteCallComment = (id: string, comment_id: string) =>
  Delete(`${config.API_URL}/records/calls/${id}/comments/${comment_id}`);
