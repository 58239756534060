import Branding from "@pages/Private/Settings/Branding";
import CompanyProfile from "@pages/Private/Settings/CompanyProfile";

export const SettingsAdminRoutes = [
  {
    path: "/:orgId/settings/company-profile",
    name: "settings_general_company_profile",
    component: CompanyProfile,
  },
  {
    path: "/:orgId/settings/branding",
    name: "settings_general_branding",
    component: Branding,
  },
//   {
//     path: "/:orgId/settings/app-branding",
//     name: "Dashboard",
//     component: AppBranding,
//   },
];
