// import CompanyProfileNavigation from "layout/CompanyProfileDetailsLayout/CompanyProfileNavigation/CompanyProfileNavigation";
import "./CompanyProfileDetailsLayout.scss";
import CompanyProfileNavigation from "./CompanyProfileNavigation";

export default function CompanyProfileDetailsLayout({
  children,
  classname,
}: any) {
  return (
    <div className={`d-flex w-100`}>
      <CompanyProfileNavigation />
      <div className={`company-profile-wrapper__main-section-wrapper `}>
        <div className={`company-profile-wrapper__main-section ${classname}`}>
          {children}
        </div>
      </div>
    </div>
  );
}
