import React from "react";
import { getValue } from "@utils/lodash";
import PendingTask from "./components/PendingTask";
import NotFoundPage from "@pages/Public/404";
import NotFoundDetail from "@components/common/NoData/Not-Found-Detail";
enum Status {
  overdue = "Overdue",
}
enum Type {
  task = "Task",
  note = "Note",
}
function MainBodyTasks(props: any) {
  return (
    <div className={"pending-wrapper__main-body-upcoming"}>
      {/* <h6 className={ClassNames["pending-wrapper__main-body-heading"]}>
        Upcoming
      </h6> */}
      {getValue(props, `tasks.length`, 0) > 0 ? (
        getValue(props, `tasks`, []).map((taskObj: any, index: number) => {
          if (props.maxLength ? index < props.maxLength : true)
            return (
              <PendingTask
                key={`taskDetails-${index}`}
                taskObj={taskObj}
                isIconVisible={true}
                type={Type.task}
                handleTaskUpdate={props.handleTaskUpdate}
                handleDelete={props.handleDelete}
              />
            );
        })
      ) : (
        <NotFoundDetail title={"No additional tasks have been appended."} />
      )}
    </div>
  );
}

export default MainBodyTasks;
