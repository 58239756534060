import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: any;
  onChange: any;
  label: string;
  validator: any;
  placeholder: string;
  mandatory: boolean;
  maxDate: any;
  minDate: any;
  hideLabel: boolean;
};

export default function InlineDateMonthYearPicker({
  disabled,
  name,
  type,
  value,
  onChange,
  validator,
  label,
  placeholder,
  mandatory,
  maxDate,
  minDate,
  hideLabel,
}: Props) {
  return (
    <div
    // className={`signup-form-wrapper__form-group ${
    //   validator && validator.current.message(label, value, "required")
    //     ? "signup-form-wrapper__form-group--error"
    //     : ""
    // } form-group mb-3`}
    >
      {!hideLabel && (
        <label className="form-label-inline">
          {label} {mandatory && "*"}
        </label>
      )}
      <div
      // style={{
      //   borderLeft: mandatory ? "1px solid red" : "",
      //   borderRadius: mandatory ? "5px" : "",
      // }}
      >
        <DatePicker
          className={`inline_form_input`}
          selected={value ? new Date(value) : null}
          onChange={onChange}
          peekNextMonth
          // startDate={startDate}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          maxDate={maxDate}
          minDate={minDate}
          placeholderText={placeholder}
          dateFormat="d MMM yyyy"
        />
      </div>

      {validator && (
        <p className={`error-text`}>
          {validator.current.message(label, value, "required")}
        </p>
      )}
    </div>
  );
}
