import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllCannedResponse = (queryRequest: string) =>
  get(`${config.API_URL}/canned-response/folders?${queryRequest}`);

export const getAllCannedFolderTableResponse = (queryRequest: string) =>
  get(`${config.API_URL}/canned-response?${queryRequest}`);

export const createCannedResponseFolder = (payload: object) =>
  post(`${config.API_URL}/canned-response/folders`, payload);

export const updateCannedResponseFolder = (payload: object) =>
  post(`${config.API_URL}/canned-response/folders`, payload);

export const deleteCannedResponseFolder = (id: string) =>
  Delete(`${config.API_URL}/canned-response/folders/${id}`);
