export const BotLinkTypes = [
  { value: "link", label: "Link" },
  { value: "messenger", label: "Messenger" },
  { value: "whatsapp", label: "WhatsApp" },
  { value: "twitter", label: "Twitter" },
  { value: "linkedin", label: "LinkedIn" },
  { value: "snapchat", label: "Snapchat" },
  { value: "telegram", label: "Telegram" },
  { value: "reach_at", label: "Reach.at" },
  { value: "slack", label: "Slack" },
  { value: "skype", label: "Skype" },
  { value: "email", label: "Email" },
  { value: "phone", label: "Phone" },
  { value: "twitter_follow", label: "Twitter-follow" },
  { value: "facebook", label: "Facebook" },
  { value: "instagram", label: "Instagram" },
  { value: "threads", label: "Threads" },
  { value: "medium", label: "Medium" },
  { value: "product_hunt", label: "Product Hunt" },
  { value: "vk", label: "VK" },
  { value: "youtube", label: "YouTube" },
  { value: "github", label: "GitHub" },
  { value: "stack_overflow", label: "Stack Overflow" },
  { value: "behance", label: "Behance" },
  { value: "dribbble", label: "Dribbble" },
  { value: "pinterest", label: "Pinterest" },
  { value: "deviantart", label: "DeviantArt" },
  { value: "tiktok", label: "TikTok" },
  { value: "twitch", label: "Twitch" },
  { value: "quora", label: "Quora" },
  { value: "soundcloud", label: "SoundCloud" },
  { value: "spotify", label: "Spotify" },
];
