import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllModuleFields = (id: string) =>
  get(`${config.API_URL}/settings/modules/${id}/form-fields`);

export const getAllModuleFieldList = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/settings/modules/${id}/fields/list?${queryRequest}`);

export const getSpecificModuleField = (id: string, field_id: string) =>
  get(`${config.API_URL}/settings/modules/${id}/fields/${field_id}`);

export const createModuleField = (id: string, payload: object) =>
  post(
    `${config.API_URL}/settings/modules/${id}/module-fields/custom`,
    payload
  );

export const updateModuleField = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/modules/${id}/fields`, payload);

export const deleteModuleField = (id: string, field_id: string) =>
  Delete(`${config.API_URL}/settings/modules/${id}/fields/${field_id}`);

//module-fields

export const getModuleFields = (id: string) =>
  get(`${config.API_URL}/settings/modules/${id}/module-fields`);

export const updateModuleFields = (
  module_id: string,
  id: string,
  payload: object
) =>
  patch(
    `${config.API_URL}/settings/modules/${module_id}/module-fields/${id}`,
    payload
  );

//task columns

export const getModuleColumns = (id: string) =>
  get(`${config.API_URL}/settings/modules/${id}/columns`);
