import { getValue } from "@utils/lodash";
// import ClassNames from "./SignupForm/signup-form.module.scss";
import './signup-form.scss';

function InputFormField(props: any) {
  const {
    inputType,
    validator,
    name,
    value,
    onChange,
    label,
    disabled,
  } = props;
  switch (inputType) {
    case "TEXT":
      return (
        <div
          className={`signup-form-wrapper__form-group ${
            validator && validator.current.message(label, value, "required")
              ? "signup-form-wrapper__form-group--error"
              : ""
          } form-group mb-3`}
        >
          <div className="position-relative">
            <form>
              <input
                type="text"
                className={`custom-signup-form-control form-control`}
                placeholder={getValue(props, `placeholder`, "")}
                name={name}
                onChange={onChange}
                value={value}
                disabled={disabled}
                autoComplete={"no-password"}
              />
              <img
                src={`/images/icons/${getValue(
                  props,
                  `icon`,
                  "person"
                )}-icon.svg`}
                className={`image-icon img-fluid`}
              />
            </form>
          </div>
          {validator && (
            <p className={`error-text text-center`}>
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );
    case "EMAIL":
      return (
        <div
          className={`signup-form-wrapper__form-group ${
            validator.current.message(label, value, "required")
              ? "signup-form-wrapper__form-group--error"
              : ""
          } form-group mb-3`}
        >
          <div className="position-relative">
            <form>
              <input
                type="email"
                // autoComplete="off"
                className={`custom-signup-form-control form-control`}
                placeholder={getValue(props, `placeholder`, "")}
                name={name}
                onChange={onChange}
                value={value}
                disabled={disabled}
                autoComplete={"no-password"}
              />
              <img
                src={`/images/icons/${getValue(
                  props,
                  `icon`,
                  "person"
                )}-icon.svg`}
                className={`image-icon img-fluid`}
              />
            </form>
          </div>
          {validator && (
            <p className={`error-text text-center`}>
              {validator.current.message(label, value, "required|email")}
            </p>
          )}
        </div>
      );

    case "NUMBER":
      return (
        <div
          className={`signup-form-wrapper__form-group${
            validator.current.message(label, value, "required")
              ? "signup-form-wrapper__form-group--error"
              : ""
          } form-group mb-3`}
        >
          <div className="position-relative">
            <input
              type="number"
              autoComplete="off"
              className={`custom-signup-form-control form-control`}
              placeholder={getValue(props, `placeholder`, "")}
              name={name}
              onChange={onChange}
              value={parseFloat(value) > 0 ? value : null}
            />
            <img
              src={`/images/icons/${getValue(
                props,
                `icon`,
                "person"
              )}-icon.svg`}
              className={`image-icon img-fluid`}
            />
          </div>
          {validator && (
            <p className={`error-text text-center`}>
              {validator.current.message(label, value, "required|number")}
            </p>
          )}
        </div>
      );
    case "PASSWORD":
      return (
        <div
          className={`signup-form-wrapper__form-group ${
            validator.current.message(label, value, "required")
              ? "signup-form-wrapper__form-group--error"
              : ""
          } form-group mb-3 position-relative`}
        >
          <div className="position-relative">
            <form>
              <input
                type="password"
                className={`custom-signup-form-control form-control`}
                placeholder={getValue(props, `placeholder`, "")}
                name={name}
                onChange={onChange}
                value={value}
                autoComplete="no-password"
              />
              <img
                src={`/images/icons/key-icon.svg`}
                className={`image-icon img-fluid`}
              />
            </form>
          </div>
          {validator && (
            <p className={`error-text text-center`}>
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );

    default:
      return null;
  }
}

export default InputFormField;
