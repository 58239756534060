import BotsPage from "@pages/Private/Bots";
import BotBuilder from "@pages/Private/Bots/BotBuilder";
import BotGroupBuilder from "@pages/Private/Bots/BotGroupBuilder";
import BotsGroupPage from "@pages/Private/Bots/chatbot-group";
import BotsLogsPage from "@pages/Private/Bots/EditBotBuilder/chatbot-logs";
import ChatbotAccountManage from "@pages/Private/Bots/EditBotBuilder/chatbot-manage";
import ChatbotSettings from "@pages/Private/Bots/EditBotBuilder/chatbot-settings";
import BotsSharePage from "@pages/Private/Bots/EditBotBuilder/chatbot-share";
import ChatbotBuilder from "@pages/Private/Bots/ReactFlowBuilder";

export const ChatbotRoutes = [
  {
    path: "/:orgId/chatbot",
    name: "chatbot",
    component: BotsPage,
  },
  {
    path: "/:orgId/chatbot/builder/:type",
    name: "chatbot",
    component: BotBuilder,
  },
  {
    path: "/:orgId/chatbot/builder/create-flow/:id",
    name: "chatbot",
    component: ChatbotBuilder,
  },
  {
    path: "/:orgId/chatbot/builder/create-flow/:id/:path",
    name: "chatbot",
    component: ChatbotBuilder,
  },
  //detail page
  {
    path: "/:orgId/chatbot/settings/:id",
    name: "chatbot",
    component: ChatbotSettings,
  },
  {
    path: "/:orgId/chatbot/logs/:id",
    name: "chatbot",
    component: BotsLogsPage,
  },
  {
    path: "/:orgId/chatbot/share/:id",
    name: "chatbot",
    component: BotsSharePage,
  },
  {
    path: "/:orgId/chatbot/manage/:id",
    name: "chatbot",
    component: ChatbotAccountManage,
  },
  //chatbot group ==> remove currently
  {
    path: "/:orgId/chatbot/group/:chatbot_id",
    name: "chatbot",
    component: BotsGroupPage,
  },
  {
    path: "/:orgId/chatbot/builder/:type",
    name: "chatbot",
    component: BotBuilder,
  },
  {
    path: "/:orgId/chatbot/group/builder/:chatbot_id",
    name: "chatbot",
    component: BotGroupBuilder,
  },
  {
    path: "/:orgId/chatbot/group/builder/create-flow/:chatbot_id/:id",
    name: "chatbot",
    component: ChatbotBuilder,
  },
];
