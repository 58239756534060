import React, { useState } from "react";
import { getValue } from "@utils/lodash";
import BackSvgComponent from "assets/svg/back-link";
import { formatString } from "@common/text-helpers";

function Tab2(props: any) {
  const { list } = props;
  const rootStyle = getComputedStyle(document.documentElement);
  const primaryColor = rootStyle.getPropertyValue("--theme-icon-color").trim();

  return (
    <div>
      <div className="d-flex">
        {/* <div onClick={() => props.handleActiveTab(1)}>
          <BackSvgComponent />
        </div> */}
        <h5 className="main_header_text">Email Integration</h5>
      </div>
      <p className="desc_text mt-2">
        Connect your email inbox with Appzo and transform the way you do sales.
      </p>
      <h6 className="header_text mt-5">Popular Email Services</h6>
      <div className={`${"email_wrap-container"}`}>
        {list.map((item: object, index: number) => {
          return (
            <div
              key={index}
              className={`${"email_wrap-image_container"}`}
              style={{
                border:
                  getValue(props, `configInfo.providerType`, "") ===
                  getValue(item, `value`, "")
                    ? `1px solid ${primaryColor}`
                    : "",
              }}
            >
              <div
                className={`${"email_wrap-image_container_image"}`}
                onClick={() => props.setType(getValue(item, `value`, ""))}
              >
                <img src={getValue(item, `url`, "")} className="logo_image" />
              </div>
              <p className="desc_text text-center mb-3 mt-1">
                {getValue(item, `label`, "")}
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-4">
        <div className="d-flex">
          <p className="small_text__16">Provider :</p>
          <p className="header_text ms-2">
            {formatString(
              getValue(props, `configInfo.providerType`, "")
            ).toUpperCase()}
          </p>
        </div>
        <div className="d-flex mt-2">
          <p className="small_text__16">Email :</p>
          <p className="header_text ms-2">
            {formatString(getValue(props, `configInfo.email`, ""))}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Tab2;
