import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { getValue } from "@utils/lodash";
import "./pagination.scss";

export default function PaginationPage(props: any) {
  const [options, setOptions] = useState<any>([]);
  const [options1, setOptions1] = useState<any>([]);

  useEffect(() => {
    let option = [];
    let option1 = [];

    for (let i = 0; i < 11; i++) {
      if (i) option.push(i * 10);
    }
    setOptions(option);
    for (let i = 0; i < 26; i++) {
      if (i) option1.push(i * 10);
    }
    setOptions1(option1);
  }, []);

  return (
    <section
      className="pagination-wrapper d-flex align-items-center"
      style={{
        marginLeft: getValue(props, `optimize`, "") === "false" ? "20px" : "",
      }}
    >
      {props.showTotalCount && (
        <div className="show-rows d-flex align-items-center">
          <p className="show-rows-count">
            Total Count:{" "}
            <span style={{ color: "#0967ac", fontWeight: "500" }}>
              {props.totalCount}
            </span>
          </p>
        </div>
      )}
      {/* {getValue(props, `showLimit`, "") !== "false" && (
				<div className="show-count mx-4">
					{getValue(props, `pageNumber`, 0)} -{" "}
					{Math.ceil(
						getValue(props, `totalCount`, 0) / getValue(props, `limit`, 1)
					)}{" "}
					of{" "}
					{Math.ceil(
						getValue(props, `totalCount`, 0) / getValue(props, `limit`, 1)
					)}
				</div>
			)} */}

      <div>
        <Pagination
          activePage={props.pageNumber}
          itemsCountPerPage={props.limit}
          totalItemsCount={props.totalCount}
          pageRangeDisplayed={5}
          onChange={props.handleChangePagination}
        />
      </div>
      {getValue(props, `showLimit`, "") !== "false" &&
        (props.moreLimit ? (
          <>
            <select
              className="show-select"
              onChange={(e) => props.handleChangeLimit(e.target.value)}
            >
              {options1.map((item: number) => {
                return (
                  <option selected={item == props.limit} value={item}>
                    {item} {item == props.limit && "per page"}
                  </option>
                );
              })}
            </select>
          </>
        ) : (
          <>
            <select
              className="show-select"
              onChange={(e) => props.handleChangeLimit(e.target.value)}
            >
              {options.map((item: number) => {
                return (
                  <option selected={item == props.limit} value={item}>
                    {item} {item == props.limit && "per page"}
                  </option>
                );
              })}
            </select>
          </>
        ))}
    </section>
  );
}
