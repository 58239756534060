import "./auth.scss";
import CompanyProfileSvgComponent from "assets/svg/company-profile";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  accept_decline_orgInvite,
  checkTokenValidity,
} from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { toast } from "sonner";
import Layout from "@layouts/Layout";
import ListLoader from "@components/common/Loader";
function ComfirmOrganization(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (getValue(localStorage, `accessToken`, "")) {
      getTokenInfo();
    } else {
      navigate(
        `/user-signup/invite?token=${getValue(
          UrlParams,
          `invite_token`,
          ""
        )}&orgId=${getValue(params, `orgId`, "")}`
      );
    }
  }, []);

  const [info, setInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getTokenInfo = async () => {
    let payload = {
      invite_token: getValue(UrlParams, `invite_token`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    setIsLoading(true);
    try {
      let resp = await checkTokenValidity(queryRequest);
      if (resp) {
        if (!getValue(resp, `data.is_already_org_user`, false)) {
          if (getValue(resp, `data.is_valid_token`, false)) {
            setInfo(getValue(resp, `data`, {}));
          } else {
            toast.error("Token is invalid");
            navigate(`/`);
          }
        } else {
          navigate(`/`);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (error) {
        navigate(`/`);
      }
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleInvitation = async (status: boolean) => {
    try {
      let payload = {
        invite_token: getValue(UrlParams, `invite_token`, ""),
        accept: status,
      };
      setSubmitLoading(true);
      let resp = await accept_decline_orgInvite(payload);
      if (resp) {
        if (getValue(resp, `data.success`, "")) {
          navigate(`/${getValue(resp, `data.org.code`, "")}/deals`);
        } else {
          navigate(`/`);
        }
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return (
    <Layout>
      {isLoading ? (
        <ListLoader />
      ) : (
        <>
          {getValue(localStorage, `accessToken`, "") ? (
            <div>
              <div className="orgauth_container mt-5">
                <CompanyProfileSvgComponent size={46} color={"#202124"} />
              </div>
              <div className="orgauth_container mt-2 text-center">
                <h6 className="header_text__18">
                  {/* Ravindra <p className="header_text">( Email ID Here )</p> has */}
                  Your invited you to use Appzo with them, in a workspace called{" "}
                  <p className="text_red__18 ms-2">
                    {getValue(info, `org.name`, "")}
                  </p>
                </h6>
              </div>
              <div className="orgauth_container mt-4">
                <button
                  className="accept_button px-3"
                  onClick={() => handleInvitation(true)}
                >
                  {submitLoading ? "Please wait..." : "Accept Invitation"}
                </button>
                <button
                  className="decline_button ms-3 px-3"
                  onClick={() => handleInvitation(false)}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <ListLoader />
          )}
        </>
      )}
    </Layout>
  );
}

export default ComfirmOrganization;
