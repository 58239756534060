import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import React, { useState } from "react";
import CommonModulesDetailLeftPagePage from "../Detail/left-container";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { deleteModuleCommon } from "@services/common.service";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";

function ShowSubPipeline(props: any) {
  const params = useParams();
  const navigate = useNavigate();

  //----------------- Email Section ----------------//
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const handleSendEmail = () => {
    setPopupVisibility(!isPopupVisible);
  };
  //----------------- Task Section ------------------//
  const [isRollsPopupVisibile, setRollsPopupVisisbility] = useState(false);
  const handleOpenForm = (module: string) => {
    props.getForm(module);
    setRollsPopupVisisbility(!isRollsPopupVisibile);
  };

  /* ----------------------------- Delete View Section  ------------------------- */
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const handleModal = () => {
    setIsDeleteOpen(!isDeleteOpen);
    props.toggle();
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteModuleCommon(
        getValue(props, `previewId`, ""),
        props.module
      );
      if (resp) {
        toast.success("Deleted Successfully");
        props.setIsOpen(false);
        setIsDeleteOpen(!isDeleteOpen);
        navigate(
          `/${getValue(params, `orgId`, "")}/${getValue(props, `module`, "")}`
        );
      }
    } catch (error) {}
  };
  return (
    <div>
      <CommonRightPopup
        isPopupVisible={props.isOpen}
        title="Contact preview"
        toggle={() => {
          props.toggle();
        }}
        width={props.width ? props.width : "500px"}
        handleBackButton={() => {
          props.toggle();
          props.handleCancel();
        }}
        handleSubmit={() => {
          props.handleSubmit();
          props.toggle();
        }}
        submitLoading={props.submitLoading}
        disabled={
          props.submitLoading ||
          props.duplicateAPINames.length > 0
          // props.checkLeftRequestChanges || !props.checkfieldRequestChanges
        }
        second_title="Update"
        hideNew
      >
        <div className="subpipeline-wrapper">
          <CommonModulesDetailLeftPagePage
            className="w-100"
            hideBackButton={true}
            params={props.params}
            data={props.data}
            // data={getValue(props, "data", "")}
            request={props.request}
            setRequest={props.setRequest}
            fields={props.fields}
            setFields={props.setFields}
            popupFields={props.popupFields}
            setPopupFields={props.setPopupFields}
            getData={props.getData}
            module={props.module}
            isChanged={props.isChanged}
            stageList={props.stageList}
            handleSubmit={props.handleSubmit}
            //emails
            handleSendEmail={handleSendEmail}
            //tasks
            handleOpenForm={handleOpenForm}
            //delete record
            handleDeleteModal={handleModal}
            duplicateAPINames={props.duplicateAPINames}
            setDuplicateAPINames={props.setDuplicateAPINames}
          />
        </div>
      </CommonRightPopup>
      <DeleteModal
        isOpen={isDeleteOpen}
        handleModal={handleModal}
        handleSubmit={handleDeleteFunction}
        deleteValue=""
      />
    </div>
  );
}

export default ShowSubPipeline;
