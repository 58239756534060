import { config } from "env";
import { Delete, get, patch, post } from "./helpers/http-handler";

// ------------------- Analytics ----------------- //

export const createAnalytics = (payload: object) =>
  post(`${config.API_URL}/analytics`, payload);

export const getAllAnalytics = (queryRequest: string) =>
  get(`${config.API_URL}/analytics?${queryRequest}`);

export const getSpecificAnalytics = (id: string) =>
  get(`${config.API_URL}/analytics/${id}`);

export const updateAnalytics = (id: string, payload: object) =>
  patch(`${config.API_URL}/analytics/${id}`, payload);

export const deleteAnalytics = (id: string) =>
  Delete(`${config.API_URL}/analytics/${id}`);

// ----------- Analytics Components --------------- //

export const createAnalyticsComponent = (payload: object) =>
  post(`${config.API_URL}/analytics/components`, payload);

export const getAllAnalyticsComponent = (queryRequest: string) =>
  get(`${config.API_URL}/analytics/components?${queryRequest}`);

export const getSpecificAnalyticsComponent = (id: string) =>
  get(`${config.API_URL}/analytics/components/${id}`);

export const updateAnalyticsComponent = (id: string, payload: object) =>
  patch(`${config.API_URL}/analytics/components/${id}`, payload);

export const deleteAnalyticsComponent = (id: string) =>
  Delete(`${config.API_URL}/analytics/components/${id}`);

// ----------- Analytics Dashboard --------------- //

export const getAnalyticsGraph = (id: string, queryRequest: string) =>
  get(
    `${config.API_URL}/analytics/components/dashboard/${id}/graph?${queryRequest}`
  );

export const getAnalyticsDashboardData = (id: string, queryRequest: string) =>
  get(
    `${config.API_URL}/analytics/components/dashboard/${id}/graph?${queryRequest}`
  );
