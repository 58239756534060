// import { useStateContext } from "context/profileProvider";
// import React, { useEffect, useRef } from "react";
// import Popup from "reactjs-popup";
// import { getValue } from "@utils/lodash";
// import CheckCircleSvgComponent from "assets/svg/check-circle";
// import { useNavigate } from "react-router-dom";
// import SettingsDefaultSvgComponent from "assets/svg/setting";
// import "./SignInDropdown.scss";
// import { concatString } from "@common/text-helpers";
// import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";
// import { getStartOfMonthDate } from "@common/date-helpers";

// function OrganizationListPopup(props) {
//   const navigate = useNavigate();
//   const { organizationList, orgInfo, handleChangeOrgainization } =
//     useStateContext();

//   /* -------------------------------------------------------------------------- */
//   /*                               UseState Section                             */
//   /* -------------------------------------------------------------------------- */

//   /* -------------------------------------------------------------------------- */
//   /*                               UseEffect Section                            */
//   /* -------------------------------------------------------------------------- */

//   /* -------------------------------------------------------------------------- */
//   /*                                     API Section                            */
//   /* -------------------------------------------------------------------------- */

//   /* -------------------------------------------------------------------------- */
//   /*                                   Popup Section                            */
//   /* -------------------------------------------------------------------------- */
//   const POSITION_TYPES = [
//     "bottom right",
//     "bottom center",
//     "top center",
//     "left top",
//     "left bottom",
//     "right top",
//     "top left",
//     "top center",
//     "right center",
//     "right bottom",
//     "bottom left",
//     "left center",
//     "center center",
//   ];

//   const popupRef = useRef();
//   const handleClose = () => {
//     if (popupRef.current) {
//       popupRef.current.close();
//     }
//   };
//   const handleOpen = () => {
//     if (popupRef.current) {
//       popupRef.current.open();
//     }
//   };
//   const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
//     <div
//       className="d-flex justify-content-between align-items-center cursor-pointer"
//       {...props}
//       ref={ref}
//     >
//       {props.header}
//     </div>
//   ));
//   const handleNavigateOrg = (code) => {
//     let date_range = getStartOfMonthDate();
//     navigate(`/${code}/dashboard?date_range=${date_range}`);
//     window.location.reload();
//     handleChangeOrgainization();
//     handleClose();
//   };

//   useEffect(() => {
//     if (props.clicked || props.isPopupVisible === true) {
//       handleClose();
//       props.setClicked(false);
//     }
//   }, [props.clicked, props.isPopupVisible]);
//   return (
//     <Popup
//       trigger={(open) => (
//         <CustomButtons
//           open={open}
//           reports={props.reports}
//           header={props.header}
//         />
//       )}
//       position={POSITION_TYPES}
//       closeOnDocumentClick
//       on={"click"}
//       contentStyle={{
//         width: "400px",
//         zIndex: 9999,
//         background: "white",
//         padding: "0",
//         boxShadow:
//           "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
//       }}
//       arrow
//       nested
//       ref={popupRef}
//       onOpen={handleOpen}
//     >
//       <div className="">
//         <div
//           className={`mt-3 dropdown-account-wrapper d-flex align-items-center  border_bottom`}
//         >
//           <h3 className={`header_text__16 mb-3`} style={{ width: "400px" }}>
//             My Organisations
//           </h3>
//           <div
//             className="d-flex align-items-center mb-3 cursor-pointer"
//             onClick={() => navigate(`/`)}
//           >
//             <SettingsDefaultSvgComponent color={"#1a73e8"} size={16} />
//             <h6 className="header_blue_text__14 ms-1">Manage</h6>
//           </div>
//         </div>
//         <div className={`dropdown-wrapper-company-list px-3`}>
//           {getValue(organizationList, `length`, 0) > 0 &&
//             organizationList.map((item, index) => {
//               return (
//                 <div
//                   className={`${
//                     getValue(item, `code`, "") !== getValue(orgInfo, `code`, "")
//                       ? "dropdown-wrapper-company"
//                       : "dropdown-wrapper-company_active"
//                   } cursor-pointer d-flex align-items-center justify-content-between
//                     ${
//                       getValue(organizationList, `length`, 0) - 1 === index
//                         ? "mb-3"
//                         : ""
//                     }
//                     `}
//                   onClick={() => handleNavigateOrg(getValue(item, `code`, ""))}
//                 >
//                   <div className="d-flex align-items-center">
//                     {getValue(item, `logo.url`, "") ? (
//                       <img
//                         src={getValue(item, `logo.url`, "")}
//                         className={"org_logo"}
//                       />
//                     ) : (
//                       <img
//                         src={"/nodata/building.jpeg"}
//                         width={250}
//                         className={"org_logo"}
//                         // className={`img-fluid cursor-pointer`}
//                       />
//                     )}
//                     <div className="ms-2">
//                       <TooltipOnHover
//                         header={
//                           <h6 className={"dropdown-wrapper-company__name"}>
//                             {concatString(getValue(item, `name`, ""), 20)}
//                           </h6>
//                         }
//                         text={getValue(item, `name`, "")}
//                       />
//                       <p className={"dropdown-wrapper-company__code"}>
//                         {getValue(item, `code`, "")}
//                       </p>
//                     </div>
//                   </div>
//                   {getValue(item, `code`, "") !==
//                   getValue(orgInfo, `code`, "") ? (
//                     <img src="/images/icons/arrow-right.svg" />
//                   ) : (
//                     <CheckCircleSvgComponent />
//                   )}
//                 </div>
//               );
//             })}
//         </div>
//       </div>
//     </Popup>
//   );
// }

// export default OrganizationListPopup;

import { Avatar, Popover, Tooltip } from "antd";
import { motion } from "framer-motion";
import { getValue } from "@utils/lodash";
import { useStateContext } from "context/profileProvider";
import { concatString, getOnlyFirstLetterOfWord } from "@common/text-helpers";
import { getStartOfMonthDate } from "@common/date-helpers";
import { useNavigate } from "react-router-dom";
import SettingsDefaultSvgComponent from "@assets/svg/setting";
import { CheckCircleFilled } from "@ant-design/icons";
import { getColorCode, getModernColorCode } from "@common/colors";

const OrganizationListPopup = (props) => {
  const navigate = useNavigate();
  const { organizationList, orgInfo, handleChangeOrgainization } =
    useStateContext();

  const popoverContent = (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div
        className={`mt-3 dropdown-account-wrapper d-flex align-items-center  border_bottom`}
      >
        <h3 className={`header_text__16 mb-3`} style={{ width: "300px" }}>
          My Organisations
        </h3>
        <div
          className="d-flex align-items-center mb-3 cursor-pointer"
          onClick={() => navigate(`/`)}
        >
          <SettingsDefaultSvgComponent color={"#1a73e8"} size={16} />
          <h6 className="header_blue_text__14 ms-1">Manage</h6>
        </div>
      </div>
      <div className={`dropdown-wrapper-company-list px-3`}>
        {getValue(organizationList, `length`, 0) > 0 &&
          organizationList.map((item, index) => {
            return (
              <div
                className={`${
                  getValue(item, `code`, "") !== getValue(orgInfo, `code`, "")
                    ? "dropdown-wrapper-company"
                    : "dropdown-wrapper-company_active"
                } cursor-pointer d-flex align-items-center justify-content-between
                    ${
                      getValue(organizationList, `length`, 0) - 1 === index
                        ? "mb-3"
                        : ""
                    }
                    `}
                onClick={() => handleNavigateOrg(getValue(item, `code`, ""))}
              >
                <div className="d-flex align-items-center">
                  {getValue(item, `logo.url`, "") ? (
                    <Avatar src={getValue(item, `logo.url`, "")} size="large" />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor:
                          getModernColorCode(
                            getOnlyFirstLetterOfWord(getValue(item, `name`, ""))
                          ) || "#fde3cf",
                      }}
                      size="large"
                    >
                      <h6 className="header_text__14 text-white">
                        {getOnlyFirstLetterOfWord(getValue(item, `name`, ""))}
                      </h6>
                    </Avatar>
                  )}

                  <div className="ms-2">
                    <Tooltip
                      title={
                        getValue(item, `name.length`, "") > 20
                          ? getValue(item, `name`, "")
                          : ""
                      }
                    >
                      <h6 className={"dropdown-wrapper-company__name"}>
                        {concatString(getValue(item, `name`, ""), 20)}
                      </h6>
                    </Tooltip>

                    <p className={"dropdown-wrapper-company__code"}>
                      {getValue(item, `code`, "")}
                    </p>
                  </div>
                </div>
                {getValue(item, `code`, "") !==
                getValue(orgInfo, `code`, "") ? (
                  <img src="/images/icons/arrow-right.svg" />
                ) : (
                  <CheckCircleFilled
                    style={{ color: "#4cc793", fontSize: "18px" }}
                  />
                )}
              </div>
            );
          })}
      </div>
    </motion.div>
  );

  const handleNavigateOrg = (code) => {
    let date_range = getStartOfMonthDate();
    // navigate(`/${code}/home`);
    // navigate(`/${code}/dashboard?date_range=${date_range}`);
    navigate(`/${code}/home`);
    window.location.reload();
    handleChangeOrgainization();
  };

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      placement="bottomLeft"
      overlayClassName="custom-popover"
    >
      <div
        className={`inline_form_field border_input inline_field d-flex align-items-center`}
      >
        <div className="input_value">
          <div className="d-flex justify-content-between align-items-center cursor-pointer">
            {props.header}
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default OrganizationListPopup;
