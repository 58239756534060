import { QueryRequestHelper } from "./query-request-helper";

export const handleNavigateURL = (name: any, value: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let payload = {
    ...params,
    [name]:value
  };
  let queryRequest = QueryRequestHelper(payload);
  return `${window.location.pathname}?${queryRequest}`;
};
