import HomeHeader from "@components/common/Header/HomeHeader/Header";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import React, { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import {
  connectFbAdManager,
  getAllAdManagers,
} from "@services/admanager.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import FacebookSDKComponent from "social/facebook-lead-login";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import FacebookList from "./FbList";
import BroadCastNavbar from "../common/navbar";
import Navbar from "../common/navbar";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import useDynamicTitle from "@context/useDynamicTitle";
import MainLayout from "@layouts/HomeLayout/NewLayout";

function AdsPage(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const views = [
    {
      id: "1",
      name: "Ad Account",
    },
  ];
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let resp = await getAllAdManagers(payload);
      if (resp) {
        setList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleSubmitFacebook = async (info: any) => {
    try {
      let resp = await connectFbAdManager({
        accessToken: getValue(info, `authResponse.accessToken`, ""),
        userID: getValue(info, `authResponse.userID`, ""),
      });
      if (resp) {
        toast.success("Connected successfully");
        getData();
      }
    } catch (error) {}
  };

  const loginOptions = [
    { value: "facebook", label: "Facebook" },
    { value: "linkedin", label: "Linkedin" },
  ];
  useDynamicTitle("Ads");
  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <FacebookSDKComponent handleSubmitFacebook={handleSubmitFacebook} />
        }
      />
      <div className="d-flex">
        <CompanyProfileNavigation />
        <div className="w-100 p-3">
          <Navbar views={views} route={"/settings/ads"} />

          <FacebookList isLoading={isLoading} list={list} />
        </div>
      </div>
    </MainLayout>
  );
}

export default AdsPage;
