import AddSvgComponent from "@assets/svg/add";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { getValue, setValue } from "@utils/lodash";
import { sendEmailRequestObj } from "./workflow-request";
import PenSvgComponent from "@assets/svg/pen";
import { concatString } from "@common/text-helpers";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";

function WorkflowDetailStep4(props: any) {
  const {
    request,
    setRequest,
    setSendEmailRequest,
    handleEditSendEmailPopup,
    handleEditUpdatesPopup,
    handleEditTasksPopup,
    handleEditWhatsappPopup
  } = props;

  /* -------------------------------------------------------------------------- */
  /*                             Send Email section                             */
  /* -------------------------------------------------------------------------- */
  const handleRemove = (index: number, type: string) => {
    let filtered = getValue(request, `actions[${type}]`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest((prevState: any) => ({
      ...prevState,
      actions: {
        ...prevState.actions,
        //  emails: [...prevState.actions.emails, filtered], //add
        [type]: filtered, //remove
      },
    }));
    setSendEmailRequest(sendEmailRequestObj);
  };
  const removeOnUpdate = (index: number, type: string) => {
    setValue(request, `actions[${type}][${index}].action`, "remove");
    setRequest({ ...request });
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Popup Section                              */
  /* -------------------------------------------------------------------------- */
  const staticData = [
    { value: "emails", label: "Send Email" },
    { value: "updates", label: "Update Field" },
    { value: "tasks", label: "Tasks" },
    { value: "whatsapp", label: "Whatsapp" },
  ];

  const handleSelect = (option: any) => {
    if (getValue(option, `value`, "") === "emails") {
      handleEditSendEmailPopup();
    }
    if (getValue(option, `value`, "") === "updates") {
      handleEditUpdatesPopup();
    }
    if (getValue(option, `value`, "") === "tasks") {
      handleEditTasksPopup();
    }
    if (getValue(option, `value`, "") === "whatsapp") {
      handleEditWhatsappPopup();
    }
  };

  return (
    <div className="workflow_detail_container mt-3">
      <div className="">
        <h6 className="header_text__20 color_primary mb-3">3. Actions</h6>
        {getValue(request, `actions.emails.length`, 0) > 0 && (
          <h6 className="header_text__16 mt-1 mb-1">Email Notifications : </h6>
        )}
        <div className="mt-3">
          {getValue(request, `actions.emails`, []).map(
            (item: object, index: number) => {
              if (getValue(item, `action`, "") !== "remove")
                return (
                  <div className="d-flex justify-content-between align-items-center mb-2 p-2 px-3 actions_list_container">
                    <p className="small_tex__12">
                      {concatString(getValue(item, `name`, ""), 40)}
                    </p>
                    <div className="d-flex justify-content-between align-items-center action_more_container">
                      <div
                        onClick={() =>
                          handleEditSendEmailPopup(item, index + 1)
                        }
                        className="ms-5 image_hover_background"
                      >
                        <PenSvgComponent size={20} />
                      </div>
                      <div
                        onClick={() =>
                          getValue(item, `id`, "")
                            ? removeOnUpdate(index, "emails")
                            : handleRemove(index, "emails")
                        }
                        className="ms-3"
                      >
                        <RemoveCircleSvgComponent />
                      </div>
                    </div>
                  </div>
                );
            }
          )}

          {getValue(request, `actions.updates.length`, 0) > 0 && (
            <h6 className="header_text__16 mt-1 mb-1">Update Fields :</h6>
          )}
          <div className="mt-3">
            {getValue(request, `actions.updates`, []).map(
              (item: object, index: number) => {
                if (getValue(item, `action`, "") !== "remove")
                  return (
                    <div className="d-flex justify-content-between align-items-center mb-2 p-2 px-3 actions_list_container">
                      <p className="small_tex__12">
                        {concatString(getValue(item, `name`, ""), 40)}
                      </p>
                      <div className="d-flex justify-content-between align-items-center action_more_container">
                        <div
                          onClick={() =>
                            handleEditUpdatesPopup(item, index + 1)
                          }
                          className="ms-5 image_hover_background"
                        >
                          <PenSvgComponent size={20} />
                        </div>
                        <div
                          onClick={() =>
                            getValue(item, `id`, "")
                              ? removeOnUpdate(index, "updates")
                              : handleRemove(index, "updates")
                          }
                          className="ms-3"
                        >
                          <RemoveCircleSvgComponent />
                        </div>
                      </div>
                    </div>
                  );
              }
            )}
          </div>

          {getValue(request, `actions.tasks.length`, 0) > 0 && (
            <h6 className="header_text__16 mt-1 mb-1">Tasks :</h6>
          )}
          <div className="mt-3">
            {getValue(request, `actions.tasks`, []).map(
              (item: object, index: number) => {
                if (getValue(item, `action`, "") !== "remove")
                  return (
                    <div className="d-flex justify-content-between align-items-center mb-2 p-2 px-3 actions_list_container">
                      <p className="small_tex__12">
                        {concatString(getValue(item, `name`, ""), 40)}
                      </p>
                      <div className="d-flex justify-content-between align-items-center action_more_container">
                        <div
                          onClick={() => handleEditTasksPopup(item, index + 1)}
                          className="ms-5 image_hover_background"
                        >
                          <PenSvgComponent size={20} />
                        </div>
                        <div
                          onClick={() =>
                            getValue(item, `id`, "")
                              ? removeOnUpdate(index, "tasks")
                              : handleRemove(index, "tasks")
                          }
                          className="ms-3"
                        >
                          <RemoveCircleSvgComponent />
                        </div>
                      </div>
                    </div>
                  );
              }
            )}
          </div>


          {getValue(request, `actions.tasks.length`, 0) > 0 && (
            <h6 className="header_text__16 mt-1 mb-1">Whatsapp :</h6>
          )}
          <div className="mt-3">
            {getValue(request, `actions.whatsapp`, []).map(
              (item: object, index: number) => {
                if (getValue(item, `action`, "") !== "remove")
                  return (
                    <div className="d-flex justify-content-between align-items-center mb-2 p-2 px-3 actions_list_container">
                      <p className="small_tex__12">
                        {concatString(getValue(item, `name`, ""), 40)}
                      </p>
                      <div className="d-flex justify-content-between align-items-center action_more_container">
                        <div
                          onClick={() => handleEditWhatsappPopup(item, index + 1)}
                          className="ms-5 image_hover_background"
                        >
                          <PenSvgComponent size={20} />
                        </div>
                        <div
                          onClick={() =>
                            getValue(item, `id`, "")
                              ? removeOnUpdate(index, "whatsapp")
                              : handleRemove(index, "whatsapp")
                          }
                          className="ms-3"
                        >
                          <RemoveCircleSvgComponent />
                        </div>
                      </div>
                    </div>
                  );
              }
            )}
          </div>
        </div>
        <div className="mt-3">
          <TextHeaderTooltip
            title={`More Actions`}
            // data={types}
            label={`label`}
            onChange={handleSelect}
            data={staticData}
            selectKey={"value"}
            width={"150px"}
            value={""}
            editInline
            staticHeader
            hideSearch
          />
        </div>
      </div>
    </div>
  );
}

export default WorkflowDetailStep4;
