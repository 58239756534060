import "./thankyou.scss";
import { Link } from "react-router-dom";
import LoginLayout from "@layouts/LoginLayout";
import Footer from "@components/common/Footer/footer";

export default function ThankYou() {
  return (
    <LoginLayout>
      <div className="container-fluid d-flex">
        <div className={"thankyou-wrapper"}>
          <h1 className={"thankyou-wrapper__title"}>
            Thank you for choosing Superteams
          </h1>
          <h2 className={"thankyou-wrapper__sub-title"}>
            Our Product is trusted by more than 3000+ businesses who care about
            teams small, middle and large companies
          </h2>
          <div className={"get-started"}>
            <p className="mb-2">Your Superteams Account is Ready</p>
            {/* <Link to="/welcome"> */}
            <Link to="/">
              <button className="superteams-button__primary-button">
                Get Started
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </LoginLayout>
  );
}
