import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

function ColumnChartComponent(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const graphData = getValue(props, "graph", []);

    if (graphData.length > 0) {
      const labels = graphData.map((item: any) =>
        typeof getValue(item, "key", "") === "object"
          ? `${getValue(item, "key.first_name", "")} ${getValue(
              item,
              "key.last_name",
              ""
            )}`
          : getValue(item, "key", "")
      );
      const dataValues = graphData.map((item: any) =>
        getValue(item, "count", 0)
      );
      const label = getValue(props, "module_field.label", "");

      // Define default colors
      const defaultColors = ["#1cb59a", "#fc5956", "#003366", "#0066CC"];

      // Generate dynamic colors based on data length
      const dynamicColors = graphData.map(
        (_: any, index: any) => defaultColors[index % defaultColors.length]
      );

      setData({
        labels: labels,
        datasets: [
          {
            label: label,
            data: dataValues,
            backgroundColor: dynamicColors,
            borderColor: dynamicColors.map((color: any) =>
              color.replace("0.5", "1")
            ), // Adjust border color to solid
            borderWidth: 1,
          },
        ],
      });
    }
  }, [props.graph, props.module_field.label]);

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, "name", "")}</p>
      <Bar data={data} options={options} />
    </div>
  );
}

export default ColumnChartComponent;
