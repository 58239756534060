import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import { getValue } from "@utils/lodash";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ReplySvgComponent from "@assets/svg/whatsapp-reply";
import LinkSvgComponent from "@assets/svg/link";
import SquareSvgComponent from "@assets/svg/square";
import FileSvgComponent from "@assets/svg/file";
import PersonSvgComponent from "@assets/svg/person";
import EmailSvgComponent from "@assets/svg/email";
import CallSvgComponent from "@assets/svg/phone";
import BrandingSvgComponent from "@assets/svg/branding";
import PowerOffSvgComponent from "@assets/svg/power-off";
import TimerSvgComponent from "@assets/svg/timer";
import ListSvgComponent from "@assets/svg/list";

const AddNodePopup = (props: any) => {
  const { selectedNode, isOpen, botInfo, handleModal, addNode } = props;
  const [label, setLabel] = useState(getValue(selectedNode, `data.label`, ""));
  useEffect(() => {
    setLabel(getValue(selectedNode, `data.label`, ""));
  }, [selectedNode]);

  const accordionItems = [
    {
      title: "Send a Message",
      content: (
        <>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("send_message")}
          >
            <div className="chatbot-button-icons">
              <ReplySvgComponent color="#aeaeae" />
            </div>
            Send Message
          </button>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("links")}
          >
            <div className="chatbot-button-icons">
              <LinkSvgComponent color="#aeaeae" size="20" />
            </div>
            Send Share Link
          </button>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("file_upload_component")}
          >
            <div className="chatbot-button-icons">
              <FileSvgComponent color="#aeaeae" size="20" />
            </div>
            Send File
          </button>
        </>
      ),
    },
    {
      title: "Selection Cards",
      content: (
        <>
          {getValue(botInfo, `platform`, "") !== "WHATSAPP" ? (
            <>
              <button
                className=" chatbot_button small_text__14"
                onClick={() => addNode("suggestions")}
              >
                <div className="chatbot-button-icons">
                  {/* <SquareSvgComponent color="#aeaeae" size="20" /> */}
                  <ListSvgComponent color="#aeaeae" size="20" />
                </div>
                Send List Option
              </button>
              {/* <button
                className=" chatbot_button small_text__14"
                onClick={() => addNode("singleselect")}
                // disabled
              >
                <div className="chatbot-button-icons">
                  <ListSvgComponent color="#aeaeae" size="20" />
                </div>
                Single choice
              </button> */}
              {/* <button
                className=" chatbot_button small_text__14"
                onClick={() => addNode("multiselect")}
                // disabled
              >
                <div className="chatbot-button-icons">
                  <CheckCircleSvgComponent color="#aeaeae" size="20" />
                </div>
                Multi choice
              </button> */}
            </>
          ) : (
            <button
              className=" chatbot_button small_text__14"
              onClick={() => addNode("template")}
            >
              <div className="chatbot-button-icons">
                <SquareSvgComponent color="#aeaeae" size="20" />
              </div>
              Whatsapp Template
            </button>
          )}

          {/* <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("file_upload_component")}
            disabled
          >
            <div className="chatbot-button-icons">
              <CalendarSvgComponent color="#aeaeae" size="20" />
            </div>
            Calender
          </button>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("file_upload_component")}
            disabled
          >
            <div className="chatbot-button-icons">
              <PinDropSvgComponent color="#aeaeae" size="20" />
            </div>
            Location
          </button>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("file_upload_component")}
            disabled
          >
            <div className="chatbot-button-icons">
              <TimerSvgComponent color="#aeaeae" size="20" />
            </div>
            Date & time
          </button> */}
        </>
      ),
    },

    {
      title: "Ask Questions",
      content: (
        <>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("visitor_name")}
          >
            <div className="chatbot-button-icons">
              <PersonSvgComponent color="#aeaeae" />
            </div>
            Ask Name
          </button>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("visitor_email")}
          >
            <div className="chatbot-button-icons">
              <EmailSvgComponent color="#aeaeae" />
            </div>
            Ask Email
          </button>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("visitor_phone")}
          >
            <div className="chatbot-button-icons">
              <CallSvgComponent color="#aeaeae" />
            </div>
            Ask Phone
          </button>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("company_name")}
          >
            <div className="chatbot-button-icons">
              <BrandingSvgComponent color="#aeaeae" />
            </div>
            Ask Company
          </button>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("visitor_datetime")}
          >
            <div className="chatbot-button-icons">
              <TimerSvgComponent color="#aeaeae" size="20" />
            </div>
            Ask <br />
            Date & Time
          </button>
        </>
      ),
    },
    // {
    //   title: "Action Cards",
    //   content: (
    //     <>
    //       <button
    //         className=" chatbot_button small_text__14"
    //         onClick={() => addNode("visitor_email")}
    //         disabled
    //       >
    //         <div className="chatbot-button-icons">
    //           <EmailSvgComponent color="#aeaeae" />
    //         </div>
    //         Email
    //       </button>
    //     </>
    //   ),
    // },
    {
      title: "End Cards",
      content: (
        <>
          <button
            className=" chatbot_button small_text__14"
            onClick={() => addNode("is_end_chat")}
          >
            <div className="chatbot-button-icons">
              <PowerOffSvgComponent color="#aeaeae" size="20" />
            </div>
            End Chat
          </button>
        </>
      ),
    },
  ];

  const [openIndices, setOpenIndices] = useState(
    accordionItems.map((_, index) => index)
  );

  const toggleAccordion = (index: any) => {
    setOpenIndices((prevOpenIndices) =>
      prevOpenIndices.includes(index)
        ? prevOpenIndices.filter((i) => i !== index)
        : [...prevOpenIndices, index]
    );
  };

  return (
    <SlideFromRightPopup
      isPopupVisible={isOpen}
      addCustomClass={"rolls-popup"}
      toggle={() => {
        handleModal();
      }}
      title={"Add Fields"}
      width={"550px"}
    >
      <div className="bot-testing-wrapper p-4">
        {accordionItems.map((item, index) => (
          <div className="accordion-item mb-3 border-bottom pb-2" key={index}>
            <div
              className="accordion-title d-flex justify-content-between cursor-pointer"
              onClick={() => toggleAccordion(index)}
            >
              <h6
                className={`header_text__16 ${
                  openIndices.includes(index) ? "text-black" : "desc_text"
                }`}
              >
                {item.title}
              </h6>
              <img
                src="/images/icons/arrow-down.svg"
                width={18}
                height={18}
                className={`${
                  openIndices.includes(index) ? "accordion-rotate" : ""
                } accordion-arrow`}
                alt="Toggle icon"
              />
            </div>
            {openIndices.includes(index) && (
              <motion.div
                initial={{ y: 0, opacity: 0 }}
                animate={{ y: "auto", opacity: 1 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <div className="nodes-panel gap-2">{item.content}</div>
              </motion.div>
            )}
          </div>
        ))}
      </div>
    </SlideFromRightPopup>
  );
};

export default AddNodePopup;
