import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateSale } from "@services/deals.service";
import { toast } from "sonner";
import PendingLoanComponentSales from "./sales";
import PendingLoanComponentContacts from "./contacts";
import { updateContact } from "@services/contacts.service";
import PendingLoanComponentCompanies from "./companies";
import { updateCompany } from "@services/companies.service";
import { FormRequestHelperWithNullData } from "@components/helpers/request-helper";
import TagPopup from "@components/Pages/Pipeline/Detail/components/Tag";
import {
  createTag,
  getAllTags,
  updateModuleTags,
} from "@services/tags.service";
import { useStateContext } from "@context/profileProvider";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import { handleSelectChange } from "@common/handleChange";
import { motion } from "framer-motion";
import TaskPendingLayout from "../../../components/TaskPendingLayout/TaskPendingLayout";
import { Button, Tooltip } from "antd";
import { updateLead } from "@services/leads.service";
import { DeleteFilled, DeleteOutlined } from "@ant-design/icons";
import { CopyPlus } from "lucide-react";

export default function PendingLoanComponent(props: any) {
  const { request, setRequest, hideBackButton } = props;
  const [clicked, setClicked] = useState(false);
  const taskArrayList = [
    // {
    //   icon: "/images/task/icons/notes.svg",
    //   text: "NOTES",
    //   name: "notes",
    // },

    {
      icon: "/images/task/icons/task.svg",
      text: "Task",
      name: "tasks",
      tooltip: "Create a task",
    },
    {
      icon: "/images/task/icons/calendar.svg",
      text: "Meeting",
      name: "meetings",
      tooltip: "Schedule a meeting",
    },
    {
      icon: "/images/task/icons/call.svg",
      text: "Calls",
      name: "calls",
      tooltip: "Add call",
    },
    {
      icon: "/images/task/icons/mail.svg",
      text: "Email",
      name: "emails",
      tooltip: "Create an email",
    },
  ];
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(props.backUrl);
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    if (getValue(props, `duplicateAPINames.length`, 0) > 0) {
      toast.error(
        "Submission is not allowed due to the presence of duplicate values."
      );
    } else {
      try {
        let fieldReq = JSON.parse(JSON.stringify(props.popupFields));
        let payload = FormRequestHelperWithNullData(fieldReq);
        let requestPayload: any = { ...payload };
        // Object.keys(requestPayload).forEach((key) => {
        //   if (!requestPayload[key] || requestPayload[key] === undefined) {
        //     delete requestPayload[key];
        //   }
        // });
        setSubmitLoading(true);
        let resp;
        if (
          getValue(props, `module`, "") === "sales" ||
          getValue(props, `module`, "") === "deals"
        ) {
          resp = await updateSale(
            getValue(props, `params.id`, ""),
            requestPayload
          );
        }

        if (getValue(props, `module`, "") === "leads") {
          resp = await updateLead(
            getValue(props, `params.id`, ""),
            requestPayload
          );
        }

        if (getValue(props, `module`, "") === "contacts") {
          resp = await updateContact(
            getValue(props, `params.id`, ""),
            requestPayload
          );
        }
        if (getValue(props, `module`, "") === "companies") {
          resp = await updateCompany(
            getValue(props, `params.id`, ""),
            requestPayload
          );
        }
        if (resp) {
          toast.success("Updated successfully");
          props.getData(false);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  const { modules } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                                 Tags Section                               */
  /* -------------------------------------------------------------------------- */
  const params = useParams();
  const [tags, setTags] = useState([]);
  const [obj, setObj] = useState({
    label: "",
    value: "",
    color_code: "#82E0AA",
  });
  useEffect(() => {
    getAllTagsList();
  }, [props.data]);

  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [allTags, setAllTags] = useState([]);
  const getAllTagsList = async () => {
    try {
      let moduleID =
        getValue(modules, `length`, 0) > 0
          ? modules.filter(
              (item: object) =>
                getValue(item, `name`, "") === getValue(props, `module`, "")
            )
          : "";
      let resp = await getAllTags(getValue(moduleID, `[${0}].id`, ""));
      if (resp) {
        setTags([]);
        let tags = getValue(resp, `data`, []);
        let selectedTags = getValue(props, `data.tags`, []);
        const newArray = tags.filter((item: any) =>
          selectedTags.includes(item.value)
        );
        setTags(
          newArray.map((item: object) => ({
            ...item,
            value: getValue(item, `value`, ""),
            label: getValue(item, `label`, ""),
          }))
        );

        setSelectedTags(selectedTags);
        setAllTags(
          tags.map((item: object) => ({
            ...item,
            value: getValue(item, `value`, ""),
            label: getValue(item, `label`, ""),
          }))
        );
      }
    } catch (error) {}
  };
  const [tagLoading, setTagLoading] = useState(false);
  const handleSubmitTags = async (obj: object) => {
    let moduleID =
      getValue(modules, `length`, 0) > 0
        ? modules.filter(
            (item: object) =>
              getValue(item, `value`, "") === getValue(props, `module`, "")
          )
        : "";
    try {
      setTagLoading(true);
      let resp = await createTag(getValue(moduleID, `[${0}].id`, ""), obj);
      if (resp) {
        let tagsList =
          getValue(tags, `length`, 0) > 0
            ? tags.map((item: object) => getValue(item, `value`, ""))
            : [];
        let payload: any = {
          tags: getValue(obj, `value`, "")
            ? tagsList.concat(getValue(obj, `value`, ""))
            : tagsList,
        };
        handleUpdateTags(payload);
        setTagLoading(false);
      } else {
        setTagLoading(false);
      }
    } catch (error) {
      setTagLoading(false);
    }
  };
  const handleUpdateTags = async (payload: any) => {
    try {
      setSubmitLoading(true);
      let resp = await updateModuleTags(
        getValue(props, `module`, ""),
        getValue(params, `id`, ""),
        {
          tags:
            getValue(payload, `tags.length`, 0) > 0
              ? getValue(payload, `tags`, []).filter(
                  (item: string) => item && item
                )
              : selectedTags,
        }
      );
      if (resp) {
        toast.success("Updated successfully");
        setObj({
          ...obj,
          label: "",
          value: "",
          color_code: "#D5F5E3",
        });
        props.getData(false);
        setSubmitLoading(false);
        setClicked(true);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  const handleChangeTags = (item: any) => {
    const itemName = getValue(item, "value", "");
    const isTagSelected = selectedTags.includes(itemName);
    if (isTagSelected) {
      const updatedTags = selectedTags.filter(
        (tag: string) => tag !== itemName
      );
      setSelectedTags([...updatedTags]);
    } else {
      setSelectedTags([...selectedTags, itemName]);
    }
  };
  const handleChangeSelect = (value: any) => {
    let selectedValue: any = getValue(value, `value`, "");
    if (selectedTags.includes(selectedValue)) {
      let filtered = selectedTags.filter((item: any) => item !== selectedValue);
      setSelectedTags(filtered);
    } else {
      selectedTags.push(selectedValue);
      setSelectedTags([...selectedTags]);
    }
  };
  const handleAddTags = async (obj: object) => {
    let moduleID =
      getValue(modules, `length`, 0) > 0
        ? modules.filter(
            (item: object) =>
              getValue(item, `name`, "") === getValue(props, `module`, "")
          )
        : "";
    try {
      let resp = await createTag(getValue(moduleID, `[${0}].id`, ""), obj);
      if (resp) {
        console.log(resp);
        getTagsAfterAddedToList();
        handleChangeTags(obj);
        handleChangeSelect(obj);
        getSubmitTags(selectedTags);
        console.log(selectedTags);
      }
    } catch (error) {}
  };
  const getTagsAfterAddedToList = async () => {
    try {
      let moduleID =
        getValue(modules, `length`, 0) > 0
          ? modules.filter(
              (item: object) =>
                getValue(item, `name`, "") === getValue(props, `module`, "")
            )
          : "";
      let resp = await getAllTags(getValue(moduleID, `[${0}].id`, ""));
      if (resp) {
        let tags = getValue(resp, `data`, []);
        let selectedTags = getValue(props, `data.tags`, []);
        setSelectedTags(selectedTags);
        setAllTags(
          tags.map((item: object) => ({
            ...item,
            value: getValue(item, `value`, ""),
            label: getValue(item, `label`, ""),
          }))
        );
      }
    } catch (error) {}
  };

  const getSubmitTags = async (tags: any) => {
    try {
      let resp = await updateModuleTags(
        getValue(props, `module`, ""),
        getValue(params, `id`, ""),
        {
          tags: tags,
        }
      );
      if (resp) {
        props.getData(false);
        setIsActive(false);
        setSubmitLoading(false);
        setClicked(true);
        toast.success("Updated successfully");
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                           Navigation Section                               */
  /* -------------------------------------------------------------------------- */

  const navigateSection = (name: string) => {
    switch (name) {
      case "notes":
        return props.handleOpenNotes();
      case "emails":
        return props.handleSendEmail();
      case "tasks":
        return props.handleOpenForm("tasks");
      case "meetings":
        return props.handleEventVisibility();
      case "calls":
        return props.handleCallVisibility();
      default:
        return null;
    }
  };
  const staticData = [{ value: "DELETE", label: "Delete" }];
  const handleSelect = (value: object) => {
    props.handleDeleteModal();
  };

  const [isActive, setIsActive] = useState(false);
  // handleConvertContact
  return (
    <>
      <TaskPendingLayout>
        <div className={"pending-wrapper__main-left-section"}>
          {/* <PendingTaskCommonTitle commonTitle="Jade Garden Site 210 Loan" /> */}
          <div className={"pending-wrapper__pending-task-details"}>
            <div className={`pending-wrapper__main-section-form`}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                {hideBackButton ? (
                  <div></div>
                ) : (
                  <div
                    className={`pending-wrapper__main-section-header d-flex align-items-center cursor-pointer`}
                    onClick={handleNavigate}
                  >
                    <img
                      src={"/dropdown/arrow_back.svg"}
                      className={`pending-wrapper__main-left-section_header-image-back`}
                      alt=""
                    />
                    <h6
                      className={
                        "pending-wrapper__main-left-section_header-text touchable-text"
                      }
                    >
                      Back
                    </h6>
                  </div>
                )}
                {/* {getValue(props, `module`, "") === "leads" && (
                  <Tooltip title="Convert to Customer" placement="bottom">
                    <CopyPlus
                      size={18}
                      onClick={() => props.handleConvertContact()}
                    />
                  </Tooltip>
                )} */}
                {getValue(props, `permissions`, []).includes("delete") && (
                  <div className="cursor-pointer" onClick={handleSelect}>
                    <Tooltip title="Delete">
                      <DeleteOutlined style={{ color: "red" }} />
                    </Tooltip>
                  </div>
                )}
                {/* <TextHeaderTooltip
                  title={`Actions`}
                  // data={types}
                  label={`label`}
                  onChange={handleSelect}
                  data={staticData}
                  selectKey={"value"}
                  width={"150px"}
                  value={""}
                  editInline
                  staticHeader
                  hideSearch
                /> */}
              </div>
              <div>
                <TagPopup
                  obj={obj}
                  setObj={setObj}
                  tagLoading={tagLoading}
                  submitLoading={submitLoading}
                  clicked={clicked}
                  setClicked={setClicked}
                  selectedTags={selectedTags}
                  handleChangeTags={handleChangeTags}
                  handleSubmitTags={handleSubmitTags}
                  handleUpdateTags={handleUpdateTags}
                  handleAddTags={handleAddTags}
                  //tags
                  values={selectedTags}
                  handleSelectChange={handleChangeSelect}
                  tags={allTags}
                  setAllTags={setAllTags}
                  tagsList={tags}
                  permissions={getValue(props, `permissions`, [])}
                  setIsActive={setIsActive}
                  isActive={isActive}
                />
                {/* {getValue(tags, `length`, 0) > 0 && (
                  <TagsDetailList tags={tags} />
                )} */}
              </div>
              {(getValue(props, `module`, "") === "contacts" ||
                getValue(props, `module`, "") === "leads") && (
                <PendingLoanComponentContacts
                  request={request}
                  setRequest={setRequest}
                  submitLoading={submitLoading}
                  handleSubmit={handleSubmit}
                  popupFields={props.popupFields}
                  setPopupFields={props.setPopupFields}
                  data={props.data}
                  permissions={getValue(props, `permissions`, [])}
                  // module={props.module}
                  duplicateAPINames={props.duplicateAPINames}
                  setDuplicateAPINames={props.setDuplicateAPINames}
                />
              )}
              {getValue(props, `module`, "") === "companies" && (
                <PendingLoanComponentCompanies
                  request={request}
                  setRequest={setRequest}
                  submitLoading={submitLoading}
                  handleSubmit={handleSubmit}
                  popupFields={props.popupFields}
                  setPopupFields={props.setPopupFields}
                  data={props.data}
                  permissions={getValue(props, `permissions`, [])}
                  // module={props.module}
                  duplicateAPINames={props.duplicateAPINames}
                  setDuplicateAPINames={props.setDuplicateAPINames}
                />
              )}
              {getValue(props, `module`, "") === "sales" ||
                (getValue(props, `module`, "") === "deals" && (
                  <PendingLoanComponentSales
                    submitLoading={submitLoading}
                    handleSubmit={handleSubmit}
                    request={request}
                    setRequest={setRequest}
                    popupFields={props.popupFields}
                    setPopupFields={props.setPopupFields}
                    data={props.data}
                    permissions={getValue(props, `permissions`, [])}
                    // module={props.module}
                    duplicateAPINames={props.duplicateAPINames}
                    setDuplicateAPINames={props.setDuplicateAPINames}
                  />
                ))}
              <div className={`form-container_desc d-flex align-items-center`}>
                <p className={"small_text__14"}>
                  Status <span className="ms-1">:</span>{" "}
                </p>
                <div className={`ms-2 mt-2 mb-1`}>
                  <TextHeaderTooltip
                    width={"300px"}
                    title={`Status`}
                    value={getValue(request, `stage`, "")}
                    placeholder={`Choose Status`}
                    onChange={(e: any) =>
                      handleSelectChange(e, "stage", request, setRequest)
                    }
                    label={`label`}
                    data={
                      getValue(props, `stageList.length`, 0) > 0
                        ? getValue(props, `stageList`, []).map(
                            (item: object) => ({
                              ...item,
                              label: getValue(item, `label`, ""),
                              display_text: getValue(item, `label`, ""),
                              value: getValue(item, `id`, ""),
                            })
                          )
                        : []
                    }
                    selectKey={"id"}
                    editInline
                  />
                </div>
              </div>
            </div>
            {!hideBackButton && (
              <div className={""}>
                <ul
                  className={`d-flex align-items-center gap justify-content-start`}
                >
                  {taskArrayList.map((taskObj, index) => {
                    return (
                      <motion.div
                        initial={{ y: 0, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.2, ease: "easeInOut" }}
                        exit={{
                          y: 50,
                          opacity: 0,
                          transition: { duration: 0.5, ease: "easeInOut" },
                        }}
                      >
                        <li
                          key={`task-${index}`}
                          onClick={() =>
                            navigateSection(getValue(taskObj, `name`, ""))
                          }
                          className={`touchable-text my-anchor-element${index}`}
                        >
                          <div className={"task-list-wrapper"}>
                            <div className={`task-list-container d-flex`}>
                              <Tooltip title={getValue(taskObj, `tooltip`, "")}>
                                <img
                                  src={taskObj.icon}
                                  className={`task-list img-fluid`}
                                />
                              </Tooltip>
                            </div>
                            <p className={"small_text__12 mt-1 text-center"}>
                              {taskObj.text}
                            </p>
                          </div>
                        </li>
                      </motion.div>
                    );
                  })}
                  {getValue(props, `module`, "") === "leads" && (
                    <motion.div
                      initial={{ y: 0, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.2, ease: "easeInOut" }}
                      exit={{
                        y: 50,
                        opacity: 0,
                        transition: { duration: 0.5, ease: "easeInOut" },
                      }}
                    >
                      <li
                        key={`task-convert`}
                        onClick={() => props.handleConvertContact()}
                        className={`touchable-text my-anchor-elementconvert`}
                      >
                        <div className={"task-list-wrapper"}>
                          <div
                            className={`task-list-container d-flex align-items-center justify-content-center`}
                          >
                            <Tooltip title="Convert to Customer">
                              <CopyPlus size={16} color="#516f90" />
                            </Tooltip>
                          </div>
                          <p className={"small_text__12 mt-1 text-center"}>
                            Convert
                          </p>
                        </div>
                      </li>
                    </motion.div>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </TaskPendingLayout>
    </>
  );
}
