import "./AddExistingEmployee.scss";
import { getValue } from "@utils/lodash";
import { formatString } from "common/text-helpers";
import Loader from "@components/common/Loader/loading";
import { SeparatorVertical } from "lucide-react";

export default function AddExistingEmployee(props: any) {
  return (
    <div>
      {/* <div className={"add-existing-employee"}> */}
      <div className={"task_list-search_container"}>
        <img src={"/dropdown/search.svg"} className={"search_image"} />
        <input
          type="text"
          className={"input_field"}
          placeholder={`Search ${formatString(getValue(props, `module`, ""))}`}
          value={props.search_text}
          onChange={(e) => props.getListOfContacts(e.target.value)}
        />
      </div>
      {props.listLoading ? (
        <Loader small />
      ) : (
        <>
          {getValue(props, `list.length`, 0) > 0 &&
            getValue(props, `list`, []).map((item: object, index: number) => {
              if (getValue(props, `search_text`, ""))
                return (
                  <label
                    key={`existing-${index}`}
                    className={`add-existing-employee-wrapper__details d-flex align-items-start cursor-pointer px-3 pt-3`}
                  >
                    <div
                      className={"add-existing-employee-wrapper__custom-check"}
                    >
                      <label className="m-0 position-relative">
                        <input
                          type="checkbox"
                          className={"default-checkbox"}
                          checked={
                            props.selectedList.includes(
                              getValue(item, `id`, "")
                            )
                              ? true
                              : false
                          }
                          disabled={props.disabled}
                          onChange={() => {
                            props.selectedList.includes(
                              getValue(item, `id`, "")
                            )
                              ? props.handleDisAssociate(
                                  getValue(item, `id`, ""),
                                  getValue(props, `module`, "")
                                )
                              : props.handleAssociate(
                                  getValue(item, `id`, ""),
                                  getValue(props, `module`, "")
                                );
                          }}
                        />
                        {props.disabled ? (
                          <img
                            src={`/images/icons/mandatory-lock.svg`}
                            className={`mandatory-lock img-fluid`}
                          />
                        ) : (
                          <div className={`custom-checkbox`}>
                            <img
                              src={`/images/icons/${
                                props.disabled ? "gray" : "blue"
                              }-tick.svg`}
                              className={`uploaded-tick img-fluid`}
                            />
                          </div>
                        )}
                      </label>
                    </div>
                    <div className="border-bottom w-100">
                      <h6
                        className={"add-existing-employee-wrapper__name mt-1 "}
                      >
                        {/* {getValue(props, `module`, "") === "contacts" ? ( */}
                          <>
                            {getValue(item, `first_name`, "")}{" "}
                            {getValue(item, `last_name`, "")}
                          </>
                        {/* ) : (
                          <>{getValue(item, `name`, "")}</>
                        )} */}
                      </h6>
                      {/* <p className={"add-existing-employee-wrapper__text"}>
                        {getValue(item, `first_name`, "")}{" "}
                        {getValue(item, `last_name`, "")}
                      </p> */}
                      <div className="d-flex ">
                        {getValue(item, `phone_number`, "") && (
                          <div className="d-flex align-items-center">
                            <p
                              className={"add-existing-employee-wrapper__text"}
                            >
                              {getValue(item, `phone_number`, "")}
                            </p>
                            {getValue(item, `email`, "") && (
                              <div className="mx-2 mb-2">•</div>
                            )}
                          </div>
                        )}
                        <p className={"add-existing-employee-wrapper__text"}>
                          {getValue(item, `email`, "")}
                        </p>
                      </div>
                    </div>
                  </label>
                );
            })}
        </>
      )}
    </div>
  );
}
