import { QueryRequestHelper } from "@common/query-request-helper";
import { getDuplicatesData } from "@services/common.service";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function DuplicateImports(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [importList, setImportList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        pipeline_id: "clxbt6dlj00ch47st0h59c6hc",
        has_advance_filter: false,
        has_group_by: false,
      };
      let resp = await getDuplicatesData(payload);
      if (resp) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                            Pagination Section                              */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <header
        className={`login-header-wrapper d-flex justify-content-between align-items-center`}
      >
        <div className="container-fluid">
          <Link to="/">
            <a
              className={`login-header-wrapper__logo cursor-pointer d-inline-block d-flex`}
            >
              <img
                src={"/appzo_logo.png"}
                className={`login-header-wrapper__logo-image img-fluid`}
              />
            </a>
          </Link>
        </div>
      </header>
    </>
  );
}

export default DuplicateImports;
