import * as React from "react";
import "./index.scss";
import Loading from "./activity_loader.json";
// import HeaderOnlyLayout from "@layouts/HeaderOnlyLayout/HeaderOnlyLayout";
import Lottie from "react-lottie";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILoaderProps {}

const ActivityLoader: React.FunctionComponent<ILoaderProps> = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },

  };
  return (
    <div className="loader-container">
      <div className="loader-wrapper__details" >
        <Lottie
          options={defaultOptions}
          height={"80px"}
          width={"80px"}
          style={{ marginTop: "200px" }}
        />
      </div>
    </div>
  );
};

export default ActivityLoader;
