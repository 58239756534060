import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllLeads = (queryRequest: string) =>
  get(`${config.API_URL}/records/leads?${queryRequest}`);

export const getListOfAllLeads = (queryRequest: string) =>
  get(`${config.API_URL}/records/leads/list?${queryRequest}`);

export const getSpecificLead = (id: string) =>
  get(`${config.API_URL}/records/leads/${id}`);

export const createLead = (payload: object) =>
  post(`${config.API_URL}/records/leads`, payload);

export const updateLead = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/leads/${id}`, payload);

export const deleteLead = (id: string) =>
  Delete(`${config.API_URL}/records/leads/${id}`);

export const leadsBulkUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/leads/bulk-update`, payload);

export const leadsSheetUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/leads/sheet-view`, payload);
