import { getValue } from "@utils/lodash";
import ChatReceiverSection from "./chat-receiver";
import ChatSenderSection from "./chat-sender";
import { groupByDate, renderDate } from "@common/date-helpers";

function ConversationMessages(props: any) {
  const { conversation, setSelectedReplyMessage } = props;
  const groupedData = groupByDate(conversation);

  console.log(conversation)
  return (
    <div>
      {Object.keys(groupedData).map((group: any) => {
        return (
          <>
            <div className="chat-date-container">
              <p className="chat-date-header">{group}</p>
            </div>
            {getValue(groupedData, `[${group}].length`, 0) > 0
              ? getValue(groupedData, `[${group}]`, []).map(
                  (item: object, index: number) => {
                    if (getValue(item, `type`, "") !== "request_welcome")
                      return (
                        <>
                          {(getValue(item, `text_body`, "") ||
                            getValue(item, `upload_file.key`, "") ||
                            getValue(item, `broadcast_id`, "") ||
                            getValue(item, `type`, "") === "request_welcome" ||
                            (!getValue(item, `broadcast_id`, "") &&
                              getValue(item, `type`, "") === "unsupported")) &&
                          !getValue(item, `business_initiated`, false) ? (
                            <ChatReceiverSection
                              item={item}
                              setSelectedReplyMessage={setSelectedReplyMessage}
                            />
                          ) : (
                            <ChatSenderSection
                              item={item}
                              setSelectedReplyMessage={setSelectedReplyMessage}
                            />
                          )}
                        </>
                      );
                  }
                )
              : ""}
          </>
        );
      })}
    </div>
  );
}

export default ConversationMessages;
