import HomeHeader from "@components/common/Header/HomeHeader/Header";
import Loader from "@components/common/Loader/loading";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../createbot.scss";
import { getValue } from "@utils/lodash";
import {
  deleteChatbotGroup,
  getAllChatbotLogs,
  getChatbotFlowInput,
  getSpecificChatbot,
} from "@services/chatbot.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { toast } from "sonner";
import BotLogTableData from "../components/botGroupLogData";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import ChatbotNavviews from "./chatbot-navviews";
import ChatbotSubHeader from "./subheader";
import { chatbotLogExport } from "@services/fb.service";
import { handleDownloadOnClick } from "@common/downloadfile";
import ListLoader from "@components/common/Loader";
import MainLayout from "@layouts/HomeLayout/NewLayout";

const BotsLogsPage = (props:any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
      getChatbot();
    }
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getData();
      getChatbot();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        chatbot_id: getValue(params, `id`, ""),
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
        status: getValue(UrlParams, `status`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbotLogs(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.logs`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [info, setInfo] = useState({});
  const getChatbot = async () => {
    try {
      let resp = await getSpecificChatbot(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal();
  };
  const handleDeleteBot = async () => {
    try {
      let resp = await deleteChatbotGroup(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        setDeleteId("");
        getData();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleNavigateBack = () => {
    navigate(`/${getValue(params, `orgId`, "")}/chatbot`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleExport = async () => {
    try {
      let resp = await chatbotLogExport({
        // filter_created_at: "string",
        chatbot_id: getValue(params, `id`, ""),
      });
      if (resp) {
        handleDownloadOnClick(getValue(resp, `data`, ""), "logs");
      }
    } catch (error) {}
  };

  return (
    <MainLayout {...props}>
      <ChatbotSubHeader
        subHeaderListTitle={getValue(info, `name`, "")}
        platform={getValue(info, `platform`, "")}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
        showBack={true}
        handleNavigateBack={handleNavigateBack}
        params={params}
        permissions={getValue(props,`permissions`,[])}
      />
      <div className="d-flex">
        <div className="w-100">
          <ChatbotNavviews handleExport={handleExport} showExport />
          <div className="w-100">
            {isLoading ? (
              <ListLoader />
            ) : (
              <div className="mt-3">
                <BotLogTableData
                  list={list}
                  handleDelete={handleDeleteView}
                  totalCount={totalCount}
                  limit={limit}
                  page_no={page_no}
                  handleChangePagination={handleChangePagination}
                  info={info}
                />
              </div>
            )}
          </div>
          {/* <Pagination
            totalCount={totalCount}
            limit={limit}
            page_no={page_no}
            handleChangePagination={handleChangePagination}
            handleChangeLimit={handleChangeLimit}
          /> */}
        </div>
        <DeleteModal
          isOpen={isOpen}
          handleModal={handleModal}
          handleSubmit={handleDeleteBot}
          deleteValue={deleteValue}
        />
      </div>
    </MainLayout>
  );
};

export default BotsLogsPage;
