import React, { useState } from "react";
import { Button, Dropdown, Menu, Space } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Link } from "lucide-react";

const FacebookSDKComponent = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          props.handleSubmitFacebook(response);
          setIsLoggedIn(true);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope:
          "email,ads_management,pages_read_engagement,pages_show_list,pages_manage_metadata,leads_retrieval,pages_manage_ads",
      }
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex align-items-center p-1">
          <img
            src="/images/icons/facebook.svg"
            alt="Facebook"
            width="20"
            height="20"
          />
          <span style={{ marginLeft: 8 }}>Facebook</span>
          <span style={{ marginLeft: 8 }}>+</span>
          <img
            src="/images/icons/instagram.svg"
            alt="Instagram"
            width="20"
            height="20"
            style={{ marginLeft: 8 }}
          />
          <span style={{ marginLeft: 8 }}>Instagram</span>
        </div>
      ),
      onClick: handleLogin,
    },
    {
      key: "2",
      label: (
        <div className="d-flex align-items-center p-1 align-items-center">
          <img
            src="/images/icons/linkedin.svg"
            alt="LinkedIn"
            width="20"
            height="20"
          />
          <span style={{ marginLeft: 8 }}>LinkedIn</span>
          <span style={{ marginLeft: 8, fontSize: "12px" }}>(Coming soon)</span>
        </div>
      ),
      disabled: true,
    },
  ];

  return (
    <div className="d-flex justify-content-end">
      <Dropdown menu={{ items }}>
        <a onClick={(e) => e.preventDefault()}>
          <Button size="large" type="primary" icon={<Link size={18} />}>
            {/* <img src="/images/icons/link-white.svg" className="add-image" />{" "} */}
            Connect Account
          </Button>
        </a>
      </Dropdown>
    </div>
  );
};

export default FacebookSDKComponent;
