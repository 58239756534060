// import Header from '@components/Dashboard/v2/Header/Header';

import Header from "@components/common/Header/LoginHeader/header";

export default function NotfoundLayout({ children }: any) {
	return (
		<>
			<Header/>
			<div className="flex-1">{children}</div>
		</>
	);
}
