import React from "react";
import "./nodata.scss";
function EmailssNoDataPage(props: any) {
  return (
    <div className="n_container">
      <div className="nodata_container">
        <div className="d-flex justify-content-center align-items-center">
          <div className="nodata_container_main">
            <h6 className="nodata_container_header">
              You haven't connected any channels
            </h6>
            <p className="nodata_container_desc mt-2">
              Manage all your conversations in one place by connecting channels
              to an inbox.
            </p>
          </div>
          <div className="w-10"></div>
          <img
            src={"/nodata/email_notification.svg"}
            alt="SVG Image"
            height={100}
            // className="nodata_container_image"
          />
        </div>
      </div>
    </div>
  );
}

export default EmailssNoDataPage;
