import { getValue } from "@utils/lodash";
import { Radio } from "antd";

const SheetBooleanRadioEditor = ({
  col,
  row,
  selectedField,
  handleDisplayItem,
  handleInputChange,
}: any) => {
  return (
    <Radio.Group
      value={handleDisplayItem(col, row)}
      onChange={(e) =>
        handleInputChange(
          getValue(row, "id", ""),
          col,
          e.target.value,
          selectedField
        )
      }
      size="small"
      buttonStyle="solid"
    >
      <Radio.Button value={true}>Yes</Radio.Button>
      <Radio.Button value={false}>No</Radio.Button>
    </Radio.Group>
  );
};

export default SheetBooleanRadioEditor;
