import { formatString } from "@common/text-helpers";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import InputRuleForm from "@components/InputRuleForm/form";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { useRef, useState } from "react";
import { toast } from "sonner";
import { tasksRequestObj } from "../workflow-request";
import { handleObjectValidation } from "@components/custom/validation";
import { handleChangeNumber } from "@common/handleChange";
import SimpleReactValidator from "simple-react-validator";
import AlarmPicker from "@components/custom/Forms/AlarmPicker";

function WorkflowTasksPopup(props: any) {
  const {
    isOpen,
    title,
    toggle,
    request,
    setRequest,
    submitLoading,
    mainRequest,
    setMainRequest,
    editIndex,
    setEditIndex,
  } = props;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const handleAddEmailToList = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (editIndex) {
        let index = editIndex - 1;
        const updatedData = [...mainRequest.actions.tasks];
        updatedData[index] = request;
        setMainRequest((prevState: any) => ({
          ...prevState,
          actions: {
            ...prevState.actions,
            tasks: updatedData,
          },
        }));
        toggle();
        setRequest({ ...tasksRequestObj });
        setEditIndex(null);
      } else {
        if (getValue(request, `name`, "")) {
          mainRequest.actions.tasks.push(request);
          setMainRequest({ ...mainRequest });
          setRequest({ ...tasksRequestObj });
          toggle();
        } else {
          toast.error("Please input required fields");
        }
      }
      hideErrorMessages();
    }
  };
  const hideErrorMessages = () => {
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };
  const [dates] = useState([
    {
      value: "workflow_triggered_at",
      label: formatString("workflow_triggered_at"),
    },
    { value: "record_created_at", label: formatString("record_created_at") },
  ]);
  const errors = handleObjectValidation(request, [
    "name",
    "owner",
    "dueDate",
    "dueDateDays",
    "priority",
  ]);
  const hasErrors = errors.length > 0;

  return (
    <SlideFromRightPopup
      isPopupVisible={isOpen}
      addCustomClass={"rolls-popup"}
      title={title}
      toggle={() => {
        toggle();
        setRequest({ ...tasksRequestObj });
        setEditIndex(null);
        hideErrorMessages();
      }}
      width={"40%"}
    >
      <div className="activity_popup-main px-4">
        <div className="mt-4">
          <InputRuleForm
            inputType="TEXT"
            placeholder="Enter Name"
            name="name"
            label={"Name"}
            value={getValue(request, `name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: e.target.value,
              })
            }
            hasRequired
            validator={simpleValidator}
            validLeft
          />
        </div>
        <div className="">
          <SearchToolTip
            label="label"
            lookup_api={"org_user"}
            selectKey={"id"}
            value={getValue(request, `owner`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                owner: getValue(e, `id`, ""),
              })
            }
            name="Owner"
            placeholder="Choose Owner"
            // width={470}
            required
            validator={simpleValidator}
          />
        </div>
        <div className="mt-2 d-flex align-items-center">
          <div className="w-50">
            <SearchToolTip
              label="label"
              selectKey={"value"}
              value={getValue(request, `dueDate`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  dueDate: getValue(e, `value`, ""),
                })
              }
              name="Due Date"
              data={dates}
              placeholder="Choose Due Date"
              required
              validator={simpleValidator}
            />
          </div>
          <p className="mt-3 small_text__22 mx-2">{"+"}</p>
          <div className="w-25 mt-1 ms-1">
            <InputRuleForm
              inputType="TEXT"
              placeholder="Enter Days"
              name="dueDateDays"
              label={"Days"}
              value={getValue(request, `dueDateDays`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  dueDateDays: handleChangeNumber(e),
                })
              }
              hasRequired
              validator={simpleValidator}
            />
          </div>
        </div>
        <div className="mt-1">
          <SearchToolTip
            label="label"
            data={[
              { value: "high", label: "High" },
              { value: "medium", label: "Medium" },
              { value: "low", label: "Low" },
            ]}
            selectKey={"value"}
            value={getValue(request, `priority`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                priority: getValue(e, `value`, ""),
              })
            }
            name="Priority"
            placeholder="Choose Priority"
            required
            validator={simpleValidator}
          />
        </div>

        {/* <div className="form_builder_field">
          <AlarmPicker
            onChange={(e: any) =>
              setRequest({
                ...request,
                reminderDate: e,
              })
            }
            label={`Reminder Date`}
            placeholder={`Enter Reminder Date`}
            showTimeInput
            mandatory
            dueDateTime={new Date()}
          />
        </div> */}
      </div>

      <SubmitContainer
        handleBackButton={() => {
          toggle();
          setRequest({ ...tasksRequestObj });
          setEditIndex(null);
          hideErrorMessages();
        }}
        handleSubmit={() => handleAddEmailToList()}
        submitLoading={submitLoading}
        first_title={"Cancel"}
        second_title={editIndex ? "Update" : "Save"}
        disableValidation
        permissions={['create']}
        // disabled={hasErrors ? true : false}
      />
    </SlideFromRightPopup>
  );
}

export default WorkflowTasksPopup;
