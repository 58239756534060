import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import React, { useState } from "react";
import "./createPipeline.scss";
import pluralize from "pluralize";
import InputRuleForm from "@components/InputRuleForm/form";

function PipelineStep1(props: any) {
  const { request, setRequest, simpleValidator, activeStep } = props;

  const modifyString = (e: any) => {
    let inputString: any = e.target.value;
    // 1. Change capital letters to lowercase
    // let modifiedString = inputString.toLowerCase();
    let modifiedString = capitalizeWords(inputString);

    // 2. Replace spaces with underscores
    // modifiedString = modifiedString.replace(/\s+/g, "_");

    // 3. Change to singular and plural form
    const words = modifiedString.split(" ");
    // modifiedString = words.map((word: any) => pluralize(word)).join("_");
    setRequest({
      ...request,
      name: e.target.value,
      singular_label: words
        .map((word: any) => pluralize.singular(word))
        .join(" "),
      plural_label: words.map((word: any) => pluralize(word)).join(" "),
    });
  };

  function capitalizeWords(str: any) {
    // Split the string into an array of words
    const words = str.split(" ");
    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word: any) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    // Join the capitalized words back into a single string
    const result = capitalizedWords.join(" ");

    return result;
  }

  const [isActive, setIsActive] = useState(false);

  return (
    <div className={"step_2_container"}>
      <div className={`form_wrapper-pipeline`}>
        {/* <h6 className="mb-2 header_blue_text__17">Pipeline Info</h6> */}
        <InputRuleForm
          inputType="TEXT"
          label={"Pipeline Name"}
          placeholder="Enter Pipeline Name"
          name={"name"}
          value={getValue(request, `name`, "")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // handleTextChange(e, request, setRequest)
            modifyString(e);
          }}
          validator={activeStep === 1 && simpleValidator}
          validLeft
          required
        />
        {/* {isActive ? (
          <>
            <h6 className="mt-4 mb-3 header_blue_text__17">
              What naming scheme do you prefer for the records within this
              pipeline?
            </h6>
            <InputRuleForm
              inputType="TEXT"
              label={"Prefix Singular"}
              placeholder="Deal, Ticket, Request etc"
              name={"singular_label"}
              value={getValue(request, `singular_label`, "")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, request, setRequest)
              }
              validator={activeStep === 1 && simpleValidator}
              validLeft
              required
            />
            <div className="mt-4"></div>
            <InputRuleForm
              inputType="TEXT"
              label={"Prefix Plural"}
              placeholder="Deals, Tickets, Requests etc"
              name={"plural_label"}
              value={getValue(request, `plural_label`, "")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, request, setRequest)
              }
              validator={activeStep === 1 && simpleValidator}
              validLeft
              required
            />
          </>
        ) : (
          <div>
            <h6 className="mt-4 mb-3 header_text__16">
              What naming convention do you prefer for the data points in this
              series? Here are the options:
              <br />
            </h6>
            <div className="">
              <div className="d-flex align-items-center mt-2">
                <p className="small_text__14  w-25">For a single item </p>
                <p className="small_text__14">
                  :{" "}
                  {getValue(request, `singular_label`, "")
                    ? getValue(request, `singular_label`, "")
                    : "-"}
                </p>
              </div>
              <div className="d-flex align-items-center mt-2">
                <p className="small_text__14  w-25">For multiple items </p>
                <p className="small_text__14">
                  :{" "}
                  {getValue(request, `plural_label`, "")
                    ? getValue(request, `plural_label`, "")
                    : "-"}
                </p>
              </div>
              <p
                className="header_blue_text__16 mt-4 cursor-pointer"
                onClick={() => setIsActive(!isActive)}
              >
                Would you like to customize these names to suit your preference?
              </p>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default PipelineStep1;
