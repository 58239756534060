import { getValue } from "@utils/lodash";
import DatePicker from "react-datepicker";
import { todayDate } from "common/date-helpers";
import { handleDateChange, handleSelectChange } from "common/handleChange";
import { useStateContext } from "@context/profileProvider";
import InlineEditTooltipComponent from "@components/Builder/InlineFormBuilder/EditFields";

function PendingLoanComponentSales(props: any) {
  const {
    submitLoading,
    handleSubmit,
    request,
    setRequest,
    popupFields,
    setPopupFields,
  } = props;
  const { orgInfo } = useStateContext();
  return (
    <div className={"form-container"}>
      <div
        className={
          "form-container_name mt-2 mb-2 detail_header_container pb-2 pt-2"
        }
      >
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="">
            <h5 className={"form-container_name-text"}>
              {getValue(props, `request.name`, "")}{" "}
            </h5>
            <div className={"form-container_desc mt-2 mb-2 ms-1"}>
              <p className={"form-container_desc-text"}>Amount: </p>
              {getValue(props, `request.amount`, 0) ? (
                <p className={`form-container_desc-text ms-2`}>
                  {getValue(orgInfo, `currency`, "")}{" "}
                  {getValue(props, `request.amount`, 0)
                    ? getValue(props, `request.amount`, 0)
                    : ""}
                </p>
              ) : (
                <p className="ms-3">-</p>
              )}
            </div>
          </div>
          <div className={"pending-wrapper-hover_edit_section"}>
            <InlineEditTooltipComponent
              position={"right top"}
              popupFields={popupFields}
              setPopupFields={setPopupFields}
              width={"335px"}
              submitLoading={submitLoading}
              handleSubmit={handleSubmit}
              permissions={getValue(props, `permissions`, [])}
              module={props.module}
            />
          </div>
        </div>
      </div>
      <>
        <div className={"form-container_desc mt-3 mb-2"}>
          <p className={"form-container_desc-text"}>Close Date: </p>
          <div className={`ms-2 position-relative`}>
            <DatePicker
              selected={
                getValue(props, `request.closing_date`, null)
                  ? todayDate(getValue(props, `request.closing_date`, null))
                  : null
              }
              onChange={(e: any) =>
                handleDateChange(e, "closing_date", request, setRequest)
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="d MMM yyyy"
              className="form_control-inline_datepicker border-radius__5"
              placeholderText="DD MMM YYYY"
            />
            <img
              src="/images/icons/calendar.svg"
              className={`form-group__input-icon img-fluid`}
            />
          </div>
        </div>
        {/* <div className={`form-container_desc align-items-center`}>
          <p className={"form-container_desc-text"}>Stage: </p>
          <div className={`ms-2`}>
            <TextHeaderTooltip
              width={"300px"}
              title={`Stage`}
              value={getValue(props, `request.stage_id`, "")}
              placeholder={`Choose Stage`}
              onChange={(e: any) =>
                handleSelectChange(e, "stage_id", request, setRequest)
              }
              label={`label`}
              data={
                getValue(props, `data.pipeline.stages.length`, 0) > 0
                  ? getValue(props, `data.pipeline.stages`, []).map(
                      (item: object) => ({
                        ...item,
                        label: getValue(item, `name`, ""),
                        display_text: getValue(item, `name`, ""),
                        value: getValue(item, `id`, ""),
                      })
                    )
                  : []
              }
              selectKey={"value"}
              editInline
            />
          </div>
        </div> */}
      </>
    </div>
  );
}

export default PendingLoanComponentSales;
