import ModuleAndField from "@pages/Private/Settings/ModuleAndField";
import ManageFields from "@pages/Private/Settings/ModuleAndField/ManageFields";
import SettingsModuleInfo from "@pages/Private/Settings/ModuleAndField/ManageFields/Info";
import SettingsModuleFields from "@pages/Private/Settings/ModuleAndField/ManageFields/Fields";
import SettingsModuleStages from "@pages/Private/Settings/ModuleAndField/ManageFields/Stages";

export const SettingsModulesAndFieldsRoutes = [
  {
    path: "/:orgId/settings/module-and-field",
    name: "settings_customisation_modules_and_fields",
    component: ModuleAndField,
  },
  {
    path: "/:orgId/settings/module-and-field/manage-fields/:id",
    name: "settings_customisation_modules_and_fields",
    component: ManageFields,
  },
  {
    path: "/:orgId/settings/module-and-field/manage-fields/info/:id/:code",
    name: "settings_customisation_modules_and_fields",
    component: SettingsModuleInfo,
  },
  {
    path: "/:orgId/settings/module-and-field/manage-fields/stages/:id/:code",
    name: "settings_customisation_modules_and_fields",
    component: SettingsModuleStages,
  },
  {
    path: "/:orgId/settings/module-and-field/manage-fields/fields/:id/:code",
    name: "settings_customisation_modules_and_fields",
    component: SettingsModuleFields,
  },
];
