import { getValue } from "@utils/lodash";
import InlineEditTooltipComponent from "@components/Builder/InlineFormBuilder/EditFields";
import { CopyToClipboard, concatString } from "@common/text-helpers";
import CopySvgComponents from "@assets/svg/copy";

function PendingLoanComponentCompanies(props: any) {
  const {
    submitLoading,
    handleSubmit,
    popupFields,
    setPopupFields,
  } = props;
  return (
    <div
      className={"d-flex align-items-center justify-content-between mt-3 mb-1"}
    >
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between detail_header_container pt-2 pb-2">
          <div className="">
            <h5 className={"form-container_name-text"}>
              {getValue(props, `request.name`, "")}{" "}
            </h5>
            {getValue(props, `request.email`, "") && (
              <p className="text-left small_text__14 mb-2 d-flex align-items-center mt-1">
                <span className="">
                  {concatString(getValue(props, `request.email`, ""), 25)}
                </span>
                <span
                  className="ms-2 cursor-pointer"
                  onClick={() =>
                    CopyToClipboard(getValue(props, `request.email`, ""))
                  }
                >
                  <CopySvgComponents size={18} color={"gray"} />
                </span>
              </p>
            )}
          </div>
          <div className={"pending-wrapper-hover_edit_section mx-1"}>
            <InlineEditTooltipComponent
              position={"right top"}
              popupFields={popupFields}
              setPopupFields={setPopupFields}
              width={"335px"}
              submitLoading={submitLoading}
              handleSubmit={handleSubmit}
              permissions={getValue(props, `permissions`, [])}
              module={props.module}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingLoanComponentCompanies;
