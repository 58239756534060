import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { convertDate } from "common/date-helpers";
import _ from "lodash";

export const FormRequestHelper = (fields: any) => {
  let sale: any = {};
  let custom_data: any = {};
  let length = getValue(fields, `length`, 0);
  for (let i = 0; i < length; i++) {
    if (getValue(fields, `[${i}].input_type`, "") === "lookup_association") {
      sale["associations"] = getValue(fields, `[${i}].value`, []).map(
        (item: string) => ({
          record_id: typeof item === "string" ? item : getValue(item, `id`, ""),
          api_name: getValue(fields, `[${i}].lookup.api_name`, ""),
        })
      );
    } else {
      if (getValue(fields, `[${i}].custom`, false)) {
        custom_data[getValue(fields, `[${i}].api_name`, "")] =
          findTypeValidation(getValue(fields, `[${i}]`, {}));
      } else {
        if (getValue(fields, `[${i}].api_name`, "") === "owner") {
          sale["owner_id"] = findTypeValidation(getValue(fields, `[${i}]`, {}));
        } else {
          sale[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
            getValue(fields, `[${i}]`, {})
          );
        }
      }
    }
  }
  let obj = {
    ...removeNullOrUndefinedProperties(sale),
    custom_data: removeNullOrUndefinedProperties(custom_data),
  };
  return removeNullOrUndefinedProperties(obj);
};

export const FormRequestHelperWithNullFields = (fields: any) => {
  let sale: any = {};
  let custom_data: any = {};
  let length = getValue(fields, `length`, 0);

  for (let i = 0; i < length; i++) {
    const inputType = getValue(fields, `[${i}].input_type`, "");
    if (inputType === "lookup_association") {
      sale["associations"] = getValue(fields, `[${i}].value`, []).map(
        (item: string) => ({
          record_id: typeof item === "string" ? item : getValue(item, `id`, ""),
          api_name: getValue(fields, `[${i}].lookup.api_name`, ""),
        })
      );
    } else {
      const apiName = getValue(fields, `[${i}].api_name`, "");
      const isCustom = getValue(fields, `[${i}].custom`, false);
      let fieldValue = getValue(fields, `[${i}]`, {});

      // Check for boolean_checkbox and convert to boolean
      if (inputType === "boolean_checkbox") {
        // fieldValue = !!getValue(fields, `[${i}].value`, false); // Convert to boolean
      }
      if (isCustom) {
        custom_data[apiName] = findTypeValidation(fieldValue);
      } else {
        if (apiName === "owner") {
          sale["owner_id"] = findTypeValidation(fieldValue);
        } else {
          sale[apiName] = findTypeValidation(fieldValue);
        }
      }
    }
  }

  let obj = {
    ...sale,
    custom_data: custom_data,
  };

  return obj;
};

export const FormRequestPayloadObj = (fields: any) => {
  let sale: any = {};
  let custom_data: any = {};
  let length = getValue(fields, `length`, 0);
  for (let i = 0; i < length; i++) {
    if (getValue(fields, `[${i}].input_type`, "") === "lookup_association") {
      sale["associations"] = getValue(fields, `[${i}].value`, []).map(
        (item: string) => ({
          record_id: typeof item === "string" ? item : getValue(item, `id`, ""),
          api_name: getValue(fields, `[${i}].lookup.api_name`, ""),
        })
      );
    } else {
      if (getValue(fields, `[${i}].custom`, false)) {
        custom_data[getValue(fields, `[${i}].api_name`, "")] =
          findTypeValidation(getValue(fields, `[${i}]`, {}));
      } else {
        if (getValue(fields, `[${i}].api_name`, "") === "owner") {
          sale["owner_id"] = findTypeValidation(getValue(fields, `[${i}]`, {}));
        } else {
          sale[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
            getValue(fields, `[${i}]`, {})
          );
        }
      }
    }
  }
  let customData = Object.keys(custom_data);
  let obj = {};
  if (getValue(customData, `length`, 0) > 0) {
    obj = {
      ...sale,
      custom_data: custom_data,
    };
  } else {
    obj = {
      ...sale,
    };
  }

  return obj;
};
export const FormRequestHelperWithModule = (
  fields: any,
  module: any,
  staticFieldRequest: any
) => {
  let key: any = getModuleName(module);
  key = {};
  let custom_data: any = {};
  let associations: any = [];
  let length = getValue(fields, `length`, 0);

  for (let i = 0; i < length; i++) {
    const inputType = getValue(fields, `[${i}].input_type`, "");
    const apiName = getValue(fields, `[${i}].api_name`, "");
    // const fieldType = getValue(fields, `[${i}].field_type`, "");
    if (inputType === "lookup_association") {
      const lookupValue = getValue(fields, `[${i}].value`, []);
      associations = lookupValue.map((item: any) => ({
        record_id: typeof item === "string" ? item : getValue(item, `id`, ""),
        api_name: getValue(fields, `[${i}].lookup.api_name`, ""),
      }));
    }
    //  else if (inputType === "tel") {
    //   if (
    //     isValidPhoneNumber(findTypeValidation(getValue(fields, `[${i}]`, {})))
    //   ) {
    //     key[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
    //       getValue(fields, `[${i}]`, {})
    //     );
    //   }
    // }
    else {
      if (getValue(fields, `[${i}].custom`, false)) {
        custom_data[apiName] = findTypeValidation(
          getValue(fields, `[${i}]`, {})
        );
      } else {
        if (apiName === "owner") {
          key["owner_id"] = findTypeValidation(getValue(fields, `[${i}]`, {}));
        } else {
          if (getValue(fields, `[${i}].api_name`, "") === "owner") {
            key["owner_id"] = findTypeValidation(
              getValue(fields, `[${i}]`, {})
            );
          } else {
            if (
              module !== "calls" &&
              module !== "meetings" &&
              module !== "tasks"
            ) {
              key[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
                getValue(fields, `[${i}]`, {})
              );
              key["pipeline_id"] = getValue(
                staticFieldRequest,
                `pipeline_id`,
                ""
              );
              key["pipeline_stage_id"] = getValue(
                staticFieldRequest,
                `stage`,
                ""
              );
            } else {
              key[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
                getValue(fields, `[${i}]`, {})
              );
            }
          }
        }
      }
    }
  }
  let obj = {
    [getModuleName(module)]: {
      ...removeNullOrUndefinedProperties(key),
      custom_data: removeNullOrUndefinedProperties(custom_data),
    },
    associations: associations,
  };
  return removeNullOrUndefinedProperties(obj);
};
export const FormRequestHelperWithModuleWithNullFields = (
  fields: any,
  module: any,
  staticFieldRequest: any
) => {
  let key: any = getModuleName(module);
  key = {};
  let custom_data: any = {};
  let associations: any = [];
  let length = getValue(fields, `length`, 0);

  for (let i = 0; i < length; i++) {
    const inputType = getValue(fields, `[${i}].input_type`, "");
    const apiName = getValue(fields, `[${i}].api_name`, "");
    // const fieldType = getValue(fields, `[${i}].field_type`, "");
    if (inputType === "lookup_association") {
      const lookupValue = getValue(fields, `[${i}].value`, []);
      associations = lookupValue.map((item: any) => ({
        record_id: typeof item === "string" ? item : getValue(item, `id`, ""),
        api_name: getValue(fields, `[${i}].lookup.api_name`, ""),
      }));
    }
    //  else if (inputType === "tel") {
    //   if (
    //     isValidPhoneNumber(findTypeValidation(getValue(fields, `[${i}]`, {})))
    //   ) {
    //     key[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
    //       getValue(fields, `[${i}]`, {})
    //     );
    //   }
    // }
    else {
      if (getValue(fields, `[${i}].custom`, false)) {
        custom_data[apiName] = findTypeValidation(
          getValue(fields, `[${i}]`, {})
        );
      } else {
        if (apiName === "owner") {
          key["owner_id"] = findTypeValidation(getValue(fields, `[${i}]`, {}));
        } else {
          if (getValue(fields, `[${i}].api_name`, "") === "owner") {
            key["owner_id"] = findTypeValidation(
              getValue(fields, `[${i}]`, {})
            );
          } else {
            if (
              module !== "calls" &&
              module !== "meetings" &&
              module !== "tasks"
            ) {
              key[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
                getValue(fields, `[${i}]`, {})
              );
              key["pipeline_id"] = getValue(
                staticFieldRequest,
                `pipeline_id`,
                ""
              );
              key["pipeline_stage_id"] = getValue(
                staticFieldRequest,
                `stage`,
                ""
              );
            } else {
              key[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
                getValue(fields, `[${i}]`, {})
              );
            }
          }
        }
      }
    }
  }

  let obj = {
    [getModuleName(module)]: {
      ...key,
      custom_data: custom_data,
    },
    associations: associations,
  };
  return obj;
};

export const FormRequestHelperWithoutAssociations = (
  fields: any,
  module: any
) => {
  let key: any = getModuleName(module);
  key = {};
  let custom_data: any = {};
  let length = getValue(fields, `length`, 0);

  for (let i = 0; i < length; i++) {
    const inputType = getValue(fields, `[${i}].input_type`, "");
    const apiName = getValue(fields, `[${i}].api_name`, "");
    // const fieldType = getValue(fields, `[${i}].field_type`, "");
    if (getValue(fields, `[${i}].custom`, false)) {
      custom_data[apiName] = findTypeValidation(getValue(fields, `[${i}]`, {}));
    } else {
      if (apiName === "owner") {
        key["owner_id"] = findTypeValidation(getValue(fields, `[${i}]`, {}));
      } else {
        if (getValue(fields, `[${i}].api_name`, "") === "owner") {
          key["owner_id"] = findTypeValidation(getValue(fields, `[${i}]`, {}));
        } else {
          if (
            module !== "calls" &&
            module !== "meetings" &&
            module !== "tasks"
          ) {
            key[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
              getValue(fields, `[${i}]`, {})
            );
          } else {
            key[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
              getValue(fields, `[${i}]`, {})
            );
          }
        }
      }
    }
  }
  let obj = {
    [getModuleName(module)]: {
      ...removeNullOrUndefinedProperties(key),
      custom_data: removeNullOrUndefinedProperties(custom_data),
    },
  };
  return removeNullOrUndefinedProperties(obj);
};

export const FormRequestHelperWithNullData = (fields: any) => {
  let sale: any = {};
  let custom_data: any = {};
  let length = getValue(fields, `length`, 0);
  for (let i = 0; i < length; i++) {
    if (getValue(fields, `[${i}].custom`, false)) {
      custom_data[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
        getValue(fields, `[${i}]`, {})
      );
    } else {
      if (getValue(fields, `[${i}].api_name`, "") === "owner") {
        sale["owner_id"] = findTypeValidation(getValue(fields, `[${i}]`, {}));
      } else {
        sale[getValue(fields, `[${i}].api_name`, "")] = findTypeValidation(
          getValue(fields, `[${i}]`, {})
        );
      }
    }
  }
  return {
    ...sale,
    custom_data: custom_data,
  };
};

const findTypeValidation = (val: any) => {
  switch (getValue(val, `input_type`, "")) {
    case "text":
      return getValue(val, `value`, "");
    case "currency":
    case "percent":
    case "integer":
    case "decimal":
    case "auto_number":
    case "autonumber":
    case "bigint":
      // case "tel":
      return getValue(val, `value`, 0)
        ? parseFloat(getValue(val, `value`, 0))
        : 0;
    case "tel":
      return getValue(val, `value`, "");
    case "date":
      return getValue(val, `value`, "")
        ? convertDate(getValue(val, `value`, ""))
        : null;
    case "datetime":
    case "datetime_alarm":
      return getValue(val, `value`, "") ? getValue(val, `value`, "") : null;
    case "boolean_checkbox":
    case "boolean_select":
    case "boolean_radio":
      return getValue(val, `value`, "") ? true : false;

    default:
      return getValue(val, `value`, "");
  }
};

//Generating Payload:
//Creating Sub Pipeline

const getModuleName = (name: string) => {
  switch (name) {
    case "contacts":
      return "contact";
    case "companies":
      return "company";
    case "sales":
    case "deals":
      return "deal";
    case "calls":
      return "call";
    case "meetings":
      return "meeting";
    case "tasks":
      return "task";
    case "leads":
      return "lead";
    default:
      return "";
  }
};
// FormRequestHelperWithoutAssociations
export const generatePayloadWithoutAssociations = (
  module: string,
  fields: any
) => {
  let mainFields = JSON.parse(JSON.stringify(fields));
  let fieldsObj: any = FormRequestHelperWithoutAssociations(mainFields, module);
  switch (module) {
    case "contacts":
    case "sales":
    case "companies":
    case "deals":
    case "calls":
    case "meetings":
    case "tasks":
      return {
        ...fieldsObj,
      };

    default:
      return {
        ...fieldsObj,
      };
  }
};
export const generatePayload = (
  module: string,
  fields: any,
  staticFieldRequest: any
) => {
  let mainFields = JSON.parse(JSON.stringify(fields));
  let fieldsObj: any = FormRequestHelperWithModule(
    mainFields,
    module,
    staticFieldRequest
  );
  switch (module) {
    // case "contacts":
    // case "sales":
    // case "companies":
    // case "deals":
    //   return {
    //     [getModuleName(module)]: {
    //       ...fieldsObj,
    //       pipeline_id: getValue(staticFieldRequest, `pipeline_id`, ""),
    //       pipeline_stage_id: getValue(staticFieldRequest, `stage`, ""),
    //     },
    //   };

    case "contacts":
    case "sales":
    case "companies":
    case "deals":
    case "calls":
    case "meetings":
    case "tasks":
    case "leads":
      return {
        ...fieldsObj,
        // pipeline_id: getValue(staticFieldRequest, `pipeline_id`, ""),
        // pipeline_stage_id: getValue(staticFieldRequest, `stage`, ""),
      };

    default:
      return {
        ...fieldsObj,
        pipeline_id: getValue(staticFieldRequest, `pipeline_id`, ""),
        pipeline_stage_id: getValue(staticFieldRequest, `stage`, ""),
      };
  }
};

export const generatePipelineAssociationPayload = (
  module: string,
  fields: any,
  staticFieldRequest: any,
  associations: any
) => {
  let mainFields = JSON.parse(JSON.stringify(fields));
  let fieldsObj: any = FormRequestHelper(
    mainFields
    // module,
    // staticFieldRequest
  );
  switch (module) {
    case "contacts":
    case "sales":
    case "companies":
    case "deals":
    case "leads":
      return {
        [getModuleName(module)]: {
          ...fieldsObj,
          pipeline_stage_id: getValue(staticFieldRequest, `stage`, ""),
          pipeline_id: getValue(staticFieldRequest, `pipeline_id`, ""),
        },
        associations: associations,
      };

    case "tasks":
      return {
        [getModuleName(module)]: {
          ...fieldsObj,
          pipeline_id: getValue(staticFieldRequest, `pipeline_id`, ""),
        },
      };
    default:
      return {
        ...fieldsObj,
        pipeline_id: getValue(staticFieldRequest, `pipeline_id`, ""),
        pipeline_stage_id: getValue(staticFieldRequest, `stage`, ""),
      };
  }
};

const findValue = (name: string, value: string) => {
  switch (name) {
    case "date":
      return value ? new Date(value) : null;
    default:
      return value;
  }
};

export const removeDuplicatesById = (fields: any) => {
  if (getValue(fields, `length`, 0) > 0) {
    const uniqueData = _.uniqBy(fields, "id");
    return uniqueData;
  } else {
    return [];
  }
};
export function appendObjectValuesToArray(array: any, obj: any) {
  let object = { ...obj, ...obj.custom_data };
  for (const item of array) {
    const fieldName = item.api_name;
    if (fieldName === "owner") {
      item.value = findValue(object["owner_id"], object["owner_id"]);
    } else if (getValue(item, `input_type`, "") === "lookup_association") {
      let values: any = findValue(object[fieldName], object[fieldName]);
      let list =
        getValue(values, `length`, 0) > 0
          ? values.map((items: object) => getValue(items, `id`, ""))
          : [];
      item.value = list;
    } else {
      if (
        findValue(object[fieldName], object[fieldName]) ===
        "1970-01-01T00:00:00.000Z"
      ) {
        item.value = null;
      } else if (object[fieldName] !== undefined) {
        item.value = findValue(object[fieldName], object[fieldName]);
      }
    }
  }
  return removeDuplicatesById(array);
}
