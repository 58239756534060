import SettingsRoles from "@pages/Private/Settings/Roles";
import SettingsRoleDetails from "@pages/Private/Settings/Roles/Role-Details";

export const SettingsRolesRoutes = [
  {
    path: "/:orgId/settings/roles",
    name: "settings_organisation_users",
    component: SettingsRoles,
  },
  {
    path: "/:orgId/settings/roles/add",
    name: "settings_organisation_users",
    component: SettingsRoleDetails,
  },
  {
    path: "/:orgId/settings/roles/:id",
    name: "settings_organisation_users",
    component: SettingsRoleDetails,
  },
];
