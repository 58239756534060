import SettingsLayout from "@layouts/Layout/settings-layout";
import React, { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import ModuleSettingNavViews from "../components/nav-views";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  addOrgEmails,
  getAllOrgEmails,
  getInboxDomains,
} from "@services/email-config.service";
import { getValue } from "@utils/lodash";
import NewEmailPopup from "./new-email-popup";
import { toast } from "sonner";
import ListLoader from "@components/common/Loader";
import SimpleReactValidator from "simple-react-validator";
import EmailssNoDataPage from "@components/common/NoData/email-nodata";
import { Button } from "antd";

function SettingsOrgEmails(props: any) {
  const [request, setRequest] = useState({
    id: "",
    domainId: "",
    email: "",
    isActive: true,
    orgUsers: [""],
  });

  const reset = () => {
    setRequest({
      ...request,
      id: "",
      domainId: "",
      email: "",
      isActive: true,
      orgUsers: [""],
    });
  };
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               useEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getOrgEmails();
    getConfig();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [orgEmailList, setOrgEmailList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getOrgEmails = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading(true);
      let resp = await getAllOrgEmails(queryRequest);
      if (resp) {
        setOrgEmailList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [domains, setdomains] = useState({});
  const getConfig = async () => {
    try {
      let resp = await getInboxDomains("");
      if (resp) {
        setdomains(
          getValue(resp, `data`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `domain`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };
  /* -------------------------------------------------------------------------- */
  /*                             Modal Section                                  */
  /* -------------------------------------------------------------------------- */
  const [emailLoading, setemailLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setemailLoading(true);
        let resp = await addOrgEmails(request);
        if (resp) {
          toast.success("Added successfully");
          getOrgEmails();
          reset();
          handleModalOpen();
          setemailLoading(false);
        } else {
          setemailLoading(false);
        }
      } catch (error) {
        setemailLoading(false);
      }
    }
  };

  return (
    <SettingsLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <Button size="large" type="primary" onClick={handleModalOpen}>
            New Email
          </Button>
        }
      />
      <div className={`email_container w-100`}>
        <CompanyProfileNavigation />
        <div className={`email_body_container w-100 p-4`}>
          <ModuleSettingNavViews />

          <div className="mt-4 d-flex align-items-center">
            <p className="small_text__14 w-50">
              Here, you can add your organization's various departmental emails
              to allow your team to communicate with users through them.These
              email addresses will show up in the 'From' and 'Reply To' fields
              when you compose emails in Appzo. Before you can use these email
              addresses, you will first need to verify them.
            </p>
          </div>

          {isLoading ? (
            <ListLoader />
          ) : getValue(orgEmailList, `length`, 0) > 0 ? (
            <table className={`common_table w-100 mt-4`}>
              <thead>
                <tr>
                  <td>Email</td>
                  <td>Created By</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {orgEmailList.map((item: object) => {
                  return (
                    <tr>
                      <td>{getValue(item, `email`, "")}</td>
                      <td>
                        {getValue(item, `created_by.first_name`, "")}{" "}
                        {getValue(item, `created_by.last_name`, "")}
                      </td>
                      <td>
                        {getValue(item, `is_active`, false)
                          ? "Active"
                          : "Inactive"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="mt-3">
              <EmailssNoDataPage />
            </div>
          )}
        </div>
      </div>

      <NewEmailPopup
        isOpen={isModalOpen}
        request={request}
        setRequest={setRequest}
        handleModal={handleModalOpen}
        handleSubmit={handleSubmit}
        isLoading={emailLoading}
        domains={domains}
        validator={simpleValidator}
      />
    </SettingsLayout>
  );
}

export default SettingsOrgEmails;
