import { getValue } from "@utils/lodash";

const SheetNumberEditor = ({
  col,
  row,
  handleDisplayItem,
  handleInputChange,
}: any) => {
  return (
    <input
      type="number"
      value={handleDisplayItem(col, row)}
      onChange={(e) =>
        handleInputChange(getValue(row, "id", ""), col, e.target.value)
      }
      className="sheet-view-input"
    />
  );
};

export default SheetNumberEditor;
