import { Button, Drawer, Radio, Switch } from "antd";
import React, { useEffect, useState } from "react";
import {
  DashboardDateGranularityList,
  DashboardGraphTypeList,
  DashboardGroupingList,
  DashboardShowRankChartList,
} from "../helpers/enum";
import { getValue } from "@utils/lodash";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";

function ComponentChartDrawer(props: any) {
  const {
    request,
    setRequest,
    getAllPipelines,
    allPipelines,
    setNewModule,
    newModule,
    allModules,
    getSpecificPipelineInfo,
    dateColumnFields,
    isLoading,
    handleSubmit,
    onClose,
    showChartStyleComponentDrawer,
    formColumnFields,
  } = props;

  const findName = (name: string) => {
    let info: any = DashboardGraphTypeList.find(
      (item: object) => getValue(item, `value`, "") === name
    );
    return getValue(info, `label`, "");
  };

  const handleChangeStyle = () => {
    setRequest((prevState: any) => ({
      ...prevState,
      graph_type: "",
      is_child: false,
      benchmark: 0,
      maximum_grouping: 0,
      child_module_field: "",
      child_date_granularity: "",
      date_granularity: "",
      module_field: "",
    }));
    setShowDate(false);
    setStackedChart(false);
    onClose();
    showChartStyleComponentDrawer();
  };

  const findGroupLabel = () => {
    switch (getValue(request, `graph_type`, "")) {
      case "donut_chart":
      case "pie_chart":
        return "(Slices)";
      case "column_chart":
      case "bar_chart":
      case "line_chart":
        return "(X-Axis)";
      case "table_chart":
        return "(1st Column)";
      case "funnel_chart":
        return "(Stages)";
      case "area_chart":
      case "heat_map":
        return "1 (Y-Axis)";
      case "line_chart":
        return "(X-Axis)";
      default:
        return "";
    }
  };

  const findStackedGroupLabel = () => {
    switch (getValue(request, `graph_type`, "")) {
      case "donut_chart":
      case "pie_chart":
        return "(Slices)";
      case "column_chart":
      case "bar_chart":
      case "line_chart":
        return "(Y-Axis)";
      case "funnel_chart":
        return "(Stages)";
      case "area_chart":
      case "heat_map":
        return "2";
      case "line_chart":
        return "(Y-Axis)";
      default:
        return "";
    }
  };

  const [showDate, setShowDate] = useState(false);
  const [stackedChart, setStackedChart] = useState(false);
  const [stackedChartDate, setStackedChartDate] = useState(false);

  useEffect(() => {
    if (
      getValue(request, `graph_type`, "") === "area_chart" ||
      getValue(request, `graph_type`, "") === "heat_map"
    ) {
      setStackedChart(true);
    }
  }, [
    getValue(request, `graph_type`, "") === "area_chart",
    getValue(request, `graph_type`, "") === "heat_map",
  ]);

  console.log(request.is_child);
  return (
    <Drawer
      title={findName(props.selectedComponentOption)}
      onClose={props.onClose}
      open={props.open}
      width={720}
      className="dashboard-slider-container-footer"
    >
      <div className="">
        <div className="d-flex justify-content-between align-items-center mb-3">
          {getValue(request, `graph_type`, "") !== "donut_chart" &&
          getValue(request, `graph_type`, "") !== "pie_chart" &&
          getValue(request, `graph_type`, "") !== "table_chart" &&
          getValue(request, `graph_type`, "") !== "funnel_chart" &&
          getValue(request, `graph_type`, "") !== "area_chart" &&
          getValue(request, `graph_type`, "") !== "heat_map" ? (
            // <Switch
            //   size="small"
            //   checked={stackedChart}
            //   onChange={() => {
            //     setRequest((prevState: any) => ({
            //       ...prevState,
            //       is_child: !stackedChart ? true : false,
            //       child_module_field: "",
            //       child_date_granularity: "",
            //     }));
            //     setStackedChart(!stackedChart);
            //   }}
            //   checkedChildren="Stacked Chart"
            //   unCheckedChildren="Bar Chart"
            // />
            <Radio.Group
              value={stackedChart ? "stacked" : "bar"}
              onChange={(e) => {
                const isStacked = e.target.value === "stacked";
                setRequest((prevState: any) => ({
                  ...prevState,
                  is_child: isStacked,
                  child_module_field: "",
                  child_date_granularity: "",
                }));
                setStackedChart(isStacked);
              }}
              buttonStyle="solid"
            >
              <Radio.Button value="stacked">Stacked Chart</Radio.Button>
              <Radio.Button value="bar">Bar Chart</Radio.Button>
            </Radio.Group>
          ) : (
            <div></div>
          )}
          <h6
            className="header_text__16 color_primary cursor-pointer"
            onClick={() => handleChangeStyle()}
          >
            Change Style
          </h6>
        </div>
        <div className="d-grid gap-3">
          <div className="bg-white rounded-3 p-4">
            <h6 className="header_text__20 ">Component</h6>
            <div className="mb-3">
              <InputRuleForm
                inputType="TEXT"
                label="Component Name"
                placeholder="Enter Component Name"
                name="name"
                value={getValue(request, `name`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div className="d-flex align-items-center gap-3">
              <div className="mb-3">
                <SearchToolTip
                  name="Module"
                  data={getValue(props, `allModules`, [])}
                  label="label"
                  selectKey="id"
                  value={getValue(request, `module`, "")}
                  onChange={(e: any) => {
                    getAllPipelines(getValue(e, `api_name`, ""), allModules);
                    setNewModule(getValue(e, `api_name`, ""));
                    setRequest({
                      ...request,
                      module: getValue(e, `id`, ""),
                      pipeline: "",
                    });
                  }}
                />
              </div>
              {(newModule === "deals" || newModule === "leads") && (
                <div className="mb-3">
                  <SearchToolTip
                    name="Pipeline"
                    label="label"
                    selectKey="id"
                    value={getValue(request, `pipeline`, "")}
                    data={allPipelines}
                    onChange={(e: any) => {
                      getSpecificPipelineInfo(getValue(e, `id`, ""));
                      setRequest({
                        ...request,
                        pipeline: getValue(e, `id`, ""),
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div className=" align-items-center w-100 gap-3">
              <div className="d-flex align-items-center w-100 gap-3">
                <Switch
                  size="small"
                  checked={showDate}
                  onChange={() => setShowDate(!showDate)}
                  checkedChildren="Date Fields"
                  unCheckedChildren="Form Fields"
                />
                {showDate ? (
                  <>
                    <div className="mb-3">
                      <SearchToolTip
                        name={`Grouping ${findGroupLabel()}`}
                        placeholder={`Enter Grouping ${findGroupLabel()}`}
                        selectKey="id"
                        data={dateColumnFields}
                        label="label"
                        value={getValue(request, `module_field`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            module_field: getValue(e, `id`, ""),
                          })
                        }
                      />
                    </div>
                    <div className="mb-3 w-50 mt-4">
                      <SearchToolTip
                        name=""
                        data={DashboardDateGranularityList}
                        label="label"
                        selectKey="value"
                        value={getValue(request, `date_granularity`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            date_granularity: getValue(e, `value`, ""),
                          })
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-3">
                      <SearchToolTip
                        name={`Grouping ${findGroupLabel()}`}
                        placeholder={`Enter Grouping ${findGroupLabel()}`}
                        selectKey="id"
                        data={formColumnFields}
                        label="label"
                        value={getValue(request, `module_field`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            date_granularity: "",
                            module_field: getValue(e, `id`, ""),
                          })
                        }
                      />
                    </div>
                  </>
                )}
              </div>
              {stackedChart && (
                <div className="d-flex align-items-center w-100 gap-3">
                  <Switch
                    size="small"
                    checked={stackedChartDate}
                    onChange={() => setStackedChartDate(!stackedChartDate)}
                    checkedChildren="Date Fields"
                    unCheckedChildren="Form Fields"
                  />
                  {stackedChartDate ? (
                    <>
                      <div className="mb-3">
                        <SearchToolTip
                          name={`Grouping ${findStackedGroupLabel()}`}
                          placeholder={`Enter Grouping ${findStackedGroupLabel()}`}
                          selectKey="id"
                          data={dateColumnFields}
                          label="label"
                          value={getValue(request, `child_module_field`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              child_module_field: getValue(e, `id`, ""),
                            })
                          }
                        />
                      </div>
                      <div className="mb-3 w-50 mt-4">
                        <SearchToolTip
                          name=""
                          data={DashboardDateGranularityList}
                          label="label"
                          selectKey="value"
                          value={getValue(
                            request,
                            `child_date_granularity`,
                            ""
                          )}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              child_date_granularity: getValue(e, `value`, ""),
                            })
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-3">
                        <SearchToolTip
                          name={`Grouping ${findStackedGroupLabel()}`}
                          placeholder={`Enter Grouping ${findStackedGroupLabel()}`}
                          selectKey="id"
                          data={formColumnFields}
                          label="label"
                          value={getValue(request, `child_module_field`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              child_date_granularity: "",
                              child_module_field: getValue(e, `id`, ""),
                            })
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="bg-white rounded-3 p-4">
            <h6 className="header_text__20">More Options</h6>
            <div className="mb-3">
              <SearchToolTip
                name="Sort By"
                data={DashboardShowRankChartList}
                label="label"
                selectKey="value"
                value={getValue(request, `show_rank`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    show_rank: getValue(e, `value`, ""),
                  })
                }
              />
            </div>
            <div className="mb-3">
              <SearchToolTip
                name="Maximum grouping"
                placeholder="Enter Maximum grouping"
                selectKey="value"
                data={DashboardGroupingList}
                label="label"
                value={getValue(request, `maximum_grouping`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    maximum_grouping: getValue(e, `value`, ""),
                  })
                }
              />
            </div>
            {getValue(request, `graph_type`, "") !== "donut_chart" &&
              getValue(request, `graph_type`, "") !== "pie_chart" &&
              getValue(request, `graph_type`, "") !== "table_chart" &&
              getValue(request, `graph_type`, "") !== "funnel_chart" &&
              getValue(request, `graph_type`, "") !== "heat_map" && (
                <div className="mb-3">
                  <InputRuleForm
                    inputType="NUMBER"
                    label="Benchmark for y-axis"
                    placeholder="Enter Benchmark for y-axis"
                    name="benchmark"
                    value={getValue(request, `benchmark`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        benchmark: e.target.value,
                      })
                    }
                  />
                </div>
              )}
          </div>
        </div>
      </div>

      <div className="text-right mt-2 ant-slider-custom-footer gap-2">
        <Button size="large" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isLoading}
        >
          Submit
        </Button>
      </div>
    </Drawer>
  );
}

export default ComponentChartDrawer;
