import { GlobalOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Badge, List, Modal, Radio } from "antd";

const BotTypeModal = (props: any) => {
  const {
    isModalOpen,
    handleChangeBotType,
    selectedBotType,
    handleCancel,
    handleSelectBotType,
  } = props;

  const websiteList = [
    {
      title: "This can be added to your website or mobile site.",
    },
    {
      title: "You can build the flow and add script to your website",
    },
    {
      title: "Responses are captured here.",
    },
  ];

  const whatsappList = [
    {
      title: "You can create a bot for your Whatsapp.",
    },
    {
      title: "You need to have a facebook business account.",
    },
    {
      title: "You need to have dedicated mobile number to activate this bot.",
    },
  ];

  return (
    <Modal
      title="Select Bot Type"
      okText="Create bot"
      open={isModalOpen}
      onOk={handleSelectBotType}
      onCancel={handleCancel}
      width={600}
    >
      <Radio.Group
        defaultValue="a"
        size="large"
        className="w-100 d-grid grid-container custom-bot-type-selector my-4 gap-3"
        value={selectedBotType}
        onChange={handleChangeBotType}
      >
        <Radio.Button value="web" className="flex-grow-1 p-3">
          <div>
            <h6 className="header_text__14 text-center">Create Web Bot</h6>
            <div className="d-flex justify-content-center mt-2 mb-3">
              <GlobalOutlined style={{ color: "#1DA1F2", fontSize: "30px" }} />
            </div>
            {websiteList.map((item: any) => (
              <Badge
                status="processing"
                text={item.title}
                className="bot-type-badge"
              />
            ))}
          </div>
        </Radio.Button>
        <Radio.Button value="whatsapp" className="flex-grow-1 p-3">
          <div>
            <h6 className="header_text__14 text-center">Create Whatsapp Bot</h6>
            <div className="d-flex justify-content-center mt-2 mb-3">
              <WhatsAppOutlined
                style={{ color: "#25D366", fontSize: "30px" }}
              />
            </div>
            {whatsappList.map((item: any) => (
              <Badge
                status="processing"
                text={item.title}
                className="bot-type-badge"
              />
            ))}
          </div>
        </Radio.Button>
      </Radio.Group>
    </Modal>
  );
};

export default BotTypeModal;
