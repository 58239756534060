import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import InputRuleForm from "@components/InputRuleForm/form";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import MultiSearchTooltip from "@components/custom/Dropdown/MultiSearchTooltip";
import { sendEmailRequestObj } from "../workflow-request";
import { handleObjectValidation } from "@components/custom/validation";
import SimpleReactValidator from "simple-react-validator";
import { useRef, useState } from "react";

function SendEmailPopup(props: any) {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const {
    isOpen,
    title,
    toggle,
    request,
    setRequest,
    submitLoading,
    mainRequest,
    setMainRequest,
    emailUsersList,
    editIndex,
    setEditIndex,
  } = props;

  const handleAddEmailToList = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (editIndex) {
        let index = editIndex - 1;
        const updatedData = [...mainRequest.actions.emails];
        updatedData[index] = request;
        setMainRequest((prevState: any) => ({
          ...prevState,
          actions: {
            ...prevState.actions,
            emails: updatedData,
          },
        }));
        toggle();
        setRequest({ ...sendEmailRequestObj });
        setEditIndex(null);
      } else {
        if (getValue(request, `name`, "")) {
          mainRequest.actions.emails.push(request);
          setMainRequest({ ...mainRequest });
          setRequest({ ...sendEmailRequestObj });
          toggle();
        } else {
          toast.error("Please input required fields");
        }
      }
      hideErrorMessages();
    }
  };

  const hideErrorMessages = () => {
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };

  const handleUpdateArray = (value: string, values: any) => {
    let options = getValue(values, `length`, 0) > 0 ? values : [];
    // Ensure 'options' is an array and contains only string elements
    if (
      !Array.isArray(options) ||
      options.some((item) => typeof item !== "string")
    ) {
      throw new Error(
        "'options' must be an array containing only string elements."
      );
    }
    // Check if 'value' exists in the 'options' array
    const foundIndex = options.indexOf(value);
    const array = [...options]; // Create a copy of 'options' to avoid mutation
    if (foundIndex !== -1) {
      // Value exists in the array, remove it
      array.splice(foundIndex, 1);
    } else {
      // Value doesn't exist, add it to the array
      array.push(value);
    }
    return array;
  };

  const errors = handleObjectValidation(request, [
    "name",
    "fromMail",
    "replyToMail",
    "emailUsers",
    "templateId",
  ]);
  const hasErrors = errors.length > 0;
  return (
    <SlideFromRightPopup
      isPopupVisible={isOpen}
      addCustomClass={"rolls-popup"}
      title={title}
      toggle={() => {
        toggle();
        setRequest({ ...sendEmailRequestObj });
        setEditIndex(null);
        hideErrorMessages();
      }}
      width={"40%"}
    >
      <div className="activity_popup-main px-4">
        <div className="mt-4">
          <InputRuleForm
            inputType="TEXT"
            placeholder="Enter Name"
            name="name"
            label={"Name"}
            value={getValue(request, `name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: e.target.value,
              })
            }
            hasRequired
            validator={simpleValidator}
            validLeft
          />
          {/* <FiedValidation
            type={"text"}
            value={getValue(request, `name`, "")}
            label={"Name"}
          /> */}
        </div>
      
        <div className="mt-3">
          {/* <InputRuleForm
            inputType="EMAIL"
            placeholder="Enter From Mail"
            name="fromMail"
            label={"From Mail"}
            value={getValue(request, `fromMail`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                fromMail: e.target.value,
              })
            }
            hasRequired
            validator={simpleValidator}
            validLeft
          /> */}
          <SearchToolTip
            data={getValue(props, `fromMails`, [])}
            label={"label"}
            name={"From Mail"}
            required
            value={getValue(request, `from`, "")}
            // onChange={handleClickPipeline}
            // isLoading={isLoading}
            onChange={(e: any) =>
              setRequest({
                ...request,
                from: getValue(e, `id`, ""),
              })
            }
            selectKey={"id"}
            validator={simpleValidator}
          />
        </div>
        <div className="mt-4">
          <InputRuleForm
            inputType="TEXT"
            placeholder="Enter Display Name"
            name="displayName"
            label={"Display Name"}
            value={getValue(request, `displayName`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                displayName: e.target.value,
              })
            }
            // hasRequired
            // validator={simpleValidator}
            validLeft
          />
          {/* <FiedValidation
            type={"text"}
            value={getValue(request, `name`, "")}
            label={"Name"}
          /> */}
        </div>
        <div className="mt-3">
          {/* <InputRuleForm
            inputType="EMAIL"
            placeholder="Enter Reply to Mail"
            name="replyToMail"
            label={"Reply to Mail"}
            value={getValue(request, `replyToMail`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                replyToMail: e.target.value,
              })
            }
            hasRequired
            validator={simpleValidator}
            validLeft
          /> */}

          <SearchToolTip
            data={emailUsersList}
            label={"label"}
            name={"Reply to Mail"}
            required
            value={getValue(request, `replyToMail`, "")}
            // onChange={handleClickPipeline}
            // isLoading={isLoading}
            onChange={(e: any) =>
              setRequest({
                ...request,
                replyToMail: getValue(e, `value`, ""),
              })
            }
            selectKey={"value"}
            validator={simpleValidator}
          />
          {/* <FiedValidation
            type={"email"}
            value={getValue(request, `replyToMail`, "")}
            label={"Reply to Mail"}
          /> */}
        </div>
        <div className="mt-3">
          <MultiSearchTooltip
            label="label"
            // lookup_api={"email_templates"}
            data={emailUsersList}
            selectKey={"id"}
            value={getValue(request, `emailUsers`, [])}
            // value={['owner']}
            onChange={(e: any) =>
              setRequest({
                ...request,
                emailUsers: handleUpdateArray(
                  getValue(e, `value`, ""),
                  getValue(request, `emailUsers`, [])
                ),
              })
            }
            name="Email Users"
            placeholder="Choose Email Template"
            // width={470}
            hasRequired
            validator={simpleValidator}
          />
          {/* <FiedValidation
            type={"array"}
            value={getValue(request, `emailUsers`, "")}
            label={"Email Users"}
          /> */}
        </div>
        <div className="mt-3">
          <InputRuleForm
            inputType="TEXT"
            placeholder="Use commas to seperate email addresses"
            name="recipients"
            label={"Additional Recipients"}
            value={getValue(request, `recipients`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                recipients: e.target.value,
              })
            }
          />
          {/* <FiedValidation
            type={"text"}
            value={getValue(request, `recipients`, "")}
            label={"Additional Recipients"}
          /> */}
        </div>
        <div className="mt-3">
          <SearchToolTip
            label="label"
            lookup_api={"email_templates"}
            selectKey={"id"}
            value={getValue(request, `templateId`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                templateId: getValue(e, `id`, ""),
              })
            }
            forModule={props.module}
            name="Email Template"
            placeholder="Choose Email Template"
            hasRequired
            validator={simpleValidator}
          />
          {/* <FiedValidation
            type={"text"}
            value={getValue(request, `templateId`, "")}
            label={"Email Template"}
          /> */}
        </div>
      </div>
      <SubmitContainer
        handleBackButton={() => {
          toggle();
          setRequest({ ...sendEmailRequestObj });
          setEditIndex(null);
          hideErrorMessages();
        }}
        handleSubmit={() => handleAddEmailToList()}
        submitLoading={submitLoading}
        first_title={"Cancel"}
        second_title={editIndex ? "Update" : "Save"}
        disableValidation
        permissions={['create']}
        // disabled={hasErrors ? true : false}
      />
    </SlideFromRightPopup>
  );
}

export default SendEmailPopup;
