import { Link } from "react-router-dom";
import { getValue } from "@utils/lodash";
import Logo from "@components/common/Logo/Logo";
// import SignInDropdownOrg from "./SignInDropdown/SignInDropdownOrg";
import "../HomeHeader/Header.scss";
import SignInDropdownOrg from "../HeaderSignin/SignInDropdownOrg";
import { useStateContext } from "@context/profileProvider";
import { getFirstLetterOfWord } from "@common/text-helpers";
import { Menu } from "antd";
import { useState } from "react";

export default function OrgHeader() {
  const navigationArray: any = [];
  let activeRoute = window.location.pathname.split("/")[2];
  const { userInfo } = useStateContext();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = `/signin`;
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopupVisible = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const menu = (
    <Menu>
      <Menu.Item key="signout">
        <div className="small_text__14" onClick={handleLogout}>
          Signout
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className={"header-wrapper"}>
      <div
        className={`d-flex align-items-center justify-content-between w-100`}
      >
        {/* <Logo /> */}
        <nav className={"header-wrapper__navigation"}>
          <ul className={"header-wrapper__navigation-list"}>
            {navigationArray.map((navObj: any, index: number) => {
              const { subMenu } = navObj;
              return (
                <li
                  className={"header-wrapper__navigation-list-items"}
                  key={`navigation-${index}`}
                >
                  <Link to={navObj.linkUrl}>
                    <div
                      className={
                        getValue(navObj, `activeRoute`, "") == activeRoute
                          ? "header-wrapper__navigation-title_active"
                          : "header-wrapper__navigation-title"
                      }
                    >
                      <p>{navObj.linkText}</p>
                      <img
                        src={navObj.image}
                        className={"header-wrapper__navigation-arrow"}
                        alt=""
                      />
                      <div className={"header-wrapper__submenu"}>
                        <ul>
                          {subMenu?.map((subMenuObj: any, index: number) => {
                            return (
                              <li
                                className={"header-wrapper__submenu-list-items"}
                                key={`menu-${index}`}
                              >
                                <Link
                                  to={subMenuObj.linkUrl}
                                  className={
                                    "header-wrapper__submenu-list-title"
                                  }
                                >
                                  {subMenuObj.linkText}
                                  <img
                                    src={subMenuObj.image}
                                    className={`submenu-icon img-fluid`}
                                    alt=""
                                  />
                                  {subMenuObj.terciaryMenu?.length >= 1 && (
                                    <div
                                      className={
                                        "header-wrapper__terciary-submenu"
                                      }
                                    >
                                      <ul>
                                        {subMenuObj.terciaryMenu?.map(
                                          (menuobj: any, index: number) => {
                                            return (
                                              <li
                                                key={`terciary-${index}`}
                                                className={
                                                  "header-wrapper__terciary-submenu-list-items"
                                                }
                                              >
                                                <Link to={menuobj.linkUrl}>
                                                  <div
                                                    className={
                                                      "header-wrapper__terciary-submenu-list-title"
                                                    }
                                                  >
                                                    {menuobj.linkText}
                                                  </div>
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <SignInDropdownOrg
          isPopupVisible={isPopupVisible}
          setIsPopupVisible={setIsPopupVisible}
          togglePopupVisible={togglePopupVisible}
          openModal={openModal}
          toggleModal={toggleModal}
          hideMyAccount
          // navigateToProfile={navigateToProfile}
        />
        {/* <Dropdown overlay={menu} trigger={["click"]}> */}
        <div className={"signin"} onClick={() => togglePopupVisible()}>
          <span className={"signin-name"}>
            {getFirstLetterOfWord(getValue(userInfo, `first_name`, ""))}
          </span>
        </div>
        {/* </Dropdown> */}
      </div>
    </header>
  );
}
