export const aiRephraseMethods = [
  "Rephrase",
  "Correct Grammar",
  "Change Tone",
  "Empathize",
  "Summarize",
  "Expand",
  "Simplify",
  "Add Examples",
  "Translate",
  "Adjust Politeness",
  "Highlight Key Points",
  "Personalize",
  "Align with Brand Voice",
  "Remove Jargon",
];
