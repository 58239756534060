import NoDocumentFound from "@components/common/NoData/NoDocument";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { getValue } from "@utils/lodash";
import DeleteSvgComponent from "assets/svg/delete";
import DropdownCloseSvgComponent from "assets/svg/dropdown-close";
import FileSvgComponent from "assets/svg/file";
import React, { useRef, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const AttachmentPopup = (props) => {
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const POSITION_TYPES = [
    "right center",
    "right bottom",
    "bottom left",
    "bottom right",
    "left center",
    "center center",
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
  ];
  const handleClose = () => {
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const popupRef = useRef(null);
  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      className="d-flex justify-content-between align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      {props.header}
    </div>
  ));
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };
  const [documents, setDocuments] = useState([
    { name: "1.jpg" },
    { name: "2.jpg" },
    { name: "3.jpg" },
    { name: "4.jpg" },
    { name: "5.jpg" },
  ]);
  return (
    <Popup
      ref={popupRef}
      trigger={(open) => (
        <CustomButtons
          open={open}
          reports={props.reports}
          header={props.header}
        />
      )}
      position={POSITION_TYPES}
      nested
      arrow={true}
      contentStyle={{
        width: "68%",
        zIndex: 9999,
        background: "white",
        padding: "0",
        minHeight: "15vh",
        top: "0",
        right: "0",
        marginRight: "0",
        height: "100vh",
        overflow: "hidden",
        // boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;",
        // "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      }}
      className="attach-pop-up"
    >
      <div>
        <div className="attachment_header d-flex align-items-center justify-content-between px-4">
          <h6 className="upload-files-header">Attach files</h6>
          <div onClick={handleClose}>
            <DropdownCloseSvgComponent size={18} />
          </div>
        </div>
        <div className="px-4">
          <DragAndDropFileComponent
            uploadFile={props.handleUploadDocuments}
            small
          />
        </div>
        {props.uploadLoading ? (
          <p className="text-center header_text__14 p-4">Please wait...</p>
        ) : getValue(props, `documentList.length`, 0) > 0 ? (
          <div className={"attachment_popup_container p-3"}>
            {getValue(props, `documentList`, []).map((item, index) => {
              return (
                <div
                  className="attachment_item align-items-center d-flex justify-content-between"
                  key={index}
                >
                  <div className="align-items-center d-flex">
                    <FileSvgComponent color={"#0279ff"} size={20} />

                    <p className="header_text__14 ms-1 cursor-pointer">
                      {getValue(item, `filename`, "")}
                    </p>
                  </div>
                  <div onClick={() => props.handleRemoveDocuments(index)}>
                    <DeleteSvgComponent color={"#E3204F"} />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <NoDocumentFound />
        )}
      </div>
    </Popup>
  );
};
export default AttachmentPopup;
