import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "context/profileProvider";
import { getValue } from "@utils/lodash";
import { getFirstLetterOfWord } from "@common/text-helpers";
import "./SignInDropdown.scss";
import ChevronCircleRight from "@assets/svg/chevron-circle-right";
import AndroidSvgComponent from "@assets/svg/android";
import AppleSvgComponent from "@assets/svg/apple";
import ChevronRight from "@assets/svg/chevron-right";
import StarSvgComponent from "@assets/svg/star";
import CloseMainSvgComponent from "@assets/svg/close-main";
import { useEffect } from "react";
import { Drawer } from "antd";

export default function SignInDropdownOrg(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { pathname } = location;

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = `/signin`;
  };
  const { userInfo, profileInfo } = useStateContext();

  const needHelp = [
    {
      icon: "/images/icons-info.svg",
      link: "https://appzo.gitbook.io/appzo",
      heading: "Help Guide",
    },
    {
      icon: "/images/icons-chat-message.png",
      link: "https://www.appzo.ai/blog",
      heading: "FAQ",
    },
    {
      icon: "/images/icons-privacy.png",
      link: "https://www.appzo.ai/policy",
      heading: "Privacy Policy",
    },
    {
      icon: "/images/icons-term.png",
      link: "https://www.appzo.ai/terms",
      heading: "Terms of Service",
    },
    {
      icon: "/images/icons-mail.png",
      link: "mailto:support@appzo.ai",
      heading: "support@appzo.ai",
    },
  ];

  const handleClosePopup = () => {
    const targetPath = `/${getValue(params, "orgId", "")}/profile`;

    if (pathname === targetPath) {
      props.setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    handleClosePopup();
  }, [params, pathname]);

  return (
    <>
      <Drawer
        onClose={props.togglePopupVisible}
        open={props.isPopupVisible}
        closable={false}
        width={400}
        className="account-sidepar-popup"
      >
        <div
        // className="overlay"
        >
          <div
          //     className={`slide-from-right-popup ${
          //       props.isPopupVisible && `slide-from-right-popup--active`
          //     }
          // `}
          >
            <div className="account-sidepop">
              <div className="profile-header">
                <button
                  onClick={props.togglePopupVisible}
                  className="close-icon-acc-dtls"
                >
                  <CloseMainSvgComponent />
                </button>
                <div className="d-flex align-items-center gap-3">
                  <div className={"signin--initial"}>
                    <span className={"signin-name--initial"}>
                      {getFirstLetterOfWord(
                        getValue(userInfo, `first_name`, "")
                      )}
                    </span>
                  </div>
                  <div>
                    <h6 className={"signin__name"}>
                      {getValue(userInfo, `first_name`, "")}{" "}
                      {getValue(userInfo, `last_name`, "")}
                    </h6>
                    <p className={"signin__text"}>
                      {getValue(userInfo, `email`, "")}
                    </p>
                    <div
                      className={`d-flex gap-3 mt-3 ${
                        props.hideMyAccount ? "" : "justify-content-center"
                      }`}
                    >
                      {!props.hideMyAccount && (
                        <button
                          className="my_account_button"
                          onClick={() =>
                            navigate(
                              `/${getValue(params, "orgId", "")}/profile`
                            )
                          }
                        >
                          My account
                        </button>
                      )}
                      <button
                        className="sign_out_button"
                        onClick={handleLogout}
                      >
                        Signout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-content">
                <div>
                  <div className="pro-sub">
                    <h6 className="header_text__14 d-flex align-items-center gap-2">
                      Subscription<div className="free-tag">Paid</div>
                    </h6>
                    <p className="profile-reset">
                      Reset Date: Sept 2, 2025 09:46 AM
                    </p>
                  </div>
                  <div className="pro-sub-border-line"></div>

                  <div className="pro-need-help">
                    <h6 className="header_text__14">Need Help?</h6>
                    <div className="d-grid gap-3 mt-3">
                      {needHelp.map((items, index) => (
                        <Link
                          to={items.link}
                          key={index}
                          className="small_text__16 d-flex align-items-center gap-2"
                          target="__blank"
                        >
                          <img src={items.icon} className="" width="18px" />
                          {items.heading}
                        </Link>
                      ))}
                      <span></span>
                    </div>
                  </div>
                </div>
                <div className="webinar-contact">
                  <StarSvgComponent size="20" />
                  <div>
                    <h6 className="header_text__14">Live Webinar</h6>
                    <p className="small_text__13">
                      Get a complete overview of Appzo with our weekly Webinars
                    </p>
                    <a
                      href="https://www.appzo.ai/contact-us"
                      target="_blank"
                      rel="noreferrer"
                      className="webinar-register mt-3"
                    >
                      Register now
                      <ChevronRight size="12" color="#eb9623" />
                    </a>
                  </div>
                </div>
              </div>
              <button
                className="profile-footer"
                // onClick={props.toggleModal}
              >
                <div>
                  <h6 className="header_text__14">
                    Get the Mobile App by Scan the QR Code
                  </h6>
                  <p className="small_text__12">
                    Empower field agents on the go to deliver better
                  </p>
                  <div className="d-flex gap-2 mt-2">
                    <AppleSvgComponent size="20" />
                    <a
                      href="https://play.google.com/store/apps/details?id=com.supercrm&pcampaignid=web_share"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AndroidSvgComponent size="20" />
                    </a>
                  </div>
                </div>
                <div>
                  <ChevronCircleRight size="16" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
