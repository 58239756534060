// import NotFoundDetail from "@components/common/Not-Found-Detail";
import { getValue } from "@utils/lodash";
// import "../../PendingTaskMainBody.scss";
import EmailNavViews from "./components/nav-views";
import EmailList from "./components/emails";
import DraftsList from "./components/drafts";
import NotFoundDetail from "@components/common/NoData/Not-Found-Detail";
import LoaderMain from "@components/common/Loader/loading1";

function MainBodyEmails(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const pendingLinkArray = [
    {
      title: "Mails",
      linkUrl: "#",
      status: "active",
    },
    {
      title: "Drafts",
      linkUrl: "#",
    },
    // {
    //   title: "Scheduled",
    //   linkUrl: "#",
    // },
  ];
  const { activeEmailTab, setActiveEmailTab } = props;

  return (
    <>
      <div className={`email_card px-3 pb-3`}>
        <div className={`email_card_body`}>
          {/* <div
            className={`card_header d-flex align-items-center justify-content-between`}
          >
            <h6 className="header_text">Emails</h6>
          </div> */}
          <div className={`card_tabs p-2`}>
            <EmailNavViews
              pendingLinkArray={pendingLinkArray}
              activeTab={activeEmailTab}
              setActiveTab={setActiveEmailTab}
            />
          </div>
          {getValue(props, `list.length`, 0) === 0 ? (
            <NotFoundDetail title={'No emails have been included.'}/>
          ) : (
            <div>
              {getValue(props, `loading`, false) ? (
                <LoaderMain />
              ) : (
                <div>
                  {(getValue(urlParams, `activeEmailSubTab`, "") == 1 ||
                    !getValue(urlParams, `activeEmailSubTab`, "")) && (
                    <EmailList
                      list={getValue(props, `list`, "")}
                      getSpecificEmailInfo={props.getSpecificEmailInfo}
                    />
                  )}
                  {getValue(urlParams, `activeEmailSubTab`, "") == 2 && (
                    <DraftsList
                      list={getValue(props, `list`, "")}
                      getSpecificDraftInfo={props.getSpecificDraftInfo}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div></div>
      </div>
    </>
  );
}

export default MainBodyEmails;
