import CommonPopupHeader from "@components/common/Header/CommonPopupHeader/CommonPopupHeader";
import "./CommonSlideFromRightPopup.scss";
import SubmitButtonContainer from "@components/common/ButtonContainer/SubmitButtonContainer";
import { useEffect } from "react";

export default function CommonRightPopup({
  isPopupVisible,
  addCustomClass,
  width,
  toggle,
  title,
  handleBackButton,
  handleSubmit,
  submitLoading,
  children,
  first_title,
  second_title,
  hideNew,
  moduleName,
  disabled,
  addZIndex,
  hideSubmit,
  hideFooter,
  permissions,
}: any) {
  useEffect(() => {
    // Add a CSS class to the body when the popup is visible
    if (isPopupVisible) {
      document.body.classList.add("popup-visible");
    } else {
      // Remove the CSS class when the popup is closed
      document.body.classList.remove("popup-visible");
    }
    // Clean up the effect
    return () => {
      document.body.classList.remove("popup-visible");
    };
  }, [isPopupVisible]);

  return (
    <>
      {isPopupVisible && (
        <>
          <div className="overlay">
            <div
              className={`slide-from-right-popup ${
                isPopupVisible && `slide-from-right-popup--active`
              } ${addCustomClass && `slide-from-right-popup--rolls-popup`}`}
              style={{ width: width, zIndex: addZIndex && "1199" }}
            >
              <CommonPopupHeader
                title={title}
                handleBackButton={toggle}
                moduleName={moduleName}
              />
              {children}
              {!hideFooter && !hideSubmit && (
                <SubmitButtonContainer
                  handleBackButton={toggle}
                  handleSubmit={handleSubmit}
                  submitLoading={submitLoading}
                  first_title={first_title}
                  second_title={second_title}
                  hideNew={hideNew}
                  disabled={disabled}
                  permissions={permissions}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
