import { QueryRequestHelper } from "@common/query-request-helper";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import SelectDropdown from "@components/custom/Dropdown/SelectDropdown";
import ButtonComponent from "@components/Form/Button/Button";
import { Button, Dropdown, MenuProps, Popover } from "antd";
import { useState } from "react";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";

function DashboardHeader(props: any) {
  const {
    isLoading,
    dateList,
    addChildren,
    selectedDashboard,
    setSelectedComponentList,
  } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                                Onchange Section                            */
  /* -------------------------------------------------------------------------- */

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const generateRequestArray = (array: any) => {
    return array.map((item: any) => ({
      ...item,
      value: getValue(item, `api_name`, ""),
      label: getValue(item, `label`, ""),
    }));
  };

  /* -------------------------------------------------------------------------- */
  /*                              Navigation Section                            */
  /* -------------------------------------------------------------------------- */
  const handleClickPipeline = (value: object) => {
    let payload = {
      ...UrlParams,
      pipeline: getValue(value, `id`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/new-dashboard?${queryRequest}`);
  };
  const handleClickDashboard = (value: object) => {
    setSelectedComponentList([]);
    let payload = {
      ...UrlParams,
      analyticsId: getValue(value, `id`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/new-dashboard?${queryRequest}`);
  };
  const handleClickModule = (value: any) => {
    delete UrlParams.pipeline;
    let payload = {
      ...UrlParams,
      module: getValue(value, `name`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/new-dashboard?${queryRequest}`);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "KPI",
      onClick: () => {
        hide();
        props.openKPI();
      },
    },
    {
      key: "2",
      label: "Chart",
      onClick: () => {
        hide();
        props.openGraph();
      },
    },
  ];

  return (
    <div className={"common-subheader"}>
      <div className={"common-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            {/* <TextHeaderTooltip
              data={generateRequestArray(getValue(props, `allModules`, []))}
              label={"label"}
              title={getValue(props, `selectedModule`, "")}
              value={getValue(props, `selectedModule`, "")}
              onChange={handleClickModule}
              width={"270px"}
              selectKey={"value"}
              editInline
            />
            {getValue(props, `selectedModule`, "") !== "tasks" &&
            getValue(props, `selectedModule`, "") !== "contacts" &&
            getValue(props, `selectedModule`, "") !== "companies" ? (
              <div className="ms-3">
                <SearchToolTip
                  data={getValue(props, `pipelines`, [])}
                  label={"label"}
                  title={getValue(props, `selectedPipeline`, "")}
                  value={getValue(props, `selectedPipeline`, "")}
                  onChange={handleClickPipeline}
                  width={"280px"}
                  isLoading={isLoading}
                  selectKey={"id"}
                />
              </div>
            ) : (
              <div style={{ height: "40px" }}></div>
            )} */}

            <div className="ms-2">
              <SelectDropdown
                placeholder={"Select Dashboard"}
                data={getValue(props, `allDashboardList`, [])}
                // value={getValue(props, `dashboardName`, "")}
                value={
                  selectedDashboard
                    ? selectedDashboard
                    : getValue(UrlParams, `analyticsId`, "")
                }
                selectKey={"id"}
                nameKey={"Dashboard"}
                onAdd={props.CreateAnalyticsDashboard}
                isLoading={props.loading}
                onSelect={handleClickDashboard}
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            {/* <Popover
              content={
                <div className="cursor-pointer">
                  <h6
                    onClick={() => {
                      hide();
                      props.openKPI();
                    }}
                  >
                    KPI
                  </h6>
                  <h6
                    onClick={() => {
                      hide();
                      props.openGraph();
                    }}
                  >
                    Chart
                  </h6>
                </div>
              }
              // title="Title"
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Button
                size="large"
                type="primary"
                onClick={props.showComponentDrawer}
              >
                Component
              </Button>
            </Popover> */}
            <Dropdown menu={{ items }}>
              <Button size="large" type="primary">
                Component
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
