import { getValue } from "@utils/lodash";
import React from "react";

function HeatMapChartComponent(props: any) {
  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, `name`, "")}</p>
    </div>
  );
}

export default HeatMapChartComponent;
