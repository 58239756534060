
export default function Google(props: any) {
  return (
    <>
      <button
        className={`google-button d-flex align-items-center transition`}
      >
        <img
          src="/images/icons/google.png"
          className="img-fluid"
          height={36}
          width={36}
        />
        {props.buttonText}
      </button>
    </>
  );
}
