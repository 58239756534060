import { getValue } from "@utils/lodash";
import { convertCurrentDateWithTime } from "common/date-helpers";
import React, { useRef } from "react";
import Popup from "reactjs-popup";

const CustomButton = React.forwardRef(({ open, ...props }, ref) => (
  <div
    className="d-flex justify-content-between align-items-center cursor-pointer"
    ref={ref}
    {...props}
  >
    <img
      src="/images/add-blue.svg"
      width="24px"
      height="24px"
      className="img-fluid"
    />
    <h5 className="header_text_blue ms-2">Add Receipts/Files</h5>
  </div>
));

function MoreEmailsTooltip(props) {
  const POSITION_TYPES = [
    "bottom right",
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "left center",
    "center center",
  ];
  const fileTypes = ["XLSX", "CSV", "XLSM", "XLX"];
  const { handleUploadDocuments } = props;

  const popupRef = useRef();
  const handleClose = (e) => {
    e.stopPropagation();
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };

  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      className="d-flex justify-content-between align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      <p className="d-flex header_blue_text text-decoration-underline">More</p>
      {/* <h5 className="header_text_blue ms-2">Add Receipts/Files</h5> */}
    </div>
  ));
  const convertStrToArray = (str) => {
    let item = str ? str.split(",") : [];
    return item;
  };
  return (
    <Popup
      trigger={(open) => <CustomButtons open={open} />}
      position={POSITION_TYPES}
      closeOnDocumentClick
      on={"hover"}
      contentStyle={{
        width: "400px",
        minHeight: "200px",
        zIndex: 9999,
        background: "white",
        boxShadow:
          "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      }}
      nested
      ref={popupRef}
      onOpen={handleOpen}
      onClick={handleClose}
    >
      <div onClick={handleClose} className="position-relative">
        <div className=" mt-3 position-relative">
          <div>
            <p className="ms-2 header_blue_text">From :</p>
            <p className="ms-5 desc_text text-break text-bg-light p-2">
              {convertStrToArray(getValue(props, `info.from`, "")).map(
                (item) => `<${item}>`
              )}
            </p>
          </div>
          <div>
            <p className="ms-2 header_blue_text">To :</p>
            <p className="ms-5 desc_text text-break text-bg-light p-2">
              {convertStrToArray(getValue(props, `info.to`, "")).map(
                (item) => `<${item}>`
              )}
            </p>
          </div>
          {getValue(props, `info.cc`, "") && (
            <div>
              <p className="ms-2 header_blue_text">Cc :</p>
              <p className="ms-5 desc_text text-break text-bg-light p-2">
                {convertStrToArray(getValue(props, `info.cc`, "")).map(
                  (item) => `<${item}>`
                )}
              </p>
            </div>
          )}
          {getValue(props, `info.bcc`, "") && (
            <div>
              <p className="ms-2 header_blue_text">Bcc :</p>
              <p className="ms-5 desc_text text-break text-bg-light p-2">
                {convertStrToArray(getValue(props, `info.bcc`, "")).map(
                  (item) => `<${item}>`
                )}
              </p>
            </div>
          )}
          <div>
            <p className="ms-2 header_blue_text">Subject :</p>
            <p className="ms-5 desc_text text-break text-bg-light p-2">
              {getValue(props, `info.subject`, "")}
            </p>
          </div>
          <div>
            <p className="ms-2 header_blue_text">Date :</p>
            <p className="ms-5 desc_text text-break text-bg-light p-2 ">
              {convertCurrentDateWithTime(
                getValue(props, `info.created_at`, "")
              )}
            </p>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default MoreEmailsTooltip;
