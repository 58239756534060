import { InboxOutlined } from "@ant-design/icons";
import CloseMainSvgComponent from "@assets/svg/close-main";
import FilterSvgComponent from "@assets/svg/filter";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { Button, Divider, Table, Tabs } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React from "react";
import { toast } from "sonner";
import BroadcastContactsUpload from "./BroadcastContactsUpload";
import ListLoader from "@components/common/Loader";

const SelectAudience = ({
  request,
  allPipelines,
  handleChangePipeline,
  allList,
  selectionType,
  rowSelection,
  page_no1,
  limit1,
  totalCount1,
  handleChangePagination1,
  selectedAdvancedFiter,
  handleOpenFilterView,
  handleRemoveSmartFilter,
  allConnectionList,
  setRequest,
  contactUploadStep,
  toggleContactUploadStep,
  downloadFile,
  handleUploadDocuments,
  uploadedFileInfo,
  uploadLoading,

  handleModule,
  pipelineId,
  setPipelineId,
  handleSelectPipeline,
  handleGenerateDownloadURL,
  listLoading,
}: any) => {
  const columns = [
    {
      title: "NAME",
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name: any, text: any) => (
        <div>
          {first_name} {text.last_name}
        </div>
      ),
    },
    {
      title: "MOBILE NUMBER",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone_number",
      key: "phone_number",
    },
  ];

  const tabs = [
    {
      key: "1",
      label: "Contacts",
    },
    {
      key: "2",
      label: "Leads",
    },
  ];

  return (
    <div className="p-4">
      {!contactUploadStep ? (
        <div>
          <div className="mb-3 mx-xxl-5 px-xxl-5 d-flex gap-2 align-items-center justify-content-between">
            <div>
              <h6 className="header_text__20">Select Audience</h6>
              <p className="small_text__14">Whom do you want to send it to?</p>
            </div>
          </div>
          <div
            className="mx-auto"
            style={{
              maxWidth: "900px",
            }}
          >
            <div className="contacts-upload-wrapper mt-4">
              <div className="d-flex gap-2 align-items-center">
                <img src="/images/icons/excel.svg" alt="excel" />
                <div>
                  <h6 className="header_text__16">
                    Upload Contacts From Excel
                  </h6>
                  <p className="small_text__14">
                    Broadcast customised messages to your contacts available in
                    your excel sheet
                  </p>
                </div>
              </div>
              <Button size="large" onClick={() => toggleContactUploadStep()}>
                <img
                  src="/images/icons/excel.svg"
                  alt="excel"
                  width={22}
                  height={22}
                />
                Upload Contacts
              </Button>
            </div>
            <Divider className="py-3">
              <h6 className="header_text__20">OR</h6>
            </Divider>
            <div
              className={`d-flex align-items-center gap-3 justify-content-end`}
            >
              {selectedAdvancedFiter.length > 0 && (
                <div
                  onClick={handleRemoveSmartFilter}
                  className="ms-2 inline_close_image_container"
                >
                  <CloseMainSvgComponent size={18} />
                </div>
              )}
              <div
                className="d-flex gap-2 align-items-center"
                onClick={handleOpenFilterView}
              >
                <FilterSvgComponent color={"#408dfb"} />
                <h6 className="header_text__16 color_primary cursor-pointer ">
                  Advanced Filters
                </h6>
              </div>
            </div>
            {/* <div className="d-flex flex-wrap mx-3 gap-4 mb-4">
              {tabs.map((item: object) => {
                return (
                  <h6
                    className="header_text__16 cursor-pointer"
                    onClick={() => handleModule(getValue(item, `key`, ""))}
                  >
                    {getValue(item, `label`, "")}
                  </h6>
                );
              })}
            </div> */}
            <Tabs
              defaultActiveKey="1"
              type="card"
              style={{ marginBottom: 32 }}
              className="mt-2"
              onChange={(key) => handleModule(key)}
              items={tabs.map((item, index) => {
                return {
                  label: getValue(item, `label`, ""),
                  key: item.key,
                  children: (
                    <div>
                      {getValue(request, `contactType`, "") === "lead" && (
                        <div className=" mb-2 w-50">
                          <SearchToolTip
                            name="Pipeline"
                            data={allPipelines}
                            label="label"
                            value={pipelineId}
                            selectKey={"id"}
                            onChange={(e: object) => {
                              handleSelectPipeline(getValue(e, `id`, ""));
                              setPipelineId(getValue(e, `id`, ""));
                            }}
                          />
                        </div>
                      )}
                      <>
                        {listLoading ? (
                          <ListLoader />
                        ) : (
                          <Table
                            size="middle"
                            bordered
                            columns={columns}
                            dataSource={allList}
                            rowKey="id"
                            rowSelection={{
                              type: selectionType,
                              ...rowSelection,
                              getCheckboxProps: (record) => ({
                                disabled:
                                  !record.mobile_number && !record.phone_number,
                              }),
                            }}
                            pagination={{
                              current: page_no1,
                              pageSize: limit1,
                              total: totalCount1,
                              showSizeChanger: true,
                              pageSizeOptions: [
                                "10",
                                "20",
                                "30",
                                "40",
                                "50",
                                "60",
                                "70",
                                "80",
                                "90",
                                "100",
                              ],
                              onChange: (page_no, pageSize) => {
                                handleChangePagination1(page_no, pageSize);
                              },
                              showTotal: (total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`,
                            }}
                          />
                        )}
                      </>
                    </div>
                  ),
                };
              })}
            />
          </div>
        </div>
      ) : (
        <BroadcastContactsUpload
          request={request}
          toggleContactUploadStep={toggleContactUploadStep}
          downloadFile={downloadFile}
          handleUploadDocuments={handleUploadDocuments}
          uploadedFileInfo={uploadedFileInfo}
          uploadLoading={uploadLoading}
          selectionType={selectionType}
          rowSelection={rowSelection}
          page_no1={page_no1}
          limit1={limit1}
          totalCount1={totalCount1}
          handleChangePagination1={handleChangePagination1}
          setRequest={setRequest}
          handleGenerateDownloadURL={handleGenerateDownloadURL}
        />
      )}
    </div>
  );
};

export default SelectAudience;
