import { Button, Modal } from "antd";
import { getValue } from "@utils/lodash";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import CommonDocumentList from "@components/custom/FileUpload/document-list";

const AttachmentsPopup = (props: any) => {
  const { handleUploadDocuments, handleRemoveDocuments, documentList } = props;
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onCancel={props.handleModal}
      onOk={props.handleSubmit}
      footer={[
        <Button
          key="cancel"
          onClick={props.handleModal}
          className="cancel__button"
        >
          Cancel
        </Button>,
        getValue(props, `permissions`, []).includes("create") && (
          <Button
            key="submit"
            type="primary"
            onClick={props.handleSubmit}
            className="submit__button"
          >
            Upload
          </Button>
        ),
      ]}
    >
      <h6 className="header_text__18">Attachments</h6>

      <section className="m-4">
        <DragAndDropFileComponent uploadFile={handleUploadDocuments} />
        <CommonDocumentList
          documentList={documentList}
          handleRemoveDocuments={handleRemoveDocuments}
        />
      </section>
    </Modal>
  );
};

export default AttachmentsPopup;
