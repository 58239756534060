import React, { useEffect, useState } from "react";
import SettingModuleNavbar from "../common/Navbar";
import ModulesEditInfo from "@pages/Private/Settings/ModuleAndField/ManageFields/Info/EdiInfo";
import SettingModuleHOCComponent from "../common/detailHoc";
import { getValue } from "@utils/lodash";
import ListLoader from "@components/common/Loader";
import InfogLeftMenu from "./Sidebar/ManagefieldsLeft";

function SettingsModuleInfo(props: any) {
  return (
    <div className={`${"main_container"} d-flex`}>
      <InfogLeftMenu />
      <div className="w-100">
        <SettingModuleNavbar
          tab={"info"}
          params={getValue(props, `params`, {})}
          handleNavigate={props.handleNavigate}
        />
        <div className={`${"main_container_list"} w-100`}>
          {props.isLoading || props.loading ? (
            <ListLoader />
          ) : (
            <ModulesEditInfo
              request={props.request}
              setRequest={props.setRequest}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SettingModuleHOCComponent(SettingsModuleInfo);
