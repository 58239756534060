import React, { useState } from "react";
import { TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";

const SheetTimePicker = ({ handleInputChange, col, row, field }: any) => {
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);

  const onChange = (time: Dayjs | null) => {
    if (time) {
      setSelectedTime(time);
      handleInputChange(row.id, col, time, field); // Pass the Dayjs time object
    } else {
      setSelectedTime(null);
      handleInputChange(row.id, col, null, field); // Pass null if cleared
    }
  };

  return (
    <div>
      <TimePicker
        onChange={onChange}
        minuteStep={30}
        format="hh:mm A"
        placeholder="Select Time"
      />
    </div>
  );
};

export default SheetTimePicker;
