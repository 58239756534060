import InlineFormBuilder from "@components/Builder/InlineFormBuilder";
import { getValue } from "@utils/lodash";

function TaskInfo(props: any) {
  const { isLoading, info } = props;
  return (
    <div>
      {isLoading ? (
        <p className="text-center mt-5 ">Loading...</p>
      ) : (
        <>
          <div className={`task_detail_right-left_section1 pt-3 pb-3`}>
            <div className="ms-4 w-75">
              <InlineFormBuilder
                fields={props.fields}
                setFields={props.setFields}
                width={"600px"}
                module={"tasks"}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TaskInfo;
