import { getValue } from "@utils/lodash";
import { useLocation, useParams } from "react-router-dom";
import Iframe from "react-iframe";

const TestChatbotPopup = (props: any) => {
  const params = useParams();
  const location = useLocation();
  const chatbotUrl = `https://chatbot-iframe.onrender.com?id=${getValue(
    params,
    `id`,
    ""
  )}&env=dev`;

  if (!props.show) {
    return null;
  }

  return (
    <>
      <div
        className={`${
          getValue(location, "pathname", "") ===
          `/${getValue(
            params,
            "orgId",
            ""
          )}/chatbot/builder/create-flow/${getValue(params, "id", "")}`
            ? "test-modal-wrapper"
            : "test-bot-modal-wrapper"
        }`}
        style={{
          right:
            getValue(props, "botInfo.position", "") === "RIGHT_CENTER"
              ? "76px"
              : "10px",
          bottom:
            getValue(props, "botInfo.position", "") === "RIGHT_CENTER"
              ? "-200px"
              : "80px",
          left:
            getValue(location, "pathname", "") !==
              `/${getValue(
                params,
                "orgId",
                ""
              )}/chatbot/builder/create-flow/${getValue(params, "id", "")}` &&
            getValue(props, "botInfo.position", "") === "LEFT_BOTTOM"
              ? "0px"
              : "",
        }}
      >
        <div className="test-bot-body">
          <div className="bot-testing-wrapper">
            {getValue(params, `id`, "") ? (
              <Iframe
                url={chatbotUrl}
                width="100%"
                height="100%"
                id=""
                className=""
                display="block"
                position="relative"
              />
            ) : (
              <div
                className="d-flex align-items-end gap-2 p-4"
                style={{ height: "100%" }}
              >
                <img
                  src="/images/bot-loader.gif"
                  className="bot-profile-dummy"
                  width={40}
                  height={40}
                />
                <div className="bot-sample-message p-3 w-60">
                  <div className="d-flex justify-content-center">
                    <img src="/images/waving-hand.gif" className="w-50 mb-3" />
                  </div>
                  <p className="text-start dummy-text-message">
                    What are you looking for?
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestChatbotPopup;
