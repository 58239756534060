import DownSvgComponent from "@assets/svg/down";
import UpSvgComponent from "@assets/svg/up";
import { getValue } from "@utils/lodash";
import React from "react";

function KPIStandardComonent(props: any) {
  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, `name`, "")}</p>
      <div className="d-flex align-items-center">
        <h6 className="standard-highlight">
          {getValue(props, `graph.current`, "")}
        </h6>
        <div className="d-flex align-items-center ms-3">
          {getValue(props, `compare_objective`, "") === "positive" ? (
            <UpSvgComponent size="20" />
          ) : (
            <DownSvgComponent size="20" />
          )}

          <p
            className={`${
              getValue(props, `compare_objective`, "") === "positive"
                ? "db_color_success"
                : "db_color_negative"
            }`}
          >
            {parseInt(getValue(props, `graph.percentage`, ""))} %
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center mt-2">
        <p className="small_text__14">
          {getValue(props, `graph.previous_label`, "")} :{" "}
          {getValue(props, `graph.previous`, "")}
        </p>
      </div>
    </div>
  );
}

export default KPIStandardComonent;
