import React, { useEffect, useRef, useState } from "react";
import "../Datatable/table.scss";
import {
  camelToSnake,
  capitalizeEverything,
  convertSnakeToCamelCaseArray,
  formatCamelString,
  formatString,
  getFirstLetterOfWord,
} from "common/text-helpers";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "common/query-request-helper";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
  convertCurrentDateWithTimezone,
  convertCurrentTime,
} from "common/date-helpers";
import Nodata from "@components/common/NoData/NoData";
import TaskSvgComponent from "assets/svg/task";
import CustomAllCheckbox from "@components/Form/CheckBox/CustomAllCheckbox";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import HoverComponent from "../HoverComponent";
import Loader from "@components/common/Loader/loading";
import { useStateContext } from "@context/profileProvider";
import LabelSvgComponent from "@assets/svg/label";
import ContactsNoDataPage from "@components/common/NoData/contacts-nodata";
import CompaniesNoDataPage from "@components/common/NoData/companies-nodata";
import DealsNoDataPage from "@components/common/NoData/deals-nodata";
import CallsNoDataPage from "@components/common/NoData/calls-nodata";
import MeetingsNoDataPage from "@components/common/NoData/meetings-nodata";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import TextHeaderTooltip from "../Dropdown/TextHeaderTooltip";
import { commonBulkUpdate } from "@services/common.service";
import { toast } from "sonner";
import SortTooltip from "../Dropdown/sortTooltip";
import BroadCastNoDataPage from "@components/common/NoData/broadcast-nodata";
import ListLoader from "@components/common/Loader";
import { Button, Checkbox, Popover, Switch } from "antd";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import SheetEditor from "./SheetEditor";
import SheetDropdownSelector from "./SheetEditor/components/SheetDropdownSelector";
import moment from "moment";

const CustomSheetTable = (props) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                               Navigation Section                           */
  /* -------------------------------------------------------------------------- */
  const { orgInfo } = useStateContext();
  const navigate = useNavigate();
  const params = useParams();
  const handleNavigate = (route) => {
    !props.disableNavigation && navigate(`${route}?${mainQueryRequest}`);
  };
  const urlParamsFun = () => {
    let request = QueryRequestHelper({ ...UrlParams });
    return request;
  };

  const mainQueryRequest = React.useMemo(
    () => urlParamsFun(),
    [window.location.href]
  );

  /* -------------------------------------------------------------------------- */
  /*                                    Checkbox Section                        */
  /* -------------------------------------------------------------------------- */
  const { selectedIds, setSelectedIds, tourRef5, tourRef6 } = props;
  const handleChangeCheckbox = (id) => {
    let checkIfExist =
      getValue(selectedIds, `length`, 0) > 0
        ? selectedIds.filter((item) => item === id)
        : [];
    if (getValue(checkIfExist, `length`, 0) > 0) {
      let filtered =
        getValue(selectedIds, `length`, 0) > 0
          ? selectedIds.filter((item) => item !== id)
          : [];
      setSelectedIds(filtered);
    } else {
      selectedIds.push(id);
      setSelectedIds([...selectedIds]);
    }
  };

  const handleChangeAllCheckbox = (value) => {
    if (value === "all_checked") {
      setSelectedIds([]);
    } else {
      let IDs = getValue(props, `data`, []).map((item) => item.id);
      setSelectedIds(IDs);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    Display Section                         */
  /* -------------------------------------------------------------------------- */
  const handleDisplayHeaderItem = (col, row) => {
    switch (col) {
      case getValue(col, `api_name`, ""):
        return formatString("owner");
      default:
        return getValue(col, `api_name`, "") === "pipeline_stage"
          ? "Status"
          : getValue(col, `label`, "");
    }
  };

  const handleDisplayItem = (col, row) => {
    const id = getValue(row, "id", "");
    const contactInRequest = props.patchRequest.contacts.find(
      (contact) => contact.id === id
    );

    // If there's a contact in the patch request, check its custom data first
    if (contactInRequest) {
      const customDataValue = getValue(
        contactInRequest.value.custom_data,
        col,
        undefined
      );
      if (customDataValue !== undefined) {
        return customDataValue; // Return custom data value if it exists
      }

      const updatedValue = getValue(contactInRequest.value, col, undefined);
      if (updatedValue !== undefined) {
        return updatedValue; // Return updated value if it exists
      }
    }

    switch (col) {
      case "closing_date":
        return convertCurrentDate(row[col]);
      case "date":
        return moment(getValue(row, col)).isValid()
          ? convertCurrentDateWithTimezone(getValue(row, col))
          : "";
      case "datetime":
        return moment(getValue(row, col)).isValid()
          ? convertCurrentDateWithTime(getValue(row, col))
          : "";
      case "time":
        const timeValue = getValue(row, col);
        return moment(timeValue).isValid() ? convertCurrentTime(timeValue) : "";
      case "created_at":
      case "updated_at":
      case "due_datetime":
      case "reminder_datetime":
        return convertCurrentDateWithTime(row[col]);
      case "pipeline_stage":
        return getValue(row["pipeline_stage"], "id", "");
      case "owner":
      case "owner_id":
        return (
          `${getValue(row["owner"], "first_name", "")} ${getValue(
            row["owner"],
            "last_name",
            ""
          )}`.trim() || ""
        );
      case "updated_by":
      case "created_by":
        return (
          `${getValue(row[col], "first_name", "")} ${getValue(
            row[col],
            "last_name",
            ""
          )}`.trim() || ""
        );
      case "amount":
        return getValue(row, `[${col}]`, "")
          ? `${getValue(orgInfo, "currency", "INR")} ${getValue(
              row,
              `[${col}]`,
              ""
            )}`
          : "";
      case "boolean_radio":
        return getValue(row, `[${col}]`, false);
      default:
        return getValue(row, `[${col}]`, "")
          ? Array.isArray(getValue(row, `[${col}]`, ""))
            ? convertSnakeToCamelCaseArray(getValue(row, `[${col}]`, ""))
            : convertDateWithString(
                formatCamelString(getValue(row, `[${col}]`, ""))
              )
          : "";
    }
  };

  const checkType = (inputValue) => {
    // Regex pattern for date (YYYY-MM-DD)
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

    // Regex pattern for datetime (YYYY-MM-DDTHH:mm:ss.sssZ)
    const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    // Check if it's a date in the "YYYY-MM-DD" format
    if (datePattern.test(inputValue)) {
      return "Date";
    }

    // Check if it's a datetime in the "YYYY-MM-DDTHH:mm:ss.sssZ" format
    if (dateTimePattern.test(inputValue)) {
      return "Datetime";
    }

    // If it doesn't match any of the above, it's considered a string
    return "String";
  };

  const findStageColor = (stage) => {
    let color =
      getValue(props, `stageList.length`, 0) > 0
        ? getValue(props, `stageList`, []).find(
            // (item) => getValue(item, `api_name`, "") === stage
            (item) => getValue(item, `id`, "") === stage
          )
        : {};
    return getValue(color, `color_code`, "");
  };

  const getText = (row) => {
    switch (getValue(props, `module`, "")) {
      case "contacts":
        return `${getFirstLetterOfWord(
          getValue(row, `first_name`, "")
        )}${getFirstLetterOfWord(getValue(row, `last_name`, ""))}`;

      case "tasks":
        return `${getFirstLetterOfWord(getValue(row, `title`, ""))}`;

      case "companies":
        return `${getFirstLetterOfWord(
          getFirstLetterOfWord(getValue(row, `name`, ""))
        )}`;

      case "sales":
      case "deals":
        return getValue(row, `owner.first_name`, "")
          ? `${getFirstLetterOfWord(
              getValue(row, `owner.first_name`, "")
            )}${getFirstLetterOfWord(getValue(row, `owner.last_name`, ""))}`
          : `${getFirstLetterOfWord(getValue(row, `name`, ""))}`;

      default:
        return "S";
    }
  };

  const getDisplayText = (row) => {
    const firstTwoLetters = getText(row).substring(0, 2);
    return firstTwoLetters;
  };

  const fixedColumnRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = fixedColumnRef.current.scrollLeft;
      const header = fixedColumnRef.current.querySelector("th:nth-child(2)");
      const columns =
        fixedColumnRef.current.querySelectorAll("td:nth-child(2)");

      if (header) {
        if (scrollPosition > 0) {
          header.style.boxShadow = `5px 0 5px -2px rgba(0, 0, 0, 0.1)`;
        } else {
          header.style.boxShadow = "none";
        }
      }
      columns.forEach((column) => {
        if (scrollPosition > 0) {
          column.style.boxShadow = `5px 0 5px -2px rgba(0, 0, 0, 0.1)`;
        } else {
          column.style.boxShadow = "none";
        }
      });
    };
    const fixedColumnElement = fixedColumnRef.current;
    if (fixedColumnElement) {
      fixedColumnElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (fixedColumnElement) {
        fixedColumnElement.removeEventListener("scroll", handleScroll);
      }
    };
  });
  function appendCustomData(jsonData) {
    if (jsonData.custom_data) {
      const customData = jsonData.custom_data;
      const updatedData = {
        ...jsonData,
        ...customData,
      };
      delete updatedData.custom_data;
      return updatedData;
    } else {
      return jsonData;
    }
  }

  function hexToRGBA(hex, alpha = 1) {
    // Remove the # if present
    hex = hex.replace("#", "");
    // Parse the hexadecimal values for red, green, and blue components
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    // Check if the alpha value is within the valid range (0 to 1)
    const clampedAlpha = Math.min(Math.max(alpha, 0), 0.1);
    // Return the RGBA value as a string
    return `rgba(${r}, ${g}, ${b}, ${clampedAlpha})`;
  }

  function convertDateWithString(value) {
    // Check if the value is a string
    // console.log(checkType(value), "----->", value);
    if (checkType(value) === "Date" || checkType(value) === "Datetime") {
      // Check if the value is in the format "YYYY-MM-DDTHH:mm:ss.sssZ"
      if (value.includes("T")) {
        return convertCurrentDateWithTime(value) === "Invalid date"
          ? value
          : convertCurrentDateWithTime(value); // Parse the string as a Date with time
      } else if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        // Check if the value matches the format "YYYY-MM-DD" (Date without time)
        return convertCurrentDate(value);
      } else {
        // If it's not a date string, print the value
        return value; // Or you can return a default value, throw an error, etc.
      }
    } else {
      if (typeof value === "boolean") {
        return value ? "Yes" : "No";
      } else {
        return value;
      }
    }
  }

  const getEmptyScreens = () => {
    switch (props.module) {
      case "contacts":
        return <ContactsNoDataPage />;
      case "companies":
        return <CompaniesNoDataPage />;
      case "deals":
        return <DealsNoDataPage />;
      case "calls":
        return <CallsNoDataPage />;
      case "meetings":
        return <MeetingsNoDataPage />;
      case "broadcast":
        return <BroadCastNoDataPage />;
      default:
        return <Nodata />;
    }
  };

  const handleChangeStage = async (id, main_id) => {
    try {
      let resp = await commonBulkUpdate(props.module, {
        ids: [main_id],
        data: {
          pipeline_stage_id: id,
        },
      });
      if (resp) {
        toast.success("Updated Succssfully");
        props.getListData(props.selectedPipeline.id, false);
      } else {
      }
    } catch (error) {}
  };

  const sortOptions = [
    { value: "asc", label: "Asc" },
    { value: "desc", label: "Desc" },
  ];

  const [sort_by, setSortBy] = useState({
    field: "",
    value: "asc",
  });

  const handleSelectValue = (name, value, column_name) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const UrlParams = Object.fromEntries(urlSearchParams.entries());
    localStorage.setItem(
      `s:${getValue(props, `module`, "")}`,
      JSON.stringify({
        field: column_name ? column_name : name,
        value: value,
      })
    );
    let payload = {
      ...UrlParams,
      page_no: 1,
      smart_filter: true,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const getFilterName = () => {
    let filter =
      getValue(localStorage, `s:${props.module}`, "") &&
      JSON.parse(getValue(localStorage, `s:${props.module}`, ""));
    return getValue(filter, `field`, "") ? getValue(filter, `field`, "") : "";
  };

  const getFilterValue = () => {
    let filter =
      getValue(localStorage, `s:${props.module}`, "") &&
      JSON.parse(getValue(localStorage, `s:${props.module}`, ""));
    return getValue(filter, `value`, "") ? getValue(filter, `value`, "") : "";
  };

  const staticData = [
    { value: "preview", label: "Preview" },
    // { value: "add_task", label: "Add task" },
    // { value: "add_notes", label: "Add notes" },
  ];

  const handleSelect = (option, row) => {
    if (getValue(option, `value`, "") === "preview") {
      props.handlePreviewPopup(getValue(row, `id`, ""));
    }
    if (getValue(option, `value`, "") === "add_task") {
      props.handleTaskPopup(getValue(row, `id`, ""));
    }
    if (getValue(option, `value`, "") === "add_notes") {
      props.handleNotesPopup(getValue(row, `id`, ""));
    }
  };

  // const handleInputChange = (id, col, value) => {
  //   props.setPatchRequest((prevRequest) => {
  //     const existingContact = prevRequest.contacts.find(
  //       (contact) => contact.id === id
  //     );

  //     if (existingContact) {
  //       return {
  //         ...prevRequest,
  //         contacts: prevRequest.contacts.map((contact) =>
  //           contact.id === id
  //             ? {
  //                 ...contact,
  //                 value: {
  //                   ...contact.value,
  //                   [col]: value,
  //                 },
  //               }
  //             : contact
  //         ),
  //       };
  //     } else {
  //       return {
  //         ...prevRequest,
  //         contacts: [
  //           ...prevRequest.contacts,
  //           {
  //             id,
  //             value: { [col]: value },
  //           },
  //         ],
  //       };
  //     }
  //   });
  // };

  const handleInputChange = (id, col, value, fieldCol) => {
    // Process value based on fieldCol type
    let processedValue;

    switch (fieldCol) {
      case "text":
      case "textarea":
        processedValue = String(value);
        break;
      case "number":
        processedValue = Number(value);
        break;
      case "boolean":
        processedValue = value === "true" || value === true;
        break;
      case "select":
      case "lookup":
        processedValue = value;
        break;
      default:
        processedValue = value;
    }

    props.setPatchRequest((prevRequest) => {
      const existingContact = prevRequest.contacts.find(
        (contact) => contact.id === id
      );

      if (existingContact) {
        return {
          ...prevRequest,
          contacts: prevRequest.contacts.map((contact) =>
            contact.id === id
              ? {
                  ...contact,
                  value: {
                    ...contact.value,
                    [col]: processedValue,
                  },
                }
              : contact
          ),
        };
      } else {
        return {
          ...prevRequest,
          contacts: [
            ...prevRequest.contacts,
            {
              id,
              value: { [col]: processedValue },
            },
          ],
        };
      }
    });
  };

  const handleCustomInputChange = (id, col, value, fieldCol) => {
    // Process value based on fieldCol type
    let processedValue;

    switch (fieldCol) {
      case "text":
      case "textarea":
        processedValue = String(value);
        break;
      case "number":
        processedValue = Number(value);
        break;
      case "boolean":
        processedValue = value === "true" || value === true;
        break;
      case "select":
        processedValue = value;
        break;
      case "date":
        processedValue = value instanceof Date ? value.toISOString() : value;
        break;
      default:
        processedValue = value;
    }

    // Update contacts in the patch request with processed value
    props.setPatchRequest((prevRequest) => {
      const existingContact = prevRequest.contacts.find(
        (contact) => contact.id === id
      );

      if (existingContact) {
        return {
          ...prevRequest,
          contacts: prevRequest.contacts.map((contact) =>
            contact.id === id
              ? {
                  ...contact,
                  value: {
                    ...contact.value,
                    custom_data: {
                      ...contact.value.custom_data,
                      [col]: processedValue,
                    },
                  },
                }
              : contact
          ),
        };
      } else {
        return {
          ...prevRequest,
          contacts: [
            ...prevRequest.contacts,
            {
              id,
              value: {
                custom_data: { [col]: processedValue },
              },
            },
          ],
        };
      }
    });
  };

  // const handleCustomInputChange = (id, col, value) => {
  //   props.setPatchRequest((prevRequest) => {
  //     const existingContact = prevRequest.contacts.find(
  //       (contact) => contact.id === id
  //     );

  //     if (existingContact) {
  //       return {
  //         ...prevRequest,
  //         contacts: prevRequest.contacts.map((contact) =>
  //           contact.id === id
  //             ? {
  //                 ...contact,
  //                 value: {
  //                   ...contact.value,
  //                   custom_data: {
  //                     ...contact.value.custom_data,
  //                     [col]: value,
  //                   },
  //                 },
  //               }
  //             : contact
  //         ),
  //       };
  //     } else {
  //       return {
  //         ...prevRequest,
  //         contacts: [
  //           ...prevRequest.contacts,
  //           {
  //             id,
  //             value: {
  //               custom_data: { [col]: value },
  //             },
  //           },
  //         ],
  //       };
  //     }
  //   });
  // };
  const [tempPhoneNumbers, setTempPhoneNumbers] = useState({});
  const [numberError, setNumberError] = useState(null);

  const handlePhoneInputChange = (newPhoneNumber, rowId, col) => {
    setTempPhoneNumbers((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [col]: newPhoneNumber, // Set the new phone number for the specific column
      },
    }));

    const countryCode = getValue(orgInfo, `country`, "").toLowerCase() || "in";

    // Validate the phone number format
    if (!isValidPhoneNumber(newPhoneNumber, countryCode.toUpperCase())) {
      setNumberError("Invalid number");
    } else {
      setNumberError("Validated");
    }
  };

  // console.log("Updating phone number:", tempPhoneNumbers);

  // Reset tempPhoneNumbers state when "Cancel" is clicked
  const handleCancel = (rowId, col) => {
    setTempPhoneNumbers((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [col]: getValue(
          props.sheetRequest.find((item) => item.id === rowId),
          col,
          ""
        ),
      },
    }));
  };

  // Confirm phone input changes on "Update"
  const handleUpdate = (rowId, col) => {
    const newPhoneNumber = tempPhoneNumbers[rowId]?.[col]; // Use optional chaining
    if (!newPhoneNumber) {
      console.error("No phone number to update for", rowId, col);
      return; // Exit early if the phone number is undefined
    }

    props.setPatchRequest((prevRequest) => {
      const existingIndex = prevRequest.contacts.findIndex(
        (contact) => contact.id === rowId
      );

      const updatedContacts =
        existingIndex !== -1
          ? prevRequest.contacts.map((contact, index) =>
              index === existingIndex
                ? {
                    ...contact,
                    value: { ...contact.value, [col]: newPhoneNumber },
                  }
                : contact
            )
          : [
              ...prevRequest.contacts,
              { id: rowId, value: { [col]: newPhoneNumber } },
            ];

      return { ...prevRequest, contacts: updatedContacts };
    });

    props.setSheetRequest((prevRequest) =>
      prevRequest.map((contact) =>
        contact.id === rowId ? { ...contact, [col]: newPhoneNumber } : contact
      )
    );
  };

  return (
    <div>
      {getValue(props, `isLoading`, false) &&
      !getValue(UrlParams, `search`, "") ? (
        <ListLoader />
      ) : (getValue(Object.keys(UrlParams), `length`, 0) &&
          getValue(props, `data.length`, 0) === 0) ||
        getValue(localStorage, `sf:companies`, "") ? (
        <FiltersNoDataPage module={props.module} />
      ) : getValue(props, `data.length`, 0) > 0 ? (
        <>
          {getValue(props, `isLoading`, false) ? (
            <ListLoader />
          ) : (
            <div
              className={"datatable_container"}
              style={{
                overflow: getValue(props, `isLoading`, false)
                  ? "hidden"
                  : "auto",
                borderBottom: getValue(props, `isLoading`, false) && "none",
              }}
              ref={fixedColumnRef}
            >
              {getValue(props, `data.length`, 0) > 0 ? (
                <div className="datatable_table_wrapper">
                  <table className="datatable_table">
                    <thead
                      className="datatable_table_thead position-relative"
                      ref={tourRef5}
                    >
                      <tr className="datatable_table_tr">
                        <th className="datatable_table_th sticky-column">
                          {getValue(props, `disableCheckbox`, false) ? (
                            <TaskSvgComponent color={"#5D6D7E"} />
                          ) : getValue(selectedIds, `length`, 0) ===
                            getValue(props, `data.length`, 0) ? (
                            <CustomCheckbox
                              checked={
                                getValue(selectedIds, `length`, 0) ===
                                getValue(props, `data.length`, 0)
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleChangeAllCheckbox(
                                  getValue(selectedIds, `length`, 0) ===
                                    getValue(props, `data.length`, 0)
                                    ? "all_checked"
                                    : "all_not_checked"
                                )
                              }
                              className={"datatable_table_th_checkbox"}
                            />
                          ) : (
                            <CustomAllCheckbox
                              checked={
                                getValue(selectedIds, `length`, 0) > 0
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleChangeAllCheckbox(
                                  getValue(selectedIds, `length`, 0) > 0
                                    ? "all_checked"
                                    : "all_not_checked"
                                )
                              }
                              className={"datatable_table_th_checkbox"}
                            />
                          )}
                        </th>
                        {props.headers.map((col, index) => {
                          if (getValue(col, `is_selected`, false))
                            return (
                              <th className="datatable_table_th " key={index}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h6 className="header_text__12 me-2">
                                    {col === "name" &&
                                      getValue(
                                        props,
                                        `singular_label`,
                                        ""
                                      )}{" "}
                                    {capitalizeEverything(
                                      handleDisplayHeaderItem(col)
                                    )}
                                  </h6>
                                  {(getValue(props, `module`, "") ===
                                    "contacts" ||
                                    getValue(props, `module`, "") === "leads" ||
                                    getValue(props, `module`, "") ===
                                      "companies" ||
                                    getValue(props, `module`, "") ===
                                      "deals") &&
                                    getValue(col, `api_name`, "") !==
                                      "pipeline_stage" && (
                                      <SortTooltip
                                        title={`Sort`}
                                        value={
                                          (getValue(col, `custom`, false)
                                            ? getValue(col, `column_name`, "")
                                            : getValue(col, `api_name`, "")) ===
                                          getFilterName()
                                            ? getFilterValue()
                                            : ""
                                        }
                                        label={`label`}
                                        onChange={(e) => {
                                          handleSelectValue(
                                            getValue(col, `api_name`, ""),
                                            getValue(e, `value`, ""),
                                            getValue(col, `custom`, false)
                                              ? getValue(col, `column_name`, "")
                                              : ""
                                          );
                                          setSortBy({
                                            ...sort_by,
                                            value: getValue(e, `value`, ""),
                                          });
                                        }}
                                        sortSelected={
                                          getValue(col, `custom`, false)
                                            ? getValue(col, `column_name`, "")
                                            : getValue(col, `api_name`, "") ===
                                              getFilterName()
                                            ? true
                                            : false
                                        }
                                        data={sortOptions}
                                        selectKey={"value"}
                                        editInline
                                      />
                                    )}
                                </div>
                              </th>
                            );
                        })}
                        {/* <th className="datatable_table_th ">Actions</th> */}
                      </tr>
                    </thead>
                    <tbody className="datatable_table_tbody " ref={tourRef6}>
                      {getValue(props, "sheetRequest", {}).map(
                        (row, rowMainIndex) => {
                          return (
                            <tr
                              key={getValue(row, "id", "")}
                              className={`datatable_table_tr ${
                                getValue(props, "selectedIds", []).includes(
                                  getValue(row, "id", "")
                                )
                                  ? "active_background"
                                  : ""
                              }`}
                            >
                              <td className="datatable_table_td">
                                {getValue(props, "disableCheckbox", false) ? (
                                  <TaskSvgComponent color={"#5D6D7E"} />
                                ) : (
                                  <Checkbox
                                    title={getDisplayText(row)}
                                    checked={getValue(
                                      props,
                                      "selectedIds",
                                      []
                                    ).includes(getValue(row, "id", ""))}
                                    onChange={() =>
                                      handleChangeCheckbox(
                                        getValue(row, "id", "")
                                      )
                                    }
                                  />
                                )}
                              </td>

                              {getValue(props, "headers", "").map(
                                (item, idx) => {
                                  let col = getValue(item, "api_name", "");

                                  // Find matching field, if any, based on api_name
                                  const matchingField = getValue(
                                    props,
                                    "fields",
                                    []
                                  ).find(
                                    (field) =>
                                      getValue(field, "api_name", "") === col
                                  );

                                  return (
                                    <>
                                      {col === "record_num" ? (
                                        <td
                                          className="datatable_table_td header_blue_text__14"
                                          key={idx}
                                          onClick={() =>
                                            getValue(props, `module`, "") &&
                                            getValue(props, `module`, "") !==
                                              "tasks" &&
                                            handleNavigate(
                                              `/${getValue(
                                                params,
                                                `orgId`,
                                                ""
                                              )}/crm/${getValue(
                                                props,
                                                `module`,
                                                ""
                                              )}/detail/${getValue(
                                                row,
                                                `id`,
                                                ""
                                              )}`
                                            )
                                          }
                                        >
                                          {handleDisplayItem(
                                            col,
                                            appendCustomData(row)
                                          )}
                                        </td>
                                      ) : (
                                        <td
                                          className="datatable_table_td"
                                          key={idx}
                                        >
                                          {matchingField ? (
                                            <SheetEditor
                                              col={col}
                                              row={row}
                                              headers={props.headers}
                                              findStageColor={findStageColor}
                                              handleDisplayItem={
                                                handleDisplayItem
                                              }
                                              appendCustomData={
                                                appendCustomData
                                              }
                                              handleChangeStage={
                                                handleChangeStage
                                              }
                                              stageList={props.stageList}
                                              orgInfo={getValue(
                                                props,
                                                "orgInfo",
                                                ""
                                              )}
                                              tempPhoneNumbers={
                                                tempPhoneNumbers
                                              }
                                              numberError={numberError}
                                              handlePhoneInputChange={
                                                handlePhoneInputChange
                                              }
                                              handleCancel={handleCancel}
                                              handleUpdate={handleUpdate}
                                              item={item}
                                              field={matchingField}
                                              handleInputChange={
                                                handleInputChange
                                              }
                                              handleCustomInputChange={
                                                handleCustomInputChange
                                              }
                                            />
                                          ) : col === "pipeline_stage" ? (
                                            <SheetDropdownSelector
                                              row={row}
                                              col={col}
                                              findStageColor={findStageColor}
                                              stageList={props.stageList}
                                              handleDisplayItem={
                                                handleDisplayItem
                                              }
                                              appendCustomData={
                                                appendCustomData
                                              }
                                              handleChangeStage={
                                                handleChangeStage
                                              }
                                            />
                                          ) : (
                                            <div>
                                              {handleDisplayItem(
                                                col,
                                                appendCustomData(row)
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                getEmptyScreens()
              )}
            </div>
          )}
        </>
      ) : (
        getEmptyScreens()
      )}
    </div>
  );
};

export default CustomSheetTable;
