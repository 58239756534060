export const DashboardGraphTypeList = [
  // { value: "standard", label: "Standard" },
  // { value: "growth_index", label: "Growth Index" },
  // { value: "basic", label: "Basic" },
  // { value: "scorecard", label: "Scorecard" },
  // { value: "rankings", label: "Rankings" },
  { value: "column_chart", label: "Column Chart" },
  { value: "donut_chart", label: "Donut Chart" },
  { value: "pie_chart", label: "Pie Chart" },
  { value: "bar_chart", label: "Bar Chart" },
  { value: "line_chart", label: "Line Chart" },
  { value: "table_chart", label: "Table Chart" },
  { value: "funnel_chart", label: "Funnel Chart" },
  { value: "area_chart", label: "Area Chart" },
  { value: "heat_map", label: "Heat Map" },
];
export const DashboardKPITypeList = [
  { value: "standard", label: "Standard" },
  { value: "growth_index", label: "Growth Index" },
  { value: "basic", label: "Basic" },
  { value: "scorecard", label: "Scorecard" },
  { value: "rankings", label: "Rankings" },
];

export const DashboardComponentTypeList = [
  { value: "chart", label: "Chart" },
  { value: "kpi", label: "KPI" },
];
export const DashboardGroupingList = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "45", label: "45" },
  { value: "50", label: "50" },
  { value: "55", label: "55" },
  { value: "60", label: "60" },
  { value: "65", label: "65" },
  { value: "70", label: "70" },
  { value: "75", label: "75" },
];

export const DashboardModuleColumnList = [
  { value: "created_by", label: "Created By" },
  { value: "updated_by", label: "Updated By" },
  { value: "created_at", label: "Created At" },
  { value: "updated_at", label: "Updated At" },
  { value: "pipeline_stage", label: "Pipeline Stage" },
];

export const DashboardDateGranularityList = [
  { value: "day", label: "Day" },
  { value: "month", label: "Month" },
  { value: "year", label: "Year" },
];

export const DashboardDurationTypeList = [
  { value: "today", label: "Today" },
  { value: "this_week", label: "This Week" },
  { value: "this_month", label: "This Month" },
  { value: "this_year", label: "This Year" },
  { value: "last", label: "Last" },
  { value: "custom", label: "Custom" },
];

export const DashboardShowRankList = [
  { value: "top_5", label: "Top 5" },
  { value: "top_10", label: "Top 10" },
  { value: "top_15", label: "Top 15" },
  { value: "top_20", label: "Top 20" },
  { value: "bottom_5", label: "Bottom 5" },
  { value: "bottom_10", label: "Bottom 10" },
  { value: "bottom_15", label: "Bottom 15" },
  { value: "bottom_20", label: "Bottom 20" },
];

export const DashboardShowRankChartList = [
  { value: "label_ascending", label: "Label Ascending" },
  { value: "label_descending", label: "Label Descending" },
  { value: "value_ascending", label: "Value Ascending" },
  { value: "value_descending", label: "Value Descending" },
];

export const DashboardCompareToList = [
  { value: "previous_period", label: "Previous Period" },
  { value: "same_period_last_year", label: "Same Period Last Year" },
  { value: "custom", label: "Custom" },
];

export const DashboardCompareObjectList = [
  { value: "positive", label: "Positive" },
  { value: "negative", label: "negative" },
];

export const DashboardFilterCondition = [
  { value: "and", label: "AND" },
  { value: "or", label: "OR" },
];
