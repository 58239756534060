import "./Button.scss";

export default function ButtonComponent(props: any) {
  const buttonTypes = (type: any) => {
    const classType = `dashboard-button--${type}`;
    return classType;
  };
  return (
    <>
      <button
        className={`dashboard-button ${
          props.buttonType && buttonTypes(props.buttonType)
        } px-4 mx-1 ${
          props.squre ? "border-radius__5" : ""
        } d-flex justify-content-center align-items-center`}
        onClick={() => {
          props.onClickHandler();
        }}
        {...props}
      >
        {props.icon}
        {props.buttonText}
      </button>
    </>
  );
}
