import { getValue } from "@utils/lodash";
// import EmailPopup from "@components/CommonComponents/Dialogs/CenteredAlignedPopup/SendEmail";
// import emojione from "emojione";
// import 'react-multi-email/dist/style.css';
import EmailPopup from "@components/Dialogs/CenteredAlignedPopup/SendEmail";
import MoreEmailsTooltip from "./more-email-tooltip";

function CommonSendEmailInfoPopup(props: any) {
  const {
    isPopupVisible,
    handleSubmit,
    isLoading,
    handleClose,
    handleReply,
  } = props;
  const renderHtmlWithEmojis = (html: any) => {
    // Convert the Unicode emojis to images using emojione
    // return emojione.toImage(html);
  };

  const findEmails = (to: string, cc: string, bcc: string) => {
    let To = to ? to.split(",") : [];
    let Bcc = bcc ? bcc.split(",") : [];
    let Cc = cc ? cc.split(",") : [];

    const combinedArray = [...To, ...Bcc, ...Cc];
    return combinedArray;
  };
  return (
    <div>
      <EmailPopup
        isPopupVisible={isPopupVisible}
        popupProperties={{
          title: getValue(
            props,
            `info[${getValue(props, `info.length`, 0) - 1}].subject`,
            ""
          ),
          isMoreDetailsCTAVisible: true,
        }}
        title={getValue(
          props,
          `info[${getValue(props, `info.length`, 0) - 1}].subject`,
          ""
        )}
        handleSubmitButtonClick={() => handleSubmit()}
        handleCloseButtonClick={() => handleClose()}
        isLoading={isLoading}
        hideFooter
      >
        <div className={`email-popup_container px-4`}>
          <>
            {getValue(props, `info`, []).map((info: object, index: number) => {
              return (
                <div className={`email-popup_right-section`} key={index}>
                  <div
                    className={`email-popup_header-section d-flex align-items-center justify-content-between`}
                  >
                    <div
                      className={`email-popup_left-section d-flex align-items-center justify-content-between`}
                    >
                      <div>
                        <img
                          src={`/images/outgoing-mail.svg`}
                          height={"20px"}
                          width={"20px"}
                          className="mt-3"
                        />
                      </div>
                      <div className="ms-3 mt-3">
                        <p className="mb-2 desc_text">
                          {getValue(info, `from`, "")}
                        </p>
                        <div className="d-flex">
                          <p className="desc_text">
                            {" "}
                            To :{" "}
                            {
                              findEmails(
                                getValue(info, `to`, ""),
                                getValue(info, `bcc`, ""),
                                getValue(info, `cc`, "")
                              )[0]
                            }
                            <span className="ms-3 text-decoration-underline"></span>
                          </p>
                          {findEmails(
                            getValue(info, `to`, ""),
                            getValue(info, `bcc`, ""),
                            getValue(info, `cc`, "")
                          ).length > 1 ? (
                            <MoreEmailsTooltip
                              info={info}
                              length={findEmails(
                                getValue(info, `to`, ""),
                                getValue(info, `bcc`, ""),
                                getValue(info, `cc`, "")
                              )}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`email-popup_right-section  `}>
                    {/* <p className="mt-4 header_blue_text">{convertCurrentDateWithTime(
                      getValue(info, `created_at`, "")
                    )}</p> */}
                  </div>

                  <div className={`email-popup_body-section`}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: decodeURIComponent(getValue(info, `html`, "")),
                      }}
                      className="pt-4 pb-4 py-4"
                    ></p>
                  </div>
                  <div className={`email-popup_body_submit-section`}>
                    <button
                      className={`email-popup_button`}
                      onClick={() => handleReply("reply", index)}
                    >
                      Reply
                    </button>
                    <button
                      className={`email-popup_button`}
                      onClick={() => handleReply("reply_all", index)}
                    >
                      Reply All
                    </button>
                    <button
                      className={`email-popup_button`}
                      onClick={() => handleReply("forward", index)}
                    >
                      Forward
                    </button>
                  </div>
                  <div className={`email-popup_border_section`}></div>
                </div>
              );
            })}
          </>
        </div>
      </EmailPopup>
    </div>
  );
}

export default CommonSendEmailInfoPopup;
