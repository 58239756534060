import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
import FormBuilder from "../FormBuilder";
import { motion } from "framer-motion";
import EditSvgComponent from "@assets/svg/edit";
import { Popover, Tooltip } from "antd";
import { useState } from "react";
import { EditOutlined, FormOutlined } from "@ant-design/icons";

const InlineEditTooltipComponent = (props) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const popoverContent = (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className={"inline_tooltip_edit_container"}>
        <FormBuilder
          fields={props.popupFields}
          setFields={props.setPopupFields}
          hideValidation
          module={props.module}
          duplicateAPINames={props.duplicateAPINames}
          setDuplicateAPINames={props.setDuplicateAPINames}
        />
        <div className="inline_toolto_footer_container d-flex mt-4 mx-4">
          <ButtonComponent
            buttonType={"scecondary"}
            buttonText={
              getValue(props, `first_title`, "")
                ? getValue(props, `first_title`, "")
                : "Cancel"
            }
            onClickHandler={() => setPopoverVisible(false)} // Close popover on cancel
          />
          {getValue(props, `permissions`, []).includes("update") && (
            <ButtonComponent
              buttonType={"primary"}
              buttonText={
                props.submitLoading
                  ? "Please wait..."
                  : getValue(props, `second_title`, "")
                  ? getValue(props, `second_title`, "")
                  : "Save"
              }
              onClickHandler={() => {
                props.handleSubmit();
                setPopoverVisible(false); // Close popover on save
              }}
              disabled={props.submitLoading || getValue(props,`duplicateAPINames.length`,0)>0}
            />
          )}
        </div>
      </div>
    </motion.div>
  );

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      placement="bottomLeft"
      overlayClassName="custom-popover"
      visible={popoverVisible} // Control visibility
      onVisibleChange={(visible) => setPopoverVisible(visible)} // Sync visibility
    >
      <Tooltip title="Edit">
        <div
          className={`inline_form_field border_input inline_field d-flex align-items-center cursor-pointer`}
        >
          <div className="input_value">
            <div
              className="image_hover_background"
              onClick={() => setPopoverVisible(!popoverVisible)}
            >
              <FormOutlined />
            </div>
          </div>
        </div>
      </Tooltip>
    </Popover>
  );
};

export default InlineEditTooltipComponent;
