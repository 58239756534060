import MainLayout from "@layouts/HomeLayout/NewLayout";
import { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CannedFolders from "./components/canned-folders";
import CannedTable from "./components/canned-table";
import "./canned.scss";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  createCannedResponseFolder,
  deleteCannedResponseFolder,
  getAllCannedFolderTableResponse,
  getAllCannedResponse,
  updateCannedResponseFolder,
} from "@services/canned-response.service";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import ListLoader from "@components/common/Loader";
import { Button, Modal } from "antd";
import InputRuleForm from "@components/InputRuleForm/form";
import { Plus } from "lucide-react";

const CannedResponse = ({ props }: any) => {
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({ name: "", id: "" });
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [tableData, setTableData] = useState({});
  const [isTableLoading, setIsTableLoading] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getCannedResponseList(true);
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getCannedResponseList = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = { page_no: 1, page_size: 100 };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllCannedResponse(queryRequest);
      if (resp) {
        setList(getValue(resp, "data", []));
      }
    } catch (error) {
      toast.error("Failed to load canned responses.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setConfirmLoading(true);
    try {
      const payload: { name: string; id?: string } = { name: request.name };
      if (request.id) {
        payload.id = request.id;
      }
      const resp = await createCannedResponseFolder(payload);
      if (resp) {
        toast.success("Created Successfully");
        setOpen(false);
        getCannedResponseList(true);
        setRequest({
          name: "",
          id: "",
        });
      } else {
        toast.error("Failed to create folder");
      }
    } catch (error) {
      toast.error("Error creating folder");
    } finally {
      setConfirmLoading(false);
      setCreateLoading(false);
    }
  };

  const handleFolderUpdate = async () => {
    setConfirmLoading(true);
    try {
      const payload = { ...request };
      const resp = await updateCannedResponseFolder(payload);
      if (resp) {
        toast.success("Updated Successfully");
        setOpen(false);
        getCannedResponseList(true);
        setRequest({
          name: "",
          id: "",
        });
      } else {
        toast.error("Failed to create folder");
      }
    } catch (error) {
      toast.error("Error creating folder");
    } finally {
      setConfirmLoading(false);
      setCreateLoading(false);
    }
  };

  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete this folder - ${name}?`,
      content:
        "Once deleted, this folder and its canned responses cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteCannedResponseFolder(id);
          toast.success("Folder deleted successfully");
          getCannedResponseList(true);
        } catch (error) {
          toast.error("Error deleting folder");
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                           Canned table Section                             */
  /* -------------------------------------------------------------------------- */

  const getCannedTableList = async (status: boolean) => {
    try {
      setIsTableLoading(status);
      const payload = {
        folder: selectedFolder,
        page_no: 1,
        page_size: 10,
        order_by: "created_at",
        order_type: "asc",
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllCannedFolderTableResponse(queryRequest);
      if (resp) {
        setTableData(getValue(resp, "data", []));
      }
    } catch (error) {
      toast.error("Failed to load canned responses.");
    } finally {
      setIsTableLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Modal Section                             */
  /* -------------------------------------------------------------------------- */
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    if (request.id !== "") {
      handleFolderUpdate();
    } else {
      handleSubmit();
    }
  };

  const handleUpdateModal = (name: string, id: string) => {
    setRequest({ name, id });
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setRequest({
      name: "",
      id: "",
    });
  };

  console.log("list", list);

  /* -------------------------------------------------------------------------- */
  /*                            Handle folder selection                         */
  /* -------------------------------------------------------------------------- */
  const handleSelectFolder = (id: string) => {
    setSelectedFolder(id);
  };

  useEffect(() => {
    if (selectedFolder) {
      getCannedTableList(true);
    }
  }, [selectedFolder]);

  console.log("tableData", tableData);

  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        addChildren={
          <Button
            type="primary"
            size="large"
            icon={<Plus size={20} />}
            onClick={() =>
              navigate(
                `/${getValue(
                  params,
                  "orgId",
                  ""
                )}/settings/canned-response/create`
              )
            }
          >
            New Canned Response
          </Button>
        }
      />
      <CompanyProfileDetailsLayout classname="my-0">
        {isLoading ? (
          <ListLoader />
        ) : (
          <div className="row w-100">
            <CannedFolders
              params={params}
              list={list}
              showModal={showModal}
              handleUpdateModal={handleUpdateModal}
              handleDeleteModal={handleDeleteModal}
              selectedFolder={selectedFolder}
              handleSelectFolder={handleSelectFolder}
            />
            <CannedTable
              params={params}
              navigate={navigate}
              tableData={tableData}
              isTableLoading={isTableLoading}
            />
          </div>
        )}
        <Modal
          title={`${
            getValue(request, "id", "") ? "Edit" : "Create New"
          } Folder`}
          open={open}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, "name", "")}
            placeholder="Enter Folder Name"
            required
            label="Folder Name"
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: e.target.value,
              })
            }
          />
        </Modal>
      </CompanyProfileDetailsLayout>
    </MainLayout>
  );
};

export default CannedResponse;
