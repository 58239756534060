import { Link } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { handleEmailChange, handleTextChange } from "common/handleChange";
import React from "react";
import InputFormField from "../../common/inputForm";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import timezones from "@common/json/standardized-timezone-data.json";

export default function SignupForm(props: any) {
  const { request, setRequest, validator, handleSubmit, submitLoading } = props;
  const [visibility, setVisibility] = React.useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="signup-form-wrapper" onKeyDown={handleKeyDown}>
      {/* Add onKeyDown event */}
      <h2 className="signup-title text-center mt-4">
        Sign up for a 30 days free trial
      </h2>
      <div className="signup-form-details">
        <InputFormField
          inputType="TEXT"
          validator={validator}
          placeholder="Enter First Name"
          label="First Name"
          name="first_name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          value={getValue(request, "first_name", "")}
        />
        <InputFormField
          inputType="TEXT"
          validator={validator}
          placeholder="Enter Last Name"
          label="Last Name"
          name="last_name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          value={getValue(request, "last_name", "")}
        />
        <InputFormField
          inputType="EMAIL"
          validator={validator}
          icon="email"
          placeholder="Enter Email"
          label="Email"
          name="identifier"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleEmailChange(e, request, setRequest)
          }
          value={getValue(request, "identifier", "")}
        />
        <div className="position-relative">
          <InputFormField
            inputType={visibility ? "TEXT" : "PASSWORD"}
            validator={validator}
            icon={visibility ? "key" : "eye"}
            placeholder="Create Password"
            label="Password"
            name="password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, request, setRequest)
            }
            value={getValue(request, "password", "")}
          />
          <img
            src={
              !visibility
                ? "/images/visibility_off.svg"
                : "/images/visibility.svg"
            }
            className="visbility_icon"
            onClick={() => setVisibility(!visibility)}
            alt=""
          />
        </div>
        <div className="mb-3">
          <SearchToolTip
            placeholder="Enter Time Zone"
            onChange={(e: object) =>
              setRequest({
                ...request,
                timezone: getValue(e, "value", ""),
              })
            }
            label="label"
            width="365px"
            data={timezones}
            value={getValue(request, "timezone", "")}
            selectKey="value"
          />
        </div>
      </div>
      <button
        className="superteams-button__primary-button"
        onClick={handleSubmit}
        disabled={submitLoading}
      >
        {submitLoading ? "Please wait..." : "Sign up for Free"}
      </button>
      <p className="mt-3 ms-1 text-center">
        Already Registered?{" "}
        <Link to="/signin">
          <span className="header_text">Sign-in</span>
        </Link>{" "}
        here
      </p>
      <p className="signup-text text-center">
        By Clicking on “SIGNUP FOR FREE” you agree to our terms and acknowledge
        reading our <span>privacy notice</span>
      </p>
    </div>
  );
}
