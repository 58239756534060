import { Tooltip } from "react-tooltip";
import BackSvgComponent from "@assets/svg/back-link";
import { getValue } from "@utils/lodash";
import { getColorStyle } from "@common/colors";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "@components/Form/Button/Button";
import { Button, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";

export default function ChatbotSubHeader({
  subHeaderListTitle,
  platform,
  children,
  showBack,
  handleNavigateBack,
  params,
  permissions,
}: any) {
  const navigate = useNavigate();
  return (
    <div className={`sub-header-wrapper`}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            {showBack && (
              <ul onClick={handleNavigateBack}>
                <li className={`gap d-flex align-items-center cursor-pointer`}>
                  <BackSvgComponent />
                </li>
              </ul>
            )}

            <ul>
              <li className={`gap d-flex align-items-center cursor-pointer`}>
                {!showBack && (
                  <img src="/images/header/star.svg" className="img-fluid" />
                )}
                <h6 className={`submenu-name ${showBack ? "ms-3" : ""}`}>
                  {subHeaderListTitle}
                </h6>
                <Tag color="green">{platform}</Tag>
              </li>
            </ul>
          </div>
          {/* <h6
            className="header_text__16 color_primary cursor-pointer"
            onClick={() =>
              navigate(
                `/${getValue(
                  params,
                  `orgId`,
                  ""
                )}/chatbot/builder/create-flow/${getValue(params, `id`, "")}`
              )
            }
          >
            Edit Bot Builder
          </h6> */}
          {permissions.includes("update") && (
            <Button
              size="large"
              type="primary"
              icon={<EditOutlined />}
              onClick={() =>
                navigate(
                  `/${getValue(
                    params,
                    `orgId`,
                    ""
                  )}/chatbot/builder/create-flow/${getValue(params, `id`, "")}`
                )
              }
            >
              Edit Bot Builder
            </Button>
          )}
        </div>
        {children}
      </div>
      <Tooltip id={`pipeline`} className="tooltip" />
    </div>
  );
}
