import { getValue } from "@utils/lodash";
import React from "react";

const SheetTextEditor = ({
  col,
  row,
  fieldCol,
  handleDisplayItem,
  handleInputChange,
}: any) => {
  return (
    <input
      value={handleDisplayItem(col, row)}
      onChange={(e) =>
        handleInputChange(
          getValue(row, "id", ""),
          col,
          e.target.value,
          fieldCol
        )
      }
      className="sheet-view-input"
    />
  );
};

export default SheetTextEditor;
