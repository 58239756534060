import { useEffect, useState } from "react";
import TextHeaderTooltip from "../Dropdown/TextHeaderTooltip";
import { getValue } from "@utils/lodash";
import {
  convertCurrentDateWithTime,
  getDate1DayBefore,
  getDate1WeekBefore,
  getDateTime15MinutesBefore,
  getDateTime1HourBefore,
  getDateTime30MinutesBefore,
} from "@common/date-helpers";
import moment from "moment";
import SearchToolTip from "../Dropdown/SearchTooltip";
import { toast } from "sonner";
import TimePicker from "./DateTime";
import { formatString } from "@common/text-helpers";

function AlarmPicker(props: any) {
  const [data, setData] = useState([
    { label: `No ${getValue(props, `parent_field_label`, "").toLowerCase()}`, value: "" },
    { label: `At ${getValue(props, `parent_field_label`, "").toLowerCase()}`, value: "" },
    { label: "15 minutes before", value: "" },
    { label: "30 minutes before", value: "" },
    { label: "1 hour before", value: "" },
    { label: "1 day before", value: "" },
    { label: "1 week before", value: "" },
  ]);

  useEffect(() => {
    if (props.dueDateTime) {
      setData(getDateFunctions(props.dueDateTime));
    }
  }, [props.dueDateTime]);
  const getDateFunctions = (date: any) => {
    let label = getValue(props,`label`,'')&&getValue(props,`label`,'').split(' ')[0]
    const now = moment(); // Get the current date and time
    let array = [
      { label: `No ${label}`, value: "" },
      { label: `At ${getValue(props, `parent_field_label`, "").toLowerCase()}`, value: date },
      {
        label: "15 minutes before",
        value: getDateTime15MinutesBefore(moment(date)),
      },
      {
        label: "30 minutes before",
        value: getDateTime30MinutesBefore(moment(date)),
      },
      { label: "1 hour before", value: getDateTime1HourBefore(moment(date)) },
      { label: "1 day before", value: getDate1DayBefore(moment(date)) },
      { label: "1 week before", value: getDate1WeekBefore(moment(date)) },
    ];
    // Filter out items with past dates
    array = array.filter((item) => {
      if (item.value === "") {
        return true; // Keep items with empty values
      }
      return moment(item.value).isSameOrAfter(now);
    });
    return array;
  };
  const [custom, setCustom] = useState(false);
  return (
    <div className="">
      {props.dueDateTime ? (
        <div className="mt-2 d-flex align-items-center">
          {custom ? (
            <TimePicker
              {...props}
              onChange={props.onChange}
              value={getValue(props, `value`, "")}
              placeholder={props.placeholder}
            />
          ) : (
            <div className="w-50">
            <SearchToolTip
              title={getValue(props, `label`, "")}
              value={getValue(props, `value`, "")}
              placeholder={props.placeholder}
              name={`${getValue(props, `label`, "")}${
                getValue(props, `value`, "") ? " - " : ""
              }${" "}${
                getValue(props, `value`, "")
                  ? convertCurrentDateWithTime(getValue(props, `value`, ""))
                  : ""
              }`}
              onChange={props.onChange}
              label={`label`}
              data={data}
              selectKey={"value"}
              editInline
              form
              disabled={!props.dueDateTime ? true : false}
              name_key={props.api_name}
              arrow={true}
            />
            </div>
          )}
          {/* <p onClick={() => setCustom(!custom)}>Custom</p> */}
        </div>
      ) : (
        <p
          className="d-flex align-items-center disabled cursor-pointer p-2"
          onClick={() =>
            toast.error(
              `Please select ${formatString(
                getValue(props, `parent_field`, "")
              )}`
            )
          }
        >
          <input type="checkbox" disabled className="common-checkbox" />
          <span className="ms-2">{getValue(props, `label`, "")}</span>
        </p>
      )}
    </div>
  );
}

export default AlarmPicker;
