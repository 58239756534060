import React, { useEffect, useRef, useState } from "react";
import { getValue } from "@utils/lodash";
import SettingModuleNavbar from "../common/Navbar";
import SettingModuleHOCComponent from "../common/detailHoc";
import FieldsEditPopup from "./EditPopup";
import ListLoader from "@components/common/Loader";
import FieldsLeftMenu from "./Sidebar/ManagefieldsLeft";
// import ManageTable from "@components/v2/Settings/ModuleAndField/ManageFields/ManageTable";
// import ClassNames from "./editfields.module.scss";
// import CommonRightPopup from "@components/CommonComponents/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import {
  deleteFormFields,
  deletePipelineFormFields,
  updateFormFields,
  updatePipelineField,
  updatePipelineFields,
  updatePipelineFormFields,
} from "@services/pipeline.service";
import CustomFieldPopup from "./custom-field-popup";
import {
  createModuleField,
  updateModuleField,
  updateModuleFields,
} from "@services/module-fields.service";
import {
  camelToSnake,
  covertCamelToSnake,
  removeNullOrUndefinedProperties,
  removeNullValuesFromObjectArray,
} from "@common/text-helpers";
import { toast } from "sonner";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import ManageTable from "./table/ManageTable";
import Loader from "@components/common/Loader/loading";
function SettingsModuleFields(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                      Module  SubPipline Section                            */
  /* -------------------------------------------------------------------------- */

  const [isVisibile, setVisisbility] = useState(false);
  const handleOpen = (obj: object, index: number) => {
    setRequest({
      ...request,
      name: getValue(obj, `name`, ""),
      seq_num: index,
      required: getValue(obj, `required`, ""),
    });
    setId(getValue(obj, `id`, ""));
    handleVisibility();
  };
  const handleVisibility = () => {
    setVisisbility(!isVisibile);
  };
  const [request, setRequest] = useState({
    name: "",
    required: true,
    seq_num: 1,
  });

  const [id, setId] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    if (props.selectedPipeline) {
      try {
        let payload = request;
        setSubmitLoading(true);
        let resp = await updatePipelineField(
          getValue(props, `params.id`, ""),
          props.selectedPipeline,
          payload,
          ""
        );
        if (resp) {
          handleVisibility();
          setId("");
          setSubmitLoading(false);
          props.getData(props.selectedPipeline);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const handleEditFields = async (name: string, status: any) => {
    let selectedData;
    if (status) {
      selectedData = getValue(props, `allFields`, []).filter(
        (item: object) => getValue(item, `api_name`, "") === name
      );
    } else {
      selectedData = getValue(props, `selectedFields`, []).filter(
        (item: object) => getValue(item, `api_name`, "") === name
      );
    }
    if (status) {
      if (
        getValue(props, `params.code`, "") !== "tasks" &&
        getValue(props, `params.code`, "") !== "calls" &&
        getValue(props, `params.code`, "") !== "meetings"
      ) {
        let resp = await updatePipelineFormFields(
          getValue(props, `selectedOption.module_id`, ""),
          getValue(props, `selectedOption.id`, ""),
          {
            module_field_id: getValue(selectedData, `[${0}].id`, ""),
            seq_num: getValue(props, `selectedFields.length`, 0) + 1,
            required: false,
          }
        );
        props.getData(props.selectedPipeline);
      } else {
        let resp = await updateFormFields(getValue(props, `params.id`, ""), {
          module_field_id: getValue(selectedData, `[${0}].id`, ""),
          seq_num: getValue(props, `selectedFields.length`, 0) + 1,
          required: false,
        });
        props.getTaskFields();
      }
    } else {
      if (
        getValue(props, `params.code`, "") !== "tasks" &&
        getValue(props, `params.code`, "") !== "calls" &&
        getValue(props, `params.code`, "") !== "meetings"
      ) {
        let resp = await deletePipelineFormFields(
          getValue(props, `selectedOption.module_id`, ""),
          getValue(props, `selectedOption.id`, ""),
          getValue(selectedData, `[${0}].form_field_id`, "")
        );
        props.getData(props.selectedPipeline);
      } else {
        let resp = await deleteFormFields(
          getValue(props, `params.id`, ""),
          getValue(selectedData, `[${0}].form_field_id`, "")
        );
        props.getTaskFields();
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                          Custom Fields Section                             */
  /* -------------------------------------------------------------------------- */
  let initialRequest: any = {
    main_type: "",
    module_id: "",
    label: "",
    name: "",
    input_type: "",
    placeholder: "",
    hint: "",
    track_history: false,
    unique: false,
    attributes: {
      default_value: null,
      regex_pattern: "",
    },
    default_value: {},
    dropdown_options: [
      { label: "", value: "" },
      { label: "", value: "" },
    ],
  };

  /* -------------------------------------------------------------------------- */
  /*                            Onchange Section                                */
  /* -------------------------------------------------------------------------- */
  const [isFieldVisibile, setIsFieldVisisbility] = useState(false);
  const handleFieldVisibility = () => {
    setIsFieldVisisbility(!isFieldVisibile);
  };
  const defaultOptions = useRef<any>([]);
  const [customFieldRequest, setCustomFieldRequest] =
    useState<any>(initialRequest);
  const resetCustomField = () => {
    // setCustomFieldRequest({ ...customFieldRequest, initialRequest });
    setCustomFieldRequest(initialRequest);
  };
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getType = (name: string) => {
    switch (name) {
      case "currency":
      case "email":
      case "url":
      case "tel":
      case "time":
        return getValue(customFieldRequest, `main_type`, "");
      default:
        return getValue(customFieldRequest, `input_type`, "");
    }
  };
  const [submitFieldLoading, setSubmitFieldLoading] = useState(false);
  const [editId, setEditId] = useState("");
  const handleSubmitCustomField = async () => {
    let req = JSON.parse(JSON.stringify(customFieldRequest));
    let options = req["dropdown_options"].filter((item: any) => item.value);
    req["dropdown_options"] =
      getValue(req, `input_type`, "") === "select" ||
      getValue(req, `input_type`, "") === "select_multiple"
        ? options.map((item: object, index: number) =>
            getValue(item, `id`, "")
              ? {
                  value: getValue(item, `value`, ""),
                  label: getValue(item, `label`, ""),
                  seq_num: index + 1,
                  archived: getValue(item, `archived`, false),
                  id: getValue(item, `id`, ""),
                }
              : {
                  value: getValue(item, `value`, ""),
                  label: getValue(item, `label`, ""),
                  seq_num: index + 1,
                  archived: getValue(item, `archived`, false),
                }
          )
        : getValue(req, `input_type`, "") === "boolean_select" ||
          getValue(req, `input_type`, "") === "boolean_checkbox" ||
          getValue(req, `input_type`, "") === "boolean_radio"
        ? []
        : [];
    req["input_type"] = getType(getValue(customFieldRequest, `main_type`, ""));
    req["main_type"] = "";
    // req["attributes"] = removeNullOrUndefinedProperties(req["attributes"]);
    req["api_name"] = editId
      ? req["name"]
      : covertCamelToSnake(getValue(customFieldRequest, `label`, ""));
    delete req["required"];
    delete req["attributes"]; // check later
    removeNullValuesFromObjectArray(req["dropdown_options"]);
    try {
      setSubmitFieldLoading(true);
      let resp;
      if (editId) {
        resp = await updateModuleFields(
          getValue(props, `params.id`, ""),
          editId,
          removeNullOrUndefinedProperties(req)
        );
      } else {
        resp = await createModuleField(
          getValue(props, `params.id`, ""),
          removeNullOrUndefinedProperties(req)
        );
      }
      if (resp) {
        let obj = {
          module_field_id: getValue(resp, `data.id`, ""),
          required: getValue(customFieldRequest, `required`, false),
          seq_num: getValue(props, `selectedFields.length`, 0),
        };
        setSubmitFieldLoading(false);
        handleFieldVisibility();
        resetCustomField();
        if (!editId) {
          saveFields(obj);
        } else {
          if (
            getValue(props, `params.code`, "") !== "tasks" &&
            getValue(props, `params.code`, "") !== "calls" &&
            getValue(props, `params.code`, "") !== "meetings"
          ) {
            props.getData(props.selectedPipeline);
          } else {
            props.getTaskFields();
          }
        }
        toast.success(`${editId ? "Updated" : "Created"} successfully`);
      } else {
        setSubmitFieldLoading(false);
      }
    } catch (error) {
      setSubmitFieldLoading(false);
    }
  };
  const saveFields = async (response: any) => {
    let resp;
    if (
      getValue(props, `params.code`, "") !== "tasks" &&
      getValue(props, `params.code`, "") !== "calls" &&
      getValue(props, `params.code`, "") !== "meetings"
    ) {
      resp = await updatePipelineFormFields(
        getValue(props, `selectedOption.module_id`, ""),
        getValue(props, `selectedOption.id`, ""),
        response
      );
      props.getData(props.selectedPipeline);
    } else {
      resp = await updateFormFields(getValue(props, `params.id`, ""), response);
      props.getTaskFields();
    }
    // if (resp) {
    //   props.getData(props.selectedPipeline);
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Edit Section                                 */
  /* -------------------------------------------------------------------------- */
  const fieldList = [
    { value: "text", label: "Text", sub_type: true },
    { value: "numarical", label: "Numerical", sub_type: true },
    { value: "currency", label: "Currency", sub_type: false },
    { value: "email", label: "Email", sub_type: false },
    { value: "url", label: "URL", sub_type: false },
    { value: "tel", label: "Phone", sub_type: false },
    { value: "select", label: "Dropdown", sub_type: true },
    { value: "boolean_choice", label: "Boolean Choice", sub_type: true },
    { value: "date", label: "Date Picker", sub_type: true },
    { value: "time", label: "Time Picker", sub_type: false },
    { value: "lookup", label: "Lookup", sub_type: false },
  ];
  const subTypes = {
    text: [
      { value: "text", label: "Single Line" },
      { value: "textarea", label: "Multi Line" },
    ],
    select: [
      { value: "select", label: "Single Select" },
      { value: "select_multiple", label: "Multi Select" },
    ],
    boolean_choice: [
      { value: "boolean_select", label: "Boolean Select" },
      { value: "boolean_checkbox", label: "Boolean Checkbox" },
      { value: "boolean_radio", label: "Boolean Radio" },
    ],
    numarical: [
      { value: "integer", label: "Number" },
      { value: "decimal", label: "Decimal" },
      { value: "percent", label: "Percent" },
      { value: "autonumber", label: "Auto Number" },
      { value: "bigint", label: "Long Number" },
    ],
    date: [
      { value: "date", label: "Date Picker" },
      { value: "datetime", label: "Date & Time Picker" },
    ],
  };

  const fieldTypeList = (value: string) => {
    switch (value) {
      case "text":
      case "textarea":
        return "text";
      case "select":
      case "select_multiple":
        return "select";
      case "boolean_select":
      case "boolean_checkbox":
      case "boolean_radio":
        return "boolean_choice";
      case "integer":
      case "decimal":
      case "percent":
      case "autonumber":
      case "bigint":
        return "numarical";
      case "date":
      case "datetime":
        return "date";
      case "currency":
        return "currency";
      case "email":
        return "email";
      case "url":
        return "url";
      case "tel":
        return "tel";
      case "time":
        return "time";
      case "lookup":
        return "lookup";
      default:
        return "";
    }
  };

  const findType = (name: any) => {
    switch (name) {
      case "lookup":
        return "select";
      default:
        return name;
    }
  };

  const addDefaultOptions = (name: string, options: any) => {
    let defaultOptionsMain: any = [];
    if (
      name === "boolean_select" ||
      name === "boolean_checkbox" ||
      name === "boolean_radio"
    ) {
      defaultOptionsMain = [
        { value: null, label: "None" },
        { value: true, label: "Yes" },
        { value: false, label: "No" },
        // ...options, // Adding existing options if needed
      ];
    } else {
      const filteredOptions = options.filter((item: any) => item.value);
      defaultOptionsMain = [{ value: "", label: "None" }, ...filteredOptions];
    }
    // return defaultOptions;
    defaultOptions.current = defaultOptionsMain;
  };
  const handleEditCustomFields = (obj: any) => {
    setEditId(getValue(obj, `id`, ""));
    setCustomFieldRequest({
      ...customFieldRequest,
      main_type: fieldTypeList(getValue(obj, `input_type`, "")),
      // module_id: getValue(obj, `module_id`, ""),
      label: getValue(obj, `label`, ""),
      name: getValue(obj, `name`, ""),
      input_type: getValue(obj, `input_type`, ""),
      placeholder: getValue(obj, `placeholder`, ""),
      hint: getValue(obj, `hint`, ""),
      required: getValue(obj, `required`, ""),
      track_history: getValue(obj, `track_history`, ""),
      unique: getValue(obj, `unique`, false),
      // attributes: getValue(obj, `attributes`, {}),
      default_value: getValue(obj, `default_value`, ""),
      dropdown_options: getValue(obj, `dropdown_options`, []),
    });
    addDefaultOptions(
      getValue(obj, `input_type`, ""),
      getValue(obj, `dropdown_options`, [])
    );
    setTimeout(() => {
      handleFieldVisibility();
    }, 100);
  };
  const handleCustomFieldPopup = () => {
    resetCustomField();
    setEditId("");
    setIsFieldVisisbility(!isFieldVisibile);
  };
  const handleOpenCustomFieldPopup = () => {
    resetCustomField();
    setEditId("");
    handleFieldVisibility();
  };
  useEffect(() => {
    if (!editId) {
      resetCustomField();
    }
  }, [!editId]);
  return (
    <div className={`main_container_fields d-flex`}>
      <FieldsLeftMenu
        allFields={getValue(props, `allFields`, [])}
        usedFields={getValue(props, `selectedFields`, [])}
        handleEditFields={handleEditFields}
      />
      <div className="w-100">
        <SettingModuleNavbar
          tab={"fields"}
          params={getValue(props, `params`, {})}
          handleNavigate={props.handleNavigate}
          toggle={props.toggle}
          code={getValue(props, `params.code`, "")}
        />

        {props.isLoading || props.loading ? (
          <Loader small />
        ) : (
          <div className={`${"main_container_list"}`}>
            <ManageTable
              usedFields={getValue(props, `selectedFields`, [])}
              handleVisibility={handleOpen}
              handleOnDragEnd={props.handleOnDragEnd}
              handleEditFields={handleEditFields}
              handleFieldVisibility={() => handleOpenCustomFieldPopup()}
              selectedPipeline={props.selectedPipeline}
              getData={props.getData}
              getTaskFields={props.getTaskFields}
              handleEditCustomFields={handleEditCustomFields}
              selectedOption={props.selectedOption}
              module={props.module}
            />
          </div>
        )}
        <CustomFieldPopup
          isVisibile={isFieldVisibile}
          handleVisibility={() => handleCustomFieldPopup()}
          handleSubmit={handleSubmitCustomField}
          submitLoading={submitFieldLoading}
          moduleName={getValue(props, `params.code`, "")}
          request={customFieldRequest}
          setRequest={setCustomFieldRequest}
          resetCustomField={resetCustomField}
          defaultOptions={defaultOptions}
          // setDefaultOptions={setDefaultOptions}
          fieldList={fieldList}
          subTypes={subTypes}
          editId={editId}
          permissions={["create"]}
        />
        <CommonRightPopup
          isPopupVisible={isVisibile}
          addCustomClass={"rolls-popup"}
          title="Edit Field"
          toggle={handleVisibility}
          first_title={"Cancel"}
          second_title={"Update"}
          handleSubmit={handleSubmit}
          width={"500px"}
          hideNew
        >
          <FieldsEditPopup
            title="Edit Field"
            handleBackButton={handleVisibility}
            submitLoading={submitLoading}
            request={request}
            setRequest={setRequest}
            handleSubmit={handleSubmit}
          />
        </CommonRightPopup>
      </div>
    </div>
  );
}

export default SettingModuleHOCComponent(SettingsModuleFields);
