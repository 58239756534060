import { getValue } from "@utils/lodash";
import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface

const GraphNoDataFound = (props: any) => {
  return (
    <div className="mt-5">
      <div className="d-flex justify-content-center align-items-center">
        <img src="/nodata/templates.svg" height={165} />
      </div>
      <p className="text-center small_text__14 mt-4 color_desc">
        {getValue(props, `title`, "")
          ? getValue(props, `title`, "")
          : "No data available"}
      </p>
    </div>
  );
};

export default GraphNoDataFound;
