export default function Linebreak() {
  return (
    <div className={`login-wrapper__line-break position-relative text-center`}>
      <span
        className={`login-wrapper__white-background position-relative d-inline-block mt-3 mb-3`}
      >
        or
      </span>
    </div>
  );
}
