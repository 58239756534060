import * as React from "react";
import { Button, Modal } from "antd";
import { getValue } from "@utils/lodash";
import bin from "./delete.json";
import "./addpopup.scss";
import { ExclamationCircleFilled } from "@ant-design/icons";

const ConfirmModal = (props: any) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bin,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onCancel={props.handleModal}
      onOk={props.handleSubmit}
      title={
        <div className="d-flex align-items-center gap-2">
          <img src="/images/info-delete.png" width={20} height={20} />
          Are you sure you want to continue?
        </div>
      }
      footer={[
        <Button
          key="cancel"
          onClick={props.handleModal}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.handleSubmit}
          className="submit__button"
        >
          Submit
        </Button>,
      ]}
    >
      {/* <section className="">
        <div className="">
          <div className="m-3 p-3">
            <img src="/images/info-delete.png" className="delete-icon" />
            <p className="delete_text mt-1">Are you sure ?</p>
            <p className="small_text__16 mt-2">
              you want to continue
              <b>{getValue(props, `deleteValue`, "")}</b>{" "}
            </p>
          </div>
        </div>
      </section> */}
      <div>
        {props.content
          ? props.content
          : "By continuing you be deleting the user and associated data."}
      </div>
    </Modal>
  );
};

export default ConfirmModal;
