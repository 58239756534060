import { getValue } from "@utils/lodash";
import React from "react";

function NotFoundDetail(props: any) {
  return (
    <div>
      <div className="d-flex justify-content-center mt-3">
        {/* <img src={`/images/not-found.png`} height={'300px'} width={'300px'}/> */}
        <img src={`/nodata/notes.svg`} height={"150px"} width={"150px"} />
      </div>
      <p className="text-center small_text__14 mt-2 mb-3 color_desc">
        {getValue(props, `title`, "")
          ? getValue(props, `title`, "")
          : "No data added"}
      </p>
    </div>
  );
}

export default NotFoundDetail;
