import { getValue } from "@utils/lodash";
import "../broadcastTemplate.scss";
import { Button, Modal } from "antd";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";

function AddWhatsappVariable(props: any) {
  const {
    isOpen,
    handleModal,
    handleAddTags,
    allTags,
    tagsByPipelineID,
    allPipelinesList,
    pipelineId,
  } = props;

  return (
    <Modal
      open={isOpen}
      onCancel={handleModal}
      footer={[
        <Button key="cancel" onClick={handleModal} className="cancel__button">
          Cancel
        </Button>,
      ]}
      title="Select Attribute"
    >
      <div className="px-3 pb-3">
        <p className="sample--template-text mt-1">
          Use variable as placeholders. Variables are filled in automatically
          when they are sent
        </p>
      </div>
      <div className="border-bottom"></div>
      <div className="px-3 py-2">
        <SearchToolTip
          label="label"
          data={allPipelinesList}
          selectKey="value"
          value={pipelineId}
          onChange={(e: any) => tagsByPipelineID(getValue(e, "id", ""))}
          name="Contact Pipelines"
          placeholder="Choose Pipeline"
        />
      </div>
      <div className="d-flex gap-3 flex-wrap mt-3">
        {getValue(allTags, `length`, 0) > 0 &&
          allTags.map((item: any, index: number) => (
            <button
              key={index}
              className="dashboard-button  hover-text touchable-text-white px-4 py-2"
              onClick={() => {
                handleAddTags(item);
                handleModal();
              }}
            >
              {getValue(item, `label`, "")}
            </button>
          ))}
      </div>
    </Modal>
  );
}

export default AddWhatsappVariable;
