import * as React from "react";
import "./index.scss";
// import Loading from './loading.json';
import Loading from "./circle_loader/loader2.json";
// import HeaderOnlyLayout from "@layouts/HeaderOnlyLayout/HeaderOnlyLayout";
import Lottie from "react-lottie";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILoaderProps {}

const Loader = (props: any) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      className={props.small ? "loader-container-small" : "loader-container"}
    >
      <div
        className="loader-wrapper__details"
        style={{ background: "transparent" }}
      >
        <Lottie
          options={defaultOptions}
          height={"150px"}
          width={"150px"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "17vh",
          }}
        />
      </div>
    </div>
  );
};

export default Loader;
