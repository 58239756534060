import SettingsWorkflows from "@pages/Private/Settings/workflows";
import SettingsWorkflowDetail from "@pages/Private/Settings/workflows/create";

export const SettingsWorkflowsRoutes = [
  {
    path: "/:orgId/settings/workflows",
    name: "settings_automation_workflows",
    component: SettingsWorkflows,
  },
  {
    path: "/:orgId/settings/workflows/create",
    name: "settings_automation_workflows",
    component: SettingsWorkflowDetail,
  },
  {
    path: "/:orgId/settings/workflows/edit/:id",
    name: "settings_automation_workflows",
    component: SettingsWorkflowDetail,
  },
  {
    path: "/:orgId/settings/workflows/edit/:id",
    name: "settings_automation_workflows",
    component: SettingsWorkflowDetail,
  },
];
