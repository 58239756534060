import { convertCurrentDate } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import React from "react";

function KPIRankingsComponents(props: any) {
  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, `name`, "")}</p>
      <table className="w-100">
        {/* <thead>
          <th></th>
          <th></th>
          <th></th>
        </thead> */}
        {getValue(props, `graph`, []).map((item: object, index: number) => {
          console.log("item", item);
          return (
            <tr
              className={`w-100 pb-3 ${
                index !== getValue(props, `graph`, []).length - 1
                  ? "border-bottom"
                  : ""
              }`}
            >
              <td className="w-10">{index + 1}.</td>
              <td className="w-50">
                {convertCurrentDate(getValue(item, `key.created_at`, ""))}
              </td>
              <td className="w-20 text-center">
                {getValue(item, `count`, "")}
              </td>
              <td className="w-20">
                {getValue(item, `percentageToCurrent`, "")}
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default KPIRankingsComponents;
