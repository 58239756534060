import { config } from "../env";
import { get, patch, post } from "./helpers/http-handler";

export const getAllAdManagers = (payload: object) =>
  post(`${config.API_URL}/ad-manager/list`, payload);

export const connectFbAdManager = (payload: object) =>
  post(`${config.API_URL}/ad-manager/fb/connect`, payload);

export const getSpecificAdManager = (id: string) =>
  get(`${config.API_URL}/ad-manager/${id}`);

export const subscribeFbAdManager = (payload: object) =>
  post(`${config.API_URL}/ad-manager/fb/subscribe`, payload);

export const getFbAdManagerLeadFormsByID = (id: string) =>
  get(`${config.API_URL}/ad-manager/fb/lead-forms/${id}`);

export const addAddManagerFormFields = (payload: object) =>
  post(`${config.API_URL}/ad-manager/fb/form-fields`, payload);

export const updateAddManagerFormFields = (id: string, payload: object) =>
  patch(`${config.API_URL}/ad-manager/fb/form-fields/${id}`, payload);
