import { Drawer } from "antd";
import React from "react";
import { DashboardGraphTypeList } from "../helpers/enum";
import { getValue } from "@utils/lodash";
import DashboardComponentGraphs from "../Graphs";

function ComponentChartStyleDrawer(props: any) {
  const selectedComponentList = [
    // Column Chart
    {
      graph_type: "column_chart",
      name: "Sales by Region",
      graph: [
        { key: "North", count: 1000 },
        { key: "South", count: 1500 },
        { key: "East", count: 800 },
        { key: "West", count: 1200 },
      ],
      module_field: { label: "Regional Sales" },
    },

    // Pie Chart
    {
      graph_type: "pie_chart",
      name: "Market Share",
      graph: [
        { key: "Product A", count: 50 },
        { key: "Product B", count: 30 },
        { key: "Product C", count: 20 },
      ],
      module_field: { label: "Market Share Data" },
    },

    // Bar Chart
    {
      graph_type: "bar_chart",
      name: "Revenue by Month",
      graph: [
        { key: "Jan", count: 2000 },
        { key: "Feb", count: 2500 },
        { key: "Mar", count: 3000 },
        { key: "Apr", count: 1800 },
        { key: "May", count: 2200 },
      ],
      module_field: { label: "Monthly Revenue" },
    },

    // Line Chart
    {
      graph_type: "line_chart",
      name: "User Growth",
      graph: [
        { key: "Jan", count: 500 },
        { key: "Feb", count: 700 },
        { key: "Mar", count: 1000 },
        { key: "Apr", count: 1300 },
        { key: "May", count: 1600 },
      ],
      module_field: { label: "Growth Over Time" },
    },

    // Donut Chart
    {
      graph_type: "donut_chart",
      name: "Website Traffic Sources",
      graph: [
        { key: "Organic", count: 40 },
        { key: "Paid", count: 35 },
        { key: "Direct", count: 25 },
      ],
      module_field: { label: "Traffic Source Distribution" },
    },

    // Funnel Chart
    {
      graph_type: "funnel_chart",
      name: "Sales Funnel",
      graph: [
        { key: "Leads", count: 5000 },
        { key: "Qualified", count: 3000 },
        { key: "Proposals", count: 1500 },
        { key: "Deals Closed", count: 800 },
      ],
      module_field: { label: "Funnel Stages" },
    },

    // Area Chart
    {
      graph_type: "area_chart",
      name: "Revenue Over Time",
      graph: [
        { key: "Q1", count: 5000 },
        { key: "Q2", count: 7000 },
        { key: "Q3", count: 8000 },
        { key: "Q4", count: 10000 },
      ],
      module_field: { label: "Quarterly Revenue" },
    },

    // HeatMap Chart
    {
      graph_type: "heat_map",
      name: "Sales Heatmap",
      graph: [
        { key: "Week 1", count: 200 },
        { key: "Week 2", count: 400 },
        { key: "Week 3", count: 600 },
        { key: "Week 4", count: 800 },
      ],
      module_field: { label: "Sales by Week" },
    },

    // Table Chart
    {
      graph_type: "table_chart",
      name: "Employee Performance",
      graph: [
        { key: "John Doe", count: 80 },
        { key: "Jane Smith", count: 92 },
        { key: "Mike Johnson", count: 75 },
        { key: "Emily Davis", count: 88 },
      ],
      module_field: { label: "Employee Scores" },
    },
  ];

  return (
    <Drawer
      title="Choose Chart Type"
      onClose={props.onClose}
      open={props.open}
      width={800}
    >
      <div className="row">
        {selectedComponentList.map((item: object, index: number) => {
          return (
            <div key={index} className="col-6 mb-3">
              <DashboardComponentGraphs props={item} />
            </div>
          );
        })}
      </div>
      <div className="d-flex flex-wrap gap-4">
        {DashboardGraphTypeList.map((item: object) => {
          return (
            <div
              className="dashboard_charttype"
              onClick={() =>
                props.openComponentDrawer(getValue(item, `value`, ""))
              }
            >
              <p className="small_text__16">{getValue(item, `label`, "")}</p>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
}

export default ComponentChartStyleDrawer;
