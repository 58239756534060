import { getValue } from '@utils/lodash';
import React, { useEffect, useRef } from 'react';
import Popup from "reactjs-popup";

function TaskInfoPopup(props) {
  const POSITION_TYPES = [
    "bottom right",
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "left center",
    "center center",
  ];

  const popupRef = useRef();
  const handleClose = () => {
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };
  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      className="d-flex justify-content-between align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      {props.header}
    </div>
  ));
  useEffect(() => {
    if (props.clicked) {
      handleClose()
      props.setClicked(false)
    }
  }, [props.clicked])
  return (
    <Popup
      trigger={(open) => (
        <CustomButtons
          open={open}
          reports={props.reports}
          header={props.header}
        />
      )}
      position={POSITION_TYPES}
      closeOnDocumentClick
      on={props.text.length > props.minLength ? "hover" : 'focus'}
      contentStyle={{
        width: "400px",
        zIndex: 9999,
        background: "white",
        padding: '0',
        boxShadow:
          "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      }}
      arrow
      nested
      ref={popupRef}
      onOpen={handleOpen}
    >
      <div>
        <p className='small_text__14 p-3'>{getValue(props, `text`, '')}</p>
      </div>
    </Popup>
  );
}

export default TaskInfoPopup;