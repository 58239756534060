import CustomNodeOptions from "./CustomNodeOptions";

const CustomNode = ({ data }: any) => {
  const handleDelete = (id: string) => {
    data.onDelete(id);
  };
  return (
    <div className="custom-node">
      <CustomNodeOptions data={data} handleDelete={handleDelete} />
    </div>
  );
};

export default CustomNode;
