import { getValue } from "@utils/lodash";

export const getStringContent = (text: string) => {
  // Extract the text content from the temp element
  const result = extractTextContent(text);
  return result;
};

export const getStringContentLength = (text: string) => {
  // Extract the text content from the temp element
  const result:any = extractTextContent(text);
  return getValue(result, `length`, 0);
};

const extractTextContent = (htmlString: any) => {
  let textContent = "";

  // Create a temporary DOM element to parse the HTML string
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Function to recursively extract text content
  const extractText = (element: any) => {
    // If the element is a text node, append the text content
    if (element.nodeType === Node.TEXT_NODE) {
      textContent += element.textContent;
    }

    // Recursively go through child nodes
    if (element.childNodes && element.childNodes.length > 0) {
      element.childNodes.forEach((child: any) => {
        extractText(child);
      });
    }
  };

  // Start extraction from the parsed HTML element
  extractText(tempElement);

  return textContent.trim(); // Trim to remove extra spaces
};
