import React from "react";
import "./nodata.scss";
function DealsNoDataPage(props: any) {
  return (
    <div className="n_container">
      <div className="nodata_container">
        <div className="d-flex justify-content-center align-items-center">
          <div className="nodata_container_main">
            <h6 className="nodata_container_header">
              Build a winning sales process.
            </h6>
            <p className="nodata_container_desc mt-2">
              Deals are the best way to keep track of how you are turning
              prospects into customers and how much money you are making over
              time.
            </p>
          </div>
          <div className="w-10"></div>
          <img
            src={"/nodata/deals1.svg"}
            alt="SVG Image"
            className="nodata_container_image"
          />
        </div>
      </div>
    </div>
  );
}

export default DealsNoDataPage;
