import { getValue } from "@utils/lodash";

const isValidEmail = (email: any) => {
  // Regular expression for validating email format
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const isValidPhoneNumber = (phoneNumber: any) => {
  // Regular expression for validating phone number format (simple example)
  const phonePattern = /^\d{10}$/; // Change this pattern according to your phone number format
  return phonePattern.test(phoneNumber);
};

export const handleFormValidation = (formData: any) => {
  const newErrors: any = {};
  formData.forEach((field: any) => {
    // Check for system_required fields
    if ((field.system_required || field.required) && !field.value) {
      newErrors[field.name] = field.system_required
        ? `${field.label} is required by the system.`
        : `${field.label} is required.`;
    }
  });

  return Object.keys(newErrors);
};

export const handleObjectValidation = (obj: any, mandatoryFields: any) => {
  const newErrors: any = {};
  mandatoryFields.forEach((field: any) => {
    const value = obj[field];
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0)
    ) {
      newErrors[field] = `${field} is required.`;
    }
  });
  return Object.keys(newErrors);
};

export const handleFieldValidation = (
  type: string,
  value: any,
  label: string
) => {
  switch (type) {
    case "text":
      return getValue(value, `length`, 0) > 0 ? "" : `Please enter ${label}`;
    case "number":
      return getValue(value, `length`, 0) > 0 ? "" : `Please enter ${label}`;
    case "email":
      const emailRegex = /^[\w.-]+@\w+\.[A-Za-z]{2,}$/;
      let validate = emailRegex.test(value);
      return validate ? "" : `Please enter valid ${label}`;
    case "array":
      return getValue(value, `length`, 0) > 0
        ? ""
        : `Please add atleaset 1 ${label}`;

    default:
      return null;
  }
};
