import React, { useEffect, useState } from "react";
import { Button, Checkbox, Modal } from "antd";
import { getValue } from "@utils/lodash";
import CloseSvgComponent from "@assets/svg/close";
import CrossSvgComponent from "@assets/svg/cross";
import InputRuleForm from "@components/InputRuleForm/form";
import ButtonComponent from "@components/Form/Button/Button";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";

function FormCreatorEditFied(props: any) {
  const { editFieldId, droppedFormFields, setDroppedFormFields } = props;

  const [request, setRequest] = useState({
    display_label: "",
    required: false,
    description: "",
  });

  useEffect(() => {
    if (getValue(props, `fieldInfo.label`, "")) {
      setRequest({
        ...request,
        display_label: getValue(props, `fieldInfo.display_label`, ""),
        required: getValue(props, `fieldInfo.system_required`, false)
          ? true
          : getValue(props, `fieldInfo.required`, false),
        description: getValue(props, `fieldInfo.description`, ""),
      });
    } else {
      setRequest({
        ...request,
        display_label: "",
        required: false,
        description: "",
      });
    }
  }, [getValue(props, `fieldInfo`, {})]);

  const handleSubmit = async () => {
    setDroppedFormFields((options: any) => {
      return options.map((x: object, index: number) => {
        if (getValue(x, `id`, "") !== editFieldId) return x;
        return {
          ...x,
          display_label: getValue(request, `display_label`, ""),
          required: getValue(request, `required`, ""),
          description: getValue(request, `description`, ""),
        };
      });
    });
    props.handleModal();
    props.resetEdit();
  };
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onOk={() => handleSubmit()}
      onCancel={props.handleModal}
      onClose={props.handleModal}
      title="Edit Field"
      footer={[
        <Button
          key="cancel"
          onClick={props.handleModal}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="submit__button"
        >
          Submit
        </Button>,
      ]}
    >
      <div>
        <InputRuleForm
          inputType={"TEXT"}
          placeholder={"Enter Label"}
          label={"Display Label"}
          value={getValue(request, `display_label`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              display_label: e.target.value,
            })
          }
        />
      </div>
      <div className="d-flex align-items-center mt-3">
        <p className="small_text__14">Appzo Field Name</p>:
        <p className="small_text__14 color_success ms-2">
          {getValue(props, `fieldInfo.label`, "")}
        </p>
      </div>
      <div className="d-flex  align-items-center mt-4">
        <div className="d-flex align-items-center gap-2 mb-2">
          <Checkbox
            onChange={(e: object) =>
              setRequest({
                ...request,
                required: !getValue(request, `required`, false),
              })
            }
            checked={getValue(request, `required`, false) ? true : false}
            disabled={
              getValue(props, `fieldInfo.system_required`, false) ? true : false
            }
          >
            Mark as Required Field
          </Checkbox>
          {/* <input
              type="checkbox"
              onChange={(e: object) =>
                setRequest({
                  ...request,
                  required: !getValue(request, `required`, false),
                })
              }
              checked={getValue(request, `required`, false) ? true : false}
              disabled={
                getValue(props, `fieldInfo.system_required`, false)
                  ? true
                  : false
              }
            />
            <label className="header_text__14 ms-3">
              Mark as Required Field
            </label> */}
        </div>
      </div>

      <div>
        <InputRuleForm
          inputType={"TEXTAREA"}
          placeholder={"Enter Description"}
          label={"Description"}
          value={getValue(request, `description`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              description: e.target.value,
            })
          }
        />
      </div>
    </Modal>
  );
}

export default FormCreatorEditFied;
