import { convertCurrentDateWithTime } from "@common/date-helpers";
import { capitalizeFirstLetter, formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Table } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CannedTable = ({ params, navigate, tableData, isTableLoading }: any) => {
  const [selectionType, setSelectionType] = useState<any>("checkbox");
  const rowSelection = {
    // selectedRowKeys: selectedIds,
    // onChange: (selectedRowKeys: any) => {
    //   setSelectedIds(selectedRowKeys);
    // },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const columns = [
    {
      title: "RESPONSE",
      dataIndex: "title",
      key: "title",
      render: (title: any, record: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          onClick={() =>
            navigate(
              `/${getValue(
                params,
                `orgId`,
                ""
              )}/settings/canned-response/detail/${getValue(record, `id`, "")}`
            )
          }
        >
          {capitalizeFirstLetter(title)}
        </div>
      ),
    },
    {
      title: "VISIBLE TO",
      dataIndex: "visibility",
      key: "visibility",
      render: (record: any, text: any) =>
        formatString(getValue(text, `visibility`, "")),
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      key: "created_at",
      render: (record: any, text: any) =>
        convertCurrentDateWithTime(getValue(text, `created_at`, "")),
    },
    {
      title: "UPDATED AT",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (record: any, text: any) =>
        convertCurrentDateWithTime(getValue(text, `updated_at`, "")),
    },
    {
      title: "ACTION",
      dataIndex: "is_active",
      key: "is_active",
      // render: (is_active: any, record: any) => (
      //   <Switch
      //     size="small"
      //     checked={is_active}
      //     onChange={() =>
      //       props.handleChangeStatus(getValue(record, `id`, ""), !is_active)
      //     }
      //   />
      // ),
    },
  ];

  return (
    <div className="col-9 canned-table-wrapper py-4">
      <Table
        size="middle"
        bordered
        columns={columns}
        dataSource={getValue(tableData, "canned_responses", "")}
        rowKey="id"
        loading={isTableLoading}
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
      />
    </div>
  );
};

export default CannedTable;
