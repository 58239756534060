import React, { useEffect, useState } from "react";
import "./profile.scss";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import {
  getSpecificProfile,
  updateProfile,
} from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import {
  getFirstLetterOfWord,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { handleSelectChange } from "@common/handleChange";
import timezones from "@common/json/standardized-timezone-data.json";
import countries from "@common/json/country.flag.json";
import { handleChangeNumber } from "@common/handleChange";
import ButtonComponent from "@components/Form/Button/Button";
import { toast } from "sonner";
import ProfileNavViews from "./components/nav-views";
import DialcodePhoneInputCommon from "@components/custom/Forms/DialCodeInputCommon";
import { useStateContext } from "@context/profileProvider";
import MainLayout from "@layouts/HomeLayout/NewLayout";

function ProfilePage(props: any) {
  const { getUserInfoCallback } = useStateContext();
  const [request, setRequest] = useState<any>({
    country: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    timezone: "",
  });
  const [countriesList, setCountriesList] = useState([]);
  useEffect(() => {
    getOrganizationUser();
    let list: any = countries.map((item: object) => ({
      ...item,
      label: getValue(item, `name`, ""),
      value: getValue(item, `code`, ""),
    }));
    setCountriesList(list);
  }, []);
  const getOrganizationUser = async () => {
    let resp = await getSpecificProfile();
    if (resp) {
      setRequest({
        ...request,
        country: getValue(resp, `data.country`, ""),
        email: getValue(resp, `data.email`, ""),
        first_name: getValue(resp, `data.first_name`, ""),
        last_name: getValue(resp, `data.last_name`, ""),
        phone: getValue(resp, `data.phone`, ""),
        timezone: getValue(resp, `data.timezone`, ""),
      });
    }
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let resp = await updateProfile(request);
      if (resp) {
        localStorage.setItem("timezone", getValue(request, `timezone`, ""));
        toast.success("Updated successfully");
        getOrganizationUser();
        getUserInfoCallback();
        setSubmitLoading(false);
        window.location.reload();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return (
    <div {...props}>
      <HomeHeader />
      <ProfileNavViews />
      <div className="d-flex justify-content-center align-items-center mt-3">
        <div className="profile_circle_section text-uppercase d-flex justify-content-center align-items-center">
          <h6>
            {getFirstLetterOfWord(getValue(request, `first_name`, ""))}
            {getFirstLetterOfWord(getValue(request, `last_name`, ""))}
          </h6>
        </div>
      </div>
      <div className="mt-3 px-5 profile-form-container">
        <div className="d-flex align-items-center gap">
          <div className="w-50">
            <InputRuleForm
              inputType="TEXT"
              label="Fist Name"
              value={getValue(request, `first_name`, "")}
              name="first_name"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  first_name: e.target.value,
                })
              }
            />
          </div>
          <div className="w-50">
            <InputRuleForm
              inputType="TEXT"
              label="Last Name"
              value={getValue(request, `last_name`, "")}
              name="last_name"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  last_name: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap">
          <div className="w-50">
            <InputRuleForm
              inputType="TEXT"
              label="Email"
              value={getValue(request, `email`, "")}
              name="email"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="w-50 mt-3">
            {/* <InputRuleForm
              inputType="TEXT"
              label="Phone Number"
              value={getValue(request, `phone`, "")}
              name="phone"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  phone: handleChangeNumber(e),
                })
              }
            /> */}
            <DialcodePhoneInputCommon
              placeholder="Enter phone number"
              value={getValue(request, `phone`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  phone: e,
                })
              }
              label={"Phone Number"}
              removeValidation
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap">
          <div className="w-50">
            <SearchToolTip
              inputType="TEXT"
              label="label"
              name={"Country"}
              value={getValue(request, `country`, "")}
              onChange={(e: object) =>
                handleSelectChange(e, "country", request, setRequest)
              }
              selectKey={"value"}
              data={countriesList}
            />
          </div>
          <div className="w-50">
            <SearchToolTip
              placeholder={`Enter Time Zone`}
              onChange={(e: object) =>
                handleSelectChange(e, "timezone", request, setRequest)
              }
              label={`label`}
              //   width={"365px"}
              data={timezones}
              value={getValue(request, `timezone`, "")}
              selectKey={"value"}
              name={"Time Zone"}
              concatLength={100}
            />
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-end">
          <ButtonComponent
            buttonType={submitLoading ? "scecondary" : "primary"}
            buttonText={"Update"}
            onClickHandler={() => handleSubmit()}
            disabled={submitLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
