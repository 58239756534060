import CloseMainSvgComponent from "@assets/svg/close-main";
import FilterSvgComponent from "@assets/svg/filter";
import { convertDateTime } from "@common/date-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  capitalizeEverything,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";
import CustomAllCheckbox from "@components/Form/CheckBox/CustomAllCheckbox";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import InputRuleForm from "@components/InputRuleForm/form";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import { intitalFilterViewState } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { useStateContext } from "@context/profileProvider";
import { getAllContacts } from "@services/contacts.service";
import {
  generateBroadcastTemplate,
  getAvailableConfigList,
  getSpecificWhatsappBroadcast,
  getWhatsappBroadcast,
  getWhatsappBroadcastContacts,
  getWhatsappTemplate,
  manageWhatsappBroadcast,
} from "@services/fb.service";
import { listAllPipelines } from "@services/pipeline.service";
import { commonCustomFilter } from "@services/smart-filter.service";
import { mediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import { toast } from "sonner";
import WhatsappIconViewPage from "../components/common/whatsapp-icon";
import { handleDownloadOnClick } from "@common/downloadfile";
import BackSvgComponent from "@assets/svg/back-link";
import EditHistoryPopup from "../components/broadcast-history/edit-history-popup";
import CommonInlineAddFilterView from "@components/Pages/Pipeline/AddFilterViews/inline-advance-filter";
import MainLayout from "@layouts/HomeLayout/NewLayout";

const EditBroadcast = (props: any) => {
  const intialState = {
    id: "",
    name: "",
    templateId: "",
    timestamp: "",
    contacts: [],
    isActive: true,
    waAccountId: "",
  };
  const { selectedModuleId } = useStateContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();

  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  const broadcastId = getValue(params, "id", "");

  useEffect(() => {
    if (broadcastId) {
      getSpecificBrodcast(broadcastId);
      getTemplateList();
      associatedContacts(broadcastId);
    } else {
      getData();
      getTemplateList();
      getAllPipelinesList();
    }
    getConnectedWhatsappList();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [timestamp, setTimestamp] = useState("now");
  const [scheduleTimestamp, setScheduleTimestamp] = useState<Date | undefined>(
    undefined
  );
  const [request, setRequest] = useState(intialState);
  const [templateRequest, setTemplateRequest] = useState({});
  const reset = () => {
    setRequest(intialState);
    setTemplateRequest({});
  };
  /* -------------------------------------------------------------------------- */
  /*                                   API Section                              */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificWhatsappBroadcast(getValue(params, `id`, ""));
      if (resp) {
        setList(getValue(resp, `data.broadcasts`, []));
        setTotalCount(getValue(resp, `data.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [isLoading1, setIsLoading1] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const getTemplateList = async () => {
    try {
      setIsLoading1(true);
      let payload = {
        status: "APPROVED",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappTemplate(queryRequest);
      if (resp) {
        setTemplateList(
          getValue(resp, `data.templates`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  const [contactPipelineId, setContactPipelineId] = useState("");
  const [allPipelines, setAllPipelines] = useState([]);

  const getAllPipelinesList = async () => {
    try {
      let allPipelines = await listAllPipelines(
        await selectedModuleId("contacts"),
        ""
      );
      let list = getValue(allPipelines, `data`, []).map((item: object) => ({
        ...item,
        value: getValue(item, `id`, ""),
        label: getValue(item, `label`, ""),
      }));
      setAllPipelines(list);
      setContactPipelineId(getValue(allPipelines, `data.[${0}].id`, ""));
      getAllContact(
        page_no1,
        limit1,
        selectedAdvancedFiter,
        getValue(allPipelines, `data.[${0}].id`, "")
      );
    } catch (error) {}
  };

  const handleChangePipeline = (id: string) => {
    setContactPipelineId(id);
    getAllContact(page_no1, limit1, selectedAdvancedFiter, id);
  };

  const [allContacts, setAllContacts] = useState([]);
  const [totalCount1, setTotalCount1] = useState(0);
  const getAllContact = async (
    page: any,
    limit: any,
    filter: any,
    pipeline_id: string
  ) => {
    try {
      let payload = {
        page_no: page,
        page_size: limit,
        pipeline_id: pipeline_id,
        module_id: await selectedModuleId("contacts"),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp;
      if (getValue(filter, `length`, 0) > 0) {
        let obj = {
          has_advance_filter: true,
          has_group_by: false,
          filter_groups: filter,
        };
        resp = await commonCustomFilter("contacts", queryRequest, obj);
      } else {
        resp = await getAllContacts(queryRequest);
      }
      if (resp) {
        setAllContacts(getValue(resp, `data.contacts`, []));
        setTotalCount1(getValue(resp, `data.pagination.total`, 0));
        // setSelectedIds([]);
      }
    } catch (error) {}
  };

  const [allConnectionList, setAllConnectionList] = useState([]);
  const getConnectedWhatsappList = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAvailableConfigList(queryRequest);
      if (resp) {
        if (getValue(resp, `data.length`, 0) > 0) {
          setAllConnectionList(
            getValue(resp, `data`, []).map((item: object) => ({
              ...item,
              label:
                getValue(item, `verifiedName`, "") +
                " ( " +
                getValue(item, `displayPhoneNumber`, "") +
                " )",
              value: getValue(item, `id`, ""),
            }))
          );
        }
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Modal Section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };
  /* -------------------------------------------------------------------------- */
  /*                               OnChange Section                             */
  /* -------------------------------------------------------------------------- */

  const handleTemplateRequest = (id: string) => {
    setRequest({
      ...request,
      templateId: id,
    });
    let info: any = templateList.find(
      (item: object) => getValue(item, `id`, "") === id
    );
    handlePreview(info);
  };
  /* -------------------------------------------------------------------------- */
  /*                                    Checkbox Section                        */
  /* -------------------------------------------------------------------------- */
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const handleChangeCheckbox = (id: any) => {
    let checkIfExist =
      getValue(selectedIds, `length`, 0) > 0
        ? selectedIds.filter((item: any) => item === id)
        : [];
    if (getValue(checkIfExist, `length`, 0) > 0) {
      let filtered =
        getValue(selectedIds, `length`, 0) > 0
          ? selectedIds.filter((item: any) => item !== id)
          : [];
      setSelectedIds(filtered);
    } else {
      selectedIds.push(id);
      setSelectedIds([...selectedIds]);
    }
  };

  const handleChangeAllCheckbox = (value: any) => {
    if (value === "all_checked") {
      setSelectedIds([]);
    } else {
      let IDs = allContacts.map((item: any) => item.id);
      setSelectedIds(IDs);
    }
  };

  const [page_no1, setPage_no1] = useState(1);
  const [limit1, setLimit1] = useState(10);
  const handleChangePagination1 = (page: any, limit1: any) => {
    setPage_no1(page);
    setLimit1(limit1);
    getAllContact(page, limit1, selectedAdvancedFiter, contactPipelineId);
  };
  const handleChangeLimit1 = (limit: any) => {
    setLimit1(limit);
    getAllContact(page_no1, limit, selectedAdvancedFiter, contactPipelineId);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Submit Section                           */
  /* -------------------------------------------------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let obj = {
        name: getValue(request, `name`, ""),
        templateId: getValue(request, `templateId`, ""),
        timestamp: scheduleTimestamp
          ? convertDateTime(scheduleTimestamp)
          : convertDateTime(new Date()),
        contacts: selectedIds,
        isActive: true,
        contactUploadId: getValue(uploadedFileInfo, `id`, ""),
        waAccountId: getValue(request, `waAccountId`, ""),
      };
      let resp = await manageWhatsappBroadcast(
        removeNullOrUndefinedProperties(obj)
      );
      if (resp) {
        toast.success("Submitted successfully");
        setSubmitLoading(false);
        getData();
        handleModal();
        reset();
        setTemplateRequest({});
        setIsOpen2(false);
        setUploadedFileInfo({});
        setDownloadFile("");
        navigate(`/${getValue(params, "orgId", "")}/broadcast`);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  const getSpecificBrodcast = async (id: string) => {
    try {
      let resp = await getSpecificWhatsappBroadcast(id);
      if (resp) {
        handlePreview(getValue(resp, `data.template`, {}));
        setRequest({
          ...request,
          id: getValue(resp, `data.id`, ""),
          name: getValue(resp, `data.name`, ""),
          templateId: getValue(resp, `data.template_id`, ""),
          timestamp: getValue(resp, `data.timestamp`, ""),
          isActive: getValue(resp, `data.is_active`, false),
          waAccountId: getValue(resp, `data.wa_account_id`, ""),
        });
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any) => {
    setPage_no(parseInt(page_no));
  };

  const handleChangeLimit = (limit: any) => {
    setLimit(limit);
  };

  const [editId, setEditId] = useState("");
  const handlePreview = (findInfo: any) => {
    setEditId("");
    let buttons =
      getValue(findInfo, `buttons.length`, 0) > 0
        ? getValue(findInfo, `buttons`, []).map((item: object) => ({
            ...item,
            type: getValue(item, `action_type`, ""),
            action_type: getValue(item, `type`, ""),
          }))
        : [];
    setTemplateRequest({
      ...templateRequest,
      header: {
        type: getValue(findInfo, `header_type`, ""),
        text: getValue(findInfo, `text`, ""),
        link: getValue(findInfo, `header_file.key`, ""),
      },
      body: getValue(findInfo, `body`, ""),
      footer: getValue(findInfo, `footer`, ""),
      buttons: buttons,
    });
    // handleModal();
  };

  const [associatedContactList, setAssociatedContactsList] = useState([]);
  const associatedContacts = async (id: string) => {
    try {
      let resp = await getWhatsappBroadcastContacts(id);
      if (resp) {
        setAssociatedContactsList(getValue(resp, `data.broadcasts`, []));
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                       Create Filterview Section                            */
  /* -------------------------------------------------------------------------- */
  const [createViewStep, setCreateViewStep] = useState(1);
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const [filterRequest, setFilterRequest] = useState(intitalFilterViewState);
  const [filterViewId, setFilterId] = useState("");
  const handleOpenFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
    setFilterRequest(intitalFilterViewState);
    setFilterId("");
    setCreateViewStep(1);
  };
  const [selectedAdvancedFiter, setSelectedAdvancedFiter] = useState([]);
  const handleSubmitFilter = (filter: any) => {
    setSelectedAdvancedFiter(filter);
    setIsOpenFilterView(!isOpenFilterView);
    getAllContact(page_no1, limit1, filter, contactPipelineId);
  };
  const handleRemoveSmartFilter = () => {
    setSelectedAdvancedFiter([]);
    getAllContact(page_no1, limit1, [], contactPipelineId);
  };

  /* -------------------------------------------------------------------------- */
  /*                          Import Popup Section                              */
  /* -------------------------------------------------------------------------- */
  const [isOpen2, setIsOpen2] = useState(false);
  const handleModal2 = () => {
    setIsOpen2(!isOpen2);
  };

  const [downloadFile, setDownloadFile] = useState("");
  const handleOpenImportPopup = async () => {
    try {
      let resp = await generateBroadcastTemplate({
        templateId: getValue(request, `templateId`, ""),
      });
      if (resp) {
        setDownloadFile(getValue(resp, `data.url`, ""));
        handleModal1();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                            Upload Section                                  */
  /* -------------------------------------------------------------------------- */

  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState({});
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setUploadedFileInfo(getValue(resp, `data`, {}));
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };

  const setImportTedFile = (value: string) => {
    if (!value) {
      setUploadedFileInfo({});
    }
  };

  const cleanName = (name: string): string => {
    return name.replace(/null/gi, "").trim();
  };

  const [selectionType, setSelectionType] = useState<any>("checkbox");
  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: (selectedRowKeys: any) => {
      setSelectedIds(selectedRowKeys);
    },
    onSelectAll: (
      selected: boolean,
      selectedRows: any[],
      changeRows: any[]
    ) => {
      if (selected) {
        setSelectedIds(allContacts.map((contact: any) => contact.id));
      } else {
        setSelectedIds([]);
      }
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name: any, text: any) => (
        <div>
          {first_name} {text.last_name}
        </div>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "MOBILE NUMBER",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
  ];

  const dataColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "contactNo",
      key: "contactNo",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: any, obj: any) => {
        console.log(status, obj);
        return (
          <div className="cursor-pointer">
            {getValue(obj, `error`, "") ? (
              <Tooltip title={getValue(obj, `error`, "")}>
                <Tag color={status === "failed" ? "error" : "success"}>
                  {capitalizeEverything(status)}
                </Tag>
              </Tooltip>
            ) : (
              <Tag color={status === "failed" ? "error" : "success"}>
                {capitalizeEverything(status)}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <MainLayout {...props}>
      <div className="px-3 py-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(`/${getValue(params, `orgId`, "")}/broadcast`)
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">
              {broadcastId ? "Edit" : "New"} broadcast message
            </p>
          </div>
          {!broadcastId &&
            getValue(props, `permissions`, []).includes("create") && (
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className=" dashboard-button--primary dashboard-button  hover-text touchable-text-white px-4 py-2"
                  onClick={handleSubmit}
                >
                  {submitLoading ? "Please wait..." : "Submit"}
                </button>
              </div>
            )}
        </div>
      </div>
      <div className="border-bottom"></div>
      {/* {!broadcastId ? ( */}
      <div className="template-wrapper">
        <div className="template-container w-100">
          <div className="template-form-container w-50">
            <div className="template-editor-wrapper">
              <div className="mb-3">
                <h6 className="header_text__18">
                  What message do you want to send?
                </h6>
                <p className="small_text__14">
                  Add broadcast name and template below
                </p>
              </div>
              <div className="">
                <div>
                  <div className="mt-3">
                    <SearchToolTip
                      label="label"
                      data={allConnectionList}
                      selectKey={"value"}
                      value={getValue(request, `waAccountId`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          waAccountId: getValue(e, `id`, ""),
                        })
                      }
                      name="Select Whatsapp Account"
                      placeholder="Choose Whatsapp Account"
                      disabled={broadcastId}
                      showWhatsappIcon
                    />
                  </div>
                  <div className="d-grid gap-1">
                    <InputRuleForm
                      label="Broadcast Name"
                      inputType="TEXT"
                      name="name"
                      placeholder="Enter Broadcast Name"
                      required
                      className="mb-2"
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          name: e.target.value,
                        })
                      }
                      value={getValue(request, `name`, "")}
                      disabled={broadcastId}
                    />
                  </div>
                  <div className="mt-3">
                    <SearchToolTip
                      label="label"
                      data={templateList}
                      selectKey={"value"}
                      value={getValue(request, `templateId`, "")}
                      onChange={(e: any) =>
                        handleTemplateRequest(getValue(e, `value`, ""))
                      }
                      name="Select Template Message"
                      placeholder="Choose template message"
                      disabled={broadcastId}
                    />
                  </div>
                </div>
                <div
                  className={`mt-4 ${
                    !broadcastId && "d-flex"
                  } justify-content-between mb-2`}
                >
                  <h6 className="header_text__16">
                    Whom do you want to send it to?
                  </h6>
                  {broadcastId ? (
                    <Table
                      size="middle"
                      className="mt-3"
                      bordered
                      columns={dataColumns}
                      dataSource={associatedContactList}
                      rowKey="id"
                      pagination={{
                        current: page_no1,
                        pageSize: limit1,
                        total: totalCount1,

                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                    />
                  ) : (
                    <div className="d-flex align-items-center">
                      <div
                        className={`d-flex align-items-center cursor-pointer ${
                          selectedAdvancedFiter.length > 0
                            ? "inline_popup_active"
                            : ""
                        }`}
                      >
                        <div
                          className="d-flex justify-content-between align-items-center"
                          onClick={handleOpenFilterView}
                        >
                          <FilterSvgComponent color={"#408dfb"} />
                          <h6 className="header_text__16 color_primary cursor-pointer ms-2">
                            Advanced Filters
                          </h6>
                        </div>
                        {selectedAdvancedFiter.length > 0 ? (
                          <div
                            onClick={handleRemoveSmartFilter}
                            className="ms-2 inline_close_image_container"
                          >
                            <CloseMainSvgComponent size={18} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {getValue(request, `templateId`, "") && (
                        <div className="ms-3" onClick={handleOpenImportPopup}>
                          <img
                            src="/images/icons/upload_cloud.svg"
                            className={"filter-image"}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {!broadcastId && (
                  <>
                    <div className="mt-3 mb-3">
                      <SearchToolTip
                        label="label"
                        data={allPipelines}
                        selectKey={"value"}
                        value={contactPipelineId}
                        onChange={(e: any) =>
                          handleChangePipeline(getValue(e, `value`, ""))
                        }
                        name="Pipeline"
                        placeholder="Choose Pipeline"
                      />
                    </div>
                    <Table
                      size="middle"
                      bordered
                      columns={columns}
                      dataSource={allContacts}
                      rowKey="id"
                      rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                      pagination={{
                        current: page_no1,
                        pageSize: limit1,
                        total: totalCount1,
                        showSizeChanger: true,
                        pageSizeOptions: [
                          "10",
                          "20",
                          "30",
                          "40",
                          "50",
                          "60",
                          "70",
                          "80",
                          "90",
                          "100",
                          "150",
                          "200",
                          "250",
                        ],
                        onChange: (page_no, pageSize) => {
                          handleChangePagination1(page_no, pageSize);
                        },
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                    />
                  </>
                )}
                {!broadcastId && (
                  <div className="my-4">
                    <h6 className="header_text__16">
                      When do you want to send it?
                    </h6>

                    <div className="mt-3">
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => {
                          setTimestamp("now");
                          setScheduleTimestamp(new Date());
                        }}
                      >
                        <input
                          type="radio"
                          checked={timestamp === "now" ? true : false}
                        />
                        <p className="small_text__16 ms-2">Send now</p>
                      </div>
                      <div
                        className="d-flex align-items-center mt-2 cursor-pointer"
                        onClick={() => setTimestamp("later")}
                      >
                        <input
                          type="radio"
                          checked={timestamp === "later" ? true : false}
                        />
                        <p className="small_text__16 ms-2">
                          Schedule for a specific time
                        </p>
                      </div>
                      <div className="w-50">
                        {timestamp === "later" && (
                          <InputRuleForm
                            inputType="DATETIME"
                            placeholder="Select Time"
                            value={scheduleTimestamp}
                            onChange={(e: any) => setScheduleTimestamp(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <WhatsappIconViewPage request={templateRequest} />
          </div>
        </div>
      </div>
      <Modal
        open={isOpen1}
        onCancel={handleModal1}
        onOk={handleSubmit}
        title="Import Contacts"
        footer={[
          <Button
            key="cancel"
            onClick={handleModal1}
            className="cancel__button"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            className="submit__button"
          >
            Submit
          </Button>,
        ]}
      >
        <div className="border-bottom"></div>
        <div className="d-flex justify-content-end mt-3">
          <div>
            <ButtonComponent
              buttonType={"primary"}
              buttonText={"Download Template"}
              onClickHandler={() =>
                handleDownloadOnClick(downloadFile, "template")
              }
            />
          </div>
        </div>
        <DragAndDropFileComponent
          uploadFile={handleUploadDocuments}
          name="file"
          classes="bulk-upload_drag-n-drop-wrapper position-absolute"
          fileName={getValue(uploadedFileInfo, `filename`, "")}
          setImportTedFile={setImportTedFile}
          isLoading={uploadLoading}
        />
      </Modal>
      {isOpenFilterView && (
        <CommonInlineAddFilterView
          isOpenFilterView={isOpenFilterView}
          module={"contacts"}
          handleOpenFilterView={handleOpenFilterView}
          pipeline_id={contactPipelineId}
          handleSubmit={handleSubmitFilter}
          selectedAdvancedFiter={selectedAdvancedFiter}
        />
      )}
    </MainLayout>
  );
};

export default EditBroadcast;
