import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { getValue } from "@utils/lodash";
import { Checkbox, Image } from "antd";
import { config } from "env";

const BroadcastMediaEdit = (props: any) => {
  const { request, handleUploadDocuments } = props;
  return (
    <div className="p-4">
      <div className="d-flex gap-4 border rounded-3 p-4">
        <div className="w-50">
          <p className="form-label mt-0">Broadcast Media</p>
          <div>
            {/* <Image
              src={`https://${config.URL}/${getValue(
                request,
                `variableConfig.headerLinkDetail.key`,
                ""
              )}`}
            /> */}

            {getValue(
              request,
              `variableConfig.headerLinkDetail.mime_type`,
              ""
            ).includes("image") ? (
              <Image
                src={`https://${config.URL}/${getValue(
                  request,
                  `variableConfig.headerLinkDetail.key`,
                  ""
                )}`}
                width={200}
                height={108}
                style={{
                  width: "180px",
                  height: "108px",
                  border: "1px solid #efefef",
                  objectFit: "cover",
                }}
                className="rounded-2"
              />
            ) : (
              <video autoPlay className="carousal-video-preview w-100">
                <source
                  src={`https://${config.URL}/${getValue(
                    request,
                    `variableConfig.headerLinkDetail.key`,
                    ""
                  )}`}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        </div>
        <div className="w-50">
          <p className="form-label mt-0">Upload (Image/video) to replace new</p>

          <DragAndDropFileComponent uploadFile={handleUploadDocuments} />

          {/* <Dragger className="broadcast-drag-drop">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                  uploading company data or other banned files.
                </p>
              </Dragger> */}
        </div>
      </div>
    </div>
  );
};

export default BroadcastMediaEdit;
