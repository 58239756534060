import LoginLayout from "layout/LoginLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ForgotPassowrdOTPInitiate } from "services/auth.service";
import { toast } from "sonner";
import { getValue } from "@utils/lodash";
import { ArrowLeft } from "lucide-react";
import "../login.scss";
// import "./login.scss";

export default function ForgotPasswordEmailScreen() {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [email, setEmail] = useState("");
  const [, forceUpdate] = useState(0);

  useEffect(() => {
    if (getValue(urlParams, `email`, "")) {
      setEmail(getValue(urlParams, `email`, ""));
    }
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setSubmitLoading(true);
      try {
        let obj = {
          identifier_type: "EMAIL",
          identifier: email,
        };
        let resp = await ForgotPassowrdOTPInitiate(obj);
        if (resp) {
          toast.success("OTP sent Successfully");
          navigate(
            `/forgot-password-otp/${getValue(resp, `data.verification_id`, "")}`
          );
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <>
      <LoginLayout>
        <div className="container-fluid position-relative pt-5">
          <div className={`back-link cursor-pointer ms-3 mt-2`}>
            <Link to="/signin" className="back-link-arrow">
              {/* <img
                src="/images/icons/back-icon.svg"
                className={`back-link-image img-fluid`}
                alt="img"
              /> */}
              <ArrowLeft color="#000" />
            </Link>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <div className="login_card">
              <div className={`support-login-wrapper__details`}>
                <h4 className={"header_text__26 mb-1"}>Forgot Password</h4>
                <div className={`support-form-wrapper position-relative mt-4`}>
                  {/* <div className={`support-form-group forzm-group`}> */}
                  <div
                    className={`signup-form-wrapper__form-group support-form-group signin-appzo-form form-group`}
                  >
                    <label className={"support-label"}>
                      {/* Your Email address */}
                      {/* / Mobile Number* */}
                      Email address
                    </label>
                    <input
                      type="text"
                      name="identifier"
                      value={email}
                      onChange={(e) =>
                        setEmail(e.target.value.toLocaleLowerCase())
                      }
                      placeholder="Enter Your Email Address"
                      className={`custom-signup-form-control form-control px-4`}
                      // className={`support-form-control form-control mt-1`}
                      onKeyDown={handleKeyDown}
                    />
                    {simpleValidator && (
                      <p className={`form-error text-center`}>
                        {simpleValidator.current.message(
                          "Email",
                          email,
                          "required|email"
                        )}
                      </p>
                    )}
                  </div>

                  <button
                    className="superteams-button__primary-button transition mt-2"
                    onClick={handleSubmit}
                    disabled={submitLoading}
                  >
                    {submitLoading ? "Please wait..." : "Send OTP"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoginLayout>
    </>
  );
}
