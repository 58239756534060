import { getValue } from "@utils/lodash";
import { Switch } from "antd";

const SheetBooleanEditor = ({
  col,
  row,
  fieldCol,
  handleDisplayItem,
  handleInputChange,
}: any) => {
  return (
    <Switch
      size="small"
      checked={handleDisplayItem(col, row)}
      onChange={(checked) =>
        handleInputChange(getValue(row, "id", ""), col, checked)
      }
    />
  );
};

export default SheetBooleanEditor;
