import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import { useState } from "react";
import { Button, Modal, Select } from "antd";
// import "./edit-popup.scss";

function UserPopup(props: any) {
  const { request, setRequest, simpleValidator } = props;
  const [editOptions] = useState([
    { value: "owner_id", label: "Owner" },
    { value: "stage_id", label: "Status" },
  ]);
  return (
    <div>
      <Modal
        open={getValue(props, `isOpen`, "")}
        onOk={props.handleSubmit}
        onCancel={props.handleModal}
        footer={[
          <Button
            key="cancel"
            onClick={props.handleModal}
            className="cancel__button"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={props.handleSubmit}
            className="submit__button"
            disabled={getValue(request, "role_id", "") === ""}
          >
            {props.isLoading ? "Please wait..." : "Invite"}
          </Button>,
        ]}
      >
        <h6 className="header_text__!6">Invite teammates</h6>
        <div className="p-4">
          <div className="">
            <InputRuleForm
              inputType="EMAIL"
              label="Email"
              placeholder={"Enter Email"}
              name="identifiers"
              value={getValue(request, `identifiers`, "")}
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
              validator={simpleValidator}
              validLeft
              required
            />
          </div>
          <p className="form-label">Role</p>
          <Select
            size="large"
            placeholder="Select role"
            value={getValue(request, "role_id", "")}
            options={props.allRoles}
            className="w-100"
            onChange={(value) => {
              // simpleValidator.current.showMessageFor("role");
              setRequest({
                ...request,
                role_id: value,
              });
            }}
            // onBlur={() => {
            //   simpleValidator.current.showMessageFor("role");
            // }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default UserPopup;
