import "./Branding.scss";
import { useEffect, useState } from "react";
import {
  getSpecificOrganisations,
  updateOrganisations,
} from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import { mediaUpload } from "@services/upload.service";
import { toast } from "sonner";
import { useStateContext } from "context/profileProvider";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import DeleteSvgComponent from "@assets/svg/delete";
import { Dropdown, Button, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import ArrowDownSvgComponent from "@assets/svg/arrow-down";
import useDynamicTitle from "@context/useDynamicTitle";
import ChevronDown from "@assets/svg/chevron-down";
import MainLayout from "@layouts/HomeLayout/NewLayout";

export default function Branding(props: any) {
  const { handleChangeOrgainization } = useStateContext();

  const [request, setRequest] = useState({
    theme: "",
    logo: "",
    logo_id: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const themeColors: any = [
    { label: "Theme Green", value: "#3fd1a9" },
    { label: "Theme Red", value: "#E74C3C" },
    { label: "Theme Blue", value: "#276ef2" },
    { label: "Theme Orange", value: "#fd7445" },
  ];

  const getData = async () => {
    try {
      let resp = await getSpecificOrganisations();
      if (resp) {
        setRequest({
          ...request,
          theme: getValue(resp, `data.theme`, ""),
          logo: getValue(resp, `data.logo.url`, ""),
          logo_id: getValue(resp, `data.logo.id`, ""),
        });
      }
    } catch (error) {}
  };

  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (event: any) => {
    try {
      const file = event.target.files[0];
      if (!file) {
        console.error("No file selected.");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("filename", file.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");

      setUploadLoading(true);

      const resp = await mediaUpload(formData);

      if (resp) {
        const response = await updateOrganisations({
          logo_id: getValue(resp, `data.id`, ""),
        });

        if (response) {
          toast.success("Imported successfully");
          getData();
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploadLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let resp = await updateOrganisations({
        theme: getValue(request, `theme`, ""),
        logo_id: getValue(request, `logo`, "")
          ? getValue(request, `logo_id`, "")
          : null,
      });
      if (resp) {
        setSubmitLoading(false);
        getData();
        handleChangeOrgainization();
        toast.success("Updated successfully");
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const themeMenu = (
    <Menu>
      {themeColors.map((item: any) => {
        return (
          <Menu.Item
            key={item.value}
            onClick={() =>
              setRequest({
                ...request,
                theme: item.value,
              })
            }
          >
            <div className="branding-wrapper__dropdown-item d-flex">
              <div className="d-flex align-items-center">
                <div
                  className="branding-wrapper__color-box branding-wrapper__color-box--color p-1"
                  style={{ backgroundColor: item.value }}
                />
              </div>
              <p className="branding-wrapper__color-box-text">{item.label}</p>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  useDynamicTitle("Branding");
  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
        isLoading={submitLoading}
        handleSubmit={handleSubmit}
        showSubmit
      />
      <CompanyProfileDetailsLayout>
        <div className="branding-wrapper">
          <h6 className="branding-wrapper__title">Your Logo</h6>
          <div className="d-flex align-items-center mb-4">
            {getValue(request, `logo`, "") ? (
              uploadLoading ? (
                <div className="branding-input">
                  <p className="mt-4">Uploading...</p>
                </div>
              ) : (
                <div className="position-relative">
                  <label htmlFor="branding-logo" className="cursor-pointer">
                    <input
                      type="file"
                      className="d-none"
                      id="branding-logo"
                      onChange={handleUploadDocuments}
                    />
                    <div className="branding-input">
                      <img
                        src={getValue(request, `logo`, "")}
                        className="img-fluid cursor-pointer"
                      />
                    </div>
                  </label>
                  <div
                    className="branding_delete_image"
                    onClick={() =>
                      setRequest({
                        ...request,
                        logo: "",
                      })
                    }
                  >
                    <DeleteSvgComponent />
                  </div>
                </div>
              )
            ) : uploadLoading ? (
              <div className="branding-input">
                <p className="mt-4">Uploading...</p>
              </div>
            ) : (
              <label htmlFor="branding-logo" className="cursor-pointer">
                <input
                  type="file"
                  className="d-none"
                  id="branding-logo"
                  onChange={handleUploadDocuments}
                />
                <div className="branding-input">
                  <img
                    src="/images/icons/camera.svg"
                    className="camera-icon img-fluid cursor-pointer"
                  />
                </div>
                <p className="branding-wrapper__text">Upload Brand Logo</p>
              </label>
            )}
            <div className="branding-wrapper__main-section">
              <h6 className="branding-wrapper__main-section-title">
                This Logo will appear on the top-left corner of this Network.
              </h6>
              <p className="branding-wrapper__main-section-text">
                Preferred Image Size: 240px x 48px @ 72 DPI. Maximum size of
                1MB.
              </p>
            </div>
          </div>
          <h6 className="branding-wrapper__title">Themes</h6>
          <div className="d-flex align-items-center">
            <Dropdown overlay={themeMenu} trigger={["click"]}>
              <Button className="py-4 px-3 theme-select-button">
                {/* branding-wrapper__dropdown --->class */}
                <div className=" d-flex align-items-center justify-content-between">
                  <div className="d-flex me-3">
                    <div className="d-flex align-items-center">
                      <div className="branding-wrapper__color-box" />
                      <div
                        className="branding-wrapper__color-box branding-wrapper__color-box--color"
                        style={{
                          background: getValue(request, `theme`, "")
                            ? getValue(request, `theme`, "")
                            : "white",
                        }}
                      />
                    </div>
                    <p className="branding-wrapper__color-box-text">
                      {getValue(request, `theme`, "")
                        ? themeColors.find(
                            (item: object) =>
                              getValue(item, `value`, "") ==
                              getValue(request, `theme`, "")
                          ).label
                        : "Select"}
                    </p>
                  </div>
                  <ChevronDown color={"gray"} size="12" />
                </div>
              </Button>
            </Dropdown>
            <div className="branding-wrapper__main-section mb-0">
              <h6 className="branding-wrapper__main-section-title">
                This theme will be applied to the portal and across all the
                products of the Superteam.
              </h6>
            </div>
          </div>
        </div>
      </CompanyProfileDetailsLayout>
    </MainLayout>
  );
}
