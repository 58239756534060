import React from 'react';
import './styles.scss'
function Nodata(props:any) {
    return (
        <div className='nodata_image_container'>
            <img src={'/images/nodata.jpg'} className='nodata_image'/>
        </div>
    );
}

export default Nodata;