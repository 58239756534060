import * as React from "react";
import "./index.scss";
import Loading from "./gift.json";
import Lottie from "react-lottie";
interface ILoaderProps {}

const GiftAnimation: React.FunctionComponent<ILoaderProps> = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie
      options={defaultOptions}
      height={"60px"}
      width={"60px"}
      style={{ display: "flex", alignItems: "center" }}
    />
  );
};

export default GiftAnimation;
