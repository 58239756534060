import "./button.scss";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
import ButtonOnHover from "../Tooltip/button-on-hover";
import { Button } from "antd";

function SubmitContainer(props: any) {
  return (
    <div
      className={`submit-button__footer d-flex align-items-center justify-content-between`}
    >
      <div className="submit-button__container">
        <div></div>
        <div className="d-flex align-items-center gap-2">
          <Button size="large" onClick={() => props.handleBackButton()}>
            {getValue(props, `first_title`, "")
              ? getValue(props, `first_title`, "")
              : "Cancel"}
          </Button>
          {getValue(props, `third_title`, "") && (
            <Button size="large" onClick={() => props.handleThirdButton()}>
              {getValue(props, `third_title`, "")}
            </Button>
          )}

          {getValue(props, `disableValidation`, false) ? (
            <ButtonOnHover
              header={
                (getValue(props, `permissions`, []).includes("update") ||
              getValue(props, `permissions`, []).includes("create")) && (
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => props.handleSubmit()}
                    disabled={props.disabled}
                    loading={props.submitLoading}
                  >
                    {getValue(props, `second_title`, "")
                      ? getValue(props, `second_title`, "")
                      : "Create"}
                  </Button>
                )
              }
              text={"Please add all required information"}
              width={"200px"}
              height={"50px"}
              hover={props.disabled}
            />
          ) : (
            (getValue(props, `permissions`, []).includes("update") ||
              getValue(props, `permissions`, []).includes("create")) && (
              <Button
                size="large"
                type="primary"
                onClick={() => props.handleSubmit()}
                disabled={props.disabled}
                loading={props.submitLoading}
              >
                {getValue(props, `second_title`, "")
                  ? getValue(props, `second_title`, "")
                  : "Create"}
              </Button>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default SubmitContainer;
