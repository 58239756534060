import { getValue } from "@utils/lodash";
import React from "react";

function NotFoundFile(props: any) {
  return (
    <div>
      <div className="d-flex justify-content-center mt-3">
        <img src={`/nodata/file.svg`} height={130} />
      </div>
      <p className="text-center small_text__14 mt-4 color_desc">
        {getValue(props, `title`, "")
          ? getValue(props, `title`, "")
          : "No data available"}
      </p>
    </div>
  );
}

export default NotFoundFile;
