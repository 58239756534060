import { getValue } from "@utils/lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import "./input.scss";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxLength: number;
  placeholder: string;
  validator: any;
  label: string;
  mandatory: boolean;
  hideLabel: boolean;
  rows: any;
};

export default function InlineTextArea(props: any) {
  const {
    disabled,
    name,
    type,
    value,
    onChange,
    maxLength,
    placeholder,
    validator,
    label,
    mandatory,
    hideLabel,
    // rows,
  } = props;
  const [rows, setRows] = useState(3);

  // useEffect(() => {
  //   if (value.trim().length > 0) {
  //     // Calculate the number of rows based on the text length and line breaks
  //     const lines = value.split('\n').length + 1; // Adding 1 to account for the last line without a line break
  //     setRows(lines > 2 ? lines : 2); // Ensure minimum of 2 rows even if there's less content
  //   } else {
  //     setRows(2); // If there's no content, default to 2 rows
  //   }
  // }, [value]);
  return (
    <div>
      {!hideLabel && (
        <label className="form-label-inline">
          {label} {mandatory && "*"}
        </label>
      )}
      <div>
        <textarea
          // className="form-control-textarea"
          className={`inline_form-control-textarea`}
          disabled={false || disabled}
          name={name}
          value={value ? value : ""}
          onChange={(e: any) => onChange(e)}
          placeholder={placeholder}
          maxLength={maxLength}
          rows={rows}
        />
      </div>
    </div>
  );
}
