import "./CenteredAlignedPopup.scss";
import AttachmentSvgComponent from "assets/svg/attachments";
import { useState } from "react";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
import CloseSvgComponent from "@assets/svg/close";
import AttachmentPopup from "@components/Pages/Pipeline/Detail/middle-container/Emails/popup/attachment-popup";

export default function EmailPopup({
  handleSubmitButtonClick,
  handleCloseButtonClick,
  isPopupVisible,
  addCustomClass,
  popupProperties,
  children,
  isLoading,
  hideFooter,
  style,
  handleUploadDocuments,
  uploadLoading,
  handleRemoveDocuments,
  documentList,
  handleSubmitDraft,
}: any) {
  const [isExpanded, setExpandedValue] = useState(false);
  return (
    <>
      {isPopupVisible && (
        <>
          <div className="overlay">
            <div
              className={`centered-aligned-popup-email ${
                isPopupVisible ? "centered-aligned-popup-email--active" : ""
              } ${addCustomClass ? addCustomClass : ""}
                ${isExpanded ? "centered-aligned-popup__expanded" : ""}
              `}
              style={style}
            >
              <div
                className={`d-flex justify-content-between align-items-center centered-aligned-popup__header`}
              >
                <div className="d-flex align-items-center">
                  {popupProperties?.title}
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className={`centered-aligned-popup__close cursor-pointer d-flex`}
                    onClick={() => {
                      handleCloseButtonClick(!isExpanded);
                    }}
                  >
                    <CloseSvgComponent size="12" />
                  </div>
                </div>
              </div>
              <div
                className={`centered-aligned-popup__body-email
				        ${isExpanded ? "centered-aligned-popup__body-email--expanded" : ""}`}
              >
                {children}
              </div>
              {!hideFooter && (
                <div
                  className={`d-flex align-items-center justify-content-between centered-aligned-popup-email__footer`}
                >
                  <div className="d-flex align-items-center cursor-pointer position-relative">
                    <AttachmentPopup
                      header={
                        <button className="d-flex align-items-center cursor-pointer attach-button">
                          <AttachmentSvgComponent color={"#333"} size="16" />
                          <p className="header_blue_text__14">
                            Attach{" "}
                            {getValue(documentList, `length`, 0) > 0
                              ? "( " +
                                getValue(documentList, `length`, 0) +
                                " )"
                              : ""}
                          </p>
                        </button>
                      }
                      documentList={documentList}
                      handleUploadDocuments={handleUploadDocuments}
                      uploadLoading={uploadLoading}
                      handleRemoveDocuments={handleRemoveDocuments}
                    />
                    <div className="email-checkbox">
                      <label className="checkbox-container">
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                        Track this email
                      </label>

                      <label className="checkbox-container ms-3">
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                        Add unsubscribe link
                      </label>
                    </div>
                  </div>
                  <div className="d-flex gap-3">
                    <button
                      className="attach-button"
                      onClick={handleSubmitDraft}
                    >
                      <p>Save as draft</p>
                    </button>
                    <div className="email-send-button">
                      <ButtonComponent
                        buttonText={isLoading ? "Sending..." : "Send"}
                        buttonType="primary"
                        onClickHandler={() => {
                          handleSubmitButtonClick();
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
