import { useEffect, useState } from "react";
import TextHeaderTooltip from "../Dropdown/TextHeaderTooltip";
import { getValue } from "@utils/lodash";
import {
  convertCurrentDateWithTime,
  convertDateTime,
  getDate1DayBefore,
  getDate1WeekBefore,
  getDateTime15MinutesBefore,
  getDateTime1HourBefore,
  getDateTime30MinutesBefore,
} from "@common/date-helpers";
import moment from "moment";
import SearchToolTip from "../Dropdown/SearchTooltip";
import { toast } from "sonner";
import TimePicker from "./DateTime";
import { formatString } from "@common/text-helpers";

function FutureAlarmPicker(props: any) {
  const [data, setData] = useState<any>([
    { label: `No ${getValue(props, `parent_field_label`, "").toLowerCase()}`, value: "" },
    // { label: "At task due time", value: "" },
    // { label: "15 minutes before", value: "" },
    // { label: "30 minutes before", value: "" },
    // { label: "1 hour before", value: "" },
    // { label: "1 day before", value: "" },
    // { label: "1 week before", value: "" },
  ]);

  useEffect(() => {
    if (props.dueDateTime) {
      setData(getDateFunctions(props.dueDateTime));
    }
  }, [props.dueDateTime]);
  const getDateFunctions = (date: any) => {
    const now = moment(props.dueDateTime); // Get the current date and time

    let array = [{ label: `None`, value: "" }];

    for (let i = 0.5; i <= 12; i += 0.5) {
      const label = `${i} hours`;
      const futureTime = now.clone().add(i, "hours");

      // Check if the future time is within the same day
      if (futureTime.isSame(now, "day")) {
        array.push({
          label: label,
          value: convertDateTime(futureTime.toDate()),
        });
      }
    }

    return array;
  };
  const [custom, setCustom] = useState(false);
  return (
    <div className="">
      {props.dueDateTime ? (
        <div className="mt-2 d-flex align-items-center">
          {custom ? (
            <TimePicker
              {...props}
              onChange={props.onChange}
              value={getValue(props, `value`, "")}
              placeholder={props.placeholder}
            />
          ) : (
            <div className="w-50">
              <SearchToolTip
                // width={"250px"}
                title={getValue(props, `label`, "")}
                value={getValue(props, `value`, "")}
                placeholder={
                  getValue(props, `value`, "")
                    ? props.placeholder
                    : `Enter Duration`
                }
                name={
                  getValue(props, `value`, "")
                    ? `${getValue(props, `label`, "")}${" - "}${" "}${
                        getValue(props, `value`, "")
                          ? convertCurrentDateWithTime(
                              getValue(props, `value`, "")
                            )
                          : ""
                      }`
                    : `Duration`
                }
                onChange={props.onChange}
                label={`label`}
                data={data}
                selectKey={"value"}
                editInline
                form
                disabled={!props.dueDateTime ? true : false}
                name_key={props.name}
                arrow={true}
              />
            </div>
          )}
          {/* <p onClick={() => setCustom(!custom)}>Custom</p> */}
        </div>
      ) : (
        <p
          className="d-flex align-items-center disabled cursor-pointer p-2"
          onClick={() =>
            toast.error(
              `Please select ${formatString(
                getValue(props, `parent_field`, "")
              )}`
            )
          }
        >
          <input type="checkbox" disabled className="common-checkbox" />
          <span className="ms-2">{getValue(props, `label`, "")}</span>
        </p>
      )}
    </div>
  );
}

export default FutureAlarmPicker;
