import HomeHeader from "@components/common/Header/HomeHeader/Header";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import React from "react";
// import CompanyProfileSubHeader from "@components/v2/Settings/CompanyProfile/CompanyProfileSubHeader/CompanyProfileSubHeader";

function SettingsLayout({ children, props }: any) {
  return (
    <MainLayout {...props}>
      {/* <CompanyProfileSubHeader /> */}
      <div className="flex-1">{children}</div>
    </MainLayout>
  );
}

export default SettingsLayout;
