import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import React from "react";

function ModulesEditInfo(props: any) {
  const { request, setRequest, simpleValidator } = props;
  return (
    <div className={`form_wrapper-pipeline`}>
      <h6 className="mb-3">Pipeline Info</h6>
      <InputRuleForm
        inputType="TEXT"
        label={"Pipeline Name"}
        placeholder="Enter Pipeline Name"
        name={"name"}
        value={getValue(request, `name`, "")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleTextChange(e, request, setRequest)
        }
        validator={simpleValidator}
        validLeft
        mandatory
      />
      <h6 className="mb-3 mt-3">
        How would you like to name the records in this pipeline
      </h6>
      <InputRuleForm
        inputType="TEXT"
        label={"Prefix Singular"}
        placeholder="Deal, Ticket, Request etc"
        name={"singular_label"}
        value={getValue(request, `singular_label`, "")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleTextChange(e, request, setRequest)
        }
        validator={simpleValidator}
        validLeft
        mandatory
      />
      <div className="mt-4"></div>
      <InputRuleForm
        inputType="TEXT"
        label={"Prefix Plural"}
        placeholder="Deals, Tickets, Requests etc"
        name={"plural_label"}
        value={getValue(request, `plural_label`, "")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleTextChange(e, request, setRequest)
        }
        validator={simpleValidator}
        validLeft
        mandatory
      />
    </div>
  );
}

export default ModulesEditInfo;
