import "../template.scss";
import { getValue } from "@utils/lodash";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import SubmitButtonContainer from "@components/common/ButtonContainer/SubmitButtonContainer";
import DraftJSEditor from "@components/custom/DraftJSEditor";
function TemplatePopup(props: any) {
  const {
    isOpen,
    handleModal,
    module,
    request,
    setRequest,
    editId,
    reset,
  } = props;
  return (
    <div>
      <SlideFromRightPopup
        isPopupVisible={isOpen}
        addCustomClass={"rolls-popup"}
        toggle={() => {
          handleModal(false);
          reset();
        }}
        title="Create Template"
        moduleName={module}
        width={"60%"}
      >
        <div className="m-2 mt-3">
          <div className={`d-flex align-items-center ${"template_container"}`}>
            <div className={`${"template_container_section__1"}`}>
              <p className="small_text__14 text-right">
                Template Name{" "}
                <span className="form-error small_text__14">*</span>
              </p>
            </div>
            <div className={`${"template_container_section__2"}`}>
              <input
                type="text"
                className={`${"template_input"} ms-2 w-90`}
                placeholder="Enter Template Name"
                name="name"
                value={getValue(request, `name`, "")}
                onChange={(e) =>
                  setRequest({
                    ...request,
                    name: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className={`d-flex align-items-center ${"template_container"}`}>
            <div className={`${"template_container_section__1"}`}>
              <p className="small_text__14 text-right">
                Subject <span className="form-error small_text__14">*</span>
              </p>
            </div>
            <div className={`${"template_container_section__2"}`}>
              <input
                type="text"
                className={`${"template_input"} ms-2 w-90`}
                placeholder="Enter Subject"
                name="subject"
                value={getValue(request, `subject`, "")}
                onChange={(e) =>
                  setRequest({
                    ...request,
                    subject: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="template_editor_container">
            <DraftJSEditor
              editorState={getValue(request, `content`, "")}
              handleChangeEditor={props.handleChangeEditor}
              name="content"
              placeholder="Enter Content here"
              editorStyle={{
                background: "white",
                paddingLeft: "30px",
                minHeight: "30vh",
              }}
            />
          </div>
        </div>
        <SubmitButtonContainer
          handleBackButton={() => {
            props.handleModal();
            reset();
          }}
          handleSubmit={() => {
            props.handleSubmit(getValue(props, `module`, ""));
          }}
          submitLoading={props.submitLoading}
          first_title={"Cancel"}
          second_title={editId ? "Update" : "Create"}
          hideNew
        />
      </SlideFromRightPopup>
    </div>
  );
}

export default TemplatePopup;
