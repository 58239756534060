import  "./CenteredAlignedPopup.scss";
import { useState } from "react";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";

export default function TaskPopup({
  handleSubmitButtonClick,
  handleCloseButtonClick,
  isPopupVisible,
  addCustomClass,
  popupProperties,
  children,
  isLoading,
  title,
  submit_text,
}: any) {
  const [isExpanded, setExpandedValue] = useState(false);

  return (
    <>
      <div
        className={`centered-aligned-popup_task ${
          isPopupVisible
            ? "centered-aligned-popup_task--active"
            : ""
        } ${addCustomClass ? addCustomClass : ""}
				  ${isExpanded ? "centered-aligned-popup__expanded" : ""}
				`}
      >
        <div
          className={`d-flex justify-content-between align-items-center centered-aligned-popup__header`}
        >
          <div className="d-flex align-items-center">
           
            {title}
          </div>
          <div className="d-flex align-items-center">
            
            <div
              className={`centered-aligned-popup__close cursor-pointer d-flex`}
              onClick={() => {
                handleCloseButtonClick(!isExpanded);
              }}
            >
              <img className="m-auto" src="/images/icons/close-white.svg" />
            </div>
          </div>
        </div>
        <div
          className={`centered-aligned-popup__body
				${isExpanded ? "centered-aligned-popup__body--expanded" : ""}`}
        >
          {children}
        </div>
        <div
          className={`d-flex align-items-center centered-aligned-popup__footer`}
        >
          <ButtonComponent
            buttonText={
              isLoading ? "Please wait..." : submit_text ? submit_text : "Send"
            }
            buttonType="primary"
            onClickHandler={() => {
              handleSubmitButtonClick();
            }}
          />
          {/* {popupProperties?.isMoreDetailsCTAVisible && (
						<>
							<label
								className={`d-flex align-items-center cursor-pointer ${ClassNames['centered-aligned-popup__details-cta']}`}
							>
								<CustomCheckbox />
								<div
									className={`d-flex align-items-center ${ClassNames['centered-aligned-popup__details-cta-text']}`}
								>
									Create a task to followup
								</div>
							</label>
						</>
					)} */}
        </div>
      </div>
    </>
  );
}
