import React, { useState } from "react";
import "./HoverComponent.scss";
import { getValue } from "@utils/lodash";
import { getFirstLetterOfWord } from "common/text-helpers";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";

const HoverComponent = (props: any) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getColorCode = (alphabet: any) => {
    const colorCodes: any = {
      A: "#D93025",
      B: "#FF8C00",
      C: "#FFDD00",
      D: "#008000",
      E: "#0064B0",
      F: "#6A0DAD",
      G: "#8C1D40",
      H: "#EC4C47",
      I: "#FBBC04",
      J: "#009587",
      K: "#00A8C1",
      L: "#0433FF",
      M: "#D500F9",
      N: "#DB4437",
      O: "#F4B400",
      P: "#0F9D58",
      Q: "#00A4FF",
      R: "#616161",
      S: "#9E9E9E",
      T: "#8D6E63",
      U: "#795548",
      V: "#BDBDBD",
      W: "#607D8B",
      X: "#FF8A65",
      Y: "#FF80AB",
      Z: "#FF4081",
    };
    // Convert input to uppercase
    alphabet = alphabet.toUpperCase();
    // Check if the alphabet exists in the dictionary
    if (alphabet in colorCodes) {
      return colorCodes[alphabet];
    } else {
      return null; // Return null if no color code is found for the input
    }
  };

  function getTextColor(backgroundColor: any) {
    // Convert hexadecimal color code to RGB values
    const hexToRGB = (hex: any) => {
      const bigint = hex && parseInt(hex.slice(1), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return [r, g, b];
    };

    // Calculate relative luminance of the color
    const getLuminance = (rgb: any) => {
      const [r, g, b] = rgb.map((c: any) => {
        const s = c / 255;
        return s <= 0.03928 ? s / 12.92 : Math.pow((s + 0.055) / 1.055, 2.4);
      });
      return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    };

    const rgb = hexToRGB(backgroundColor);
    const luminance = getLuminance(rgb);

    // Check if the luminance is below a threshold (0.5)
    return luminance < 0.5 ? "#ffffff" : "#000000";
  }

  return (
    <div
      className={
        props.checked
          ? `hover-component-checked`
          : `hover-component ${isHovered ? "hovered" : ""}`
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="checkbox">
        <CustomCheckbox checked={props.checked} onChange={props.onChange} />
      </div>

      <div
        className="image-container"
        style={{
          background: getColorCode(
            getFirstLetterOfWord(getValue(props, `title`, ""))
          ),
          color: getTextColor(
            getColorCode(getFirstLetterOfWord(getValue(props, `title`, "")))
          ),
        }}
      >
        <p className="header-text">{getValue(props, `title`, "")}</p>
      </div>
    </div>
  );
};

export default HoverComponent;
