import { EditOutlined } from "@ant-design/icons";
import GlobeSvgComponent from "@assets/svg/globe";
import WhatsappSvgComponent from "@assets/svg/whastapp";
import ImportCheckbox from "@components/BulkImport/components/import-checkbox";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Button } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const BotBuilderStepOne = (props: any) => {
  const { request, setRequest } = props;
  const params = useParams();
  const navigate = useNavigate();
  const platformList = [
    {
      label: "Web",
      value: "WEB",
    },
    {
      label: "Whatsapp",
      value: "WHATSAPP",
    },
  ];

  const handleCheckboxChange = (value: string) => {
    if (!getValue(props, "edit", false)) {
      setRequest({
        ...request,
        platform: value,
      });

      navigate(
        `/${getValue(
          params,
          "orgId",
          ""
        )}/chatbot/builder/${value.toLowerCase()}`
      );
    } else if (getValue(request, "platform", "") !== value) {
      toast.error("Platform update not allowed");
    }
  };

  const [showDescription, setShowDescription] = useState(true);

  return (
    <div>
      <div className="">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, `name`, "")}
          placeholder="Enter a name (Ex: Landing page bot )"
          required
          label="Name Your Bot"
          className="mb-2"
          onChange={(e: any) =>
            setRequest({
              ...request,
              name: e.target.value,
            })
          }
        />
      </div>
      <div className="mt-3">
        {showDescription ? (
          <div className="d-flex gap-3 mt-3">
            {getValue(request, `description`, "") ? (
              getValue(request, `description`, "")
            ) : (
              <span
                className="header_blue_text__14 cursor-pointer"
                onClick={() => setShowDescription(false)}
              >
                Add description
              </span>
            )}
            <EditOutlined onClick={() => setShowDescription(false)} />
          </div>
        ) : (
          <>
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="Enter a description (Ex: Landing page bot to capture leads from google ads)"
              label="Describe Your Bot"
              // required
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  description: e.target.value,
                })
              }
            />
            <Button onClick={() => setShowDescription(true)}>Cancel</Button>
          </>
        )}
      </div>

      {/* <h6 className="header_text__16 mt-4 ">Platform</h6>
      <p className="small_text__14">
        Build a compelling chatbot with our in-house bot-building platforms to
        suit your business needs.
      </p> */}
      {/* <div className="d-flex flex-wrap mt-2">
        {platformList.map((item: object) => {
          return (
            <div
              className="platform_container mx-2 cursor-pointer d-flex align-items-center gap-1"
              style={{
                border:
                  getValue(request, `platform`, "") ===
                  getValue(item, `value`, "")
                    ? "1px solid #1a73e8"
                    : "",
              }}
              onClick={() => {
                !getValue(props, `edit`, false)
                  ? setRequest({
                      ...request,
                      platform: getValue(item, `value`, ""),
                    })
                  : getValue(request, `platform`, "") !==
                      getValue(item, `value`, "") &&
                    toast.error("Platform update not allowed");
              }}
            >
              {getValue(item, `label`, "") === "Web" ? (
                <GlobeSvgComponent />
              ) : (
                <WhatsappSvgComponent />
              )}
              <h6 className="header_text__14">{getValue(item, `label`, "")}</h6>
            </div>
          );
        })}
      </div> */}
      {/* <div className="d-flex gap-3 mt-2">
        {platformList.map((item: object, index: any) => (
          <ImportCheckbox
            key={index}
            value={getValue(item, "value", "")}
            label={getValue(item, "label", "")}
            svgComponent={
              getValue(item, "label", "") === "Web" ? (
                <GlobeSvgComponent />
              ) : (
                <WhatsappSvgComponent />
              )
            }
            onChange={handleCheckboxChange}
            selectedOption={getValue(request, `platform`, "")}
            checked={
              getValue(request, `platform`, "") === getValue(item, "value", "")
            }
            groupName="platform"
          />
        ))}
      </div> */}
      {/* <div className="mt-3">
        <p className="form-label">Platform</p>
        <InputRuleForm
          inputType="TEXT"
          name="platform"
          value={getValue(request, `platform`, "")}
          placeholder="Enter a Platform"
          required
          onChange={(e: any) =>
            setRequest({
              ...request,
              platform: e.target.value,
            })
          }
        />
      </div> */}
      {/* <div className="mt-4">
        <p className="header_text__16">Choose a platform</p>
        <button className="main_button__primary mt-3">
          Sales IQ Platforms
        </button>
        <p className="pending-task-commondescription mt-3">
          Build a compelling chatbot with our in-house bot-building platforms to
          suit your business needs.
        </p>
        <ImportCheckbox
          value="Codeless"
          label="Codeless bot builder"
          description="A no coding bot builder platform. Just drag and drop and build a powerful bot to assist your website visitors."
          iconPath={"/images/icons/code-64.png"}
          selectedOption={selectedOption}
          // onChange={handleChange}
        />
      </div> */}
    </div>
  );
};

export default BotBuilderStepOne;
