import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register the necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

function DonutChartComponent(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const graphData = getValue(props, "graph", []);

    if (graphData.length > 0) {
      const colorPalette = [
        "#AFDC8F",
        "#63993D",
        "#3D7317",
        "#87BB62",
        "#3D7317",
      ];

      const dynamicColors = graphData.map(
        (_: any, index: any) => colorPalette[index % colorPalette.length]
      );

      const labels = graphData.map((item: any) =>
        typeof getValue(item, "key", "") === "object"
          ? `${getValue(item, "key.first_name", "")} ${getValue(
              item,
              "key.last_name",
              ""
            )}`
          : getValue(item, "key", "")
      );
      const dataValues = graphData.map((item: any) =>
        getValue(item, "count", 0)
      );
      const label = getValue(props, "module_field.label", "");

      setData({
        labels: labels,
        datasets: [
          {
            label: label,
            data: dataValues,
            backgroundColor: dynamicColors,
            borderColor: dynamicColors.map((color: any) =>
              color.replace("0.5", "1")
            ),
            borderWidth: 1,
          },
        ],
      });
    }
  }, [props.graph, props.module_field.label]);

  const options = {
    responsive: true,
    cutout: "50%",
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          color: "#000",
        },
      },
    },
  };

  return (
    <div className="dashboard_border">
      <p className="header_text__16 pb-3">{getValue(props, "name", "")}</p>
      <Doughnut data={data} options={options} className="pb-4 pe-3" />
    </div>
  );
}

export default DonutChartComponent;
