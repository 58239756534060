import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllWorkflows = (queryRequest: string) =>
  get(`${config.API_URL}/workflow?${queryRequest}`);

export const getSpecificWorkflow = (id: string) =>
  get(`${config.API_URL}/workflow/${id}`);

export const createWorkflow = (payload: object) =>
  post(`${config.API_URL}/workflow/manage`, payload);

export const getAllWorkflowConditionFields = (queryRequest: string) =>
  get(`${config.API_URL}/workflow/condition-fields?${queryRequest}`);

  export const changeWorkflowStatus = (payload: object) =>
  post(`${config.API_URL}/workflow/status`, payload);


  export const getAvailableEmails = () =>
  // get(`${config.API_URL}/workflow/actions/email/available-list`);
  get(`${config.API_URL}/inbox-manager/inbox/email/available-list`);

