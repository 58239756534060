import Loader from "@components/common/Loader/loading";
import { getPublicGlobalFormInfo } from "@services/web-forms.service";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

function SettingsPreviewFormThankyou(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const params = useParams();
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = useState(false);
  const [fieldInfo, setFieldInfo] = useState({});
  const getData = async () => {
    let slug = getValue(params, `slug`, "")
      ? getValue(params, `slug`, "").trim(" ")
      : "";
    if (slug) {
      try {
        setIsLoading(true);
        let resp = await getPublicGlobalFormInfo(
          getValue(params, `orgId`, ""),
          slug
        );
        if (resp) {
          setFieldInfo(getValue(resp, `data`, {}));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      toast.error("Someting went wrong, Please try after sometime");
    }
  };
  return (
    <div
      className="w-100 d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className="">
          <p className="small_text__16">
            {getValue(fieldInfo, `thank_you_message`, "")}
          </p>
        </div>
      )}
    </div>
  );
}

export default SettingsPreviewFormThankyou;
