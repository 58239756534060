import * as React from "react";
import { getValue } from "@utils/lodash";
import "./switch.scss";

const SwitchMain = (props: any) => {
  return (
    <label className={"switch"}>
      <input
        type="checkbox"
        checked={getValue(props, `checked`, false)}
        onChange={props.onChange}
      />
      <span className={`${"slider"} ${`round`} d-flex`}></span>
    </label>
  );
};

export default SwitchMain;
