import ProgressBarLoader from "@components/common/Loader/progress-loader";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import React from "react";
import { Link } from "react-router-dom";

function ImportStep3(props: any) {
  const { handleUploadDocuments, isLoading } = props;
  return (
    <div className="contact-type w-100">
      <h3>Upload your file</h3>
      <div className="w-50 mt-4">
        <DragAndDropFileComponent
          isLoading={isLoading}
          uploadFile={handleUploadDocuments}
        />
      </div>
    </div>
  );
}

export default ImportStep3;
