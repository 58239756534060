export default function CompanyList() {
  const CompanyImagesArray = [
    {
      image: "logo_1",
    },
    {
      image: "logo_2",
    },
    {
      image: "logo_3",
    },
    {
      image: "logo_4",
    },
    {
      image: "logo_5",
    },
    {
      image: "logo_6",
    },
  ];
  return (
    <div className={"try-superteam-wrapper__company-images"}>
      {CompanyImagesArray.map((imageObj, index) => {
        return (
          <img
            src={`/images/superteam/${imageObj.image}.png`}
            className="img-fluid"
            key={index}
          />
        );
      })}
    </div>
  );
}
