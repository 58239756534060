import MainLayout from "@layouts/HomeLayout/NewLayout";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";

const CannedResponseDetails = ({ props }: any) => {
  return (
    <MainLayout {...props}>
      <CompanyProfileSubHeader
      // isLoading={submitLoading}
      // handleSubmit={handleSubmit}
      // showSubmit
      />
      <CompanyProfileDetailsLayout classname="my-0">
        {/* {isLoading ? (
  <ListLoader />
) : (
  <CompanyProfileForm
    request={request}
    setRequest={setRequest}
    simpleValidator={simpleValidator}
  />
)} */}
        <div className="row">
          <div className="col-10">
            <h6 className="header_text__18">Edit Canned Response</h6>
          </div>
          <div className="col-12">
            <h6 className="header_text__16">Canned Responses</h6>
          </div>
        </div>
      </CompanyProfileDetailsLayout>
    </MainLayout>
  );
};

export default CannedResponseDetails;
